import useStrings from '../../../strings';
import useServices from '../../services';

const useAuthActions = () => {
  const { useAuthServices } = useServices();
  const {
    registerUser,
    loginUser,
    emailVerificationService,
    logoutUser,
    refreshTokenService,
  } = useAuthServices();

  const { useTypes } = useStrings();
  const { useAuthTypes } = useTypes();
  const {
    LOGIN,
    EMAIL_VERIFICATION,
    LOG_OUT,
    REFRESH_TOKEN,
    TOGGLE_REFRESH_TOKEN,
    REFRESH_TOKEN_CONFIRMATION,
    LEAVE_BROADCASTING,
  } = useAuthTypes();

  const actRegisterUser = (data, onSuccess, onError) => async (dispatch) => {
    try {
      const user = await registerUser(data);
      dispatch({
        type: LOGIN,
        payload: user.data,
      });
      onSuccess && onSuccess(user);
    } catch (e) {
      onError && onError(e);
    }
  };

  const actLogin = (data, callback, onError) => async (dispatch) => {
    try {
      const user = await loginUser(data);
      dispatch({
        type: LOGIN,
        payload: user.data,
      });
      if (callback) {
        callback(user.data);
      }
    } catch (e) {
      onError && onError(e);
    }
  };

  const actEmailVerification =
    ({ signature, expires, id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await emailVerificationService({
          signature,
          expires,
          id,
        });
        dispatch({
          type: EMAIL_VERIFICATION,
          payload: response.data.data,
        });
        onSuccess && onSuccess(response.data.data);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actLogout = (onSuccess, onError) => async (dispatch) => {
    try {
      const response = await logoutUser();
      dispatch({ type: LOG_OUT });
      onSuccess && onSuccess(response.data);
    } catch (e) {
      onError && onError(e);
    }
  };

  const actRefreshToken =
    (lastRefreshToken, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await refreshTokenService(lastRefreshToken);
        dispatch({
          type: REFRESH_TOKEN,
          payload: response.data,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actToggleRefreshToken =
    (state, onSuccess, onError) => async (dispatch) => {
      try {
        dispatch({
          type: TOGGLE_REFRESH_TOKEN,
          payload: state,
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };

  const actRefreshTokenConfirmation =
    (onSuccess, onError) => async (dispatch) => {
      try {
        dispatch({
          type: REFRESH_TOKEN_CONFIRMATION,
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };

  const actLeaveBroadcasting = (onSuccess, onError) => async (dispatch) => {
    try {
      dispatch({
        type: LEAVE_BROADCASTING,
      });
      onSuccess && onSuccess;
    } catch (e) {
      onError && onError(e);
    }
  };

  return {
    actRegisterUser,
    actLogin,
    actLogout,
    actEmailVerification,
    actLeaveBroadcasting,
    actRefreshToken,
    actToggleRefreshToken,
    actRefreshTokenConfirmation,
  };
};

export default useAuthActions;
