import React, { useEffect } from 'react';
import useModels from '../../../models';

// eslint-disable-next-line no-unused-vars
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import useGeneralHooks from '../../generalHooks';
import useViews from '../../../views';
import useApi from '../../../api';

const useRouting = () => {
  const { useComponents } = useViews();
  const { Toast } = useComponents();

  //Api
  const { useActions } = useApi();
  const {
    dispatch,
    useAuthActions,
    useUserActions,
    useNotificationsActions,
    useApplicationsTableActions,
  } = useActions();
  const { actLogout, actToggleRefreshToken } = useAuthActions();
  const { actLogoutUser } = useUserActions();
  const { actSetNewNotification } = useNotificationsActions();
  const { actGetApplicationsTable } = useApplicationsTableActions();

  // Helpers
  // Models
  const { useSelectors } = useModels();
  const { useSelector, useAuthSelectors, useUserSelectors } = useSelectors();
  const { session, broadcastingSelector } = useAuthSelectors();
  const { userSelector } = useUserSelectors();
  const { profile } = useSelector(userSelector);
  const { id } = profile;
  const { tokenType, accessToken, expiresAt, refreshToken } =
    useSelector(session);
  const { leaveBroadcasting } = useSelector(broadcastingSelector);
  // const { pathname } = useLocation();

  //Hooks
  const { useToast } = useGeneralHooks();
  const { info } = useToast();

  // React

  const handleOnAction = () => {
    dispatch(actToggleRefreshToken());
  };

  const handleOnActive = () => {
    // console.log({ noIdle: 'No longer idel' });
  };

  const handleOnIdle = () => {
    handleLogout();
  };

  const handleLogoutUserProfile = () => {
    dispatch(actLogoutUser());
  };

  const handleLogout = () => {
    dispatch(actLogout(handleLogoutUserProfile()));
    dispatch(actSetNewNotification(false));
  };
  const echo = new Echo({
    broadcaster: 'pusher',
    key: `${process.env.REACT_APP_WS_KEY}`,
    cluster: 'mt1',
    wsHost: `${process.env.REACT_APP_WS_HOST}`,
    wsPort: `${process.env.REACT_APP_WS_PORT}`,
    authEndpoint: `${process.env.REACT_APP_WS_AUTH_ENDPOINT}`,
    encrypted: false,
    disableStats: true,
    auth: {
      headers: {
        Authorization: `${tokenType} ${accessToken}`,
        Accept: 'application/json',
      },
    },
  });

  const handleSubscribePrivate = () => {
    echo.private(`App.Models.User.${id}`).notification((notification) => {
      if (id) {
        dispatch(actSetNewNotification(true));
        setTimeout(
          () =>
            dispatch(
              actGetApplicationsTable({
                page: 1,
              })
            ),
          1000
        );
        info(
          <Toast text={notification.title} listOfErrors={[]} state={'error'} />,
          {
            position: 'top-right',
            autoClose: true,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            toastId: Math.random(),
          }
        );
      }
    });
  };

  const handleUnsubscribePrivate = () => {
    echo.leave(`App.Models.User.${id}`);
    echo.leaveChannel(`private-App.Models.User.${id}`);
    handleLogout();
  };

  useEffect(() => {
    accessToken &&
      id &&
      !leaveBroadcasting &&
      echo.options?.auth?.headers?.Authorization !== ' ' &&
      handleSubscribePrivate();

    accessToken && id && leaveBroadcasting && handleUnsubscribePrivate();
  }, [accessToken, id, leaveBroadcasting]);

  return {
    accessToken,
    expiresAt,
    refreshToken,
    handleOnAction,
    handleOnActive,
    handleOnIdle,
  };
};

export default useRouting;
