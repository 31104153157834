import React, { Suspense } from 'react';
import useComponents from '../../components';
import useLayouts from '../../layouts';
import { useForm } from 'react-hook-form';
import stamp from '../../../assets/images/stamp.png';

import {
  StyledSectionApplication,
  StyledButtonDownloadRenewal,
  StyledForDownloadDisabled,
  StyledContainerIconDownloadTop,
  StyledTitleSection,
  StyledFieldsContainer,
  StyledFieldColumn,
  StyledFieldColumnInputField,
  StyledFieldColumnTitle,
  StyledFieldColumnValue,
  StyledContainerAdressPremises,
  StyledFieldsContainerMiddle,
  // StyledTextAreaControlled,
  StyledTextFieldControlled,
  StyledDocumentsReportAccordion,
  StyledTitleDocumentReportContainerAccordion,
  StyledTitleReportAccordion,
  StyledContainerIconView,
  StyledContainerIconDownload,
  StyledContainerDownload,
  StyledFieldRowBottomContainer,
  StyledRowButtons,
  StyledButtonSaveAndContinue,
  StyledButtonContinue,
  StyledButtonComment,
  StyledButtonApprove,
  StyledTitleModal,
  StyledSubtitleModal,
  StyledChildContainerModal,
  StyledTextAreaField,
  // StyledSwitchField,
  StyledButtonModal,
  StyledContainerStamp,
  StyledContainerStampMobile,
  StyledStamp,
  StyledCommentsContainer,
  StyledCommentsInnerContainer,
  StyledCommentTitle,
  StyledCommentDescription,
  StyledTypographyTooltipContainer,
  StyledTooltipField,
  StyledTypographyTooltip,
  StyledTypographyTooltipBold,
  StyledCommentsRejectedContainer,
  StyledCommentsMobileContainer,
  StyledCommentsMobileIconContainer,
  StyledCommentsMobile,
  StyledContainerInputFile,
  StyledButtonDownloadRenewalMobile,
} from './RenewalNotice.styles';
import useControllers from '../../../controllers';
import useAssets from '../../../assets';
import useHelpers from '../../../helpers';
import { yupResolver } from '@hookform/resolvers/yup';

const RenewalNotice = () => {
  const {
    Modal,
    ActivityIndicator,
    Wrapper,
    InputFileFieldControlled,
    Typography,
  } = useComponents();
  const { useIcons } = useAssets();
  const {
    iconOpenedEye: IconOpenedEye,
    iconDownload: IconDownload,
    iconWarning: IconWarning,
  } = useIcons();
  const { PrivateContentLayout } = useLayouts();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, usePromises } = useQuickFunctions();
  const { renewalNoticeValidator, commentsValidator, renewalApproveValidator } =
    useValidators();
  const { promiseInProgress } = usePromises();

  // Hooks
  const { useScreenHooks } = useControllers();
  const { useRenewalNotice } = useScreenHooks();

  // Yup validator RenewalForm
  const {
    control,
    resetField,
    getValues,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(renewalNoticeValidator),
  });
  const {
    applicationRenewal,
    goBack,
    fileTypesPdf,
    onChangeFilePdf,
    onSubmitRenewal,
    onSubmitSaveRenewal,
    handleFileNameExtension,
    handleYear,
    handleDate,
    handleLicenseType,
    helperModalStateSuccess,
    helperModalSuccessState,
    handleCloseHelperModalSuccess,
    handleGetPrivateDocumentView,
    profile,
    onSubmitApprove,
    onSubmitComments,
    modalStateComments,
    handleShowModalComments,
    // sendCommentsSuccessState,
    handleCloseModalComments,
    handleRemoveProofOfPayment,
    base64RenewalPaymentState,
    handleUserRoleType,
    downloadRenewalNoticePaymentRef,
    handleDownloadRenewalPayment,
    handleSaveRenewalBeforeDownloadOfPayment,
    handleStatusApplicationCopysMobile,
  } = useRenewalNotice(resetField);

  // Yup validator Renewal Review
  const {
    control: controlReview,
    handleSubmit: handleSubmitReview,
    formState: { errors: errorsReview, isValid: isValidReview },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(renewalApproveValidator),
  });

  // Yup validator comments

  const {
    control: controlComments,
    handleSubmit: handleSubmitComment,
    formState: { errors: errorsComments, isValid: isValidComments },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(commentsValidator),
  });
  return (
    <PrivateContentLayout background="tertiary">
      {promiseInProgress && <ActivityIndicator />}
      <Suspense fallback={<ActivityIndicator />}>
        <StyledSectionApplication>
          <Wrapper
            title={'Renewal notice'}
            subTitle={
              'Advice of annual fees payable in respect of a liquor license for the year'
            }
            description={'See the information!  Everything must be correct.'}
            maxWidth={'892px'}
            withBackButton={true}
            onClickBackButton={goBack}
          >
            {profile.roleType.id === 4 &&
              applicationRenewal.renewalDocument?.data &&
              applicationRenewal.statusId !== 9 && (
                <StyledButtonDownloadRenewal
                  fullWidth={false}
                  color="secondary"
                >
                  <StyledContainerIconDownloadTop>
                    <IconDownload color={'#FFFFFF'} />
                  </StyledContainerIconDownloadTop>
                  <StyledContainerDownload
                    download={'RenewalDocument'}
                    href={applicationRenewal.renewalDocument.data}
                    title="Download Renewal document"
                  >
                    Download PDF
                  </StyledContainerDownload>
                </StyledButtonDownloadRenewal>
              )}

            {profile.roleType.id === 4 &&
              (applicationRenewal.statusId === 1 ||
                applicationRenewal.statusId === 7) && (
                <StyledButtonDownloadRenewal
                  fullWidth={false}
                  color="secondary"
                  onClick={() => {
                    isDirty
                      ? handleSaveRenewalBeforeDownloadOfPayment(getValues())
                      : handleDownloadRenewalPayment();
                  }}
                >
                  <StyledContainerIconDownloadTop>
                    <IconDownload color={'#FFFFFF'} />
                  </StyledContainerIconDownloadTop>
                  <StyledForDownloadDisabled
                    ref={downloadRenewalNoticePaymentRef}
                    download={'RenewalNotice'}
                    href={base64RenewalPaymentState}
                    title="Download Renewal notice document"
                  />
                  <StyledContainerDownload>
                    Download PDF
                  </StyledContainerDownload>
                </StyledButtonDownloadRenewal>
              )}

            {applicationRenewal.licensingMemberComment?.comment &&
              applicationRenewal.statusId === 7 && (
                <StyledCommentsContainer>
                  <StyledCommentsInnerContainer>
                    <StyledCommentTitle>
                      <IconWarning size={1.5} />
                      {`Application for changes required by ${
                        applicationRenewal.licensingMemberComment.commentMadeBy
                          ?.name
                      } ${
                        applicationRenewal.licensingMemberComment.commentMadeBy
                          ?.surname
                      } - ${
                        applicationRenewal.licensingMemberComment.commentMadeBy
                          ?.roleId &&
                        handleUserRoleType(
                          applicationRenewal.licensingMemberComment
                            .commentMadeBy.roleId
                        )
                      }`}
                    </StyledCommentTitle>
                    <StyledCommentDescription>
                      {applicationRenewal.licensingMemberComment.comment}
                    </StyledCommentDescription>
                  </StyledCommentsInnerContainer>
                </StyledCommentsContainer>
              )}
            <StyledTitleSection variant="h3">
              Northern Cape liquor ACT, 2008
            </StyledTitleSection>
            <StyledFieldsContainer>
              <StyledFieldColumn>
                <StyledFieldColumnTitle>Year</StyledFieldColumnTitle>

                <StyledFieldColumnValue>
                  {applicationRenewal.year &&
                    handleYear(applicationRenewal.year)}
                </StyledFieldColumnValue>
              </StyledFieldColumn>
              <StyledFieldColumn>
                <StyledFieldColumnTitle>ID User</StyledFieldColumnTitle>

                <StyledFieldColumnValue>
                  {applicationRenewal.idUser && applicationRenewal.idUser}
                </StyledFieldColumnValue>
              </StyledFieldColumn>
              <StyledFieldColumn>
                <StyledFieldColumnTitle>Reference No.</StyledFieldColumnTitle>

                <StyledFieldColumnValue>
                  {applicationRenewal.licenseNumber
                    ? applicationRenewal.licenseNumber
                    : applicationRenewal.reference}
                </StyledFieldColumnValue>
              </StyledFieldColumn>
            </StyledFieldsContainer>
            <StyledFieldsContainer>
              <StyledFieldColumn>
                <StyledFieldColumnTitle>Address</StyledFieldColumnTitle>
                {applicationRenewal.statusId !== 2 &&
                applicationRenewal.statusId !== 8 &&
                profile.roleType.id === 4 ? (
                  <StyledFieldColumnValue>
                    {applicationRenewal.address && applicationRenewal.address}
                  </StyledFieldColumnValue>
                ) : (
                  <StyledFieldColumnValue>
                    {applicationRenewal.newAddressIfChanged
                      ? applicationRenewal.newAddressIfChanged
                      : applicationRenewal.address}
                  </StyledFieldColumnValue>
                )}
              </StyledFieldColumn>
            </StyledFieldsContainer>
            {applicationRenewal.statusId !== 2 &&
              applicationRenewal.statusId !== 8 &&
              applicationRenewal.statusId !== 9 &&
              profile.roleType.id === 4 && (
                <StyledFieldColumnInputField>
                  <StyledTextFieldControlled
                    inputValue={
                      applicationRenewal.newAddressIfChanged &&
                      applicationRenewal.newAddressIfChanged
                    }
                    id={'newAddressIfChanged'}
                    placeholder={'New Address'}
                    label={
                      'If the postal address has changed, please indicate the new address'
                    }
                    name={'newAddressIfChanged'}
                    variant={'outlined'}
                    fullWidth={true}
                    control={control}
                    error={!!errors.newAddress}
                    helperText={errors?.newAddress?.message}
                  />
                </StyledFieldColumnInputField>
              )}
            {applicationRenewal.statusId === 8 && (
              <StyledContainerStamp>
                <StyledStamp src={stamp} />
              </StyledContainerStamp>
            )}
            <StyledTitleSection variant="h3">
              Licensed premises
            </StyledTitleSection>
            {applicationRenewal.statusId !== 2 &&
            applicationRenewal.statusId !== 8 &&
            applicationRenewal.statusId !== 9 &&
            profile.roleType.id === 4 ? (
              <StyledFieldColumn>
                <StyledTextFieldControlled
                  inputValue={
                    applicationRenewal.nameLicensedPremises &&
                    applicationRenewal.nameLicensedPremises
                  }
                  id={'nameLicensedPremises'}
                  placeholder={'Name of licensed premises'}
                  label={'Name of licensed premises'}
                  name={'nameLicensedPremises'}
                  variant={'outlined'}
                  fullWidth={true}
                  control={control}
                  error={!!errors.nameLicensedPremises}
                  helperText={errors?.nameLicensedPremises?.message}
                />
              </StyledFieldColumn>
            ) : (
              <StyledFieldsContainerMiddle>
                <StyledFieldColumn>
                  <StyledContainerAdressPremises>
                    <StyledFieldColumnTitle>
                      Name of licensed premises
                    </StyledFieldColumnTitle>
                    <StyledFieldColumnValue>
                      {applicationRenewal.nameLicensedPremises
                        ? applicationRenewal.nameLicensedPremises
                        : 'None'}
                    </StyledFieldColumnValue>
                  </StyledContainerAdressPremises>
                </StyledFieldColumn>
              </StyledFieldsContainerMiddle>
            )}

            {applicationRenewal.statusId !== 2 &&
            applicationRenewal.statusId !== 8 &&
            applicationRenewal.statusId !== 9 &&
            profile.roleType.id === 4 ? (
              <StyledFieldColumn>
                <StyledTextFieldControlled
                  inputValue={
                    applicationRenewal.addressLicensedPremises &&
                    applicationRenewal.addressLicensedPremises
                  }
                  id={'addressLicensedPremises'}
                  placeholder={'Address of licensed premises'}
                  label={'Address of licensed premises'}
                  name={'addressLicensedPremises'}
                  variant={'outlined'}
                  fullWidth={true}
                  control={control}
                  error={!!errors.addressLicensedPremises}
                  helperText={errors?.addressLicensedPremises?.message}
                />
              </StyledFieldColumn>
            ) : (
              <StyledFieldsContainerMiddle>
                <StyledFieldColumn>
                  <StyledContainerAdressPremises>
                    <StyledFieldColumnTitle>
                      Address of licensed premises
                    </StyledFieldColumnTitle>
                    <StyledFieldColumnValue>
                      {applicationRenewal.addressLicensedPremises
                        ? applicationRenewal.addressLicensedPremises
                        : 'None'}
                    </StyledFieldColumnValue>
                  </StyledContainerAdressPremises>
                </StyledFieldColumn>
              </StyledFieldsContainerMiddle>
            )}
            <StyledFieldsContainerMiddle>
              <StyledFieldColumn>
                <StyledFieldColumnTitle>Kind of license</StyledFieldColumnTitle>

                <StyledFieldColumnValue>
                  {applicationRenewal.licenceTypeId &&
                    handleLicenseType(applicationRenewal.licenceTypeId)}
                </StyledFieldColumnValue>
              </StyledFieldColumn>
            </StyledFieldsContainerMiddle>
            <StyledFieldsContainerMiddle>
              <StyledFieldColumn>
                <StyledFieldColumnTitle>Fees payable</StyledFieldColumnTitle>

                <StyledFieldColumnValue>
                  {`R ${
                    applicationRenewal.feesPayable
                      ? applicationRenewal.feesPayable
                      : 'None'
                  }`}
                </StyledFieldColumnValue>
              </StyledFieldColumn>
              <StyledFieldColumn>
                <StyledFieldColumnTitle>
                  Payable on or before
                </StyledFieldColumnTitle>

                <StyledFieldColumnValue>
                  {applicationRenewal.payableOnBefore
                    ? handleDate(applicationRenewal.payableOnBefore)
                    : 'None'}
                </StyledFieldColumnValue>
              </StyledFieldColumn>
              <StyledFieldColumn>
                <StyledFieldColumnTitle>Date of issue</StyledFieldColumnTitle>

                <StyledFieldColumnValue>
                  {applicationRenewal.dateOfIssue
                    ? handleDate(applicationRenewal.dateOfIssue)
                    : 'None'}
                </StyledFieldColumnValue>
              </StyledFieldColumn>
              <StyledFieldColumn>
                <StyledFieldColumnTitle>Place</StyledFieldColumnTitle>

                <StyledFieldColumnValue>
                  {applicationRenewal.place ? applicationRenewal.place : 'None'}
                </StyledFieldColumnValue>
              </StyledFieldColumn>
              {applicationRenewal.statusId !== 2 &&
                applicationRenewal.statusId !== 8 &&
                applicationRenewal.statusId !== 9 &&
                profile.roleType.id === 4 && (
                  <StyledFieldColumn>
                    <StyledContainerInputFile>
                      <InputFileFieldControlled
                        id={'proofOfPayment'}
                        inputValue={handleFileNameExtension(
                          applicationRenewal.proofOfPayment
                        )}
                        label={
                          <>
                            Proof of Payment
                            <StyledTooltipField
                              title={
                                <>
                                  <StyledTypographyTooltipBold>
                                    Note:
                                  </StyledTypographyTooltipBold>
                                  <StyledTypographyTooltipContainer>
                                    <StyledTypographyTooltipBold>
                                      {'(I)'}
                                    </StyledTypographyTooltipBold>
                                    <StyledTypographyTooltip>
                                      The Board shall not accept payment of the
                                      above-mentioned fees unless this advice is
                                      produced.
                                    </StyledTypographyTooltip>
                                  </StyledTypographyTooltipContainer>
                                  <StyledTypographyTooltipContainer>
                                    <StyledTypographyTooltipBold>
                                      {'(II)'}
                                    </StyledTypographyTooltipBold>
                                    <StyledTypographyTooltip>
                                      If the fees are received after 31
                                      December, the fees payable shall be
                                      increased by 50% and if the fees are
                                      received after 31 January, such fees shall
                                      be increased by 100%. No fees are to be
                                      received after 28 February.
                                    </StyledTypographyTooltip>
                                  </StyledTypographyTooltipContainer>
                                </>
                              }
                              size={1.3333}
                            />
                          </>
                        }
                        name={'proofOfPayment'}
                        variant={'outlined'}
                        fullWidth={true}
                        control={control}
                        onInputChange={onChangeFilePdf}
                        // onSaveDraftById={onSaveDraftDocumentById}
                        fileTypes={fileTypesPdf}
                        resetField={handleRemoveProofOfPayment}
                        error={!!errors.proofOfPayment}
                        helperText={errors?.proofOfPayment?.message}
                      />
                    </StyledContainerInputFile>
                  </StyledFieldColumn>
                )}
            </StyledFieldsContainerMiddle>
            {(applicationRenewal.statusId === 2 ||
              applicationRenewal.statusId === 7 ||
              applicationRenewal.statusId === 8 ||
              applicationRenewal.statusId === 9) && (
              <StyledDocumentsReportAccordion
                disabled={true}
                disabledIcon
                title={
                  <StyledTitleDocumentReportContainerAccordion>
                    <StyledTitleReportAccordion variant="body1">
                      Proof of Payment
                    </StyledTitleReportAccordion>
                    <StyledContainerIconView
                      onClick={() =>
                        handleGetPrivateDocumentView(
                          applicationRenewal.proofOfPayment.name
                        )
                      }
                    >
                      <IconOpenedEye size={1.5} />
                    </StyledContainerIconView>
                    <StyledContainerIconDownload
                      download={'Proof of Payment'}
                      href={applicationRenewal.proofOfPayment.data}
                      title="Download pdf document"
                    >
                      <IconDownload size={1} />
                    </StyledContainerIconDownload>
                  </StyledTitleDocumentReportContainerAccordion>
                }
              />
            )}

            {applicationRenewal.statusId === 8 && (
              <StyledFieldsContainer>
                <StyledFieldColumn>
                  <StyledFieldColumnTitle>
                    Amount received
                  </StyledFieldColumnTitle>

                  <StyledFieldColumnValue>
                    {applicationRenewal.amountReceived
                      ? `R ${applicationRenewal.amountReceived}`
                      : 'None'}
                  </StyledFieldColumnValue>
                </StyledFieldColumn>
                <StyledFieldColumn>
                  <StyledFieldColumnTitle>Receipt No.</StyledFieldColumnTitle>

                  <StyledFieldColumnValue>
                    {applicationRenewal.receiptNumber
                      ? applicationRenewal.receiptNumber
                      : 'None'}
                  </StyledFieldColumnValue>
                </StyledFieldColumn>
                <StyledFieldColumn>
                  <StyledFieldColumnTitle>
                    Office Date Stamp
                  </StyledFieldColumnTitle>

                  <StyledFieldColumnValue>
                    {applicationRenewal.officeDateStamp &&
                      handleDate(applicationRenewal.officeDateStamp)}
                  </StyledFieldColumnValue>
                </StyledFieldColumn>
              </StyledFieldsContainer>
            )}

            {applicationRenewal.statusId === 2 &&
              (profile.roleType.id === 2 || profile.roleType.id === 6) && (
                <StyledFieldsContainerMiddle>
                  <StyledFieldColumn>
                    <StyledTextFieldControlled
                      id={'amountReceived'}
                      placeholder={'R'}
                      label={'Amount received'}
                      name={'amountReceived'}
                      variant={'outlined'}
                      fullWidth={true}
                      control={controlReview}
                      error={!!errorsReview.amountReceived}
                      helperText={errorsReview?.amountReceived?.message}
                    />
                  </StyledFieldColumn>
                  <StyledFieldColumn>
                    <StyledTextFieldControlled
                      id={'receiptNumber'}
                      placeholder={'Receipt No.'}
                      label={'Receipt No.'}
                      name={'receiptNumber'}
                      variant={'outlined'}
                      fullWidth={true}
                      control={controlReview}
                      error={!!errorsReview.receiptNumber}
                      helperText={errorsReview?.receiptNumber?.message}
                    />
                  </StyledFieldColumn>
                </StyledFieldsContainerMiddle>
              )}
            {applicationRenewal.statusId === 8 && (
              <StyledContainerStampMobile>
                <StyledStamp src={stamp} />
              </StyledContainerStampMobile>
            )}
            {profile.roleType.id === 4 &&
              applicationRenewal.renewalDocument?.data &&
              applicationRenewal.statusId !== 9 && (
                <StyledButtonDownloadRenewalMobile
                  fullWidth={false}
                  color="secondary"
                >
                  <StyledContainerIconDownloadTop>
                    <IconDownload color={'#FFFFFF'} />
                  </StyledContainerIconDownloadTop>
                  <StyledContainerDownload
                    download={'RenewalDocument'}
                    href={applicationRenewal.renewalDocument.data}
                    title="Download Renewal document"
                  >
                    Download PDF
                  </StyledContainerDownload>
                </StyledButtonDownloadRenewalMobile>
              )}
            {applicationRenewal.statusId !== 2 &&
              applicationRenewal.statusId !== 8 &&
              applicationRenewal.statusId !== 9 &&
              profile.roleType.id === 4 && (
                <StyledFieldRowBottomContainer>
                  <Typography
                    color="secondary"
                    variant="body2"
                    sx={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '-0.006em',
                      cursor: 'pointer',
                    }}
                    onClick={goBack}
                  >
                    Cancel
                  </Typography>
                  <StyledRowButtons>
                    {applicationRenewal.statusId !== 7 && (
                      <StyledButtonSaveAndContinue
                        fullWidth={false}
                        variant={'outlined'}
                        color="secondary"
                        onClick={() => onSubmitSaveRenewal(getValues())}
                      >
                        Save and continue later
                      </StyledButtonSaveAndContinue>
                    )}
                    <StyledButtonContinue
                      disabled={
                        !(applicationRenewal.statusId === 7
                          ? isValid && isDirty
                          : isValid)
                      }
                      fullWidth={false}
                      color="primary"
                      onClick={() => onSubmitRenewal(getValues())}
                    >
                      Continue{' '}
                    </StyledButtonContinue>
                  </StyledRowButtons>
                </StyledFieldRowBottomContainer>
              )}
            {applicationRenewal.statusId !== 9 && (
              <StyledCommentsMobileContainer>
                <StyledCommentsMobileIconContainer>
                  <IconWarning color={'#3B4D86'} size={0.6} />
                </StyledCommentsMobileIconContainer>
                <StyledCommentsMobile>
                  {`${handleStatusApplicationCopysMobile()}`}
                </StyledCommentsMobile>
              </StyledCommentsMobileContainer>
            )}

            {applicationRenewal.statusId === 9 && (
              <StyledCommentsRejectedContainer>
                <StyledCommentsMobileIconContainer>
                  <IconWarning color={'#FF2D55'} size={0.8} />
                </StyledCommentsMobileIconContainer>
                <StyledCommentsMobile>
                  {`${handleStatusApplicationCopysMobile()}`}
                </StyledCommentsMobile>
              </StyledCommentsRejectedContainer>
            )}

            {(applicationRenewal.statusId === 2 ||
              applicationRenewal.statusId === 7) &&
              (profile.roleType.id === 2 || profile.roleType.id === 6) && (
                <StyledFieldRowBottomContainer>
                  <Typography
                    color="secondary"
                    variant="body2"
                    sx={{
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '-0.006em',
                      cursor: 'pointer',
                    }}
                    onClick={goBack}
                  >
                    Cancel
                  </Typography>
                  <StyledRowButtons>
                    <StyledButtonComment
                      fullWidth={false}
                      color="secondary"
                      onClick={handleShowModalComments}
                    >
                      Comment
                    </StyledButtonComment>

                    <StyledButtonApprove
                      disabled={!isValidReview}
                      fullWidth={false}
                      color="primary"
                      onClick={handleSubmitReview(onSubmitApprove)}
                    >
                      Approve
                    </StyledButtonApprove>
                  </StyledRowButtons>
                </StyledFieldRowBottomContainer>
              )}
          </Wrapper>
        </StyledSectionApplication>
        <Modal
          color={'#5E5C5C'}
          type={'child'}
          showModal={modalStateComments.show}
          onClose={handleCloseModalComments}
        >
          <StyledTitleModal variant={'h2'} color={'success'}>
            Comments
          </StyledTitleModal>

          <StyledSubtitleModal variant={'body1'}>
            About this license renewal
          </StyledSubtitleModal>

          <StyledChildContainerModal>
            <StyledTextAreaField
              id={'textAreaComments'}
              label={'The license renewal was rejected because:'}
              name={'textAreaComments'}
              placeholder={'Comments'}
              control={controlComments}
              error={!!errorsComments.textAreaComments}
              helperText={errorsComments?.textAreaComments?.message}
              multiline
            />
            {/* <StyledSwitchField
              id={'sendToApplicant'}
              label={'Send to applicant'}
              name={'sendToApplicant'}
              inputValue={false}
              control={controlComments}
            /> */}
            <StyledButtonModal
              disabled={!isValidComments}
              onClick={handleSubmitComment(onSubmitComments)}
              fullWidth={false}
            >
              Send Comment
            </StyledButtonModal>
          </StyledChildContainerModal>
        </Modal>
        <Modal
          type={helperModalSuccessState}
          showModal={helperModalStateSuccess.show}
          onClose={handleCloseHelperModalSuccess}
          title={helperModalStateSuccess.title}
          description={helperModalStateSuccess.description}
        />
      </Suspense>
    </PrivateContentLayout>
  );
};

export default RenewalNotice;
