import React from 'react';

const iconTwitter = ({ color = '#FFFFFF', size = 1 }) => {
  return (
    <svg
      width={(size * 24).toString()}
      height={(size * 24).toString()}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.4956 4.70684L22.799 8.47745C22.0609 17.2127 14.8387 24 6.19415 24C4.41417 24 2.94727 23.7122 1.83408 23.1444C0.935713 22.6863 0.567701 22.1945 0.476386 22.0547C0.394464 21.9293 0.341361 21.7867 0.321044 21.6375C0.300726 21.4883 0.313719 21.3364 0.359052 21.193C0.404385 21.0496 0.480887 20.9186 0.582844 20.8096C0.684802 20.7006 0.80958 20.6165 0.94786 20.5636C0.979395 20.5515 3.87047 19.4189 5.73901 17.2625C4.57678 16.4183 3.55386 15.3906 2.70875 14.2181C1.02882 11.8926 -0.74792 7.85409 0.327506 1.82081C0.359516 1.64126 0.439085 1.47406 0.557593 1.33734C0.676101 1.20061 0.82903 1.09957 0.999811 1.04516C1.17059 0.990751 1.35272 0.985045 1.52645 1.02866C1.70018 1.07228 1.8589 1.16356 1.98541 1.2926C2.02862 1.33655 6.10828 5.43902 11.0955 6.76418L11.0962 5.99927C11.1068 4.39817 11.7402 2.86685 12.8572 1.74191C13.9742 0.616974 15.4835 -0.00952881 17.0532 0.000109586C18.0729 0.0145139 19.0716 0.298453 19.9516 0.824191C20.8316 1.34993 21.5628 2.09949 22.074 2.99966L25.8023 2.99972C25.9962 2.99972 26.1858 3.05837 26.347 3.16825C26.5082 3.27813 26.6339 3.4343 26.7081 3.61703C26.7823 3.79975 26.8017 4.00082 26.7639 4.1948C26.7261 4.38879 26.6327 4.56698 26.4956 4.70684Z"
        fill={color}
      />
    </svg>
  );
};

export default iconTwitter;
