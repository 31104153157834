import { useEffect, useState } from 'react';
// import useApi from '../../../api';
import useModels from '../../../models';

const useFilter = (watch) => {
  // const { useActions } = useApi();
  // const { dispatch, useApplicationsTableActions } =
  //   useActions();
  // const {
  //   actGetApplicationsStatusHelpList,
  // } = useApplicationsTableActions();

  const { useSelectors } = useModels();
  const { useSelector, useUserSelectors } = useSelectors();
  const { userSelector } = useUserSelectors();
  const { profile } = useSelector(userSelector);
  const [statusList, setStatusList] = useState([
    { value: 'none', text: 'Select' },
  ]);
  const [typeList, setTypeList] = useState([{ value: 'none', text: 'Select' }]);
  const [filterStatus, setFilterStatus] = useState(false);

  const filterTypeApplicationStatus = watch('applicationType');

  //List of options for every user filter
  // CEO
  const statusListCeoApplications = [
    { value: 'none', text: 'Select', disabled: true },
    { value: '1', text: 'Draft', disabled: false },
    { value: '2', text: 'Submitted', disabled: false },
    { value: '4', text: 'Reports pending', disabled: false },
    { value: '5', text: 'Reports approval', disabled: false },
    { value: '6', text: 'Voting pending', disabled: false },
    { value: '7', text: 'Request for changes', disabled: false },
    { value: '11', text: 'Final approval', disabled: false },
    { value: '13', text: 'Payment failed', disabled: false },
    { value: '14', text: 'Application reverted', disabled: false },
    { value: '8', text: 'Approved', disabled: false },
    { value: '9', text: 'Rejected', disabled: false },
  ];
  const statusListCeoRenewals = [
    { value: 'none', text: 'Select', disabled: true },
    { value: '1', text: 'Draft', disabled: true },
    { value: '2', text: 'Submitted', disabled: false },
    { value: '4', text: 'Reports pending', disabled: true },
    { value: '5', text: 'Reports approval', disabled: true },
    { value: '6', text: 'Voting pending', disabled: true },
    { value: '7', text: 'Request for changes', disabled: false },
    { value: '11', text: 'Final approval', disabled: true },
    { value: '13', text: 'Payment failed', disabled: true },
    { value: '14', text: 'Application reverted', disabled: true },
    { value: '8', text: 'Approved', disabled: false },
    { value: '9', text: 'Rejected', disabled: false },
  ];

  //Front user
  const statusListFrontUserApplications = [
    { value: 'none', text: 'Select', disabled: true },
    { value: '1', text: 'Draft', disabled: false },
    { value: '2', text: 'Submitted', disabled: false },
    { value: '3,4,5,6', text: 'In Progress', disabled: false },
    { value: '7', text: 'Request for changes', disabled: false },
    { value: '10', text: 'Payment pending', disabled: false },
    { value: '11', text: 'Payment completed', disabled: false },
    { value: '13', text: 'Payment failed', disabled: false },
    { value: '8', text: 'Approved', disabled: false },
    { value: '9', text: 'Rejected', disabled: false },
  ];
  const statusListFrontUserRenewals = [
    { value: 'none', text: 'Select', disabled: true },
    { value: '1', text: 'Draft', disabled: true },
    { value: '2', text: 'Submitted', disabled: false },
    { value: '3,4,5,6', text: 'In Progress', disabled: true },
    { value: '7', text: 'Request for changes', disabled: false },
    { value: '10', text: 'Payment pending', disabled: true },
    { value: '11', text: 'Payment completed', disabled: true },
    { value: '13', text: 'Payment failed', disabled: true },
    { value: '8', text: 'Approved', disabled: false },
    { value: '9', text: 'Rejected', disabled: false },
  ];

  //Manager
  const statusListManagerApplications = [
    { value: 'none', text: 'Select', disabled: true },
    { value: '2', text: 'Pending Approval', disabled: false },
    { value: '4', text: 'Reports pending', disabled: false },
    { value: '5', text: 'Reports approval', disabled: false },
    { value: '6', text: 'Voting pending', disabled: false },
    { value: '7', text: 'Request for changes', disabled: false },
    { value: '12', text: 'Payment review', disabled: false },
    { value: '13', text: 'Payment failed', disabled: false },
    { value: '14', text: 'Application reverted', disabled: false },
    { value: '8', text: 'Approved', disabled: false },
    { value: '9', text: 'Rejected', disabled: false },
  ];
  const statusListManagerRenewals = [
    { value: 'none', text: 'Select', disabled: true },
    { value: '2', text: 'Pending Approval', disabled: false },
    { value: '4', text: 'Reports pending', disabled: true },
    { value: '5', text: 'Reports approval', disabled: true },
    { value: '6', text: 'Voting pending', disabled: true },
    { value: '7', text: 'Request for changes', disabled: false },
    { value: '12', text: 'Payment review', disabled: true },
    { value: '13', text: 'Payment failed', disabled: true },
    { value: '14', text: 'Application reverted', disabled: true },
    { value: '8', text: 'Approved', disabled: false },
    { value: '9', text: 'Rejected', disabled: false },
  ];

  //Administrator
  const statusListAdministratorApplications = [
    { value: 'none', text: 'Select', disabled: true },
    { value: '2', text: 'Pending Approval', disabled: false },
    { value: '4', text: 'Reports pending', disabled: false },
    { value: '5', text: 'Reports approval', disabled: false },
    { value: '6', text: 'Voting pending', disabled: false },
    { value: '7', text: 'Request for changes', disabled: false },
    { value: '10', text: 'Payment pending', disabled: false },
    { value: '11', text: 'Sent to CEO', disabled: false },
    { value: '12', text: 'Payment review', disabled: false },
    { value: '13', text: 'Payment failed', disabled: false },
    { value: '14', text: 'Application reverted', disabled: false },
    { value: '8', text: 'Approved', disabled: false },
    { value: '9', text: 'Rejected', disabled: false },
  ];
  const statusListAdministratorRenewals = [
    { value: 'none', text: 'Select', disabled: true },
    { value: '2', text: 'Pending Approval', disabled: false },
    { value: '4', text: 'Reports pending', disabled: true },
    { value: '5', text: 'Reports approval', disabled: true },
    { value: '6', text: 'Voting pending', disabled: true },
    { value: '7', text: 'Request for changes', disabled: false },
    { value: '10', text: 'Payment pending', disabled: true },
    { value: '11', text: 'Sent to CEO', disabled: true },
    { value: '12', text: 'Payment review', disabled: true },
    { value: '13', text: 'Payment failed', disabled: true },
    { value: '14', text: 'Application reverted', disabled: true },
    { value: '8', text: 'Approved', disabled: false },
    { value: '9', text: 'Rejected', disabled: false },
  ];

  //Inspector
  const statusListInspectorApplications = [
    { value: 'none', text: 'Select', disabled: true },
    { value: '4', text: 'Reports pending', disabled: false },
    { value: '8', text: 'Approved', disabled: false },
    { value: '9', text: 'Rejected', disabled: false },
  ];

  //BoardMember
  const statusListBoardMemberApplications = [
    { value: 'none', text: 'Select', disabled: true },
    { value: '6', text: 'Voting pending', disabled: false },
    { value: '14', text: 'Application reverted', disabled: false },
    { value: '8', text: 'Approved', disabled: false },
    { value: '9', text: 'Rejected', disabled: false },
  ];

  const statusListOthers = [
    { value: 'none', text: 'Select' },
    { value: '1', text: 'Draft' },
    { value: '2', text: 'Submitted' },
    { value: '3,4,5', text: 'Pending approval' },
    { value: '6', text: 'Voting pending' },
    { value: '7', text: 'Request for changes' },
    { value: '8', text: 'Approved' },
    { value: '9', text: 'Rejected' },
  ];

  // License Type options
  const licenseTypesListRenewal = [
    { value: 'none', text: 'Select', disabled: true },
    { value: '1', text: 'License Application' },
    { value: '2', text: 'Renewal' },
  ];
  const licenseTypesList = [
    { value: 'none', text: 'Select' },
    { value: '1', text: 'License Application' },
  ];
  useEffect(() => {
    if (
      filterTypeApplicationStatus === 'none' ||
      filterTypeApplicationStatus === '1'
    ) {
      switch (profile.roleType.id) {
        case 1:
          // BoardMember
          setStatusList(statusListBoardMemberApplications);
          setTypeList(licenseTypesList);
          break;
        case 2:
          // Manager
          setStatusList(statusListManagerApplications);
          setTypeList(licenseTypesListRenewal);

          break;
        case 3:
          // Inspector
          setStatusList(statusListInspectorApplications);
          setTypeList(licenseTypesList);
          break;
        case 4:
          // Front user
          setStatusList(statusListFrontUserApplications);
          setTypeList(licenseTypesListRenewal);
          break;
        case 5:
          // ceo
          setStatusList(statusListCeoApplications);
          setTypeList(licenseTypesListRenewal);
          break;
        case 6:
          // Administrator
          setStatusList(statusListAdministratorApplications);
          setTypeList(licenseTypesListRenewal);

          break;
        default:
          setStatusList(statusListOthers);
          setTypeList(licenseTypesList);
          break;
      }
    } else {
      // Only renewals
      switch (profile.roleType.id) {
        case 2:
          // Manager
          setStatusList(statusListManagerRenewals);
          setTypeList(licenseTypesListRenewal);

          break;
        case 4:
          // Front user
          setStatusList(statusListFrontUserRenewals);
          setTypeList(licenseTypesListRenewal);
          break;
        case 5:
          // ceo
          setStatusList(statusListCeoRenewals);
          setTypeList(licenseTypesListRenewal);
          break;
        case 6:
          // Administrator
          setStatusList(statusListAdministratorRenewals);
          setTypeList(licenseTypesListRenewal);

          break;
        default:
          setStatusList(statusListOthers);
          setTypeList(licenseTypesList);
          break;
      }
    }
  }, [filterTypeApplicationStatus]);
  const hanldeShowFilter = () => {
    setFilterStatus(!filterStatus);
  };
  return {
    statusList,
    typeList,
    filterStatus,
    hanldeShowFilter,
  };
};

export default useFilter;
