import React from 'react';

const iconLogo = ({ size = 1 }) => {
  return (
    <svg
      width={(size * 133).toString()}
      height={(size * 40).toString()}
      viewBox="0 0 133 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.73841 16.2958C10.9804 16.3523 11.9683 16.5216 12.9374 16.8415C14.4333 17.3402 15.9387 17.8389 17.4347 18.3375C17.8769 18.4787 18.3097 18.6292 18.7519 18.7703C19.006 18.855 19.0248 18.8832 18.9025 19.1279C18.5073 19.9652 18.1215 20.8026 17.7264 21.64C17.2277 22.7126 16.7479 23.7946 16.2022 24.8389C15.6941 25.8174 15.0261 26.6924 14.0852 27.304C12.9374 28.0567 11.7519 28.0755 10.5381 27.4733C9.39029 26.9088 8.54351 25.9962 7.83785 24.9518C7.16043 23.9545 6.66177 22.8631 6.37951 21.687C6.09725 20.5015 6.01257 19.316 6.52064 18.1588C6.9064 17.2932 7.57441 16.738 8.47764 16.5028C8.96689 16.3899 9.46556 16.3429 9.73841 16.2958Z"
        fill="#CEC655"
      />
      <path
        d="M18.3097 32.6105C18.3661 31.5379 18.5167 30.2019 18.7143 29.0164C18.9495 27.6051 19.2882 26.2126 19.5987 24.8201C19.8527 23.6722 20.1256 22.5432 20.3984 21.3953C20.4925 20.9908 20.5866 20.5862 20.6807 20.191C20.6995 20.1252 20.7559 20.0687 20.7936 20.0029C20.8406 20.0499 20.9159 20.0969 20.9441 20.1534C21.3299 20.9531 21.7062 21.7623 22.092 22.562C22.6471 23.7099 23.221 24.8577 23.7667 26.015C24.3971 27.351 24.8487 28.7435 25.0934 30.2019C25.338 31.6508 25.3568 33.1091 25.0087 34.5393C24.7264 35.6871 24.2654 36.7503 23.3998 37.5783C22.807 38.1522 22.1296 38.5191 21.264 38.4533C20.6054 38.4062 20.1068 38.0675 19.6928 37.5877C19.2412 37.0608 18.9777 36.4492 18.7895 35.7812C18.4885 34.7368 18.3097 33.6831 18.3097 32.6105Z"
        fill="#70501A"
      />
      <path
        d="M22.6565 19.5983C22.8447 19.6547 23.0235 19.7018 23.2116 19.7676C24.1996 20.1158 25.1875 20.4639 26.166 20.8214C27.4267 21.273 28.7063 21.64 29.9012 22.2609C32.0276 23.3618 33.7494 24.8671 34.7467 27.1064C35.2265 28.1884 35.4429 29.3174 35.2924 30.4935C35.1983 31.2086 34.9631 31.8672 34.455 32.3941C33.8811 33.0057 33.1754 33.2315 32.3475 33.0997C31.3219 32.9304 30.5128 32.3753 29.7695 31.6978C28.3488 30.4089 27.295 28.8376 26.3918 27.1629C25.545 25.5916 24.7641 23.9733 23.9643 22.3738C23.5598 21.5741 23.1552 20.7838 22.76 19.984C22.713 19.8805 22.6659 19.777 22.6189 19.6736C22.6283 19.6453 22.6377 19.6265 22.6565 19.5983Z"
        fill="#D66127"
      />
      <path
        d="M6.85925 6.08741C6.88748 5.25945 7.03802 4.39385 7.61195 3.65997C8.18588 2.9355 8.91975 2.64383 9.85121 2.84142C10.7921 3.04841 11.526 3.59411 12.194 4.24331C13.2383 5.25004 14.0851 6.42612 14.7437 7.7057C15.8728 9.89793 16.9454 12.1278 18.0368 14.3388C18.2438 14.7528 18.4507 15.1668 18.6483 15.5808C18.686 15.6466 18.6766 15.7407 18.6954 15.816C18.6107 15.816 18.526 15.8254 18.4507 15.8066C17.9897 15.6466 17.5193 15.4773 17.0583 15.3173C16.3997 15.0915 15.7505 14.8563 15.0918 14.6399C13.6805 14.1695 12.2881 13.6802 11.0273 12.8617C9.65363 11.9678 8.51518 10.8482 7.74367 9.38986C7.17915 8.37372 6.87807 7.30113 6.85925 6.08741Z"
        fill="#D66127"
      />
      <path
        d="M17.0866 3.50003C17.0489 2.94492 17.143 2.28632 17.5006 1.69357C17.8205 1.15727 18.2815 0.846787 18.9213 0.809152C19.975 0.743292 20.8312 1.21373 21.6121 1.8347C22.7882 2.77557 23.5597 3.99869 24.002 5.42881C24.3783 6.66135 24.3971 7.91271 24.1149 9.16406C23.8702 10.2366 23.381 11.1963 22.8353 12.1372C22.299 13.0498 21.7815 13.9625 21.2546 14.8751C21.217 14.941 21.1511 14.9786 21.1041 15.0351C21.057 14.9786 20.9912 14.941 20.963 14.8751C20.3043 13.3509 19.6269 11.8267 18.9871 10.2931C18.5543 9.25815 18.1215 8.21378 17.7546 7.16001C17.3594 6.03097 17.0678 4.85488 17.0866 3.50003Z"
        fill="#911010"
      />
      <path
        d="M4.25311 0.0376341C4.20607 0.122312 4.16844 0.206991 4.11198 0.28226C3.46279 1.1949 2.91708 2.1734 2.5031 3.20836C2.20202 3.96105 1.97621 4.76079 1.76922 5.55112C1.28938 7.367 1.13884 9.22051 1.21411 11.0834C1.25174 12.0713 1.33642 13.0592 1.50578 14.0283C1.69395 15.148 1.97621 16.2582 2.24907 17.359C2.616 18.8268 3.17112 20.2381 3.8015 21.6211C4.50715 23.183 5.33511 24.6696 6.28539 26.0997C7.1604 27.4263 8.12008 28.6777 9.17385 29.8537C10.5005 31.3309 11.94 32.7046 13.5583 33.8807C14.2545 34.3887 14.9696 34.8686 15.6847 35.3578C15.9011 35.5084 16.1363 35.6213 16.3715 35.753C16.5408 35.8471 16.6067 35.9882 16.6538 36.1764C16.8137 36.7691 17.0113 37.3525 17.1901 37.9452C17.1995 37.9734 17.2089 38.0017 17.2371 38.1146C17.0301 38.0487 16.8607 38.0111 16.7196 37.9358C14.5744 36.8632 12.5986 35.546 10.7827 33.9653C8.97627 32.3941 7.3862 30.6347 5.96549 28.7153C4.98699 27.3887 4.09317 26.0056 3.3687 24.5284C2.74773 23.2583 2.19261 21.9505 1.65632 20.6426C1.37406 19.9464 1.13884 19.2125 0.969485 18.4786C0.687224 17.2649 0.404964 16.0418 0.235608 14.8093C0.0756606 13.7084 0.000391225 12.5982 0.000391225 11.488C-0.00901745 9.93557 0.15093 8.39254 0.517869 6.87774C0.997711 4.91133 1.75981 3.07664 2.95472 1.43012C3.27461 0.987911 3.64155 0.583338 3.99908 0.169356C4.05553 0.103495 4.14962 0.0564521 4.22489 0C4.21548 0 4.2343 0.0188168 4.25311 0.0376341Z"
        fill="#E0A034"
      />
      <path
        d="M30.3621 19.1937C29.4025 19.1467 28.4898 18.9303 27.596 18.6386C26.5704 18.2999 25.5543 17.9424 24.5288 17.5942C24.0019 17.4155 23.475 17.2367 22.9481 17.0579C22.8164 17.0109 22.7411 16.9544 22.807 16.8039C23.5314 15.2232 24.2465 13.6426 24.9992 12.0713C25.3567 11.3281 25.8742 10.6788 26.5799 10.2178C27.5489 9.58744 28.5463 9.62508 29.5436 10.152C30.6914 10.7541 31.4912 11.7044 32.1592 12.7864C32.6014 13.5109 32.9213 14.2824 33.1189 15.1009C33.3165 15.9477 33.3917 16.7945 33.0718 17.6225C32.7708 18.4222 32.1686 18.8832 31.3501 19.062C31.0302 19.1184 30.7009 19.1467 30.3621 19.1937Z"
        fill="#911010"
      />
      <path
        d="M35.5181 35.2543C35.4428 35.3766 35.377 35.499 35.2829 35.6025C34.2197 36.8444 32.9684 37.8699 31.4912 38.585C30.9267 38.8579 30.3339 39.0648 29.7506 39.3001C28.7815 39.6952 27.7465 39.827 26.721 39.9681C26.1565 40.0528 26.1188 39.9963 26.3164 39.46C26.3823 39.2718 26.4199 39.0743 26.4575 38.8673C26.4858 38.6979 26.5516 38.6226 26.7304 38.6132C27.3702 38.5756 28.0194 38.5568 28.6498 38.4627C29.2519 38.3686 29.8353 38.2087 30.4186 38.0393C31.0302 37.8511 31.6417 37.663 32.2157 37.3807C32.9119 37.0326 33.5705 36.5998 34.2291 36.167C34.6243 35.9035 34.9724 35.5742 35.3393 35.2826C35.377 35.2543 35.424 35.2355 35.4617 35.2073C35.4899 35.2261 35.4993 35.2355 35.5181 35.2543Z"
        fill="#E0A034"
      />
      <path
        d="M37.9971 18.4C37.9971 18.96 38.4504 19.4133 39.0104 19.4133C39.5704 19.4133 40.0237 18.96 40.0237 18.4V13.4L44.1304 18.7867C44.4104 19.1467 44.7037 19.3867 45.1971 19.3867H45.2637C45.8371 19.3867 46.2904 18.9333 46.2904 18.36V10.9333C46.2904 10.3733 45.8371 9.92 45.2771 9.92C44.7171 9.92 44.2637 10.3733 44.2637 10.9333V15.7467L40.3037 10.5467C40.0237 10.1867 39.7304 9.94667 39.2371 9.94667H39.0237C38.4504 9.94667 37.9971 10.4 37.9971 10.9733V18.4Z"
        fill="#70501A"
      />
      <path
        d="M51.5309 19.4933C53.7576 19.4933 55.4109 17.8267 55.4109 15.7733V15.7467C55.4109 13.6933 53.7709 12.0533 51.5576 12.0533C49.3309 12.0533 47.6776 13.72 47.6776 15.7733V15.8C47.6776 17.8533 49.3176 19.4933 51.5309 19.4933ZM51.5576 17.7467C50.4376 17.7467 49.6776 16.8267 49.6776 15.7733V15.7467C49.6776 14.6933 50.3709 13.8 51.5309 13.8C52.6509 13.8 53.4109 14.72 53.4109 15.7733V15.8C53.4109 16.8533 52.7176 17.7467 51.5576 17.7467Z"
        fill="#70501A"
      />
      <path
        d="M56.5851 18.4C56.5851 18.96 57.0384 19.4133 57.5984 19.4133C58.1584 19.4133 58.6118 18.96 58.6118 18.4V16.5067C58.6118 15.0667 59.1851 14.28 60.2118 14.04C60.6251 13.9467 60.9718 13.6267 60.9718 13.0933C60.9718 12.52 60.6118 12.1067 59.9851 12.1067C59.3851 12.1067 58.9051 12.7467 58.6118 13.44V13.12C58.6118 12.56 58.1584 12.1067 57.5984 12.1067C57.0384 12.1067 56.5851 12.56 56.5851 13.12V18.4Z"
        fill="#70501A"
      />
      <path
        d="M64.278 19.4533C64.718 19.4533 65.0647 19.4 65.438 19.2533C65.718 19.1467 65.958 18.8533 65.958 18.4933C65.958 18.0267 65.5713 17.6533 65.118 17.6533C65.078 17.6533 64.9447 17.6667 64.878 17.6667C64.4247 17.6667 64.2247 17.44 64.2247 16.9733V13.92H65.118C65.598 13.92 65.9847 13.5333 65.9847 13.0533C65.9847 12.5733 65.598 12.1867 65.118 12.1867H64.2247V11.2933C64.2247 10.7333 63.7713 10.28 63.2113 10.28C62.6513 10.28 62.198 10.7333 62.198 11.2933V12.1867H62.1313C61.6513 12.1867 61.2647 12.5733 61.2647 13.0533C61.2647 13.5333 61.6513 13.92 62.1313 13.92H62.198V17.3067C62.198 18.96 63.038 19.4533 64.278 19.4533Z"
        fill="#70501A"
      />
      <path
        d="M67.1052 18.4C67.1052 18.96 67.5585 19.4133 68.1185 19.4133C68.6785 19.4133 69.1319 18.96 69.1319 18.4V15.3467C69.1319 14.3867 69.6252 13.8933 70.3985 13.8933C71.1719 13.8933 71.6252 14.3867 71.6252 15.3467V18.4C71.6252 18.96 72.0785 19.4133 72.6385 19.4133C73.1985 19.4133 73.6519 18.96 73.6519 18.4V14.7067C73.6519 13.0667 72.7585 12.0533 71.2252 12.0533C70.1985 12.0533 69.5985 12.6 69.1319 13.2V10.5333C69.1319 9.97333 68.6785 9.52 68.1185 9.52C67.5585 9.52 67.1052 9.97333 67.1052 10.5333V18.4Z"
        fill="#70501A"
      />
      <path
        d="M76.7592 15.2C76.9192 14.28 77.4658 13.68 78.3058 13.68C79.1591 13.68 79.6925 14.2933 79.8125 15.2H76.7592ZM80.9858 18.6267C81.1325 18.4933 81.2525 18.2933 81.2525 18.0267C81.2525 17.5733 80.9192 17.2267 80.4658 17.2267C80.2525 17.2267 80.1192 17.28 79.9725 17.3867C79.5592 17.6933 79.0925 17.8667 78.5325 17.8667C77.6258 17.8667 76.9858 17.3867 76.7858 16.4667H80.7992C81.3325 16.4667 81.7458 16.08 81.7458 15.4933C81.7458 14.0133 80.6925 12.0533 78.3058 12.0533C76.2258 12.0533 74.7725 13.7333 74.7725 15.7733V15.8C74.7725 17.9867 76.3591 19.4933 78.5058 19.4933C79.5458 19.4933 80.3591 19.16 80.9858 18.6267Z"
        fill="#70501A"
      />
      <path
        d="M83.0029 18.4C83.0029 18.96 83.4562 19.4133 84.0162 19.4133C84.5762 19.4133 85.0296 18.96 85.0296 18.4V16.5067C85.0296 15.0667 85.6029 14.28 86.6296 14.04C87.0429 13.9467 87.3896 13.6267 87.3896 13.0933C87.3896 12.52 87.0296 12.1067 86.4029 12.1067C85.8029 12.1067 85.3229 12.7467 85.0296 13.44V13.12C85.0296 12.56 84.5762 12.1067 84.0162 12.1067C83.4562 12.1067 83.0029 12.56 83.0029 13.12V18.4Z"
        fill="#70501A"
      />
      <path
        d="M88.2825 18.4C88.2825 18.96 88.7358 19.4133 89.2958 19.4133C89.8558 19.4133 90.3092 18.96 90.3092 18.4V15.3467C90.3092 14.3867 90.8025 13.8933 91.5758 13.8933C92.3492 13.8933 92.8025 14.3867 92.8025 15.3467V18.4C92.8025 18.96 93.2558 19.4133 93.8158 19.4133C94.3758 19.4133 94.8291 18.96 94.8291 18.4V14.7067C94.8291 13.0667 93.9358 12.0533 92.4025 12.0533C91.3758 12.0533 90.7758 12.6 90.3092 13.2V13.12C90.3092 12.56 89.8558 12.1067 89.2958 12.1067C88.7358 12.1067 88.2825 12.56 88.2825 13.12V18.4Z"
        fill="#70501A"
      />
      <path
        d="M104.587 19.4933C106.081 19.4933 107.067 19.0533 107.907 18.32C108.094 18.16 108.254 17.9067 108.254 17.5867C108.254 17.0667 107.814 16.64 107.294 16.64C107.054 16.64 106.84 16.7333 106.681 16.8667C106.094 17.3333 105.521 17.6 104.654 17.6C103.054 17.6 101.947 16.2667 101.947 14.6667V14.64C101.947 13.04 103.081 11.7333 104.654 11.7333C105.387 11.7333 105.987 11.9733 106.561 12.4C106.707 12.4933 106.894 12.6 107.174 12.6C107.734 12.6 108.187 12.16 108.187 11.6C108.187 11.24 108.001 10.9467 107.787 10.7867C107.014 10.2133 106.067 9.84 104.667 9.84C101.801 9.84 99.8005 12.0133 99.8005 14.6667V14.6933C99.8005 17.3733 101.841 19.4933 104.587 19.4933Z"
        fill="#70501A"
      />
      <path
        d="M111.578 19.4667C112.564 19.4667 113.244 19.1067 113.738 18.56V18.5733C113.738 19 114.124 19.4133 114.711 19.4133C115.258 19.4133 115.698 18.9867 115.698 18.44V15.1867C115.698 14.2267 115.458 13.44 114.924 12.9067C114.418 12.4 113.618 12.1067 112.511 12.1067C111.564 12.1067 110.871 12.24 110.231 12.48C109.911 12.6 109.684 12.9067 109.684 13.2667C109.684 13.7333 110.058 14.0933 110.524 14.0933C110.618 14.0933 110.698 14.08 110.818 14.04C111.204 13.92 111.658 13.84 112.218 13.84C113.231 13.84 113.751 14.3067 113.751 15.1467V15.2667C113.244 15.0933 112.724 14.9733 112.004 14.9733C110.311 14.9733 109.124 15.6933 109.124 17.2533V17.28C109.124 18.6933 110.231 19.4667 111.578 19.4667ZM112.191 18.0667C111.538 18.0667 111.084 17.7467 111.084 17.2V17.1733C111.084 16.5333 111.618 16.1867 112.484 16.1867C112.978 16.1867 113.431 16.2933 113.778 16.4533V16.8133C113.778 17.5467 113.138 18.0667 112.191 18.0667Z"
        fill="#70501A"
      />
      <path
        d="M117.148 20.5333C117.148 21.0933 117.602 21.5467 118.162 21.5467C118.722 21.5467 119.175 21.0933 119.175 20.5333V18.4C119.655 18.9733 120.322 19.4667 121.402 19.4667C123.095 19.4667 124.655 18.16 124.655 15.7733V15.7467C124.655 13.36 123.068 12.0533 121.402 12.0533C120.348 12.0533 119.668 12.5467 119.175 13.2133V13.12C119.175 12.56 118.722 12.1067 118.162 12.1067C117.602 12.1067 117.148 12.56 117.148 13.12V20.5333ZM120.882 17.7467C119.935 17.7467 119.148 16.96 119.148 15.7733V15.7467C119.148 14.56 119.935 13.7733 120.882 13.7733C121.828 13.7733 122.628 14.56 122.628 15.7467V15.7733C122.628 16.9733 121.828 17.7467 120.882 17.7467Z"
        fill="#70501A"
      />
      <path
        d="M127.492 15.2C127.652 14.28 128.199 13.68 129.039 13.68C129.892 13.68 130.426 14.2933 130.546 15.2H127.492ZM131.719 18.6267C131.866 18.4933 131.986 18.2933 131.986 18.0267C131.986 17.5733 131.652 17.2267 131.199 17.2267C130.986 17.2267 130.852 17.28 130.706 17.3867C130.292 17.6933 129.826 17.8667 129.266 17.8667C128.359 17.8667 127.719 17.3867 127.519 16.4667H131.532C132.066 16.4667 132.479 16.08 132.479 15.4933C132.479 14.0133 131.426 12.0533 129.039 12.0533C126.959 12.0533 125.506 13.7333 125.506 15.7733V15.8C125.506 17.9867 127.092 19.4933 129.239 19.4933C130.279 19.4933 131.092 19.16 131.719 18.6267Z"
        fill="#70501A"
      />
      <path
        d="M43.3257 32.3067C43.3257 32.88 43.779 33.3333 44.3523 33.3333H49.179C49.699 33.3333 50.1123 32.92 50.1123 32.4C50.1123 31.88 49.699 31.4667 49.179 31.4667H45.379V24.9467C45.379 24.3733 44.9257 23.92 44.3523 23.92C43.779 23.92 43.3257 24.3733 43.3257 24.9467V32.3067Z"
        fill="#70501A"
      />
      <path
        d="M51.0475 24.5067C51.0475 25.0933 51.5409 25.4667 52.1809 25.4667C52.8209 25.4667 53.3142 25.0933 53.3142 24.5067V24.48C53.3142 23.8933 52.8209 23.5333 52.1809 23.5333C51.5409 23.5333 51.0475 23.8933 51.0475 24.48V24.5067ZM51.1675 32.4C51.1675 32.96 51.6209 33.4133 52.1809 33.4133C52.7409 33.4133 53.1942 32.96 53.1942 32.4V27.12C53.1942 26.56 52.7409 26.1067 52.1809 26.1067C51.6209 26.1067 51.1675 26.56 51.1675 27.12V32.4Z"
        fill="#70501A"
      />
      <path
        d="M61.991 27.12C61.991 26.56 61.5376 26.1067 60.9776 26.1067C60.4176 26.1067 59.9643 26.56 59.9643 27.1067V27.12C59.4843 26.5467 58.8176 26.0533 57.7376 26.0533C56.0443 26.0533 54.4843 27.36 54.4843 29.7467V29.7733C54.4843 32.16 56.071 33.4667 57.7376 33.4667C58.791 33.4667 59.471 32.9733 59.9643 32.3067V34.5333C59.9643 35.0933 60.4176 35.5467 60.9776 35.5467C61.5376 35.5467 61.991 35.0933 61.991 34.5333V27.12ZM58.2576 27.7733C59.2043 27.7733 59.991 28.56 59.991 29.7467V29.7733C59.991 30.96 59.2043 31.7467 58.2576 31.7467C57.311 31.7467 56.511 30.96 56.511 29.7733V29.7467C56.511 28.5467 57.311 27.7733 58.2576 27.7733Z"
        fill="#70501A"
      />
      <path
        d="M70.0017 27.12C70.0017 26.56 69.5484 26.1067 68.9884 26.1067C68.4284 26.1067 67.975 26.56 67.975 27.12V30.1733C67.975 31.1333 67.4817 31.6267 66.7084 31.6267C65.935 31.6267 65.4817 31.1333 65.4817 30.1733V27.12C65.4817 26.56 65.0284 26.1067 64.4684 26.1067C63.9084 26.1067 63.455 26.56 63.455 27.12V30.8133C63.455 32.4533 64.3484 33.4667 65.8817 33.4667C66.9084 33.4667 67.5084 32.92 67.975 32.32V32.4C67.975 32.96 68.4284 33.4133 68.9884 33.4133C69.5484 33.4133 70.0017 32.96 70.0017 32.4V27.12Z"
        fill="#70501A"
      />
      <path
        d="M75.029 33.4933C77.2557 33.4933 78.909 31.8267 78.909 29.7733V29.7467C78.909 27.6933 77.269 26.0533 75.0557 26.0533C72.829 26.0533 71.1757 27.72 71.1757 29.7733V29.8C71.1757 31.8533 72.8157 33.4933 75.029 33.4933ZM75.0557 31.7467C73.9357 31.7467 73.1757 30.8267 73.1757 29.7733V29.7467C73.1757 28.6933 73.869 27.8 75.029 27.8C76.149 27.8 76.909 28.72 76.909 29.7733V29.8C76.909 30.8533 76.2157 31.7467 75.0557 31.7467Z"
        fill="#70501A"
      />
      <path
        d="M80.0832 32.4C80.0832 32.96 80.5365 33.4133 81.0965 33.4133C81.6565 33.4133 82.1098 32.96 82.1098 32.4V30.5067C82.1098 29.0667 82.6832 28.28 83.7098 28.04C84.1232 27.9467 84.4698 27.6267 84.4698 27.0933C84.4698 26.52 84.1098 26.1067 83.4832 26.1067C82.8832 26.1067 82.4032 26.7467 82.1098 27.44V27.12C82.1098 26.56 81.6565 26.1067 81.0965 26.1067C80.5365 26.1067 80.0832 26.56 80.0832 27.12V32.4Z"
        fill="#70501A"
      />
      <path
        d="M89.2801 32.3067C89.2801 32.88 89.7335 33.3333 90.3068 33.3333H93.7201C95.8268 33.3333 97.2135 32.48 97.2135 30.7733V30.7467C97.2135 29.4933 96.5468 28.8667 95.4668 28.4533C96.1335 28.08 96.6935 27.4933 96.6935 26.44V26.4133C96.6935 25.7733 96.4801 25.2533 96.0535 24.8267C95.5201 24.2933 94.6801 24 93.6135 24H90.3068C89.7335 24 89.2801 24.4533 89.2801 25.0267V32.3067ZM91.2801 27.7733V25.8H93.3068C94.1735 25.8 94.6535 26.1467 94.6535 26.76V26.7867C94.6535 27.48 94.0801 27.7733 93.1735 27.7733H91.2801ZM91.2801 31.5333V29.48H93.6535C94.7068 29.48 95.1735 29.8667 95.1735 30.4933V30.52C95.1735 31.2133 94.6268 31.5333 93.7201 31.5333H91.2801Z"
        fill="#70501A"
      />
      <path
        d="M101.903 33.4933C104.129 33.4933 105.783 31.8267 105.783 29.7733V29.7467C105.783 27.6933 104.143 26.0533 101.929 26.0533C99.7025 26.0533 98.0492 27.72 98.0492 29.7733V29.8C98.0492 31.8533 99.6892 33.4933 101.903 33.4933ZM101.929 31.7467C100.809 31.7467 100.049 30.8267 100.049 29.7733V29.7467C100.049 28.6933 100.743 27.8 101.903 27.8C103.023 27.8 103.783 28.72 103.783 29.7733V29.8C103.783 30.8533 103.089 31.7467 101.929 31.7467Z"
        fill="#70501A"
      />
      <path
        d="M108.983 33.4667C109.97 33.4667 110.65 33.1067 111.143 32.56V32.5733C111.143 33 111.53 33.4133 112.117 33.4133C112.663 33.4133 113.103 32.9867 113.103 32.44V29.1867C113.103 28.2267 112.863 27.44 112.33 26.9067C111.823 26.4 111.023 26.1067 109.917 26.1067C108.97 26.1067 108.277 26.24 107.637 26.48C107.317 26.6 107.09 26.9067 107.09 27.2667C107.09 27.7333 107.463 28.0933 107.93 28.0933C108.023 28.0933 108.103 28.08 108.223 28.04C108.61 27.92 109.063 27.84 109.623 27.84C110.637 27.84 111.157 28.3067 111.157 29.1467V29.2667C110.65 29.0933 110.13 28.9733 109.41 28.9733C107.717 28.9733 106.53 29.6933 106.53 31.2533V31.28C106.53 32.6933 107.637 33.4667 108.983 33.4667ZM109.597 32.0667C108.943 32.0667 108.49 31.7467 108.49 31.2V31.1733C108.49 30.5333 109.023 30.1867 109.89 30.1867C110.383 30.1867 110.837 30.2933 111.183 30.4533V30.8133C111.183 31.5467 110.543 32.0667 109.597 32.0667Z"
        fill="#70501A"
      />
      <path
        d="M114.554 32.4C114.554 32.96 115.007 33.4133 115.567 33.4133C116.127 33.4133 116.581 32.96 116.581 32.4V30.5067C116.581 29.0667 117.154 28.28 118.181 28.04C118.594 27.9467 118.941 27.6267 118.941 27.0933C118.941 26.52 118.581 26.1067 117.954 26.1067C117.354 26.1067 116.874 26.7467 116.581 27.44V27.12C116.581 26.56 116.127 26.1067 115.567 26.1067C115.007 26.1067 114.554 26.56 114.554 27.12V32.4Z"
        fill="#70501A"
      />
      <path
        d="M126.89 24.5333C126.89 23.9733 126.437 23.52 125.877 23.52C125.317 23.52 124.863 23.9733 124.863 24.5333V27.12C124.383 26.5467 123.717 26.0533 122.637 26.0533C120.943 26.0533 119.383 27.36 119.383 29.7467V29.7733C119.383 32.16 120.97 33.4667 122.637 33.4667C123.69 33.4667 124.37 32.9733 124.863 32.3067V32.4C124.863 32.96 125.317 33.4133 125.877 33.4133C126.437 33.4133 126.89 32.96 126.89 32.4V24.5333ZM123.157 27.7733C124.103 27.7733 124.89 28.56 124.89 29.7467V29.7733C124.89 30.96 124.103 31.7467 123.157 31.7467C122.21 31.7467 121.41 30.96 121.41 29.7733V29.7467C121.41 28.5467 122.21 27.7733 123.157 27.7733Z"
        fill="#70501A"
      />
    </svg>
  );
};

export default iconLogo;
