import useHelpers from '../../../helpers';

const useRenewalNoticesSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const renewalsNoticesSelector = createSelector(
    (state) => state.renewalsNotices,
    (renewalsNotices) => renewalsNotices
  );
  const applicationReadySelector = createSelector(
    (state) => state.applicationsRenewals,
    (applicationsRenewals) => {
      return {
        applicationReady: applicationsRenewals.applicationReady,
      };
    }
  );

  const applicationRenewalSelector = createSelector(
    (state) => state.applicationsRenewals,
    (applicationsRenewals) => {
      return {
        applicationRenewal: applicationsRenewals.applicationRenewal,
      };
    }
  );
  return {
    renewalsNoticesSelector,
    applicationRenewalSelector,
    applicationReadySelector,
  };
};
export default useRenewalNoticesSelectors;
