import useHelpers from '../../../helpers';
import useStrings from '../../../strings';
import useInitialStates from '../../initialStates';

const useApplicationReviewReducers = () => {
  // Helpers
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  //strings
  const { useTypes } = useStrings();
  const { applicationReviewTypes } = useTypes();
  const {
    SET_STATUS_APPLICATION_REVIEW,
    SET_CURRENT_STEP_APPLICATION_REVIEW,
    GET_SAPS_AND_MUNICIPALITY_REPORTS,
    SET_SAPS_AND_MUNICIPALITY_REPORTS_DRAFT,
    RESET_APPLICATION_REVIEW,
    EDIT_INSPECTION_FORM,
  } = applicationReviewTypes();

  //initial states
  const { useApplicationReviewInitialStates } = useInitialStates();
  const { applicationReviewInitialState } = useApplicationReviewInitialStates();

  //handlers
  const userHandler = {
    [RESET_APPLICATION_REVIEW]() {
      return applicationReviewInitialState;
    },
    [SET_STATUS_APPLICATION_REVIEW](state, action) {
      const { payload } = action;
      return {
        ...state,
        stepsEnabled: payload,
        // ...payload,
      };
    },
    [SET_CURRENT_STEP_APPLICATION_REVIEW](state, action) {
      const { payload } = action;
      return {
        ...state,
        currentStep: payload,
      };
    },

    [SET_SAPS_AND_MUNICIPALITY_REPORTS_DRAFT](state, action) {
      const { payload } = action;
      return {
        ...state,
        sapsAndMunicipalityReportsDraft: {
          ...state.sapsAndMunicipalityReportsDraft,
          ...payload,
        },
      };
    },
    [GET_SAPS_AND_MUNICIPALITY_REPORTS](state, action) {
      const { payload } = action;
      const { reports, inspectionForm } = payload;

      return {
        ...state,
        sapsAndMunicipalityReportsDraft: {
          ...state.sapsAndMunicipalityReportsDraft,
          ...reports,
          sapsReport: reports.sapsReport.data
            ? reports.sapsReport
            : { name: '', data: '' },
          municipalityReport: reports.municipalityReport.data
            ? reports.municipalityReport
            : { name: '', data: '' },
        },
        sapsAndMunicipalityReports: {
          ...state.sapsAndMunicipalityReports,
          ...reports,
          sapsReport: reports.sapsReport.data
            ? reports.sapsReport
            : { name: '', data: '' },
          municipalityReport: reports.municipalityReport.data
            ? reports.municipalityReport
            : { name: '', data: '' },
        },
        inspectionForm: inspectionForm,
      };
    },
    [EDIT_INSPECTION_FORM](state, action) {
      const { payload } = action;
      return {
        ...state,
        editInspectionForm: payload,
      };
    },
  };
  //reducers
  const applicationReview = createReducer(
    applicationReviewInitialState,
    userHandler
  );

  return {
    applicationReview,
  };
};

export default useApplicationReviewReducers;
