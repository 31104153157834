import React, { Suspense } from 'react';
import useComponents from '../../components';
import useHelpers from '../../../helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import useLayouts from '../../layouts';
import { FormControlLabel } from '@mui/material';
import useControllers from '../../../controllers';

import {
  StyledFieldsContainer,
  StyledSectionSignUp,
  StyledTermContainer,
  StyledSignInRow,
  StyledButtonRegister,
  StyledField,
  StyledFieldRowHidden,
  StyledPhoneInputField,
} from './SignUp.styles';
import { Box } from '@mui/system';

const SignUp = () => {
  // Components
  const {
    TextFieldControlled,
    ActivityIndicator,
    PassWordFieldControlled,
    CheckboxControlled,
    Typography,
    Modal,
    SelectFieldControlled,
    DocumentFieldControlled,
    Wrapper,
  } = useComponents();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, usePromises } = useQuickFunctions();
  const { signUpSchemaValidator } = useValidators();
  const { promiseInProgress } = usePromises();

  // Layouts
  const { PublicContentLayout } = useLayouts();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useSignUp } = useScreenHooks();

  // Yup validator
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(signUpSchemaValidator),
  });

  const {
    onSubmit,
    handleRedirectLogin,
    modalState,
    handleCloseModal,
    registerState,
    documentsType,
    phoneIndicatorChoices,
    onChangeInputFieldPhone,
    handleIndicative,
    defaultIndicative,
    userContactMethodsList,
  } = useSignUp(setValue);

  const isPassword = watch('password');
  const isConfirmPassword = watch('passwordConfirmation');

  return (
    <PublicContentLayout background={'secondary'}>
      {promiseInProgress && <ActivityIndicator />}
      <Suspense fallback={<ActivityIndicator />}>
        <StyledSectionSignUp>
          <Wrapper
            title={'Register'}
            description={'Personal information'}
            maxWidth={'860px'}
            className="container"
          >
            <StyledFieldsContainer>
              <TextFieldControlled
                id={'name'}
                placeholder={'First Name'}
                label={'First Name'}
                name={'name'}
                variant={'outlined'}
                fullWidth={true}
                control={control}
                error={!!errors.name}
                helperText={errors?.name?.message}
              />
              <TextFieldControlled
                id={'surname'}
                placeholder={'Last Name'}
                label={'Last Name'}
                name={'surname'}
                fullWidth={true}
                control={control}
                error={!!errors.surname}
                helperText={errors?.surname?.message}
              />
              <TextFieldControlled
                id={'email'}
                placeholder={'Email'}
                label={'Email'}
                name={'email'}
                control={control}
                type={'email'}
                error={!!errors.email}
                helperText={errors?.email?.message}
              />
              <StyledFieldRowHidden>
                <SelectFieldControlled
                  id={'phoneIndicatorId'}
                  label={'Phone'}
                  name={'phoneIndicatorId'}
                  valueName={'phoneIndicator'}
                  control={control}
                  options={phoneIndicatorChoices}
                  inputValue={defaultIndicative(phoneIndicatorChoices).id}
                />
                <TextFieldControlled
                  placeholder={'Contact Number'}
                  label={'Contact Number'}
                  id={'phone'}
                  control={control}
                  name={'phone'}
                  inputValue=""
                  error={!!errors.phone}
                  helperText={errors?.phone?.message}
                />
              </StyledFieldRowHidden>
              <StyledPhoneInputField
                id={'phoneVisual'}
                indicatorId={'phoneIndicatorId'}
                placeholder={'Contact Number'}
                label={'Contact Number'}
                name={'phoneVisual'}
                control={control}
                inputValue={
                  // applicationForm.phoneIndicator &&
                  `${handleIndicative(phoneIndicatorChoices[201], '')}`
                }
                onInputChange={onChangeInputFieldPhone}
                error={!!errors.phone}
                helperText={errors?.phone?.message}
              />
              <SelectFieldControlled
                id={'contactMethodId'}
                label={'Preferred method of communication'}
                name={'contactMethodId'}
                control={control}
                inputValue={userContactMethodsList[0].id}
                options={userContactMethodsList}
                error={!!errors.contactMethodId}
                helperText={errors?.contactMethodId?.message}
              />
            </StyledFieldsContainer>
            <StyledFieldsContainer>
              <SelectFieldControlled
                id={'alias'}
                label={'Document Type'}
                name={'alias'}
                control={control}
                inputValue={documentsType[0].value}
                options={documentsType}
                error={!!errors.alias}
                helperText={errors?.alias?.message}
              />

              <DocumentFieldControlled
                id={'registrationNumber'}
                label={'ID / Registration Number'}
                placeholder={'ID / Registration Number'}
                name={'registrationNumber'}
                control={control}
                error={!!errors.registrationNumber}
                helperText={errors?.registrationNumber?.message}
              />
            </StyledFieldsContainer>
            <StyledFieldsContainer>
              <PassWordFieldControlled
                id={'password'}
                label={'Password'}
                placeholder={'Password'}
                name={'password'}
                control={control}
                fullWidth={true}
                error={!!errors.password}
                helperText={errors?.password?.message}
              />
              <PassWordFieldControlled
                id={'passwordConfirmation'}
                label={'Confirm Password'}
                placeholder={'Password'}
                name={'passwordConfirmation'}
                control={control}
                fullWidth={true}
                error={
                  !!errors.passwordConfirmation ||
                  (isConfirmPassword !== '' &&
                    isConfirmPassword !== undefined &&
                    isPassword !== isConfirmPassword)
                }
                helperText={
                  errors?.passwordConfirmation?.message ||
                  (isConfirmPassword !== '' &&
                    isConfirmPassword !== undefined &&
                    isPassword !== isConfirmPassword &&
                    "Password doesn't match")
                }
              />
            </StyledFieldsContainer>

            <StyledTermContainer className="my-4">
              <FormControlLabel
                style={{ marginBottom: 25 }}
                control={
                  <CheckboxControlled
                    id={'isCheck'}
                    name={'isCheck'}
                    label={'isCheck'}
                    control={control}
                    error={errors}
                    helperText={errors?.isCheck?.message}
                    checked={false}
                    value={false}
                    defaultValue={false}
                  />
                }
                label={
                  <Typography
                    color="neutral"
                    variant="body2"
                    className="StyledTypographyLight"
                    sx={{
                      marginLeft: '40px',
                      fontSize: '12px',
                      lineHeight: '17px',
                      minWidth: '186px',
                    }}
                  >
                    We are collecting your personal data so that we may process
                    your liquor license application with our organization. For
                    administrative purposes, we may share your personal data
                    across the Northern Cape Liquor Board as well as other third
                    parties that process personal data in conjunction with us
                    and on our behalf, when required. By clicking the square, I
                    confirm that I give consent to my personal information being
                    processed for this purpose.
                  </Typography>
                }
              />
            </StyledTermContainer>
            <StyledField>
              <StyledButtonRegister
                disabled={!isValid || isPassword !== isConfirmPassword}
                fullWidth={false}
                color="secondary"
                // className="self-center"
                onClick={handleSubmit(onSubmit)}
              >
                Register
              </StyledButtonRegister>
            </StyledField>
            <StyledSignInRow>
              <Typography
                className="StyledTypographyBook"
                color="primary"
                variant="body2"
                sx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  color: '#6D7074',
                }}
              >
                Already have an account?
              </Typography>
              <Typography
                color="primary"
                variant="body2"
                onClick={handleRedirectLogin}
                sx={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  color: '#6D7074',
                  marginLeft: '10px',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
              >
                <Box component="b"> Sign in</Box>
              </Typography>
            </StyledSignInRow>
          </Wrapper>
          <Modal
            type={registerState}
            showModal={modalState.show}
            onClose={handleCloseModal}
            title={modalState.title}
            description={modalState.description}
          />
        </StyledSectionSignUp>
      </Suspense>
    </PublicContentLayout>
  );
};

export default SignUp;
