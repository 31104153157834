const useSocialMedia = () => {
  const goToFacebook = 'https://www.facebook.com/NCMFET/';
  const goToTwitter = 'https://twitter.com/';
  const goToInstagram = 'https://www.instagram.com/';
  const goToWhatsapp = 'https://www.whatsapp.com/';

  const handleRedirectNewPage = (link) => {
    window.open(link, '_blank');
  };

  return {
    goToFacebook,
    goToTwitter,
    goToInstagram,
    goToWhatsapp,
    handleRedirectNewPage,
  };
};

export default useSocialMedia;
