import useHelpers from '../../../helpers';

const useVotingSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const votingSelector = createSelector(
    (state) => state.voting,
    (voting) => voting
  );

  const votesSelector = createSelector(
    (state) => state.votes,
    (votes) => votes
  );

  return {
    votingSelector,
    votesSelector,
  };
};

export default useVotingSelectors;
