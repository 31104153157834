const notificationsTypes = () => {
  const GET_LATEST_NOTIFICATIONS = 'GET_LATEST_NOTIFICATIONS';
  const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS';
  const SET_SIDE_NOTIFICATIONS = 'SET_SIDE_NOTIFICATIONS';
  const SET_NEW_NOTIFICATION = 'SET_NEW_NOTIFICATION';

  return {
    GET_LATEST_NOTIFICATIONS,
    GET_ALL_NOTIFICATIONS,
    SET_SIDE_NOTIFICATIONS,
    SET_NEW_NOTIFICATION,
  };
};
export default notificationsTypes;
