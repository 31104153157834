import styled from 'styled-components';
import tw from 'twin.macro';
export const StyledContactInfo = styled.div.attrs({
  className: 'StyledContactInfo',
})`
  && {
    ${tw` flex flex-col`}
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledRow = styled.div.attrs({
  className: 'StyledRow',
})`
  && {
    ${tw` flex flex-row flex-nowrap space-x-6 py-2 align-baseline`}
    width: 380px;
    align-self: center;
  }
`;

export const StyledRowDetails = styled.div.attrs({
  className: 'StyledRowDetails',
})`
  && {
    ${tw` flex flex-col`}
  }
`;
