import BackUser from './BackUser';
import BlogsEditor from './BlogsEditor';
import ChangePassword from './ChangePassword';
import EmailVerification from './EmailVerification';
import Home from './Home';
import Login from './Login';
import ApplicationForm from './ApplicationForm';
import Profile from './Profile';
import RecoveryPassword from './RecoveryPassword';
import ResetPassword from './ResetPassword';
import SignUp from './SignUp';
import Splash from './Splash';
import ApplicationsTable from './ApplicationsTable';
import Application from './Application';
import UsersTable from './UsersTable';
import ApplicationReview from './ApplicationReview';
import Logs from './Logs';
import RenewalNotice from './RenewalNotice';
import ResendEmail from './ResendEmail';
import Renewals from './Renewals';
import BlogsTable from './BlogsTable';
import BlogVisualization from './BlogVisualization';
import BlogsLastNews from './BlogsLastNews';
const useScreens = () => {
  return {
    Renewals,
    BlogsLastNews,
    BlogVisualization,
    BlogsTable,
    ResendEmail,
    BackUser,
    ChangePassword,
    Home,
    Login,
    RecoveryPassword,
    ResetPassword,
    Profile,
    SignUp,
    Splash,
    EmailVerification,
    ApplicationForm,
    ApplicationsTable,
    Application,
    UsersTable,
    ApplicationReview,
    Logs,
    RenewalNotice,
    BlogsEditor,
  };
};

export default useScreens;
