import { useEffect, useState } from 'react';
import useComponentHooks from '..';
import useApi from '../../../api';
import useHelpers from '../../../helpers';
import useModels from '../../../models';

const useApplicationDocuments = (isValid, setValue, watch) => {
  // Api
  const { useActions } = useApi();
  const { dispatch, useApplicationFormActions, useApplicationsTableActions } =
    useActions();
  const {
    actSetCurrentStep,
    actSaveUserApplication,
    actSubmitApplicationFormStep2,
    actEnableFinalStep,
    actCancelApplication,
    actUpdateApplication,
    actSetDocumentsDraft,
  } = useApplicationFormActions();
  const { actGetApplicationsTable } = useApplicationsTableActions();

  const { useModal } = useComponentHooks();
  const { modalState, handleShowModal } = useModal();
  const {
    modalState: modalStateCancel,
    handleShowModal: handleShowModalCancel,
  } = useModal();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation, usePromises } = useQuickFunctions();
  const { navigateTo, goBack } = useNavigation();
  const { promiseInProgressArea: promiseSaveUserApplication } = usePromises(
    'saveUserApplication'
  );
  const { promiseInProgressArea: promiseUpdateUserApplication } = usePromises(
    'updateUserApplication'
  );

  const { promiseInProgressArea: promiseCancelApplication } =
    usePromises('cancelApplication');
  const { promiseInProgressArea: promiseGetApplicationImages } = usePromises(
    'getApplicationImages'
  );

  const { useSelectors } = useModels();
  const { useSelector, useApplicationFormSelectors } = useSelectors();
  const { applicationFormSelector, applicationFormHelpDataSelector } =
    useApplicationFormSelectors();
  const { currentStep, applicationForm, applicationFormDraft } = useSelector(
    applicationFormSelector
  );
  const { landUseRightsDocumentType, typeOfRegistration } = useSelector(
    applicationFormHelpDataSelector
  );

  const [currentValueLandState, setCurrentValueLandState] = useState(1);
  const [
    currentcurrentTypeOfRegistrationState,
    setCurrentTypeOfRegistrationState,
  ] = useState(1);

  const currentValueLand = watch('landUseRightsDocumentTypeId');
  const currentTypeOfRegistration = watch('typeOfRegistrationId');
  const fileTypesPdf = ['application/pdf'];
  const fileTypesImage = ['image/jpeg', 'image/png'];

  useEffect(() => {
    if (setValue) {
      applicationForm.photo1?.data &&
        setValue('photo1', handleFileNameExtension(applicationForm.photo1), {
          shouldValidate: true,
        });
      applicationForm.photo2?.data &&
        setValue('photo2', handleFileNameExtension(applicationForm.photo2), {
          shouldValidate: true,
        });
      applicationForm.photo3?.data &&
        setValue('photo3', handleFileNameExtension(applicationForm.photo3), {
          shouldValidate: true,
        });
      applicationForm.photo4?.data &&
        setValue('photo4', handleFileNameExtension(applicationForm.photo4), {
          shouldValidate: true,
        });
      applicationForm.photo5?.data &&
        setValue('photo5', handleFileNameExtension(applicationForm.photo5), {
          shouldValidate: true,
        });
    }
  }, [
    setValue,
    applicationForm.photo1,
    applicationForm.photo2,
    applicationForm.photo3,
    applicationForm.photo4,
    applicationForm.photo5,
  ]);

  useEffect(() => {
    dispatch(actEnableFinalStep({ status: isValid }));
  }, [isValid]);

  const onSaveDraftDocumentById = (id, data) => {
    dispatch(actSetDocumentsDraft({ field: id, data: data }));
  };

  useEffect(() => {
    setValue(
      'typeOfRegistrationId',
      applicationForm.typeOfRegistrationId
        ? applicationForm.typeOfRegistrationId
        : typeOfRegistration[0].id
    );
    setValue(
      'landUseRightsDocumentTypeId',
      applicationForm.landUseRightsDocumentTypeId
        ? applicationForm.landUseRightsDocumentTypeId
        : landUseRightsDocumentType[0].id
    );
    setValue(
      'businessDocument',
      handleFileNameExtension(applicationForm.businessDocument)
    );
    setValue(
      'certifiedId',
      handleFileNameExtension(applicationForm.certifiedId)
    );
    setValue(
      'townPlanningScheme',
      handleFileNameExtension(applicationForm.townPlanningScheme)
    );
    setValue(
      'foundingConditions',
      handleFileNameExtension(applicationForm.foundingConditions)
    );
    setValue(
      'titleDeedOfLand',
      handleFileNameExtension(applicationForm.titleDeedOfLand)
    );
    setValue(
      'tribalResolution',
      handleFileNameExtension(applicationForm.tribalResolution)
    );
  }, [applicationForm]);

  useEffect(() => {
    setCurrentValueLandState(currentValueLand);
  }, [currentValueLand]);
  useEffect(() => {
    setCurrentTypeOfRegistrationState(currentTypeOfRegistration);
  }, [currentTypeOfRegistration]);

  // only used for existing file
  const handleFileNameExtension = (fileObject) => {
    const fileObjectWithoutExtension = {
      name: fileObject?.name ? fileObject.name.replace(/\.[^/.]+$/, '') : '',
      data: fileObject?.data ? fileObject.data : '',
    };
    return fileObjectWithoutExtension;
  };

  const onChangeFilePdf = ({ event, remove, reset, id }) => {
    event.preventDefault();
    if (event.target.files.length > 0) {
      const { type } = event.target.files[0];
      if (!fileTypesPdf.some((s) => type.includes(s))) {
        alert('invalid document type');
        remove(reset, id);
      } else {
        if (event.target.files[0].size > 2000000) {
          alert('Document too large, please try again with 2MB or less');
          remove(reset, id);
        }
      }
    }
  };

  const onChangeFileImage = ({ event, remove, reset, id }) => {
    event.preventDefault();
    if (event.target.files.length > 0) {
      const { type } = event.target.files[0];
      if (!fileTypesImage.some((s) => type.includes(s))) {
        alert('invalid document type');
        remove(reset, id);
      } else {
        if (event.target.files[0].size > 2000000) {
          alert('Document too large, please try again with 2MB or less');
          remove(reset, id);
        }
      }
    }
  };

  const handleApplicationFormStep = (nextStep) => {
    dispatch(actSetCurrentStep({ step: nextStep }));
  };

  const handleCancelApplication = () => {
    handleShowModalCancel();
  };
  const handleRequestApplication = () => {
    dispatch(actGetApplicationsTable({ page: 1 }, navigateTo(`/applications`)));
    handleShowModalCancel();
  };
  const handleCloseModalCancel = () => {
    applicationForm.status?.id !== 7 &&
      dispatch(
        actCancelApplication(
          {
            id: applicationForm.id ? applicationForm.id : '',
          },
          handleRequestApplication
        )
      );
  };

  const handleCloseModalSuccesHome = () => {
    handleShowModal();
    navigateTo(`/home`);
  };

  const handleCloseModalSucces = () => {
    handleShowModal();
    navigateTo(`/applications`);
  };

  const handleSuccessSave = () => {
    handleShowModal(
      'Your application was saved!',
      'To view your saved application, click on the  "my applications" at the top of the Homepage.'
    );
  };

  const handleSaveUserApplication = (data) => {
    const draftApplicationData = { ...applicationForm, ...data };
    dispatch(actSaveUserApplication(draftApplicationData, handleSuccessSave));
  };

  const handleUpdateUserApplication = (data) => {
    const draftApplicationData = { ...applicationForm, ...data };
    dispatch(actUpdateApplication(draftApplicationData, handleSuccessSave));
  };
  const onSubmit = (data) => {
    let dataToSend = data;
    dataToSend['photograpsExternalInternalFeatures'] = [
      data.photo1 ? data.photo1 : { name: '', data: '' },
      data.photo2 ? data.photo2 : { name: '', data: '' },
      data.photo3 ? data.photo3 : { name: '', data: '' },
      data.photo4 ? data.photo4 : { name: '', data: '' },
      data.photo5 ? data.photo5 : { name: '', data: '' },
    ];
    delete dataToSend.photo1;
    delete dataToSend.photo2;
    delete dataToSend.photo3;
    delete dataToSend.photo4;
    delete dataToSend.photo5;
    dispatch(
      actSubmitApplicationFormStep2(dataToSend, handleApplicationFormStep(3))
    );
  };

  const onSubmitDraft = (data) => {
    applicationForm.status = 1;

    let dataToSend = data;
    dataToSend['photograpsExternalInternalFeatures'] = [
      data.photo1 ? data.photo1 : { name: '', data: '' },
      data.photo2 ? data.photo2 : { name: '', data: '' },
      data.photo3 ? data.photo3 : { name: '', data: '' },
      data.photo4 ? data.photo4 : { name: '', data: '' },
      data.photo5 ? data.photo5 : { name: '', data: '' },
    ];

    delete dataToSend.photo1;
    delete dataToSend.photo2;
    delete dataToSend.photo3;
    delete dataToSend.photo4;
    delete dataToSend.photo5;

    if (applicationForm.id) {
      dispatch(
        actSubmitApplicationFormStep2(dataToSend, handleUpdateUserApplication)
      );
    } else {
      dispatch(
        actSubmitApplicationFormStep2(dataToSend, handleSaveUserApplication)
      );
    }
  };
  const onSubmitLocal = (data) => {
    let dataToSend = data;
    dataToSend['photograpsExternalInternalFeatures'] = [
      data.photo1 ? data.photo1 : { name: '', data: '' },
      data.photo2 ? data.photo2 : { name: '', data: '' },
      data.photo3 ? data.photo3 : { name: '', data: '' },
      data.photo4 ? data.photo4 : { name: '', data: '' },
      data.photo5 ? data.photo5 : { name: '', data: '' },
    ];

    delete dataToSend.photo1;
    delete dataToSend.photo2;
    delete dataToSend.photo3;
    delete dataToSend.photo4;
    delete dataToSend.photo5;

    dispatch(
      actSubmitApplicationFormStep2(dataToSend, handleApplicationFormStep(1))
    );
  };
  return {
    promiseSaveUserApplication,
    promiseUpdateUserApplication,
    promiseCancelApplication,
    promiseGetApplicationImages,
    goBack,

    onSubmit,
    onSubmitDraft,
    onSubmitLocal,
    applicationForm,
    handleApplicationFormStep,
    currentStep,
    onChangeFilePdf,
    onChangeFileImage,
    fileTypesPdf,
    fileTypesImage,
    applicationFormDraft,
    handleCancelApplication,
    modalState,
    modalStateCancel,
    handleCloseModalSucces,
    handleCloseModalSuccesHome,
    handleCloseModalCancel,
    handleShowModalCancel,
    handleFileNameExtension,
    typeOfRegistration,
    landUseRightsDocumentType,
    onSaveDraftDocumentById,
    currentValueLandState,
    currentcurrentTypeOfRegistrationState,
  };
};

export default useApplicationDocuments;
