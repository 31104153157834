import _ from 'lodash';
import { useEffect, useState } from 'react';
import useComponentHooks from '..';
import useApi from '../../../api';
import useModels from '../../../models';
import useHelpers from '../../../helpers';

const useReportsSubmitted = (setValue) => {
  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation, usePromises } = useQuickFunctions();
  const { navigateTo } = useNavigation();
  const { promiseInProgressArea: promiseGetSapsAndMunicipalityReports } =
    usePromises('getSapsAndMunicipalityReports');
  //Api
  const { useActions } = useApi();
  const {
    dispatch,
    useApplicationReviewActions,
    usePrivateDocumentsActions,
    useApplicationFormActions,
    useApplicationsTableActions,
  } = useActions();
  const {
    actGetSapsAndMunicipalityReports,
    actSetCurrentStepApplicationReview,
    actSubmitForVote,
    actSetStatusApplicationReview,
    actEditInspectionForm,
  } = useApplicationReviewActions();
  const { actGetPrivateDocument } = usePrivateDocumentsActions();
  const { actSetApplicationForm } = useApplicationFormActions();
  const { actGetApplicationById } = useApplicationsTableActions();

  //hooks
  const { useModal } = useComponentHooks();
  const {
    modalState: helperModalStateSuccess,
    handleShowModal: handleHelperModalStateSuccess,
  } = useModal();
  //Selectors
  const { useSelectors } = useModels();
  const {
    useSelector,
    useUserSelectors,
    useApplicationFormSelectors,
    useApplicationReviewSelectors,
  } = useSelectors();

  const { userSelector } = useUserSelectors();
  const { profile } = useSelector(userSelector);
  const { applicationReviewStateSelector } = useApplicationReviewSelectors();
  const { applicationFormSelector } = useApplicationFormSelectors();
  const { sapsAndMunicipalityReports, inspectionForm } = useSelector(
    applicationReviewStateSelector
  );
  const { applicationForm } = useSelector(applicationFormSelector);
  const [inspectionFormRecived, setInspectionFormRecived] = useState({});
  const fileTypesPdf = ['application/pdf'];
  const optionsInspectorForm = [
    { value: '1', text: ' ' },
    { value: '0', text: ' ' },
  ];
  const yesNoType = [
    { value: '1', text: 'Yes' },
    { value: '0', text: 'No' },
  ];

  const typeOfInterest = [
    { value: '1', text: 'Do you own a stake in the business?' },
    {
      value: '2',
      text: 'Have you previously conducted business with the applicant?',
    },
    { value: '3', text: 'Is your interest in financial value/gains?' },
    { value: '4', text: 'Other' },
  ];

  const [helperModalSuccessTypeState, setHelperModalSuccessTypeState] =
    useState('error');

  useEffect(() => {
    applicationForm.id &&
      dispatch(
        actGetSapsAndMunicipalityReports({ applicationId: applicationForm.id })
      );
  }, []);

  useEffect(() => {
    _.forEach(inspectionForm, (value, key) => {
      setValue && setValue(key, `${value}`);
      setInspectionFormRecived({
        ...inspectionFormRecived,
        [`${key}`]: `${value}`,
      });
    });
  }, [inspectionForm]);

  const handleEditInspectionForm = () => {
    dispatch(actEditInspectionForm(true));
  };

  const handleGetPrivateLink = (url) => {
    window.open(url);
  };

  const handleGetPrivateDocumentView = (documentName) => {
    dispatch(
      actGetPrivateDocument(
        {
          documentName: documentName,
          applicationId: applicationForm.id,
        },
        handleGetPrivateLink
      )
    );
  };

  // modal success control
  const handleGetApplicationSuccess = (data) => {
    // dispatch(actSetApplicationForm({ application: data.data.application }));
    switch (data.application.status.id) {
      case 4:
        dispatch(
          actSetApplicationForm(
            { application: data.application },
            dispatch(
              actSetCurrentStepApplicationReview(
                { step: 2 },
                dispatch(
                  actGetSapsAndMunicipalityReports({
                    applicationId: applicationForm.id,
                  })
                )
              )
            )
          )
        );
        dispatch(actSetStatusApplicationReview({ steps: 2 }));

        break;
      case 5:
        dispatch(
          actSetApplicationForm(
            { application: data.application },
            dispatch(actSetCurrentStepApplicationReview({ step: 3 }))
          )
        );
        dispatch(actSetStatusApplicationReview({ steps: 3 }));

        break;
      case 6:
        if (profile.roleType.id === 1) {
          dispatch(
            actSetApplicationForm(
              { application: data.application },
              dispatch(actSetCurrentStepApplicationReview({ step: 4 }))
            )
          );
          dispatch(actSetStatusApplicationReview({ steps: 4 }));
        } else {
          navigateTo('/applications');
        }

        break;
      default:
        dispatch(actSetApplicationForm({ application: data.application }));
        break;
    }
  };

  const handleCloseHelperModalSuccess = () => {
    handleHelperModalStateSuccess();
    dispatch(
      actGetApplicationById(
        { id: applicationForm.id },
        handleGetApplicationSuccess
      )
    );
  };

  const handleSubmitForVoteSuccess = () => {
    setHelperModalSuccessTypeState('success');
    handleHelperModalStateSuccess(
      'Success!',
      "Application ready for Board's adjudication"
    );
  };

  const onSubmiForVote = () => {
    const dataSubmiForVote = {
      applicationId: applicationForm.id,
      newStatusId: '6',
    };
    applicationForm.id &&
      dispatch(actSubmitForVote(dataSubmiForVote, handleSubmitForVoteSuccess));
  };
  return {
    promiseGetSapsAndMunicipalityReports,
    yesNoType,
    profile,
    applicationForm,
    fileTypesPdf,
    sapsAndMunicipalityReports,
    inspectionForm,
    optionsInspectorForm,
    handleGetPrivateDocumentView,
    onSubmiForVote,
    helperModalStateSuccess,
    helperModalSuccessTypeState,
    handleCloseHelperModalSuccess,
    handleEditInspectionForm,

    typeOfInterest,
  };
};

export default useReportsSubmitted;
