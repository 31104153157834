import { trackPromise } from 'react-promise-tracker';
import useProviders from '../../providers';

const useApplicationReviewService = () => {
  const { useApplicationReviewProviders } = useProviders();
  const {
    sendComment,
    approveApplication,
    getSapsAndMunicipalityReports,
    uploadApplicationReviewReports,
    sendInspectionReport,
    submitForVote,
    grantApplication,
    rejectApplication,
  } = useApplicationReviewProviders();

  const sendCommentService = (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(sendComment(data)));
      } catch (e) {
        reject(e);
      }
    });
  };

  const approveApplicationService = (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(approveApplication(data)));
      } catch (e) {
        reject(e);
      }
    });
  };

  const getSapsAndMunicipalityReportsService = (applicationId) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(
            getSapsAndMunicipalityReports(applicationId),
            'getSapsAndMunicipalityReports'
          )
        );
      } catch (e) {
        reject(e);
      }
    });
  };

  const uploadApplicationReviewReportsService = (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(uploadApplicationReviewReports(data)));
      } catch (e) {
        reject(e);
      }
    });
  };
  const sendInspectionReportService = (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(sendInspectionReport(data)));
      } catch (e) {
        reject(e);
      }
    });
  };

  const submitForVoteService = (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(submitForVote(data)));
      } catch (e) {
        reject(e);
      }
    });
  };

  const grantApplicationService = (applicationId) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(grantApplication(applicationId)));
      } catch (e) {
        reject(e);
      }
    });
  };

  const rejectApplicationService = (applicationId, data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(rejectApplication(applicationId, data)));
      } catch (e) {
        reject(e);
      }
    });
  };
  return {
    sendCommentService,
    approveApplicationService,
    getSapsAndMunicipalityReportsService,
    uploadApplicationReviewReportsService,
    sendInspectionReportService,
    submitForVoteService,
    grantApplicationService,
    rejectApplicationService,
  };
};

export default useApplicationReviewService;
