import styled from 'styled-components';
import tw from 'twin.macro';

export const StyledHeader = styled.header.attrs({
  className: 'StyledHeader',
})`
  && {
    ${tw` flex flex-row py-[20px] px-[40px] justify-between`}
    height: 80px;
    background-color: #f8f9fa;
    opacity: 1;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    @media (max-width: 768px) {
      align-items: center;
      background: #5e5c5c;
      opacity: 0.8;
      height: 44px;
      padding: 6px 16px;
    }
  }
`;

export const StyledNavBar = styled.div.attrs({
  className: 'StyledNavBar',
})`
  && {
    ${tw` relative flex flex-row space-x-3.5 `}
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledLogoContainer = styled.div.attrs({
  className: 'StyledLogoContainer',
})`
  && {
    ${tw` relative cursor-pointer`}
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledLogoMobileContainer = styled.div.attrs({
  className: 'StyledLogoMobileContainer',
})`
  && {
    ${tw` relative cursor-pointer`}
    display: none;
    @media (max-width: 768px) {
      display: block;
      margin: 0 auto;
    }
  }
`;

export const StyledHamMenuContainer = styled.div.attrs({
  className: 'StyledHamMenuContainer',
})`
  && {
    ${tw` absolute cursor-pointer`}
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
`;
