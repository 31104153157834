import React from 'react';
// import PropTypes from 'prop-types';

// Input
import AvatarField from '../AvatarField';

const AvatarControlled = () => {
  return <AvatarField />;
};

AvatarControlled.propTypes = {};

AvatarControlled.defaultProps = {};

export default AvatarControlled;
