import axios from 'axios';

const useApplicationFormProvider = () => {
  const applicationHelpData = () => {
    return axios({
      method: 'get',
      url: `api/application-help-data`,
    });
  };

  const saveUserApplication = (data) => {
    return axios({
      method: 'post',
      url: `api/save-user-application`,
      data,
    });
  };

  const saveFinalSubmitApplication = (data) => {
    return axios({
      method: 'post',
      url: `api/save-user-application`,
      data,
    });
  };

  const cancelApplication = (id) => {
    return axios({
      method: 'delete',
      url: `api/cancel-application/${id}`,
    });
  };

  const updateApplication = (data) => {
    return axios({
      method: 'put',
      url: `api/update-application/${data.id}`,
      data,
    });
  };
  const updateFinalSubmitApplication = (data) => {
    return axios({
      method: 'put',
      url: `api/update-application/${data.id}`,
      data,
    });
  };
  const photosZip = (id) => {
    return axios({
      method: 'get',
      responseType: 'blob',
      url: `api/photos-zip/${id}`,
    });
  };

  const uploadApplicationFeeProofOfPayment = (data) => {
    return axios({
      method: 'post',
      url: `api/upload-fee-proof-of-payment`,
      data,
    });
  };

  const getLicenseFeePdf = (applicationId) => {
    return axios({
      method: 'get',
      url: `api/download-licence-fee-pdf/${applicationId}`,
    });
  };

  const approveApplicationFeeProofOfPayment = (data) => {
    return axios({
      method: 'patch',
      url: `api/approve-application-payment/${data.applicationId}`,
      data: {
        amountReceived: data.amountReceived,
        receiptNumber: data.receiptNumber,
      },
    });
  };

  const getApplicationFiles = (applicationId) => {
    return axios({
      method: 'get',
      url: `api/application-form-files/${applicationId}`,
    });
  };

  const getApplicationImages = (applicationId) => {
    return axios({
      method: 'get',
      url: `api/application-photos/${applicationId}`,
    });
  };
  return {
    getApplicationImages,
    applicationHelpData,
    saveUserApplication,
    saveFinalSubmitApplication,
    cancelApplication,
    updateApplication,
    updateFinalSubmitApplication,
    photosZip,
    uploadApplicationFeeProofOfPayment,
    getLicenseFeePdf,
    approveApplicationFeeProofOfPayment,
    getApplicationFiles,
  };
};

export default useApplicationFormProvider;
