import styled from 'styled-components';
import tw from 'twin.macro';
import Button from '../../components/Buttons';
import Typography from '../../components/Typography';
import CircularProgress from '../../components/CircularProgress';
import InputFileFieldControlled from '../../components/InputFileFieldControlled';
import Accordion from '../../components/Accordion';
import TooltipField from '../../components/TooltipField';
import { Avatar } from '@mui/material';
export const StyledSectionApplication = styled.div.attrs({
  className: 'StyledSectionMyApplications',
})`
&& {
    ${tw`relative  mt-[48px] pb-[48px
    ] mx-[10px]`}
    width: calc(100% - 20px);
    box-sizing:content-box;

    @media (max-width: 768px){
      width: calc(100% - 32px);
      margin: 0 16px;
      padding-top: 16px;
      padding-bottom: 24px;
    }
    }}
    
`;
export const StyledButtonDownloadLicense = styled(Button).attrs({
  className: 'StyledButtonDownloadLicense',
})`
  && {
    ${tw`absolute flex`}
    column-gap: 16px;
    align-items: center;
    width: 195px;
    height: 44px;
    right: 0px;
    top: -32px;

    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    z-index: 7;
    @media (max-width: 767.98px) {
      display: none;
    }
  }
`;

export const StyledButtonDownloadLicenseMobile = styled(Button).attrs({
  className: 'StyledButtonDownloadLicenseMobile',
})`
  && {
    ${tw`relative`}
    display: none;
    margin: 0 auto;
    column-gap: 16px;
    align-items: center;
    width: 195px;
    height: 44px;

    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    z-index: 7;
    @media (max-width: 767.98px) {
      display: flex;
      justify-self: center;
    }
  }
`;
export const StyledContainerDownload = styled.a.attrs({
  className: 'StyledContainerDownload ',
})`
  && {
    ${tw`absolute flex`}
    padding-left: 72px;
    width: 100%;
    height: 44px;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
  }
`;
export const StyledContainerIconDownloadTop = styled.a.attrs({
  className: 'StyledContainerIconDownloadTop ',
})`
  && {
    ${tw`absolute`}
    left: 24px;
    display: grid;
    place-content: center;
    cursor: pointer;
  }
`;

export const StyledConteinerCircularProgress = styled.div.attrs({
  className: 'StyledConteinerCircularProgress',
})`
&& {
    ${tw`absolute  `}
    display: grid;
    place-content: center;
    top: 0;
    right: 0;
    transform:translate(0,-64px);
    @media (max-width: 768px){
      ${tw`relative`}
      margin-top: 96px;
    }
    }}
`;

export const StyledCircularProgress = styled(CircularProgress).attrs({
  className: 'StyledCircularProgress',
})`
&& {
    ${tw`relative  `}
  
`;

export const StyledAccordionContainer = styled(Typography).attrs({
  className: 'StyledAccordionContainer',
})`
  && {
    ${tw`relative`}
    border: 1px solid #ECEFF3;
    box-sizing: border-box;
    border-radius: 4px;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
export const StyledTitleAccordion = styled(Typography).attrs({
  className: 'StyledTitleAccordion StyledTypographyBold',
})`
  && {
    ${tw`relative`}
    font-size: 14px;
    line-height: 20px;
    letter-spacing: +0.021em;

    color: #d66127;
  }
`;

export const StyledFieldsContainer = styled.div.attrs({
  className: 'StyledFieldsContainer',
})`
  && {
    ${tw`relative flex flex-wrap pb-[32px]`}
    gap: 20px 40px;
    & > * {
      flex: 0 1 calc(50% - 20px);
    }
    @media (max-width: 767.98px) {
      padding-top: 20px;
      flex-direction: column;
    }
  }
`;

export const StyledFieldsContainerLastApprove = styled.div.attrs({
  className: 'StyledFieldsContainerLastApprove',
})`
  && {
    ${tw`relative grid`}
    margin-top: 32px;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
    column-gap: 20px @media (max-width: 767.98px) {
      display: none;
    }
  }
`;
export const StyledFieldsContainerFeeInnerContainer = styled.div.attrs({
  className: 'StyledFieldsContainerFeeInnerContainer',
})`
  && {
    ${tw`relative`}
    @media (max-width: 767.98px) {
      display: none;
    }
  }
`;
export const StyledFieldsContainerMultiple = styled.div.attrs({
  className: 'StyledFieldsContainerMultiple',
})`
  && {
    ${tw`relative flex flex-wrap pb-[32px]`}
    gap: 20px 40px;
    & > * {
      flex: 1 1 calc(33% - 80px);
    }
    @media (max-width: 767.98px) {
      display: none;
    }
  }
`;

export const StyledFieldsColumnContainer = styled.div.attrs({
  className: 'StyledFieldsColumnContainer',
})`
  && {
    ${tw`relative flex flex-col pb-[32px]`}
    max-width: 100%;
    row-gap: 28px;

    @media (max-width: 768px) {
      padding-top: 20px;
    }
  }
`;
export const StyledCommentsMobileContainer = styled.div.attrs({
  className: 'StyledCommentsMobileContainer',
})`
  && {
    ${tw`relative`}
    display: none;

    @media (max-width: 767.98px) {
      display: flex;
      flex-wrap: no-wrap;
      column-gap: 8px;
    }
  }
`;

export const StyledCommentsMobile = styled(Typography).attrs({
  className: 'StyledCommentsMobile',
})`
  && {
    ${tw`relative`}
    font-size: 12px;
    line-height: 17px;
    color: #0b1e2e;
  }
`;
export const StyledCommentsMobileIconContainer = styled.div.attrs({
  className: 'StyledCommentsMobileIconContainer',
})`
&& {
    ${tw`relative  `}
    display: grid;
    place-content: center;
    margin: 0;
    width: 16px;
    height: 16px;

    }}
`;

export const StyledFieldRowFlex = styled.div.attrs({
  className: 'StyledFieldRowFlex',
})`
  justify-content: space-between;
  && {
    ${tw`relative flex `}
    margin-bottom: 6px;
    align-items: center;
    flex-wrap: no-wrap;
    column-gap: 24px;

    @media (max-width: 768px) {
      column-gap: 12px;
      flex-wrap: wrap;
    }
  }
`;

export const StyledFieldRow = styled.div.attrs({
  className: 'StyledFieldRow',
})`
  && {
    ${tw`relative flex `}
    margin-bottom: 6px;
    align-items: center;
    flex-wrap: no-wrap;
    column-gap: 24px;

    @media (max-width: 768px) {
      column-gap: 12px;
      flex-wrap: wrap;
    }
  }
`;

export const StyledFieldRowDateContainer = styled.div.attrs({
  className: 'StyledFieldRowDateContainer',
})`
  && {
    ${tw`relative flex `}
    margin-bottom: 62px;
    align-items: center;
    flex-wrap: no-wrap;
    column-gap: 24px;

    @media (max-width: 768px) {
      column-gap: 12px;
      flex-wrap: wrap;
    }
  }
`;

export const StyledFieldRowTitle = styled(Typography).attrs({
  className: 'StyledFieldRowTitle ',
})`
  && {
    ${tw`relative`}
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #b6bbc1;
  }
`;

export const StyledItemStatusInProgress = styled.div.attrs({
  className: 'StyledItemStatusInProgress',
})`
  && {
    ${tw`relative  `}
    padding: 2px 8px;
    height: 21px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 32px;
    font-size: 12px;
    line-height: 17px;

    background: #dce3bf;
    border: 1px solid #b9c780;
    color: #64722b;
  }
  .StyledItemStatusInProgress::first-letter {
    text-transform: uppercase;
  }
`;

export const StyledFieldRowDate = styled(Typography).attrs({
  className: 'StyledFieldRowDate',
})`
  && {
    ${tw`relative`}
    left: -10px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;

    color: #2c2d2e;
  }
`;

export const StyledTitleSection = styled(Typography).attrs({
  className: 'StyledTitleSection StyledTypographyBold',
})`
  && {
    ${tw`relative`}
    padding-top: 32px;
    padding-bottom: 24px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.017em;

    color: #d66127;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledCommentsContainer = styled.div.attrs({
  className: 'StyledCommentsContainer',
})`
  && {
    ${tw`relative mt-[36px]`}
  }
`;
export const StyledCommentsInnerContainer = styled.div.attrs({
  className: 'StyledCommentsInnerContainer',
})`
  && {
    ${tw`relative flex flex-col`}
    row-gap: 8px;
  }
`;
export const StyledCommentTitle = styled(Typography).attrs({
  className: 'StyledCommentTitle StyledTypographyBold',
})`
  && {
    ${tw`relative flex`}
    flex-wrap: no-wrap;
    column-gap: 8px;
    align-items: center;

    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.011em;
    color: #ff2d55;
  }
`;

export const StyledCommentDescription = styled(Typography).attrs({
  className: 'StyledCommentDescription StyledTypographyBook',
})`
  && {
    ${tw`relative`}
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #000000;
    @media (max-width: 767.98px) {
      margin-left: 24px;
      margin-bottom: 56px;
    }
  }
`;

export const StyledButtonEditApplication = styled(Button).attrs({
  className: 'StyledButtonEditApplication',
})`
  && {
    ${tw`relative flex`}
    align-self: flex-end;
    margin-top: 10;
    width: 160px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #ffffff;
    background: #ff2d55 !important;
    border: 2px solid #ff2d55 !important;
    @media (max-width: 767.98px) {
      display: none;
    }
  }
`;
export const StyledFieldRowReference = styled(Typography).attrs({
  className: 'StyledFieldRowReference StyledTypographyBold',
})`
  && {
    ${tw`relative`}
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.017em;

    color: #2c2d2e;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;
export const StyledFieldColumn = styled.div.attrs({
  className: 'StyledFieldColumn',
})`
  && {
    ${tw`relative flex flex-col`}
    min-width: 25%;
  }
`;
export const StyledFieldColumnProofOfPayment = styled.div.attrs({
  className: 'StyledFieldColumnProofOfPayment',
})`
  && {
    ${tw`relative flex`}
    min-width: 100%;
    align-items: center;
    // flex-wrap: wrap;
    column-gap: 16px;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledInputFieldProofOfPayment = styled(
  InputFileFieldControlled
).attrs({
  className: 'StyledInputFieldProofOfPayment',
})`
  && {
    ${tw`relative flex`}
    width: 600px;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
export const StyledContainerImage = styled.div.attrs({
  className: 'StyledContainerImage',
})`
  flex-direction: row;
  flex: 1;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const StyledDownloadContainer = styled.a.attrs({
  className: 'StyledDownloadContainer',
})`
  && {
    ${tw`relative`}
    display: grid;
    place-content: center;
    width: 21px;
    height: 21px;
    cursor: pointer;
  }
`;
export const StyledDownloadImageContainer = styled.a.attrs({
  className: 'StyledDownloadImageContainer',
})`
  margin-left: 10px;
`;

export const StyledFieldColumnTitle = styled(Typography).attrs({
  className: 'StyledFieldColumnTitle',
})`
  && {
    ${tw`relative`}
    font-size: 12px;
    line-height: 17px;

    color: #6d7074;
  }
`;

export const StyledFieldColumnValue = styled(Typography).attrs({
  className: 'StyledFieldColumnValue StyledTypographyBook',
})`
  && {
    ${tw`relative`}
    font-size: 14px;
    line-height: 20px;flex;
    align-items: center;
    letter-spacing: -0.006em;

    color: #2C2D2E;

  }
`;

export const StyledRowPhoneIndicative = styled.div.attrs({
  className: 'StyledRowPhoneIndicative',
})`
  && {
    ${tw`relative`}
    width: 78px;
    max-width: 78px;
    margin-right: 16px;
    @media (max-width: 768px) {
      width: 78px;
      max-width: 78px;
    }
  }
`;

export const StyledRowPhoneNumber = styled.div.attrs({
  className: 'StyledRowPhoneNumber',
})`
  && {
    ${tw`relative`}
    height: 100%;
    width: 100%;
    max-width: 268px;
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
`;
export const StyledFieldRowBottom = styled.div.attrs({
  className: 'StyledFieldRowBottom',
})`
  && {
    ${tw`relative flex`}
    margin-top: 22px;
    align-items: center;
    justify-content: space-between;
  }
`;
export const StyledFieldRowBottomContainer = styled.div.attrs({
  className: 'StyledFieldRowBottomContainer',
})`
  && {
    ${tw`relative flex`}
    margin-top: 60px;
    align-items: center;
    justify-content: space-between;
  }
`;
export const StyledRowButtons = styled.div.attrs({
  className: 'StyledRowButtons',
})`
  && {
    ${tw`relative flex`}
    column-gap: 16px;
  }
`;

export const StyledButtonSaveAndContinue = styled(Button).attrs({
  className: 'StyledButtonSaveAndContinue',
})`
  && {
    ${tw`relative`}

    width: 250px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 130px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;

export const StyledButtonContinue = styled(Button).attrs({
  className: 'StyledButtonContinue',
})`
  && {
    ${tw`relative`}
    top: 11px;
    width: 100px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
  }
`;

export const StyledImagesContainer = styled.div.attrs({
  className: 'StyledImagesContainer',
})`
  display: flex;
`;

export const StyledFieldsRowContainer = styled.div.attrs({
  className: 'StyledFieldsRowContainer',
})`
  justify-content: space-between;
  && {
    ${tw`relative flex flex-wrap`}
    column-gap: 40px;
  }
`;

export const StyledSubTitle = styled(Typography).attrs({
  className: 'StyledSubTitle',
})`
  && {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 6px 0;
    margin-top: 70px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.017em;
    color: #494b4d;
    @media (max-width: 768px) {
      margin-top: 0px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;
export const StyledAvatarField = styled(Avatar).attrs({
  className: 'StyledAvatarField',
})`
  && {
    margin-left: 5px;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    border: 1px solid #dae0e8;
  }
`;
export const StyledFileImageLabel = styled.label.attrs({
  className: 'StyledFileImageLabel',
})`
  && {
    position: relative;
    cursor: pointer;
    z-index: 10;
  }
`;

// Accordion Document

export const StyledDocumentsReportAccordion = styled(Accordion).attrs({
  className: 'StyledDocumentsReportAccordion',
})`
  && {
    ${tw`relative`}
    max-width: 764px;

    margin: 32px 0 !important;
    margin-bottom: 48px 0 !important;
    background-color: #ffffff !important;
    opacity: 1 !important;
    color: #242731 !important;
    border: 1px solid #eceff3 !important;
    border-radius: 4px !important;
    .Mui-disabled {
      pointer-events: auto;
      opacity: 1;
    }
  }
`;
export const StyledTitleDocumentReportContainerAccordion = styled.div.attrs({
  className: 'StyledTitleDocumentReportContainerAccordion ',
})`
  && {
    ${tw`relative`}
    width: 100%;
    max-width: 764px;

    display: flex;
    align-items: center;
  }
`;
export const StyledTitleReportAccordion = styled(Typography).attrs({
  className: 'StyledTitleReportAccordion StyledTypographyBook',
})`
  && {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #242731;
  }
`;
export const StyledContainerIconView = styled.a.attrs({
  className: 'StyledContainerIconView ',
})`
  && {
    ${tw`absolute`}
    right:0;
    transform: translate(-48px, 0);
    display: grid;
    place-content: center;
    cursor: pointer;
  }
`;

export const StyledContainerDownloadAccordion = styled.a.attrs({
  className: 'StyledContainerDownloadAccordion ',
})`
  && {
    ${tw`absolute flex`}
    left: 100%;
    transform: translate(-100%, 0);
    width: 30px;
    height: 44px;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
  }
`;

export const StyledContainerStamp = styled.div.attrs({
  className: 'StyledContainerStamp',
})`
  && {
    ${tw`relative grid`}
    place-content: center;
    width: 300px;
    @media (max-width: 767.98px) {
      display: none;
    }
  }
`;

export const StyledContainerStampMobile = styled.div.attrs({
  className: 'StyledContainerStampMobile',
})`
  && {
    ${tw`relative `}
    display:none;
    margin: 32px auto;
    width: 120px;
    heigt: 120px;
    right: 0;
    place-content: center;
    @media (max-width: 768px) {
      display: grid;
    }
  }
`;
export const StyledStamp = styled.img.attrs({
  className: 'StyledStamp',
})`
  && {
    ${tw`relative`}
    width: 120px;
    heigt: 120px;
  }
`;

// modal advise
// modal styles

export const StyledTitleModal = styled(Typography).attrs({
  className: 'StyledTitleModal StyledTypographyBold',
})`
  && {
    margin-top: 24px;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    text-align: center;
    letter-spacing: -0.017em;
  }
`;
export const StyledChildContainerModal = styled.div.attrs({
  className: 'StyledChildContainerModal',
})`
  && {
    ${tw`relative flex flex-col`}
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;
export const StyledContainerAdviseIconModal = styled.div.attrs({
  className: 'StyledContainerAdviseIconModal',
})`
  && {
    ${tw`relative grid`}

    margin-top: 24px;
    margin-bottom: 8px;
    width: 160px;
    height: 160px;
    place-content: center;
    @media (max-width: 768px) {
      margin: 0;
      width: 80px;
      height: 80px;
    }
  }
`;
export const StyledSubtitleModalCancel = styled(Typography).attrs({
  className: 'StyledSubtitleModalCancel',
})`
  && {
    margin-top: 16px;
    max-width: 460px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    letter-spacing: -0.006em;

    color: #6d7074;
  }
`;

export const StyledButtonModal = styled(Button).attrs({
  className: 'StyledButtonModal',
})`
  && {
    ${tw`relative`}

    width: 250px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    margin-top: 36px;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 160px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;

// Tooltip proof of payment

export const StyledTooltipField = styled(TooltipField).attrs({
  className: 'StyledTooltipField',
})`
  && {
    .MuiTooltip-popper .MuiTooltip-tooltip {
      max-width: 400px !important;
    }
  }
`;

export const StyledTypographyTooltip = styled(Typography).attrs({
  className: 'StyledTypographyTooltip StyledTypographyLight',
})`
  && {
    font-size: 12px;
    line-height: 17px;
    color: #ffffff;
    margin-bottom: 4px;
  }
`;

export const StyledTypographyTooltipBold = styled(Typography).attrs({
  className: 'StyledTypographyTooltipBold',
})`
  && {
    font-size: 12px;
    line-height: 17px;
    color: #ffffff;
    margin-bottom: 4px;
  }
`;
export const StyledTooltipBorder = styled.div.attrs({
  className: 'StyledTooltipBorder',
})`
  && {
    margin: 8px 0;
    width: 100%;
    height: 0;
    border-top: 1px solid #ffffff;
  }
`;
