import { Route, Switch } from 'react-router';
import useViews from '../../views';
import React from 'react';

const PublicRouting = () => {
  const { useScreens } = useViews();
  const {
    EmailVerification,
    Splash,
    Home,
    SignUp,
    Login,
    ResetPassword,
    RecoveryPassword,
    ResendEmail,
    BlogVisualization,
    BlogsLastNews,
  } = useScreens();
  return (
    <Switch>
      <Route exact path="/" component={Splash} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/blog-view" component={BlogVisualization} />
      <Route exact path="/latest-news" component={BlogsLastNews} />
      <Route exact path="/verify-email" component={EmailVerification} />
      <Route exact path="/signup" component={SignUp} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/recovery-password" component={RecoveryPassword} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/resend-email" component={ResendEmail} />
    </Switch>
  );
};

export default PublicRouting;
