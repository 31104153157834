import useApplicationConfirmation from './applicationConfirmation';
import useApplicationDocuments from './applicationDocuments';
import useApplicationInformation from './applicationInformation';
import useApplicationReports from './applicationReports';
import useContactInfo from './contactInfo';
import useFilter from './filter';
import useFilterBlogs from './filterBlogs';
import useFilterUser from './filterUser';
import useHeader from './header';
import useInputFile from './inputFile';
import useInternalComments from './internalComments';
import useLoggedHeader from './loggedHeader';
import useModal from './modal';
import useNotifications from './notifications';
import useProfileNavBar from './profileNavBar';
import useRadioGroup from './radioGroup';
import useReportsSubmitted from './reportsSubmitted';
import useRichTextEditor from './rickTextEditor';
import useSideMenuBackuser from './sideMenuBackuser';
import useSideMenuMobile from './sideMenuMobile';
import useSocialMedia from './socialMedia';
import useStatusVisualization from './statusVisualizartion';
import useTimer from './timer';
import useTrackingInformation from './trackingInformation';
import useTycInfo from './tycInfo';
import usePasswordField from './usePasswordField';
import useWizard from './wizard';
const useComponentHooks = () => {
  return {
    useTrackingInformation,
    useApplicationConfirmation,
    useApplicationDocuments,
    useApplicationInformation,
    useContactInfo,
    useModal,
    useSocialMedia,
    useTycInfo,
    usePasswordField,
    useHeader,
    useProfileNavBar,
    useLoggedHeader,
    useSideMenuMobile,
    useWizard,
    useInputFile,
    useSideMenuBackuser,
    useStatusVisualization,
    useFilter,
    useFilterBlogs,
    useFilterUser,
    useRadioGroup,
    useApplicationReports,
    useReportsSubmitted,
    useRichTextEditor,
    useNotifications,
    useTimer,
    useInternalComments,
  };
};

export default useComponentHooks;
