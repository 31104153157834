const useApplicationFormInitialStates = () => {
  const applicationFormInitialState = {
    date: '',
    applicationReady: false,
    applicationForm: {
      id: '',
      name: '',
      registrationNumber: '',
      phoneIndicator: '',
      phone: '',
      phoneAlternative: '',
      alternativePhoneIndicator: '',
      email: '',
      taxNumber: '',
      postalResidenceAddress: '',
      propocedOutletName: '',
      licenceType: '',
      outletAddress: '',
      oultetAddressSameAsResidence: '',
      rightOfOcuppation: '',
      buildingNotErectedYet: '',
      erectedRequireAdditions: '',
      erectedDoNotRequireAdditions: '',
      additionsCompletedById: '',
      proofProvincialGovermentGazette: { name: '', data: '' },
      affidavitVicinity: { name: '', data: '' },
      typeOfRegistrationId: '',
      certifiedId: { name: '', data: '' },
      validWorkPermit: { name: '', data: '' },
      businessDocument: { name: '', data: '' },
      detailedSketch: { name: '', data: '' },
      photograpsExternalInternalFeatures: [
        { name: '', data: '' },
        { name: '', data: '' },
        { name: '', data: '' },
        { name: '', data: '' },
        { name: '', data: '' },
      ],
      photo1: { name: '', data: '' },
      photo2: { name: '', data: '' },
      photo3: { name: '', data: '' },
      photo4: { name: '', data: '' },
      photo5: { name: '', data: '' },
      proofOfPayment: { name: '', data: '' },
      contributionCombatingAlcoholAbuse: { name: '', data: '' },
      motivationSupport: { name: '', data: '' },
      landUseRightsDocumentTypeId: '',
      foundingConditions: { name: '', data: '' },
      townPlanningScheme: { name: '', data: '' },
      titleDeedOfLand: { name: '', data: '' },
      tribalResolution: {
        name: '',
        data: '',
      },
      anyOtherSource: { name: '', data: '' },
      licensingMemberComment: {
        comment: '',
        commentMadeBy: {
          id: '',
          name: '',
          surname: '',
        },
      },
      status: { id: 1, status: 'Draft', subStatus: '' },
      licenseToSell: '',
      prescribedFees: '',
      payableBefore: '',
      municipalityId: '',
      issueDate: '',
      amountPaid: '',
      receiptNumber: '',
      officeDateStamp: '',
    },
    applicationFormDraft: {
      name: '',
      registrationNumber: '',
      phoneIndicator: '',
      phone: '',
      phoneAlternative: '',
      alternativePhoneIndicator: '',
      email: '',
      taxNumber: '',
      postalResidenceAddress: '',
      propocedOutletName: '',
      licenceType: '',
      outletAddress: '',
      oultetAddressSameAsResidence: '',
      rightOfOcuppation: '',
      buildingNotErectedYet: '',
      erectedRequireAdditions: '',
      erectedDoNotRequireAdditions: '',
      additionsCompletedById: '',
      proofProvincialGovermentGazette: { name: '', data: '' },
      affidavitVicinity: { name: '', data: '' },
      typeOfRegistrationId: '',
      certifiedId: { name: '', data: '' },
      validWorkPermit: { name: '', data: '' },
      businessDocument: { name: '', data: '' },
      detailedSketch: { name: '', data: '' },
      photograpsExternalInternalFeatures: [
        { name: '', data: '' },
        { name: '', data: '' },
        { name: '', data: '' },
        { name: '', data: '' },
        { name: '', data: '' },
      ],
      photo1: { name: '', data: '' },
      photo2: { name: '', data: '' },
      photo3: { name: '', data: '' },
      photo4: { name: '', data: '' },
      photo5: { name: '', data: '' },
      proofOfPayment: { name: '', data: '' },
      contributionCombatingAlcoholAbuse: { name: '', data: '' },
      motivationSupport: { name: '', data: '' },
      landUseRightsDocumentTypeId: '',
      foundingConditions: { name: '', data: '' },
      townPlanningScheme: { name: '', data: '' },
      titleDeedOfLand: { name: '', data: '' },
      tribalResolution: {
        name: '',
        data: '',
      },
      anyOtherSource: { name: '', data: '' },
      licensingMemberComment: {
        comment: '',
        commentMadeBy: {
          id: '',
          name: '',
          surname: '',
        },
      },
      status: { id: 1, status: 'Draft', subStatus: '' },
      licenseToSell: '',
      prescribedFees: '',
      payableBefore: '',
      municipalityId: '',
      issueDate: '',
      amountPaid: '',
      receiptNumber: '',
      officeDateStamp: '',
    },
    blankPdf:
      'data:application/pdf;base64,JVBERi0xLjcKCjQgMCBvYmoKPDwKL0ZpbHRlciAvRmxhdGVEZWNvZGUKL0xlbmd0aCA5MQo+PgpzdHJlYW0KeJxljDEOgCAQBPt9BR/wsneAyDN8g4laSKH/L6TRQLhqMpM9Soqs5yhkD9PYlqCSjQzBbQU39AsjPAco3vp9Qcpe0kxqY6/WqppJrBxzDf+LXp/YseIFLLIj/wplbmRzdHJlYW0KZW5kb2JqCjUgMCBvYmoKPDwKPj4KZW5kb2JqCjMgMCBvYmoKPDwKL0NvbnRlbnRzIFsgNCAwIFIgXQovQ3JvcEJveCBbIDAuMCAwLjAgNTk1LjMyMDAxIDg0MS45MjAwNCBdCi9NZWRpYUJveCBbIDAuMCAwLjAgNTk1LjMyMDAxIDg0MS45MjAwNCBdCi9QYXJlbnQgMiAwIFIKL1Jlc291cmNlcyA1IDAgUgovUm90YXRlIDAKL1R5cGUgL1BhZ2UKPj4KZW5kb2JqCjIgMCBvYmoKPDwKL0NvdW50IDEKL0tpZHMgWyAzIDAgUiBdCi9UeXBlIC9QYWdlcwo+PgplbmRvYmoKMSAwIG9iago8PAovUGFnZXMgMiAwIFIKL1R5cGUgL0NhdGFsb2cKPj4KZW5kb2JqCjYgMCBvYmoKPDwKL0F1dGhvciAoSmF2aWVyIEdhbWJvYSkKL0NyZWF0aW9uRGF0ZSAoRDoyMDIxMTIyOTE2MjkwNC0wNScwMCcpCi9Nb2REYXRlIChEOjIwMjExMjI5MTYyOTA0LTA1JzAwJykKL1Byb2R1Y2VyIChNaWNyb3NvZnQ6IFByaW50IFRvIFBERikKL1RpdGxlIChEb2N1bWVudG8gc2luIHTtdHVsbyAtIERvY3VtZW50b3MgZGUgR29vZ2xlKQo+PgplbmRvYmoKeHJlZgowIDcNCjAwMDAwMDAwMDAgNjU1MzUgZg0KMDAwMDAwMDQyOCAwMDAwMCBuDQowMDAwMDAwMzY5IDAwMDAwIG4NCjAwMDAwMDAxOTIgMDAwMDAgbg0KMDAwMDAwMDAwOSAwMDAwMCBuDQowMDAwMDAwMTcxIDAwMDAwIG4NCjAwMDAwMDA0NzcgMDAwMDAgbg0KdHJhaWxlcgo8PAovSW5mbyA2IDAgUgovUm9vdCAxIDAgUgovU2l6ZSA3Cj4+CnN0YXJ0eHJlZgo2ODcKJSVFT0YK',
    currentStep: 1,
    aditionsCompletedBy: [
      {
        id: 1,
        name: 'IN THE NEXT 1 - 3 MONTHS',
      },
      {
        id: 2,
        name: 'IN THE NEXT 4 - 6 MONTHS',
      },
    ],
    applicationTypes: [
      { id: 1, name: 'License Application' },
      { id: 2, name: 'Renewal' },
    ],
    landUseRightsDocumentType: [
      { id: 1, name: 'Town planning scheme' },
      {
        id: 2,
        name: 'Founding conditions the township or other subdivision of land',
      },
      { id: 3, name: 'The title deed of the land' },
      { id: 4, name: 'Tribal resolution' },
      { id: 5, name: 'Any other source/type of document' },
    ],
    typeOfRegistration: [
      { id: 1, name: 'Sole trader' },
      {
        id: 2,
        name: 'Company',
      },
    ],
    municipalities: [
      {
        id: 1,
        name: 'Dikgatlong',
      },
      {
        id: 2,
        name: 'Emthanjeni',
      },
      {
        id: 4,
        name: 'Ga-segonyana',
      },
      {
        id: 5,
        name: 'Gamagara',
      },
      {
        id: 6,
        name: 'Hantam',
      },
      {
        id: 7,
        name: 'Joe Morolong',
      },
      {
        id: 9,
        name: 'Kai Garib',
      },
      {
        id: 10,
        name: 'Kamiesberg',
      },
      {
        id: 11,
        name: 'Kareeberg',
      },
      {
        id: 12,
        name: 'Karoo Hoogland',
      },
      {
        id: 13,
        name: 'Kgatelopele',
      },
      {
        id: 14,
        name: 'Khai Ma',
      },
      {
        id: 15,
        name: 'Khara Hais',
      },
      {
        id: 16,
        name: 'Kheis',
      },
      {
        id: 17,
        name: 'Magareng',
      },
      {
        id: 18,
        name: 'Mier',
      },
      {
        id: 19,
        name: 'Nama-khoi',
      },
      {
        id: 21,
        name: 'Phokwane',
      },
      {
        id: 23,
        name: 'Renosterburg',
      },
      {
        id: 24,
        name: 'Richtersveld',
      },
      {
        id: 25,
        name: 'Siyancuma',
      },
      {
        id: 26,
        name: 'Siyathemba',
      },
      {
        id: 27,
        name: 'Sol Plaatje',
      },
      {
        id: 28,
        name: 'Thembelihle',
      },
      {
        id: 29,
        name: 'Tsantsabane',
      },
      {
        id: 30,
        name: 'Ubuntu',
      },
      {
        id: 31,
        name: 'Umsobomvu',
      },
    ],
    phoneIndicators: [
      {
        id: 1,
        phoneIndicator: '+93',
        countryCode: 'AF',
      },
      {
        id: 2,
        phoneIndicator: '+355',
        countryCode: 'AL',
      },
      {
        id: 3,
        phoneIndicator: '+213',
        countryCode: 'DZ',
      },
      {
        id: 4,
        phoneIndicator: '+1-684',
        countryCode: 'AS',
      },
      {
        id: 5,
        phoneIndicator: '+376',
        countryCode: 'AD',
      },
      {
        id: 6,
        phoneIndicator: '+244',
        countryCode: 'AO',
      },
      {
        id: 7,
        phoneIndicator: '+1-264',
        countryCode: 'AI',
      },
      {
        id: 8,
        phoneIndicator: '+672',
        countryCode: 'AQ',
      },
      {
        id: 9,
        phoneIndicator: '+1-268',
        countryCode: 'AG',
      },
      {
        id: 10,
        phoneIndicator: '+54',
        countryCode: 'AR',
      },
      {
        id: 11,
        phoneIndicator: '+374',
        countryCode: 'AM',
      },
      {
        id: 12,
        phoneIndicator: '+297',
        countryCode: 'AW',
      },
      {
        id: 13,
        phoneIndicator: '+61',
        countryCode: 'AU',
      },
      {
        id: 14,
        phoneIndicator: '+43',
        countryCode: 'AT',
      },
      {
        id: 15,
        phoneIndicator: '+994',
        countryCode: 'AZ',
      },
      {
        id: 16,
        phoneIndicator: '+1-242',
        countryCode: 'BS',
      },
      {
        id: 17,
        phoneIndicator: '+973',
        countryCode: 'BH',
      },
      {
        id: 18,
        phoneIndicator: '+880',
        countryCode: 'BD',
      },
      {
        id: 19,
        phoneIndicator: '+1-246',
        countryCode: 'BB',
      },
      {
        id: 20,
        phoneIndicator: '+375',
        countryCode: 'BY',
      },
      {
        id: 21,
        phoneIndicator: '+32',
        countryCode: 'BE',
      },
      {
        id: 22,
        phoneIndicator: '+501',
        countryCode: 'BZ',
      },
      {
        id: 23,
        phoneIndicator: '+229',
        countryCode: 'BJ',
      },
      {
        id: 24,
        phoneIndicator: '+1-441',
        countryCode: 'BM',
      },
      {
        id: 25,
        phoneIndicator: '+975',
        countryCode: 'BT',
      },
      {
        id: 26,
        phoneIndicator: '+591',
        countryCode: 'BO',
      },
      {
        id: 27,
        phoneIndicator: '+387',
        countryCode: 'BA',
      },
      {
        id: 28,
        phoneIndicator: '+267',
        countryCode: 'BW',
      },
      {
        id: 29,
        phoneIndicator: '+55',
        countryCode: 'BR',
      },
      {
        id: 30,
        phoneIndicator: '+246',
        countryCode: 'IO',
      },
      {
        id: 31,
        phoneIndicator: '+1-284',
        countryCode: 'VG',
      },
      {
        id: 32,
        phoneIndicator: '+673',
        countryCode: 'BN',
      },
      {
        id: 33,
        phoneIndicator: '+359',
        countryCode: 'BG',
      },
      {
        id: 34,
        phoneIndicator: '+226',
        countryCode: 'BF',
      },
      {
        id: 35,
        phoneIndicator: '+257',
        countryCode: 'BI',
      },
      {
        id: 36,
        phoneIndicator: '+855',
        countryCode: 'KH',
      },
      {
        id: 37,
        phoneIndicator: '+237',
        countryCode: 'CM',
      },
      {
        id: 38,
        phoneIndicator: '+1',
        countryCode: 'CA',
      },
      {
        id: 39,
        phoneIndicator: '+238',
        countryCode: 'CV',
      },
      {
        id: 40,
        phoneIndicator: '+1-345',
        countryCode: 'KY',
      },
      {
        id: 41,
        phoneIndicator: '+236',
        countryCode: 'CF',
      },
      {
        id: 42,
        phoneIndicator: '+235',
        countryCode: 'TD',
      },
      {
        id: 43,
        phoneIndicator: '+56',
        countryCode: 'CL',
      },
      {
        id: 44,
        phoneIndicator: '+86',
        countryCode: 'CN',
      },
      {
        id: 45,
        phoneIndicator: '+61',
        countryCode: 'CX',
      },
      {
        id: 46,
        phoneIndicator: '+61',
        countryCode: 'CC',
      },
      {
        id: 47,
        phoneIndicator: '+57',
        countryCode: 'CO',
      },
      {
        id: 48,
        phoneIndicator: '+269',
        countryCode: 'KM',
      },
      {
        id: 49,
        phoneIndicator: '+682',
        countryCode: 'CK',
      },
      {
        id: 50,
        phoneIndicator: '+506',
        countryCode: 'CR',
      },
      {
        id: 51,
        phoneIndicator: '+385',
        countryCode: 'HR',
      },
      {
        id: 52,
        phoneIndicator: '+53',
        countryCode: 'CU',
      },
      {
        id: 53,
        phoneIndicator: '+599',
        countryCode: 'CW',
      },
      {
        id: 54,
        phoneIndicator: '+357',
        countryCode: 'CY',
      },
      {
        id: 55,
        phoneIndicator: '+420',
        countryCode: 'CZ',
      },
      {
        id: 56,
        phoneIndicator: '+243',
        countryCode: 'CD',
      },
      {
        id: 57,
        phoneIndicator: '+45',
        countryCode: 'DK',
      },
      {
        id: 58,
        phoneIndicator: '+253',
        countryCode: 'DJ',
      },
      {
        id: 59,
        phoneIndicator: '+1-767',
        countryCode: 'DM',
      },
      {
        id: 60,
        phoneIndicator: '+1-809',
        countryCode: 'DO',
      },
      {
        id: 61,
        phoneIndicator: '+1-829',
        countryCode: 'DO',
      },
      {
        id: 62,
        phoneIndicator: '+1-849',
        countryCode: 'DO',
      },
      {
        id: 63,
        phoneIndicator: '+670',
        countryCode: 'TL',
      },
      {
        id: 64,
        phoneIndicator: '+593',
        countryCode: 'EC',
      },
      {
        id: 65,
        phoneIndicator: '+20',
        countryCode: 'EG',
      },
      {
        id: 66,
        phoneIndicator: '+503',
        countryCode: 'SV',
      },
      {
        id: 67,
        phoneIndicator: '+240',
        countryCode: 'GQ',
      },
      {
        id: 68,
        phoneIndicator: '+291',
        countryCode: 'ER',
      },
      {
        id: 69,
        phoneIndicator: '+372',
        countryCode: 'EE',
      },
      {
        id: 70,
        phoneIndicator: '+251',
        countryCode: 'ET',
      },
      {
        id: 71,
        phoneIndicator: '+500',
        countryCode: 'FK',
      },
      {
        id: 72,
        phoneIndicator: '+298',
        countryCode: 'FO',
      },
      {
        id: 73,
        phoneIndicator: '+679',
        countryCode: 'FJ',
      },
      {
        id: 74,
        phoneIndicator: '+358',
        countryCode: 'FI',
      },
      {
        id: 75,
        phoneIndicator: '+33',
        countryCode: 'FR',
      },
      {
        id: 76,
        phoneIndicator: '+689',
        countryCode: 'PF',
      },
      {
        id: 77,
        phoneIndicator: '+241',
        countryCode: 'GA',
      },
      {
        id: 78,
        phoneIndicator: '+220',
        countryCode: 'GM',
      },
      {
        id: 79,
        phoneIndicator: '+995',
        countryCode: 'GE',
      },
      {
        id: 80,
        phoneIndicator: '+49',
        countryCode: 'DE',
      },
      {
        id: 81,
        phoneIndicator: '+233',
        countryCode: 'GH',
      },
      {
        id: 82,
        phoneIndicator: '+350',
        countryCode: 'GI',
      },
      {
        id: 83,
        phoneIndicator: '+30',
        countryCode: 'GR',
      },
      {
        id: 84,
        phoneIndicator: '+299',
        countryCode: 'GL',
      },
      {
        id: 85,
        phoneIndicator: '+1-473',
        countryCode: 'GD',
      },
      {
        id: 86,
        phoneIndicator: '+1-671',
        countryCode: 'GU',
      },
      {
        id: 87,
        phoneIndicator: '+502',
        countryCode: 'GT',
      },
      {
        id: 88,
        phoneIndicator: '+44-1481',
        countryCode: 'GG',
      },
      {
        id: 89,
        phoneIndicator: '+224',
        countryCode: 'GN',
      },
      {
        id: 90,
        phoneIndicator: '+245',
        countryCode: 'GW',
      },
      {
        id: 91,
        phoneIndicator: '+592',
        countryCode: 'GY',
      },
      {
        id: 92,
        phoneIndicator: '+509',
        countryCode: 'HT',
      },
      {
        id: 93,
        phoneIndicator: '+504',
        countryCode: 'HN',
      },
      {
        id: 94,
        phoneIndicator: '+852',
        countryCode: 'HK',
      },
      {
        id: 95,
        phoneIndicator: '+36',
        countryCode: 'HU',
      },
      {
        id: 96,
        phoneIndicator: '+354',
        countryCode: 'IS',
      },
      {
        id: 97,
        phoneIndicator: '+91',
        countryCode: 'IN',
      },
      {
        id: 98,
        phoneIndicator: '+62',
        countryCode: 'ID',
      },
      {
        id: 99,
        phoneIndicator: '+98',
        countryCode: 'IR',
      },
      {
        id: 100,
        phoneIndicator: '+964',
        countryCode: 'IQ',
      },
      {
        id: 101,
        phoneIndicator: '+353',
        countryCode: 'IE',
      },
      {
        id: 102,
        phoneIndicator: '+44-1624',
        countryCode: 'IM',
      },
      {
        id: 103,
        phoneIndicator: '+972',
        countryCode: 'IL',
      },
      {
        id: 104,
        phoneIndicator: '+39',
        countryCode: 'IT',
      },
      {
        id: 105,
        phoneIndicator: '+225',
        countryCode: 'CI',
      },
      {
        id: 106,
        phoneIndicator: '+1-876',
        countryCode: 'JM',
      },
      {
        id: 107,
        phoneIndicator: '+81',
        countryCode: 'JP',
      },
      {
        id: 108,
        phoneIndicator: '+44-1534',
        countryCode: 'JE',
      },
      {
        id: 109,
        phoneIndicator: '+962',
        countryCode: 'JO',
      },
      {
        id: 110,
        phoneIndicator: '+7',
        countryCode: 'KZ',
      },
      {
        id: 111,
        phoneIndicator: '+254',
        countryCode: 'KE',
      },
      {
        id: 112,
        phoneIndicator: '+686',
        countryCode: 'KI',
      },
      {
        id: 113,
        phoneIndicator: '+383',
        countryCode: 'XK',
      },
      {
        id: 114,
        phoneIndicator: '+965',
        countryCode: 'KW',
      },
      {
        id: 115,
        phoneIndicator: '+996',
        countryCode: 'KG',
      },
      {
        id: 116,
        phoneIndicator: '+856',
        countryCode: 'LA',
      },
      {
        id: 117,
        phoneIndicator: '+371',
        countryCode: 'LV',
      },
      {
        id: 118,
        phoneIndicator: '+961',
        countryCode: 'LB',
      },
      {
        id: 119,
        phoneIndicator: '+266',
        countryCode: 'LS',
      },
      {
        id: 120,
        phoneIndicator: '+231',
        countryCode: 'LR',
      },
      {
        id: 121,
        phoneIndicator: '+218',
        countryCode: 'LY',
      },
      {
        id: 122,
        phoneIndicator: '+423',
        countryCode: 'LI',
      },
      {
        id: 123,
        phoneIndicator: '+370',
        countryCode: 'LT',
      },
      {
        id: 124,
        phoneIndicator: '+352',
        countryCode: 'LU',
      },
      {
        id: 125,
        phoneIndicator: '+853',
        countryCode: 'MO',
      },
      {
        id: 126,
        phoneIndicator: '+389',
        countryCode: 'MK',
      },
      {
        id: 127,
        phoneIndicator: '+261',
        countryCode: 'MG',
      },
      {
        id: 128,
        phoneIndicator: '+265',
        countryCode: 'MW',
      },
      {
        id: 129,
        phoneIndicator: '+60',
        countryCode: 'MY',
      },
      {
        id: 130,
        phoneIndicator: '+960',
        countryCode: 'MV',
      },
      {
        id: 131,
        phoneIndicator: '+223',
        countryCode: 'ML',
      },
      {
        id: 132,
        phoneIndicator: '+356',
        countryCode: 'MT',
      },
      {
        id: 133,
        phoneIndicator: '+692',
        countryCode: 'MH',
      },
      {
        id: 134,
        phoneIndicator: '+222',
        countryCode: 'MR',
      },
      {
        id: 135,
        phoneIndicator: '+230',
        countryCode: 'MU',
      },
      {
        id: 136,
        phoneIndicator: '+262',
        countryCode: 'YT',
      },
      {
        id: 137,
        phoneIndicator: '+52',
        countryCode: 'MX',
      },
      {
        id: 138,
        phoneIndicator: '+691',
        countryCode: 'FM',
      },
      {
        id: 139,
        phoneIndicator: '+373',
        countryCode: 'MD',
      },
      {
        id: 140,
        phoneIndicator: '+377',
        countryCode: 'MC',
      },
      {
        id: 141,
        phoneIndicator: '+976',
        countryCode: 'MN',
      },
      {
        id: 142,
        phoneIndicator: '+382',
        countryCode: 'ME',
      },
      {
        id: 143,
        phoneIndicator: '+1-664',
        countryCode: 'MS',
      },
      {
        id: 144,
        phoneIndicator: '+212',
        countryCode: 'MA',
      },
      {
        id: 145,
        phoneIndicator: '+258',
        countryCode: 'MZ',
      },
      {
        id: 146,
        phoneIndicator: '+95',
        countryCode: 'MM',
      },
      {
        id: 147,
        phoneIndicator: '+264',
        countryCode: 'NA',
      },
      {
        id: 148,
        phoneIndicator: '+674',
        countryCode: 'NR',
      },
      {
        id: 149,
        phoneIndicator: '+977',
        countryCode: 'NP',
      },
      {
        id: 150,
        phoneIndicator: '+31',
        countryCode: 'NL',
      },
      {
        id: 151,
        phoneIndicator: '+599',
        countryCode: 'AN',
      },
      {
        id: 152,
        phoneIndicator: '+687',
        countryCode: 'NC',
      },
      {
        id: 153,
        phoneIndicator: '+64',
        countryCode: 'NZ',
      },
      {
        id: 154,
        phoneIndicator: '+505',
        countryCode: 'NI',
      },
      {
        id: 155,
        phoneIndicator: '+227',
        countryCode: 'NE',
      },
      {
        id: 156,
        phoneIndicator: '+234',
        countryCode: 'NG',
      },
      {
        id: 157,
        phoneIndicator: '+683',
        countryCode: 'NU',
      },
      {
        id: 158,
        phoneIndicator: '+850',
        countryCode: 'KP',
      },
      {
        id: 159,
        phoneIndicator: '+1-670',
        countryCode: 'MP',
      },
      {
        id: 160,
        phoneIndicator: '+47',
        countryCode: 'NO',
      },
      {
        id: 161,
        phoneIndicator: '+968',
        countryCode: 'OM',
      },
      {
        id: 162,
        phoneIndicator: '+92',
        countryCode: 'PK',
      },
      {
        id: 163,
        phoneIndicator: '+680',
        countryCode: 'PW',
      },
      {
        id: 164,
        phoneIndicator: '+970',
        countryCode: 'PS',
      },
      {
        id: 165,
        phoneIndicator: '+507',
        countryCode: 'PA',
      },
      {
        id: 166,
        phoneIndicator: '+675',
        countryCode: 'PG',
      },
      {
        id: 167,
        phoneIndicator: '+595',
        countryCode: 'PY',
      },
      {
        id: 168,
        phoneIndicator: '+51',
        countryCode: 'PE',
      },
      {
        id: 169,
        phoneIndicator: '+63',
        countryCode: 'PH',
      },
      {
        id: 170,
        phoneIndicator: '+64',
        countryCode: 'PN',
      },
      {
        id: 171,
        phoneIndicator: '+48',
        countryCode: 'PL',
      },
      {
        id: 172,
        phoneIndicator: '+351',
        countryCode: 'PT',
      },
      {
        id: 173,
        phoneIndicator: '+1-787',
        countryCode: 'PR',
      },
      {
        id: 174,
        phoneIndicator: '+1-939',
        countryCode: 'PR',
      },
      {
        id: 175,
        phoneIndicator: '+974',
        countryCode: 'QA',
      },
      {
        id: 176,
        phoneIndicator: '+242',
        countryCode: 'CG',
      },
      {
        id: 177,
        phoneIndicator: '+262',
        countryCode: 'RE',
      },
      {
        id: 178,
        phoneIndicator: '+40',
        countryCode: 'RO',
      },
      {
        id: 179,
        phoneIndicator: '+7',
        countryCode: 'RU',
      },
      {
        id: 180,
        phoneIndicator: '+250',
        countryCode: 'RW',
      },
      {
        id: 181,
        phoneIndicator: '+590',
        countryCode: 'BL',
      },
      {
        id: 182,
        phoneIndicator: '+290',
        countryCode: 'SH',
      },
      {
        id: 183,
        phoneIndicator: '+1-869',
        countryCode: 'KN',
      },
      {
        id: 184,
        phoneIndicator: '+1-758',
        countryCode: 'LC',
      },
      {
        id: 185,
        phoneIndicator: '+590',
        countryCode: 'MF',
      },
      {
        id: 186,
        phoneIndicator: '+508',
        countryCode: 'PM',
      },
      {
        id: 187,
        phoneIndicator: '+1-784',
        countryCode: 'VC',
      },
      {
        id: 188,
        phoneIndicator: '+685',
        countryCode: 'WS',
      },
      {
        id: 189,
        phoneIndicator: '+378',
        countryCode: 'SM',
      },
      {
        id: 190,
        phoneIndicator: '+239',
        countryCode: 'ST',
      },
      {
        id: 191,
        phoneIndicator: '+966',
        countryCode: 'SA',
      },
      {
        id: 192,
        phoneIndicator: '+221',
        countryCode: 'SN',
      },
      {
        id: 193,
        phoneIndicator: '+381',
        countryCode: 'RS',
      },
      {
        id: 194,
        phoneIndicator: '+248',
        countryCode: 'SC',
      },
      {
        id: 195,
        phoneIndicator: '+232',
        countryCode: 'SL',
      },
      {
        id: 196,
        phoneIndicator: '+65',
        countryCode: 'SG',
      },
      {
        id: 197,
        phoneIndicator: '+1-721',
        countryCode: 'SX',
      },
      {
        id: 198,
        phoneIndicator: '+421',
        countryCode: 'SK',
      },
      {
        id: 199,
        phoneIndicator: '+386',
        countryCode: 'SI',
      },
      {
        id: 200,
        phoneIndicator: '+677',
        countryCode: 'SB',
      },
      {
        id: 201,
        phoneIndicator: '+252',
        countryCode: 'SO',
      },
      {
        id: 202,
        phoneIndicator: '+27',
        countryCode: 'ZA',
      },
      {
        id: 203,
        phoneIndicator: '+82',
        countryCode: 'KR',
      },
      {
        id: 204,
        phoneIndicator: '+211',
        countryCode: 'SS',
      },
      {
        id: 205,
        phoneIndicator: '+34',
        countryCode: 'ES',
      },
      {
        id: 206,
        phoneIndicator: '+94',
        countryCode: 'LK',
      },
      {
        id: 207,
        phoneIndicator: '+249',
        countryCode: 'SD',
      },
      {
        id: 208,
        phoneIndicator: '+597',
        countryCode: 'SR',
      },
      {
        id: 209,
        phoneIndicator: '+47',
        countryCode: 'SJ',
      },
      {
        id: 210,
        phoneIndicator: '+268',
        countryCode: 'SZ',
      },
      {
        id: 211,
        phoneIndicator: '+46',
        countryCode: 'SE',
      },
      {
        id: 212,
        phoneIndicator: '+41',
        countryCode: 'CH',
      },
      {
        id: 213,
        phoneIndicator: '+963',
        countryCode: 'SY',
      },
      {
        id: 214,
        phoneIndicator: '+886',
        countryCode: 'TW',
      },
      {
        id: 215,
        phoneIndicator: '+992',
        countryCode: 'TJ',
      },
      {
        id: 216,
        phoneIndicator: '+255',
        countryCode: 'TZ',
      },
      {
        id: 217,
        phoneIndicator: '+66',
        countryCode: 'TH',
      },
      {
        id: 218,
        phoneIndicator: '+228',
        countryCode: 'TG',
      },
      {
        id: 219,
        phoneIndicator: '+690',
        countryCode: 'TK',
      },
      {
        id: 220,
        phoneIndicator: '+676',
        countryCode: 'TO',
      },
      {
        id: 221,
        phoneIndicator: '+1-868',
        countryCode: 'TT',
      },
      {
        id: 222,
        phoneIndicator: '+216',
        countryCode: 'TN',
      },
      {
        id: 223,
        phoneIndicator: '+90',
        countryCode: 'TR',
      },
      {
        id: 224,
        phoneIndicator: '+993',
        countryCode: 'TM',
      },
      {
        id: 225,
        phoneIndicator: '+1-649',
        countryCode: 'TC',
      },
      {
        id: 226,
        phoneIndicator: '+688',
        countryCode: 'TV',
      },
      {
        id: 227,
        phoneIndicator: '+1-340',
        countryCode: 'VI',
      },
      {
        id: 228,
        phoneIndicator: '+256',
        countryCode: 'UG',
      },
      {
        id: 229,
        phoneIndicator: '+380',
        countryCode: 'UA',
      },
      {
        id: 230,
        phoneIndicator: '+971',
        countryCode: 'AE',
      },
      {
        id: 231,
        phoneIndicator: '+44',
        countryCode: 'GB',
      },
      {
        id: 232,
        phoneIndicator: '+1',
        countryCode: 'US',
      },
      {
        id: 233,
        phoneIndicator: '+598',
        countryCode: 'UY',
      },
      {
        id: 234,
        phoneIndicator: '+998',
        countryCode: 'UZ',
      },
      {
        id: 235,
        phoneIndicator: '+678',
        countryCode: 'VU',
      },
      {
        id: 236,
        phoneIndicator: '+379',
        countryCode: 'VA',
      },
      {
        id: 237,
        phoneIndicator: '+58',
        countryCode: 'VE',
      },
      {
        id: 238,
        phoneIndicator: '+84',
        countryCode: 'VN',
      },
      {
        id: 239,
        phoneIndicator: '+681',
        countryCode: 'WF',
      },
      {
        id: 240,
        phoneIndicator: '+212',
        countryCode: 'EH',
      },
      {
        id: 241,
        phoneIndicator: '+967',
        countryCode: 'YE',
      },
      {
        id: 242,
        phoneIndicator: '+260',
        countryCode: 'ZM',
      },
      {
        id: 243,
        phoneIndicator: '+263',
        countryCode: 'ZW',
      },
    ],
    licenceTypes: [
      {
        id: 1,
        name: 'Hotel',
      },
      {
        id: 2,
        name: 'Restaurant',
      },
      {
        id: 3,
        name: 'Wine-house',
      },
      {
        id: 4,
        name: 'Theatre',
      },
      {
        id: 5,
        name: 'Club',
      },
      {
        id: 6,
        name: 'Tavern',
      },
      {
        id: 7,
        name: 'Guest House',
      },
      {
        id: 8,
        name: 'Nightclub',
      },
      {
        id: 9,
        name: 'Gambling house',
      },
      {
        id: 10,
        name: 'Sportsclub',
      },
      {
        id: 11,
        name: 'Sorghum beer-drinking house',
      },
      {
        id: 12,
        name: 'Liquor store',
      },
      {
        id: 13,
        name: 'Grocer selling wine',
      },
      {
        id: 14,
        name: 'Business selling sorghum beer',
      },
      {
        id: 15,
        name: 'Micro-manufacturing of liquor',
      },
    ],
    contactMethods: [
      {
        id: 1,
        name: 'SMS',
      },
      {
        id: 2,
        name: 'Email',
      },
      {
        id: 3,
        name: 'Both (SMS and Email)',
      },
    ],
    statusSecondStep: false,
    statusFinalStep: false,
    applicationSaved: false,
  };
  return {
    applicationFormInitialState,
  };
};

export default useApplicationFormInitialStates;
