import useStrings from '../../../strings';
import useServices from '../../services';

const useApplicationFormActions = () => {
  // Strings
  const { useTypes } = useStrings();
  const { applicationFormTypes } = useTypes();
  const {
    GET_APPLICATION_IMAGES,
    GET_APPLICATION_HELP_DATA,
    SUBMIT_APPLICATION_FORM_STEP_1,
    SUBMIT_APPLICATION_FORM_STEP_2,
    SUBMIT_APPLICATION_FORM_FINAL,
    SET_CURRENT_STEP,
    ENABLE_SECOND_STEP,
    ENABLE_FINAL_STEP,
    CANCEL_APPLICATION,
    USER_APPLICATION_SAVED,
    RESET_FIELD_APPLICATION_FORM,
    SET_APPLICATION_FORM,
    APPLICATION_READY,
    SET_APPLICATION_DATE,
    SET_DOCUMENTS_DRAFT,
    UPLOAD_APPLICATION_FEE,
    GET_LICENSE_FEE_PDF,
    APPROVE_APPLICATION_FEE,
    GET_APPLICATION_FILES,
  } = applicationFormTypes();

  // Services
  const { useApplicationFormServices } = useServices();
  const {
    getApplicationImagesService,
    applicationHelpDataService,
    saveUserApplicationService,
    saveFinalSubmitApplicationService,
    cancelApplicationService,
    updateApplicationService,
    updateFinalSubmitApplicationService,
    photosZipService,
    uploadApplicationFeeProofOfPaymentService,
    getLicenseFeePdfService,
    approveApplicationFeeProofOfPaymentService,
    getApplicationFilesService,
  } = useApplicationFormServices();

  const actGetApplicationImages =
    (applicationId, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await getApplicationImagesService(applicationId);
        dispatch({
          type: GET_APPLICATION_IMAGES,
          payload: response.data,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actSubmitApplicationFormStep1 =
    (data, onSuccess, onError) => async (dispatch) => {
      try {
        dispatch({
          type: SUBMIT_APPLICATION_FORM_STEP_1,
          payload: data,
        });
        onSuccess && onSuccess(data);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actSubmitApplicationFormStep2 =
    (data, onSuccess, onError) => async (dispatch) => {
      try {
        dispatch({
          type: SUBMIT_APPLICATION_FORM_STEP_2,
          payload: data,
        });
        onSuccess && onSuccess(data);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actSubmitApplicationFormFinal =
    (data, onSuccess, onError) => async (dispatch) => {
      try {
        dispatch({
          type: SUBMIT_APPLICATION_FORM_FINAL,
          payload: data,
        });
        onSuccess && onSuccess(data);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actSaveUserApplication =
    (data, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await saveUserApplicationService(data);

        dispatch({
          type: USER_APPLICATION_SAVED,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };
  const actSaveFinalSubmitApplication =
    (data, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await saveFinalSubmitApplicationService(data);

        dispatch({
          type: USER_APPLICATION_SAVED,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actSetCurrentStep =
    ({ step }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        dispatch({
          type: SET_CURRENT_STEP,
          payload: step,
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };

  const actEnableSecondStep =
    ({ status }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        dispatch({
          type: ENABLE_SECOND_STEP,
          payload: status,
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };

  const actEnableFinalStep =
    ({ status }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        dispatch({
          type: ENABLE_FINAL_STEP,
          payload: status,
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };

  const actResetFieldApplicationForm =
    ({ field }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        dispatch({
          type: RESET_FIELD_APPLICATION_FORM,
          payload: field,
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };
  const actGetApplicationHelpData =
    (data, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await applicationHelpDataService(data);
        dispatch({
          type: GET_APPLICATION_HELP_DATA,
          payload: response.data,
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };

  const actSetApplicationForm =
    ({ application }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        dispatch({
          type: SET_APPLICATION_FORM,
          payload: application,
        });
        dispatch({
          type: APPLICATION_READY,
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };

  const actCancelApplication =
    ({ id = '' }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        if (id) {
          const response = await cancelApplicationService(id);
          dispatch({
            type: CANCEL_APPLICATION,
          });
          onSuccess && onSuccess(response.data);
        } else {
          dispatch({
            type: CANCEL_APPLICATION,
          });
          onSuccess && onSuccess();
        }
      } catch (e) {
        onError && onError(e);
      }
    };

  const actUpdateApplication =
    (data, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await updateApplicationService(data);

        dispatch({
          type: USER_APPLICATION_SAVED,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };
  const actUpdateFinalSubmitApplication =
    (data, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await updateFinalSubmitApplicationService(data);

        dispatch({
          type: USER_APPLICATION_SAVED,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actSetApplicationDate =
    ({ date }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        dispatch({
          type: SET_APPLICATION_DATE,
          payload: date,
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };

  const actSetDocumentsDraft =
    ({ field, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        dispatch({
          type: SET_DOCUMENTS_DRAFT,
          payload: { field, data },
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };

  const actGetZipDownloadPhotos = (id, onSuccess, onError) => async () => {
    try {
      const response = await photosZipService(id);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError();
    }
  };
  const actUploadApplicationFeeProofOfPayment =
    (data, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await uploadApplicationFeeProofOfPaymentService(data);

        dispatch({
          type: UPLOAD_APPLICATION_FEE,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };
  const actGetLicenseFeePdf =
    (applicationId, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await getLicenseFeePdfService(applicationId);

        dispatch({
          type: GET_LICENSE_FEE_PDF,
        });
        onSuccess && onSuccess(response.data.base64);
      } catch (e) {
        onError && onError(e);
      }
    };
  const actApproveApplicationFeeProofOfPayment =
    (data, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await approveApplicationFeeProofOfPaymentService(data);

        dispatch({
          type: APPROVE_APPLICATION_FEE,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actGetApplicationFiles =
    (applicationId, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await getApplicationFilesService(applicationId);
        dispatch({
          type: GET_APPLICATION_FILES,
          payload: response.data,
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };
  return {
    actGetApplicationImages,
    actUpdateApplication,
    actUpdateFinalSubmitApplication,
    actGetApplicationHelpData,
    actSaveUserApplication,
    actSaveFinalSubmitApplication,
    actSubmitApplicationFormStep1,
    actSubmitApplicationFormStep2,
    actSubmitApplicationFormFinal,
    actSetCurrentStep,
    actEnableSecondStep,
    actEnableFinalStep,
    actCancelApplication,
    actResetFieldApplicationForm,
    actSetApplicationForm,
    actSetApplicationDate,
    actSetDocumentsDraft,
    actGetZipDownloadPhotos,
    actUploadApplicationFeeProofOfPayment,
    actGetLicenseFeePdf,
    actApproveApplicationFeeProofOfPayment,
    actGetApplicationFiles,
  };
};
export default useApplicationFormActions;
