// import { useEffect } from 'react';
// import useHelpers from '../../../helpers';
import useHelpers from '../../../helpers';
import useModels from '../../../models';
import useApi from '../../../api';
import useComponentHooks from '../../componentHooks';
import { useEffect, useState } from 'react';
import _ from 'lodash';

const useApplicationReview = (
  setValueComments,
  setValuePaymentFee,
  watchBoardMemberVoting,
  setValueMemberVoting,
  resetFiedMemberVoting
) => {
  // Api
  const { useActions } = useApi();
  const {
    dispatch,
    useApplicationReviewActions,
    useApplicationsTableActions,
    useApplicationFormActions,
    usePrivateDocumentsActions,
    useVotingActions,
  } = useActions();
  const {
    actSetStopwatchState,
    actVoteApplication,
    actGetCurrentsVotes,
    actRevertApplication,
  } = useVotingActions();
  const {
    actSendComment,
    actSetCurrentStepApplicationReview,
    actApproveApplication,
    actSetStatusApplicationReview,
    actGetSapsAndMunicipalityReports,
    actGrantApplication,
    actRejectApplication,
  } = useApplicationReviewActions();
  const { actGetApplicationById } = useApplicationsTableActions();
  const { actSetApplicationForm, actApproveApplicationFeeProofOfPayment } =
    useApplicationFormActions();
  const { actGetPrivateDocument } = usePrivateDocumentsActions();
  //Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation, usePromises, useFileManager } = useQuickFunctions();
  const { navigateTo } = useNavigation();
  const { fileTypesPdf, onChangeFilePdf } = useFileManager();

  const { promiseInProgressArea: promiseGetApplicationFiles } = usePromises(
    'getApplicationFiles'
  );
  const { promiseInProgressArea: promiseGetApplicationImages } = usePromises(
    'getApplicationImages'
  );
  const { promiseInProgress } = usePromises();
  //hooks
  const { useModal } = useComponentHooks();
  const {
    modalState: modalStateComments,
    handleShowModal: handleShowModalComments,
  } = useModal();
  const {
    modalState: helperModalStateSuccess,
    handleShowModal: handleHelperModalStateSuccess,
  } = useModal();
  const {
    modalState: lastApproveModalState,
    handleShowModal: handleShowModalLastApprove,
  } = useModal();

  //Selectors
  const { useSelectors } = useModels();
  const {
    useSelector,
    useUserSelectors,
    useApplicationFormSelectors,
    useApplicationReviewSelectors,
    useCommentsSelectors,
    useVotingSelectors,
  } = useSelectors();
  const { votingSelector, votesSelector } = useVotingSelectors();
  const { userSelector } = useUserSelectors();
  const { profile, userRolesList } = useSelector(userSelector);
  const {
    state: stateStopWatch,
    currentTime,
    alreadyVoted,
    canRepeatVote,
    hasVestedInterest,
    hasVestedType,
    hasVestedTypeOther,
  } = useSelector(votingSelector);
  const { votes } = useSelector(votesSelector);
  const {
    applicationFormSelector,
    applicationFormHelpDataSelector,
    applicationDateSelector,
  } = useApplicationFormSelectors();

  const { applicationForm } = useSelector(applicationFormSelector);
  const { licenceTypes } = useSelector(applicationFormHelpDataSelector);
  const { date } = useSelector(applicationDateSelector);
  const { applicationReviewStateSelector } = useApplicationReviewSelectors();
  const { commentsSelector } = useCommentsSelectors();

  const {
    currentStep: currentStepWizard,
    stepsEnabled: stepsEnabledWizzard,
    applicationReviewWizard,
    sapsAndMunicipalityReports,
    inspectionForm,
  } = useSelector(applicationReviewStateSelector);
  const { frontComments } = useSelector(commentsSelector);
  const [sendCommentsSuccessState, setSendCommentsSuccessState] =
    useState('error');
  const [lastApproveState, setLastApproveState] = useState('');
  const [redirectApplication, setRedirectApplication] = useState(false);
  const [applicationForVoteState, setApplicationForVoteState] = useState({
    voteReady: false,
    statusOfVote: 0,
  });

  const { hasVestedInterest: hasVestedInterestWatch } =
    watchBoardMemberVoting();

  const typeOfInterest = [
    { value: '1', text: 'Do you own a stake in the business?' },
    {
      value: '2',
      text: 'Have you previously conducted business with the applicant?',
    },
    { value: '3', text: 'Is your interest in financial value/gains?' },
    { value: '4', text: 'Other' },
  ];

  // current step at mounting
  useEffect(() => {
    switch (applicationForm.status.id) {
      // Pending Appoval (Submitted)
      case 2:
        dispatch(actSetCurrentStepApplicationReview({ step: 1 }));
        break;

      // Pending Appoval
      case 3:
        dispatch(actSetCurrentStepApplicationReview({ step: 1 }));
        break;

      // Reports pending
      case 4:
        dispatch(actSetCurrentStepApplicationReview({ step: 2 }));
        dispatch(actSetStatusApplicationReview({ steps: 2 }));
        break;

      // Reports approval
      case 5:
        dispatch(actSetCurrentStepApplicationReview({ step: 3 }));
        dispatch(actSetStatusApplicationReview({ steps: 3 }));
        break;

      // Voting pending
      case 6:
        dispatch(
          actGetSapsAndMunicipalityReports({
            applicationId: applicationForm.id,
          })
        );
        dispatch(actSetCurrentStepApplicationReview({ step: 4 }));
        dispatch(
          actSetStatusApplicationReview({
            steps: profile.roleType.id !== 1 ? 4 : 0,
          })
        );
        break;

      //Approved
      case 8:
        dispatch(
          actGetSapsAndMunicipalityReports({
            applicationId: applicationForm.id,
          })
        );
        dispatch(actSetCurrentStepApplicationReview({ step: 5 }));
        dispatch(actSetStatusApplicationReview({ steps: 0 }));
        break;

      // Rejected
      case 9:
        dispatch(
          actGetSapsAndMunicipalityReports({
            applicationId: applicationForm.id,
          })
        );
        dispatch(
          actSetCurrentStepApplicationReview({
            step: profile.roleType.id !== 1 ? 5 : 4,
          })
        );
        dispatch(actSetStatusApplicationReview({ steps: 0 }));
        break;
      // Payment review
      case 10:
        dispatch(
          actGetSapsAndMunicipalityReports({
            applicationId: applicationForm.id,
          })
        );
        dispatch(actSetCurrentStepApplicationReview({ step: 5 }));
        dispatch(actSetStatusApplicationReview({ steps: 0 }));
        break;

      // Final approval
      case 11:
        dispatch(
          actGetSapsAndMunicipalityReports({
            applicationId: applicationForm.id,
          })
        );
        dispatch(actSetCurrentStepApplicationReview({ step: 6 }));
        dispatch(actSetStatusApplicationReview({ steps: 0 }));
        break;

      // Payment review
      case 12:
        dispatch(
          actGetSapsAndMunicipalityReports({
            applicationId: applicationForm.id,
          })
        );
        dispatch(actSetCurrentStepApplicationReview({ step: 5 }));
        dispatch(actSetStatusApplicationReview({ steps: 0 }));
        break;

      // Payment failed
      case 13:
        dispatch(
          actGetSapsAndMunicipalityReports({
            applicationId: applicationForm.id,
          })
        );
        dispatch(actSetCurrentStepApplicationReview({ step: 5 }));
        dispatch(actSetStatusApplicationReview({ steps: 0 }));
        break;
      default:
        dispatch(actSetCurrentStepApplicationReview({ step: 1 }));
        break;
    }
  }, []);
  useEffect(() => {
    !applicationForm.id && handleRedirectBack();
  }, []);
  // Current votes when CEO
  useEffect(() => {
    profile.roleType.id === 5 &&
      applicationForm.id &&
      dispatch(actGetCurrentsVotes(applicationForm.id));
  }, [applicationForm.id]);
  // Application Review
  useEffect(() => {
    (profile.roleType.id === 2 || profile.roleType.id === 6) &&
      setValuePaymentFee &&
      setValuePaymentFee('prescribedFees', `${applicationForm.prescribedFees}`);
  }, [applicationForm.prescribedFees]);

  // vote application
  useEffect(() => {
    if (!stateStopWatch && currentTime && applicationForVoteState.voteReady) {
      switch (applicationForVoteState.statusOfVote) {
        case 1:
          dispatch(
            actVoteApplication(
              {
                approved: applicationForVoteState.statusOfVote,
                votingTime: currentTime,
                applicationId: applicationForm.id,
                ...watchBoardMemberVoting(),
              },
              handleApplicationVoteApproved
            )
          );

          break;
        default:
          handlesSendCommentAfterDeny();
          break;
      }
    }
  }, [applicationForVoteState.voteReady, stateStopWatch, currentTime]);

  useEffect(() => {
    applicationForm.status.id === 7 &&
      redirectApplication &&
      navigateTo('/applications');
  }, [applicationForm.status.id, redirectApplication]);

  useEffect(() => {
    if (hasVestedInterestWatch === '0') {
      resetFiedMemberVoting('hasVestedType', { defaultValue: '0' });
      resetFiedMemberVoting('hasVestedTypeOther', { defaultValue: '' });
    }
  }, [hasVestedInterestWatch]);

  useEffect(() => {
    setValueMemberVoting(
      'hasVestedInterest',
      hasVestedInterest === 1 ? '1' : '0',
      {
        shouldValidate: true,
      }
    );
  }, [hasVestedInterest]);

  useEffect(() => {
    setValueMemberVoting(
      'hasVestedTypeOther',
      hasVestedTypeOther ? hasVestedTypeOther : '',
      {
        shouldValidate: true,
      }
    );
  }, [hasVestedTypeOther]);

  useEffect(() => {
    setValueMemberVoting(
      'hasVestedType',
      hasVestedType?.id ? `${hasVestedType.id}` : '0',
      {
        shouldValidate: true,
      }
    );
  }, [hasVestedType?.id]);

  const handleRedirectBack = () => {
    navigateTo('/applications');
  };

  const handleUserRoleType = () => {
    const findRole = _.find(
      userRolesList,
      (userRole) =>
        userRole.id ===
        applicationForm.licensingMemberComment?.commentMadeBy?.roleId
    );
    return findRole?.name ? findRole.name : '';
  };
  //Wizard Controll
  const handleApplicationReviewStep = (event) => {
    const nextStep = parseInt(event.target.value);
    dispatch(actSetCurrentStepApplicationReview({ step: nextStep }));
  };
  //Modal comments sent
  const handleSendCommentsSuccess = () => {
    setSendCommentsSuccessState('success');
    handleShowModalComments();
    handleHelperModalStateSuccess('Success!', 'Comments sent successfully!');
  };

  const handleSendCommentsDenyPaymentSuccess = () => {
    setSendCommentsSuccessState('success');
    handleShowModalComments();
    handleHelperModalStateSuccess(
      'Success!',
      'Application successfully returned to the applicant for amendments'
    );
  };

  const handleSendCommentsSuccessBoardMember = () => {
    setApplicationForVoteState({ voteReady: false, statusOfVote: 0 });
    setSendCommentsSuccessState('success');
    handleShowModalComments();
    handleHelperModalStateSuccess('Success!', 'Comments sent successfully!');
  };

  const handleSendDeny = (data) => {
    data && setSendCommentsSuccessState('success');
    handleHelperModalStateSuccess(
      'Success!',
      'The vote was sent successfully!'
    );
  };
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const handleDate = (currentDate) => {
    const currentFullDate = new Date(currentDate);
    const currentYear = currentFullDate.getFullYear();
    const currentMonth = monthNames[currentFullDate.getMonth()];
    const currentDay = currentFullDate.getDate();

    return ` ${currentDay} ${currentMonth} ${currentYear}`;
  };

  const handleTimeExpend = (currentTimeMilisecond) => {
    const currentTimeSeconds = currentTimeMilisecond / 1000;

    const hours = Math.floor(currentTimeSeconds / 3600);
    const minutes = Math.floor(currentTimeSeconds / 60);
    const seconds = currentTimeSeconds - minutes * 60;

    return ` ${hours < 10 ? `0${hours}` : hours}h : ${
      minutes < 10 ? `0${minutes}` : minutes
    }m : ${seconds < 10 ? `0${seconds}` : seconds}s`;
  };
  const handleGetApplicationSuccess = (data) => {
    // dispatch(actSetApplicationForm({ application: data.data.application }));
    switch (data.application.status.id) {
      // Reports pendding
      case 4:
        dispatch(
          actSetApplicationForm(
            { application: data.application },
            dispatch(actSetCurrentStepApplicationReview({ step: 2 }))
          )
        );
        break;

      // Reports approval
      case 5:
        dispatch(
          actSetApplicationForm(
            { application: data.application },
            dispatch(actSetCurrentStepApplicationReview({ step: 3 }))
          )
        );
        break;

      // Voting pending
      case 6:
        if (profile.roleType.id === 1) {
          dispatch(
            actSetApplicationForm(
              { application: data.application },
              dispatch(actSetCurrentStepApplicationReview({ step: 4 }))
            )
          );
          dispatch(actSetStatusApplicationReview({ steps: 4 }));
        } else {
          navigateTo('/applications');
        }
        break;

      // Request for changes
      case 7:
        dispatch(
          actSetApplicationForm(
            { application: data.application },
            setRedirectApplication(true)
          )
        );
        break;

      // Approved
      case 8:
        navigateTo('/applications');
        break;

      // Rejected
      case 9:
        navigateTo('/applications');
        break;

      // Payment completed
      case 11:
        navigateTo('/applications');
        break;

      // Payment failed
      case 13:
        navigateTo('/applications');
        break;
      default:
        dispatch(actSetApplicationForm({ application: data.application }));
        break;
    }
  };

  const handleCloseHelperModalSuccess = () => {
    setValueComments('textAreaComments', '');
    handleHelperModalStateSuccess();
    profile.roleType.id !== 1
      ? dispatch(
          actGetApplicationById(
            { id: applicationForm.id },
            handleGetApplicationSuccess
          )
        )
      : navigateTo('/applications');
  };

  // Application Vote Approved
  const handleRevertApplicationSuccess = () => {
    setSendCommentsSuccessState('success');
    handleHelperModalStateSuccess('Success!', 'You Reverted this application');
  };
  // Application Vote Approved
  const handleApplicationVoteApproved = () => {
    setSendCommentsSuccessState('success');
    handleHelperModalStateSuccess(
      'Success!',
      'Thank you for successfully submitting your vote'
    );
  };

  // Application Approved Phase 1
  const handleApplicationApproved = () => {
    setSendCommentsSuccessState('success');
    handleHelperModalStateSuccess(
      'Success!',
      'Application meets the basic requirements of Phase 1'
    );
  };

  //handle title
  const handleStepTitle = () => {
    switch (currentStepWizard) {
      case 1:
        return 'Application';
      case 2:
        if (profile.roleType.id !== 3) {
          if (sapsAndMunicipalityReports.sapsReport?.data && !inspectionForm) {
            return 'SAPS and Municipality reports';
          } else if (
            !sapsAndMunicipalityReports.sapsReport?.data &&
            inspectionForm
          ) {
            return 'Inspection form and Municipality reports';
          } else {
            return 'SAPS and Municipality reports';
          }
        } else {
          if (sapsAndMunicipalityReports.sapsReport?.data && !inspectionForm) {
            return 'SAPS and Municipality reports';
          } else if (
            !sapsAndMunicipalityReports.sapsReport?.data &&
            inspectionForm
          ) {
            return 'Inspection form and Municipality reports';
          } else {
            return 'Inspection form and Municipality reports';
          }
        }
      case 3:
        if (sapsAndMunicipalityReports.sapsReport?.data && !inspectionForm) {
          return 'SAPS and Municipality reports';
        } else if (
          !sapsAndMunicipalityReports.sapsReport?.data &&
          inspectionForm
        ) {
          return 'Inspection form and Municipality reports';
        } else {
          return 'Inspection form and Municipality reports';
        }
      default:
        return 'Application';
    }
  };
  const handleGetPrivateLink = (url) => {
    window.open(url);
  };
  const handleGetPrivateDocumentView = (documentName) => {
    dispatch(
      actGetPrivateDocument(
        {
          documentName: documentName,
          applicationId: applicationForm.id,
        },
        handleGetPrivateLink
      )
    );
  };

  const handlesSendCommentAfterDeny = () => {
    dispatch(
      actVoteApplication(
        {
          approved: applicationForVoteState.statusOfVote,
          votingTime: currentTime,
          applicationId: applicationForm.id,
          ...watchBoardMemberVoting(),
        },
        handleSendDeny
      )
    );
  };
  const onSubmitComments = (data) => {
    const dataComments = {
      comment: data.textAreaComments,
      applicationId: applicationForm.id,
      sendFrontUserNotification: data.sendToApplicant,
      applicationStatusId:
        currentStepWizard === 5 &&
        (profile.roleType.id === 2 || profile.roleType.id === 6) //For payment failed
          ? 13
          : 7,
    };
    profile.roleType.id !== 1
      ? dispatch(
          actSendComment(
            { data: dataComments },
            dataComments.applicationStatusId === 13
              ? handleSendCommentsDenyPaymentSuccess
              : handleSendCommentsSuccess
          )
        )
      : data.sendToApplicant
      ? dispatch(
          actSendComment({ data: dataComments }, handlesSendCommentAfterDeny)
        )
      : dispatch(
          actSendComment(
            { data: dataComments },
            handleSendCommentsSuccessBoardMember
          )
        );
  };

  /**Approve phase 1 */
  const onSubmitApprove = () => {
    const dataApprove = {
      applicationId: applicationForm.id,
      newStatusId: '4',
    };
    applicationForm.id &&
      dispatch(actApproveApplication(dataApprove, handleApplicationApproved));
  };

  /**Board member Voting */

  const onSubmitVoteApplicationApprove = () => {
    stateStopWatch
      ? dispatch(
          actSetStopwatchState(
            false,
            setApplicationForVoteState({ voteReady: true, statusOfVote: 1 })
          )
        )
      : dispatch(
          actVoteApplication(
            {
              approved: 1,
              votingTime: currentTime,
              applicationId: applicationForm.id,
              ...watchBoardMemberVoting(),
            },
            handleApplicationVoteApproved
          )
        );
  };

  const handleCloseModalComments = () => {
    setApplicationForVoteState({ voteReady: false, statusOfVote: 0 });
    handleShowModalComments();
  };

  const handleRevertApplication = (data) => {
    const dataRevert = {
      applicationId: applicationForm.id,
      comment: data.textAreaComments,
      document: data.document,
      isObjection: 1,
    };
    dispatch(actRevertApplication(dataRevert, handleRevertApplicationSuccess));
  };

  const onSubmitVoteRevertApplication = (data) => {
    dispatch(actSetStopwatchState(false, handleRevertApplication(data)));
  };

  const onSubmitVoteApplicationDeny = () => {
    if (stateStopWatch) {
      dispatch(
        actSetStopwatchState(
          false,
          setApplicationForVoteState({ voteReady: true, statusOfVote: 0 })
        )
      );
    } else {
      setApplicationForVoteState({ voteReady: true, statusOfVote: 0 });
      !currentTime && handlesSendCommentAfterDeny();
    }
  };

  /**Application Fee */
  const handleSubmitApplicationFeeApproveSuccess = () => {
    setSendCommentsSuccessState('success');
    handleHelperModalStateSuccess(
      'Success!',
      ' Application successfully sent for CEO signature'
    );
  };

  const onSubmitApplicationFeeApprove = (data) => {
    const dataApprove = {
      amountReceived: data.amountReceived,
      receiptNumber: data.receiptNumber,
      applicationId: applicationForm.id,
    };
    dispatch(
      actApproveApplicationFeeProofOfPayment(
        dataApprove,
        handleSubmitApplicationFeeApproveSuccess
      )
    );
  };

  /**Last Approve */

  //Modal before approve Application
  const handleShowModalApproveApplication = () => {
    setLastApproveState('approve');
    handleShowModalLastApprove(
      'Are you sure you want to approve this application?',
      ''
    );
  };
  //Modal before reject Application
  const handleShowModalRejectApplication = () => {
    setLastApproveState('reject');
    handleShowModalLastApprove(
      'Are you sure you want to reject this application?',
      ''
    );
  };
  //close modal last approve
  const handleCloseModalApproveApplication = () => {
    setLastApproveState('');
    handleShowModalLastApprove();
  };

  // Application Approved
  const handleApplicationLastApprove = () => {
    setSendCommentsSuccessState('success');
    handleCloseModalApproveApplication();
    handleHelperModalStateSuccess(
      'Success!',
      'The application has been approved!'
    );
  };

  // Application Approved
  const handleApplicationRejected = () => {
    setSendCommentsSuccessState('success');
    handleCloseModalApproveApplication();
    handleShowModalComments();
    handleHelperModalStateSuccess(
      'Success!',
      'The application has been rejected!'
    );
  };
  const onRejectApplication = (data) => {
    const dataComments = {
      comment: data.textAreaComments,
    };
    dispatch(
      actRejectApplication(
        { applicationId: applicationForm.id, data: dataComments },
        handleApplicationRejected
      )
    );
  };

  const onApplicationLastApprove = () => {
    dispatch(
      actGrantApplication(applicationForm.id, handleApplicationLastApprove)
    );
  };
  return {
    promiseInProgress,
    promiseGetApplicationFiles,
    promiseGetApplicationImages,
    stateStopWatch,
    applicationForm,
    profile,
    licenceTypes,
    handleRedirectBack,
    date,
    onSubmitComments,
    modalStateComments,
    handleShowModalComments,
    sendCommentsSuccessState,
    helperModalStateSuccess,
    handleCloseHelperModalSuccess,
    currentStepWizard,
    applicationReviewWizard,
    stepsEnabledWizzard,
    handleApplicationReviewStep,
    onSubmitApprove,
    handleStepTitle,
    handleGetPrivateDocumentView,
    onSubmitVoteApplicationApprove,
    onSubmitVoteApplicationDeny,
    frontComments,
    handleUserRoleType,
    handleCloseModalComments,
    onSubmitApplicationFeeApprove,
    handleDate,
    onRejectApplication,
    onApplicationLastApprove,
    lastApproveState,
    lastApproveModalState,
    handleShowModalApproveApplication,
    handleShowModalRejectApplication,
    handleCloseModalApproveApplication,
    votes,
    handleTimeExpend,
    onSubmitVoteRevertApplication,
    alreadyVoted,
    canRepeatVote,
    onChangeFilePdf,
    fileTypesPdf,
    typeOfInterest,
  };
};

export default useApplicationReview;
