import { combineReducers } from 'redux';
import useApplicationFormReducers from './applicationForm';
import useApplicationReviewReducers from './applicationReview';
import useApplicationsTableReducers from './applicationsTable';
import useAuthReducers from './auth';
import useCommentsReducers from './comments';
import useNotificationsReducers from './notifications';
import usePrivateDocumentsReducers from './privateDocuments';
import useUserReducers from './user';
import useUsersTableReducers from './usersTable';
import useVotingReducers from './voting';
import useRenewalsNoticesReducers from './renewalNotices';
import useBlogsReducers from './blogs';

const useReducers = () => {
  const { session, broadcasting } = useAuthReducers();
  const { user } = useUserReducers();
  const { applicationForm } = useApplicationFormReducers();
  const { applicationsTable } = useApplicationsTableReducers();
  const { usersTable } = useUsersTableReducers();
  const { applicationReview } = useApplicationReviewReducers();
  const { reportsState } = usePrivateDocumentsReducers();
  const { notifications, newNotification } = useNotificationsReducers();
  const { voting, votes } = useVotingReducers();
  const { comments } = useCommentsReducers();
  const { renewalsNotices, applicationsRenewals } =
    useRenewalsNoticesReducers();
  const { currentBlog, blogsTable, blogsLastNews, blogsHomepage } =
    useBlogsReducers();
  return combineReducers({
    session,
    broadcasting,
    user,
    applicationForm,
    applicationsTable,
    usersTable,
    applicationReview,
    reportsState,
    notifications,
    voting,
    votes,
    comments,
    renewalsNotices,
    applicationsRenewals,
    newNotification,
    currentBlog,
    blogsTable,
    blogsLastNews,
    blogsHomepage,
  });
};

export default useReducers;
