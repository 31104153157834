const privateDocumentsTypes = () => {
  const GET_PRIVATE_DOCUMENT = 'GET_PRIVATE_DOCUMENT';
  const GET_PRIVATE_COMMENT_DOCUMENT = 'GET_PRIVATE_COMMENT_DOCUMENT';
  const RESET_DELETED_STATUS = 'RESET_DELETED_STATUS';
  const DELETE_REPORT = 'DELETE_REPORT';

  return {
    GET_PRIVATE_DOCUMENT,
    GET_PRIVATE_COMMENT_DOCUMENT,
    RESET_DELETED_STATUS,
    DELETE_REPORT,
  };
};
export default privateDocumentsTypes;
