import React from 'react';

const iconUploadFile = ({ color = '#5E5C5C', size = 1 }) => {
  return (
    <svg
      width={(size * 18).toString()}
      height={(size * 20).toString()}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.97447 0.0098877C1.70899 0.0098877 1.45439 0.115365 1.26667 0.303087C1.07894 0.490809 0.973483 0.745462 0.973483 1.01094C0.973483 1.27642 1.07894 1.53096 1.26667 1.71868C1.45439 1.9064 1.70899 2.01188 1.97447 2.01188H15.9706C16.2361 2.01188 16.4907 1.9064 16.6784 1.71868C16.8661 1.53096 16.9716 1.27642 16.9716 1.01094C16.9716 0.745462 16.8661 0.490809 16.6784 0.303087C16.4907 0.115365 16.2361 0.0098877 15.9706 0.0098877H1.97447ZM8.96471 4.01191C8.704 4.01604 8.4552 4.12183 8.27135 4.30672L1.26551 11.3048C1.17216 11.3978 1.09807 11.5083 1.04749 11.63C0.9969 11.7517 0.970809 11.8822 0.970703 12.014C0.970598 12.1458 0.996481 12.2764 1.04687 12.3982C1.09726 12.52 1.17117 12.6306 1.26437 12.7238C1.35757 12.817 1.46824 12.891 1.59003 12.9413C1.71182 12.9917 1.84235 13.0176 1.97416 13.0175C2.10596 13.0174 2.23645 12.9914 2.35817 12.9408C2.47988 12.8902 2.59042 12.816 2.68347 12.7227L7.97644 7.42972V19.0118C7.98214 19.2732 8.09001 19.522 8.27692 19.7049C8.46384 19.8878 8.71494 19.9901 8.97644 19.9901C9.23794 19.9901 9.48905 19.8878 9.67596 19.7049C9.86288 19.522 9.97074 19.2732 9.97645 19.0118V7.42188L15.2694 12.7149C15.3591 12.8196 15.4694 12.9046 15.5935 12.9646C15.7176 13.0247 15.8527 13.0584 15.9904 13.0637C16.1282 13.069 16.2655 13.0458 16.3938 12.9956C16.5222 12.9453 16.6387 12.869 16.7362 12.7716C16.8337 12.6741 16.9099 12.5574 16.9602 12.4291C17.0105 12.3008 17.0337 12.1635 17.0284 12.0258C17.0231 11.888 16.9893 11.7528 16.9293 11.6288C16.8693 11.5047 16.7842 11.3944 16.6796 11.3048L9.68932 4.30672C9.59455 4.21141 9.48153 4.13615 9.35703 4.0855C9.23253 4.03485 9.0991 4.00983 8.96471 4.01191Z"
        fill={color}
      />
    </svg>
  );
};

export default iconUploadFile;
