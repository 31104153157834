import { useState } from 'react';
// import useApi from '../../../api';
import useModels from '../../../models';

const useFilterUser = () => {
  // const { useActions } = useApi();
  // const { dispatch, useApplicationsTableActions } =
  //   useActions();
  // const {
  //   actGetApplicationsStatusHelpList,
  // } = useApplicationsTableActions();

  const { useSelectors } = useModels();
  const { useSelector, useUserSelectors } = useSelectors();
  const { userSelector } = useUserSelectors();
  const { userRolesList } = useSelector(userSelector);

  const [filterStatus, setFilterStatus] = useState(false);

  //List of options for every user filter
  const userRolesListSelect = [
    { value: 'none', text: 'Select' },
    ...userRolesList,
  ];

  const hanldeShowFilter = () => {
    setFilterStatus(!filterStatus);
  };
  return {
    userRolesListSelect,
    filterStatus,
    hanldeShowFilter,
  };
};

export default useFilterUser;
