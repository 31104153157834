import React from 'react';

const iconMyProfile = ({ color = '#FFFFFF', size = 1, className = '' }) => {
  return (
    <svg
      className={className}
      width={(size * 24).toString()}
      height={(size * 23).toString()}
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.8894 19.9656C21.7203 17.852 19.0324 16.3475 16.0966 15.6038C15.1293 15.3301 14.9651 13.3591 14.9651 13.3591C16.014 12.3125 16.6603 10.9296 16.7901 9.45355C17.3011 9.45355 17.5748 8.76005 17.5748 8.21254C17.5912 8.03505 17.5591 7.85645 17.4819 7.69577C17.4048 7.53508 17.2855 7.39831 17.1368 7.30004C17.3313 6.8124 17.4304 6.292 17.4288 5.76703C17.4556 5.03297 17.3369 4.30086 17.0794 3.6129C16.8219 2.92495 16.4308 2.29476 15.9287 1.75867C15.4265 1.22257 14.8232 0.79117 14.1535 0.489331C13.4839 0.187492 12.7611 0.0211864 12.0268 0C11.2895 0.0117644 10.5619 0.169905 9.88626 0.465236C9.2106 0.760567 8.60036 1.1872 8.09097 1.72036C7.58158 2.25352 7.1832 2.88258 6.91897 3.571C6.65474 4.25941 6.52993 4.99347 6.55178 5.73053C6.58163 6.27089 6.70514 6.80196 6.91679 7.30004C6.7578 7.34431 6.6179 7.43989 6.51888 7.57192C6.41986 7.70395 6.36726 7.86502 6.36928 8.03004C6.36928 8.57755 6.78904 9.41705 7.30004 9.41705C7.43931 10.8746 8.08509 12.2372 9.12505 13.2678C9.12505 13.2678 8.8878 15.3666 8.26729 15.5126C5.20964 16.2274 2.40682 17.7676 0.164251 19.9656C0.113719 20.0123 0.073125 20.0686 0.0449015 20.1314C0.016678 20.1941 0.00140601 20.2618 1.04122e-07 20.3306V22.1556C-8.43752e-05 22.288 0.0512409 22.4153 0.14316 22.5107C0.23508 22.606 0.360414 22.6619 0.492753 22.6666H23.4879C23.6234 22.6666 23.7534 22.6128 23.8492 22.517C23.945 22.4211 23.9989 22.2911 23.9989 22.1556V20.3306C24.0073 20.1998 23.9684 20.0702 23.8894 19.9656Z"
        fill={color}
      />
    </svg>
  );
};

export default iconMyProfile;
