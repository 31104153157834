const useBlogsTypes = () => {
  const POST_NEW_BLOG = 'POST_NEW_BLOG';
  const UPDATE_BLOG = 'UPDATE_BLOG';
  const GET_BLOG = 'GET_BLOG';
  const DELETE_BLOG = 'DELETE_BLOG';
  const RESET_CURRENT_BLOG = 'RESET_CURRENT_BLOG';
  const GET_BLOGS_TABLE = 'GET_BLOGS_TABLE';
  const GET_BLOGS_LAST_NEWS = 'GET_BLOGS_LAST_NEWS';
  const GET_BLOGS_HOMEPAGE = 'GET_BLOGS_HOMEPAGE';
  const RESET_FIELD_IMAGE = 'RESET_FIELD_IMAGE';

  return {
    POST_NEW_BLOG,
    RESET_FIELD_IMAGE,
    UPDATE_BLOG,
    GET_BLOG,
    DELETE_BLOG,
    RESET_CURRENT_BLOG,
    GET_BLOGS_TABLE,
    GET_BLOGS_LAST_NEWS,
    GET_BLOGS_HOMEPAGE,
  };
};
export default useBlogsTypes;
