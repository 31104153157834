import tw from 'twin.macro';
import styled from 'styled-components';
import Typography from '../../components/Typography';
import Button from '../../components/Buttons';
import ContainerImageShape from '../../components/ContainerImageShape';

export const StyledSectionHome = styled.div.attrs({
  className: 'StyledSectionHome',
})`
&& {
    ${tw`relative w-full h-full py-[20px] flex flex-col`}
    box-sizing:content-box;
    overflow: hidden;
    position: initial;
    @media (max-width: 768px){
      padding-bottom: 14px;
    }
    }}
`;

export const StyledContainerArticle = styled.div.attrs({
  className: 'StyledContainerArticle',
})`
&& {
    ${tw`relative h-[600px] grid`}
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 10%;
    position: initial;

    @media (max-width: 1239.98px) and (min-width: 768px) {
      height: auto;
      margin: 0 3%;

    }
    @media (max-width: 768px){
      width: 100%;
      height: auto;
      display: flex;
      margin-left: 8%;
    }
    }}
`;

export const StyledArticle = styled.article.attrs({
  className: 'StyledArticle',
})`
&& {
    ${tw`relative flex flex-col flex-nowrap`}
    position: initial;
    // justify-content: center;
    width: 100%;
      justify-self: end;
      grid-column: 1/2;
      max-width: 1093px;
      padding-right: 20px;
      @media (max-width: 768px){
        width: 45%;
      }
    }}

`;
export const StyledArticleTitle = styled(Typography).attrs({
  className: 'StyledArticleTitle',
})`
  && {
    max-width: 540px;
    padding: 30px 0;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.022em;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledArticleTitleMobile = styled(Typography).attrs({
  className: 'StyledArticleTitle StyledTypographyBold',
})`
  && {
    display: none;
    @media (max-width: 768px) {
      display: block;
      padding: 20px 0;
      padding-bottom: 16px;
      font-size: 32px;
      line-height: 48px;
      letter-spacing: -0.021em;
    }
  }
`;

export const StyledArticleParagraph = styled(Typography).attrs({
  className: 'StyledArticleParagraph',
})`
  && {
    width: 100%;
    max-height: 220px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledArticleCallToAction = styled(Typography).attrs({
  className: 'StyledArticleCallToAction',
})`
  && {
    display: none;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    text-decoration: underline;
    @media (max-width: 768px) {
      display: block;
    }
  }
`;

export const StyledArticleButton = styled(Button).attrs({
  className: 'StyledArticleButton',
})`
  && {
    width: 180px;
    margin-top: 55px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    @media (max-width: 1300px) {
      margin-top: 30px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledContainerImage = styled.div.attrs({
  className: 'StyledContainerImage',
})`
  && {
    ${tw`relative grid place-items-center`}
    justify-self: end;
    width: 586px;
    height: 564px;
    grid-colmun: 2/3;
    top: 16px;
    position: initial;

    @media (max-width: 1239.98px) and (min-width: 768px) {
      ${tw`relative grid place-items-center`}
      grid-colmun: 2/3;
      top: 16px;
      width: 484px;
      height: 464px;
    }
    @media (max-width: 768px) {
      width: 240px;
      height: 230px;
      margin-left: 5px;
    }
  }
`;
export const StyledImageShape = styled(ContainerImageShape).attrs({
  className: 'StyledImageShape',
})`
  && {
    display: block;
    position: initial;
  }
`;
export const StyledCarousel = styled.div`
  && {
    ${tw`absolute  flex flex-row flex-nowrap`}
    top: 514px;
    margin: 0  calc(10% - 40px);
    @media (max-width: 1240px) and (min-width: 1000px) {
      position: relative;
      justify-content: center;
      top: 0;
      margin: 0 10px;
    }
    @media (max-width: 999.98px) and (min-width: 768px) {

      position: relative;
      justify-content: center;
      top: 0;
      flex-wrap: wrap;
      margin: 0 10px;
    }
    @media (max-width: 767.98px){
      display: none;
    }
}}
  }
`;

export const StyledInfo = styled.div`
  && {
    ${tw`relative h-[100px] mt-[64px] grid place-items-center mx-auto`}
    width: 70%;
    border-top:1px solid #ECEFF3;
    @media (max-width: 1200px) and (min-width: 768px) {
      width: 90%;

    }
    @media (max-width: 767.98px){
      display: none;
    }
}}
  }
`;

export const StyledButtonBlogContainer = styled.div.attrs({
  className: 'StyledButtonBlogContainer',
})`
  && {
      ${tw` relative flex`}
      margin-top: 24px;
      margin-left: 10%;
      z-index: 3;
    }
}}
  }
`;

export const StyledViewMoreButton = styled(Button).attrs({
  className: 'StyledViewMoreButton',
})`
  && {
    width: 180px;
    margin-top: 55px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    @media (max-width: 1300px) {
      margin-top: 30px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
export const StyledButtonsContainer = styled.div`
  && {
    display: none;
    @media (max-width: 768px){
      ${tw` relative flex flex-row space-x-3.5 `}
      margin: 0 auto;
      // margin-top: 35px;
      margin-bottom: 75px;
    }
}}
  }
`;
