import { useState, useEffect } from 'react';
import useApi from '../../../api';
import useHelpers from '../../../helpers';
import useComponentHooks from '../../componentHooks';

const useEmailVerification = () => {
  // Hooks
  const { useModal } = useComponentHooks();
  const { modalState, handleShowModal } = useModal();

  // Api
  const { useActions } = useApi();
  const { dispatch, useAuthActions } = useActions();
  const { actEmailVerification } = useAuthActions();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation } = useQuickFunctions();
  const { getParam, replaceAndNavigateTo } = useNavigation();

  // React
  const [registerState, setRegisterState] = useState('success');

  const handleCloseModal = () => {
    replaceAndNavigateTo('/login');
  };

  const handleEmailVerification = () => {
    const signature = getParam('signature');
    const expires = getParam('expires');
    const id = getParam('id');

    const onSuccessEmailVerification = () => {
      setRegisterState('success');
      handleShowModal('Account verified!', 'Your account has been verfied!');
    };

    const onErrorEmailVerifcation = () => {
      replaceAndNavigateTo('/resend-email');
    };

    dispatch(
      actEmailVerification(
        { signature, expires, id },
        onSuccessEmailVerification,
        onErrorEmailVerifcation
      )
    );
  };

  useEffect(() => {
    handleEmailVerification();
  }, []);

  return {
    registerState,
    handleCloseModal,
    modalState,
  };
};
export default useEmailVerification;
