import { useEffect, useState } from 'react';
import useComponentHooks from '..';
import useApi from '../../../api';
import useHelpers from '../../../helpers';
import useModels from '../../../models';
import _ from 'lodash';

const useApplicationReports = (resetField, setValueInspection) => {
  //Api
  const { useActions } = useApi();
  const {
    dispatch,
    useApplicationReviewActions,
    useApplicationsTableActions,
    useApplicationFormActions,
    usePrivateDocumentsActions,
  } = useActions();
  const {
    actSetCurrentStepApplicationReview,
    actGetSapsAndMunicipalityReports,
    actSetSapsAndMunicipalityReportsDraft,
    actUploadApplicationReviewReports,
    actSendInspectionReport,
    actSetStatusApplicationReview,
    actEditInspectionForm,
  } = useApplicationReviewActions();
  const { actGetApplicationById } = useApplicationsTableActions();
  const { actSetApplicationForm } = useApplicationFormActions();
  const { actDeleteReport, actResetDeletedStatus } =
    usePrivateDocumentsActions();

  //Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation } = useQuickFunctions();
  const { navigateTo } = useNavigation();
  //hooks
  const { useModal } = useComponentHooks();
  const {
    modalState: helperModalStateSuccess,
    handleShowModal: handleHelperModalStateSuccess,
  } = useModal();

  //Selectors
  const { useSelectors } = useModels();
  const {
    useSelector,
    useUserSelectors,
    useApplicationFormSelectors,
    useApplicationReviewSelectors,
    usePrivateDocumentsSelectors,
  } = useSelectors();

  const { userSelector } = useUserSelectors();
  const { profile } = useSelector(userSelector);
  const { applicationReviewStateSelector } = useApplicationReviewSelectors();
  const { applicationFormSelector } = useApplicationFormSelectors();
  const { reportsSelector } = usePrivateDocumentsSelectors();
  const {
    sapsAndMunicipalityReports,
    sapsAndMunicipalityReportsDraft,
    inspectionForm,
    editInspectionForm,
  } = useSelector(applicationReviewStateSelector);
  const { applicationForm } = useSelector(applicationFormSelector);
  const { reportDeleted } = useSelector(reportsSelector);

  const [locationData, setLocationData] = useState({
    latitude: '',
    longitude: '',
  });
  const [isInpectorForm, setIsInpectorForm] = useState(false);

  const [helperModalSuccessTypeState, setHelperModalSuccessTypeState] =
    useState('error');

  const [editInspectionFormState, setEditInspectionFormState] = useState(false);

  // const [getReportsState, setGetReportsState] = useState(false);
  const fileTypesPdf = ['application/pdf'];
  const optionsInspectorForm = [
    { value: '1', text: ' ' },
    { value: '0', text: ' ' },
  ];
  useEffect(() => {
    handleEditInspectionForm(false);
    applicationForm.id &&
      (!sapsAndMunicipalityReports.sapsReport.data ||
        !sapsAndMunicipalityReports.municipalityReport.data) &&
      dispatch(
        actGetSapsAndMunicipalityReports({ applicationId: applicationForm.id })
      );
  }, []);

  useEffect(() => {
    reportDeleted &&
      applicationForm.id &&
      (!sapsAndMunicipalityReportsDraft.sapsReport.data ||
        !sapsAndMunicipalityReportsDraft.municipalityReport.data) &&
      dispatch(
        actGetSapsAndMunicipalityReports(
          { applicationId: applicationForm.id },
          dispatch(actResetDeletedStatus())
        )
      );
  }, [
    sapsAndMunicipalityReportsDraft.sapsReport.data,
    sapsAndMunicipalityReportsDraft.municipalityReport.data,
    reportDeleted,
  ]);

  useEffect(() => {
    _.forEach(inspectionForm, (value, key) => {
      setValueInspection && value && setValueInspection(key, `${value}`);
      setValueInspection && value === null && setValueInspection(key, '');
    });
  }, [inspectionForm, editInspectionForm]);

  useEffect(() => {
    editInspectionForm
      ? setEditInspectionFormState(true)
      : setEditInspectionFormState(false);
  }, [editInspectionForm]);
  const handleEditInspectionForm = (state) => {
    dispatch(actEditInspectionForm(state));
  };
  const handleFileNameExtension = (fileObject) => {
    const fileObjectWithoutExtension = {
      name: fileObject.name ? fileObject.name?.replace(/\.[^/.]+$/, '') : '',
      data: fileObject.data ? fileObject.data : '',
    };
    return fileObjectWithoutExtension;
  };

  const onChangeFilePdf = ({ event, remove, reset, id }) => {
    event.preventDefault();
    if (event.target.files.length > 0) {
      const { type } = event.target.files[0];
      if (!fileTypesPdf.some((s) => type.includes(s))) {
        alert('invalid document type');
        remove(reset, id);
      } else {
        if (event.target.files[0].size > 2000000) {
          alert('Document too large, please try again with 2MB or less');
          remove(reset, id);
        }
      }
    }
  };

  const handleOnSuccessGeoLocation = (position) => {
    setLocationData({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    });
  };
  const handleOnErrorGeoLocation = () => {
    alert(
      'Geolocation is not enabled. Please enable it in your browser to send the inspection report.'
    );
  };
  const handleGetGeolocation = (isChecked) => {
    isChecked &&
      'geolocation' in navigator &&
      navigator.geolocation.getCurrentPosition(
        handleOnSuccessGeoLocation,
        handleOnErrorGeoLocation
      );

    !('geolocation' in navigator) &&
      alert('Geolocation is not supported by this device');
  };

  // modal success control
  const handleGetApplicationSuccess = (data) => {
    // dispatch(actSetApplicationForm({ application: data.data.application }));
    switch (data.application.status.id) {
      case 4:
        dispatch(
          actSetApplicationForm(
            { application: data.application },
            dispatch(
              actSetCurrentStepApplicationReview(
                { step: 2 },
                dispatch(
                  actGetSapsAndMunicipalityReports({
                    applicationId: applicationForm.id,
                  })
                )
              )
            )
          )
        );
        dispatch(actSetStatusApplicationReview({ steps: 2 }));

        break;
      case 5:
        if (!isInpectorForm) {
          dispatch(
            actSetApplicationForm(
              { application: data.application },
              dispatch(actSetCurrentStepApplicationReview({ step: 3 }))
            )
          );
          dispatch(actSetStatusApplicationReview({ steps: 3 }));
        } else {
          navigateTo(`/applications`);
        }

        break;
      default:
        dispatch(actSetApplicationForm({ application: data.application }));
        break;
    }
  };

  const handleCloseHelperModalSuccess = () => {
    handleHelperModalStateSuccess();
    handleEditInspectionForm(false);
    dispatch(
      actGetApplicationById(
        { id: applicationForm.id },
        handleGetApplicationSuccess
      )
    );
  };
  const handleSendInspectionReportSuccess = () => {
    setIsInpectorForm(true);
    setHelperModalSuccessTypeState('success');
    handleHelperModalStateSuccess(
      'Reports uploaded successfully',
      'Inspection report was sent successfully!'
    );
  };
  const handleSendReportsSuccess = () => {
    setHelperModalSuccessTypeState('success');
    handleHelperModalStateSuccess(
      'Reports uploaded successfully',
      'SAPS And Municipality reports uploaded successfully'
    );
  };

  const handleSendReportsSaps = () => {
    setHelperModalSuccessTypeState('success');
    handleHelperModalStateSuccess(
      'Reports uploaded successfully',
      'SAPS report uploaded successfully'
    );
  };

  const handleSendReportsMunicipality = () => {
    setHelperModalSuccessTypeState('success');
    handleHelperModalStateSuccess(
      'Reports uploaded successfully',
      'Municipality report uploaded successfully'
    );
  };
  //submit functions
  const onSaveDraftSaps = (data) => {
    const sapsFile = { sapsReport: data };
    dispatch(actSetSapsAndMunicipalityReportsDraft(sapsFile));
  };

  const onSaveDraftMunicipality = (data) => {
    const municipalityFile = { municipalityReport: data };
    dispatch(actSetSapsAndMunicipalityReportsDraft(municipalityFile));
  };

  const handleSuccessDeleteReport = (fieldId, newState, getReports) => {
    resetField(`${fieldId}`, newState);
    getReports
      ? dispatch(
          actSetSapsAndMunicipalityReportsDraft({
            [`${fieldId}`]: { ...newState },
          })
        )
      : dispatch(
          actSetSapsAndMunicipalityReportsDraft({
            [`${fieldId}`]: { ...newState },
          })
        );
  };
  const handleDeleteReport = (fieldId, newState) => {
    (fieldId === 'sapsReport' && sapsAndMunicipalityReports.sapsReport.data) ||
    (fieldId === 'municipalityReport' &&
      sapsAndMunicipalityReports.municipalityReport.data)
      ? dispatch(
          actDeleteReport(
            {
              documentName:
                fieldId === 'sapsReport'
                  ? sapsAndMunicipalityReports.sapsReport.name
                  : sapsAndMunicipalityReports.municipalityReport.name,
              applicationId: applicationForm.id,
            },
            handleSuccessDeleteReport(fieldId, newState, true)
          )
        )
      : handleSuccessDeleteReport(fieldId, newState, false);
  };
  const onSubmitReports = (data) => {
    const reportsData = { ...data, applicationId: applicationForm.id };

    delete reportsData.reports;

    !reportsData.municipalityReport?.name &&
      (sapsAndMunicipalityReportsDraft.municipalityReport?.data
        ? (reportsData.municipalityReport = handleFileNameExtension(
            sapsAndMunicipalityReportsDraft.municipalityReport
          ))
        : (reportsData.municipalityReport = { name: '', data: '' }));
    !reportsData.sapsReport?.name &&
      (sapsAndMunicipalityReportsDraft.sapsReport?.data
        ? (reportsData.sapsReport = handleFileNameExtension(
            sapsAndMunicipalityReportsDraft.sapsReport
          ))
        : (reportsData.sapsReport = { name: '', data: '' }));

    // Both reports
    reportsData.municipalityReport.data &&
      reportsData.sapsReport.data &&
      dispatch(
        actUploadApplicationReviewReports(reportsData, handleSendReportsSuccess)
      );

    // Saps report
    !reportsData.municipalityReport.data &&
      reportsData.sapsReport.data &&
      dispatch(
        actUploadApplicationReviewReports(reportsData, handleSendReportsSaps)
      );

    // Municipality report
    reportsData.municipalityReport.data &&
      !reportsData.sapsReport.data &&
      dispatch(
        actUploadApplicationReviewReports(
          reportsData,
          handleSendReportsMunicipality
        )
      );
  };

  const onSubmitInspectionReport = (data) => {
    const inspectionreportData = {
      ...data,
      applicationId: applicationForm.id,
      ...locationData,
    };
    delete inspectionreportData.addLocation;
    if (!inspectionreportData.comment) inspectionreportData.comment = null;
    !locationData.latitude
      ? handleGetGeolocation(data.addLocation)
      : dispatch(
          actSendInspectionReport(
            inspectionreportData,
            handleSendInspectionReportSuccess
          )
        );
  };
  return {
    profile,
    locationData,
    inspectionForm,
    applicationForm,
    handleFileNameExtension,
    onChangeFilePdf,
    fileTypesPdf,
    sapsAndMunicipalityReports,
    onSaveDraftSaps,
    onSaveDraftMunicipality,
    sapsAndMunicipalityReportsDraft,
    onSubmitReports,
    optionsInspectorForm,
    onSubmitInspectionReport,
    handleGetGeolocation,
    handleCloseHelperModalSuccess,
    helperModalStateSuccess,
    helperModalSuccessTypeState,
    handleDeleteReport,
    editInspectionFormState,
  };
};

export default useApplicationReports;
