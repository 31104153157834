const applicationReviewTypes = () => {
  const SEND_COMMENT = 'SEND_COMMENT';
  const SET_STATUS_APPLICATION_REVIEW = 'SET_STATUS_APPLICATION_REVIEW';
  const SET_CURRENT_STEP_APPLICATION_REVIEW =
    'SET_CURRENT_STEP_APPLICATION_REVIEW';
  const APPROVE_APPLICATION = 'APPROVE_APPLICATION';
  const GET_SAPS_AND_MUNICIPALITY_REPORTS = 'GET_SAPS_AND_MUNICIPALITY_REPORTS';
  const SET_SAPS_AND_MUNICIPALITY_REPORTS_DRAFT =
    'SET_SAPS_AND_MUNICIPALITY_REPORTS_DRAFT';
  const SEND_INSPECTION_REPORT = 'SEND_INSPECTION_REPORT';
  const UPLOAD_REPORTS = 'UPLOAD_REPORTS';
  const RESET_APPLICATION_REVIEW = 'RESET_APPLICATION_REVIEW';
  const SUBMIT_FOR_VOTE = 'SUBMIT_FOR_VOTE';
  const EDIT_INSPECTION_FORM = 'EDIT_INSPECTION_FORM';
  const GRANT_APPLICATION = 'GRANT_APPLICATION';
  const REJECT_APPLICATION = 'REJECT_APPLICATION';
  return {
    SEND_COMMENT,
    SET_STATUS_APPLICATION_REVIEW,
    SET_CURRENT_STEP_APPLICATION_REVIEW,
    APPROVE_APPLICATION,
    GET_SAPS_AND_MUNICIPALITY_REPORTS,
    SET_SAPS_AND_MUNICIPALITY_REPORTS_DRAFT,
    SEND_INSPECTION_REPORT,
    UPLOAD_REPORTS,
    RESET_APPLICATION_REVIEW,
    SUBMIT_FOR_VOTE,
    EDIT_INSPECTION_FORM,
    GRANT_APPLICATION,
    REJECT_APPLICATION,
  };
};
export default applicationReviewTypes;
