import tw from 'twin.macro';
import styled from 'styled-components';
import Button from '@mui/material/Button';

export const StyledButton = styled(Button).attrs({
  className: 'StyledButton',
})`
  && {
    ${tw`rounded-lg px-[16px] py-[12px] normal-case bg-transparent`}
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

    ${(props) => {
      return `
      ${props.disabled ? `opacity: 0.5; ` : ``}`;
    }}
    ${(props) => {
      return `
        ${
          props.color === 'primary'
            ? `background-color: #5E5C5C; color: white; border: 2px solid #5E5C5C;
              &:hover{
                background-color: #5E5C5C; color: white; border: 2px solid #5E5C5C;
              }
              ${
                props.variant === 'outlined' &&
                `background-color: white; color: #5E5C5C; 
                &:hover{
                  background-color: white; color: #5E5C5C;
                }
                `
              };
              `
            : `background-color: #D66127; color: white; border: 2px solid #D66127;
              &:hover{
                background-color: #D66127; color: white; border: 2px solid #D66127;
              }
              ${
                props.variant === 'outlined' &&
                `background-color: white; color: #D66127;
                &:hover{
                  background-color: white; color: #D66127;
                }`
              };
              `
        };
        ${
          props.color === 'error'
            ? `background-color: #AE1313; color: white; border: 2px solid #AE1313;
              &:hover{
                background-color: #AE1313; color: white; border: 2px solid #AE1313;
              }
              ${
                props.variant === 'outlined' &&
                `background-color: white; color: #AE1313; 
                &:hover{
                  background-color: white; color: #AE1313;
                }
                `
              };
              `
            : `
              `
        };
        ${
          props.promiseLoading
            ? `
            padding:0px;
            cursor: not-allowed;
              `
            : `
              `
        };
      `;
    }}
  }
`;

export const StyleLoading = styled.div`
  && {
    ${tw`relative py-[3px] grid`}
    width: 60%;
    place-content: center;
  }
`;
