const userUserTypes = () => {
  const RECOVERY_USER_PASSWORD = 'RECOVERY_USER_PASSWORD';
  const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
  const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
  const GET_USER_PROFILE = 'GET_USER_PROFILE';
  const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
  const LOG_OUT_USER = 'LOG_OUT_USER';
  const GET_USER_ROLES = 'GET_USER_ROLES';
  const GET_CONTACT_METHODS = 'GET_CONTACT_METHODS';
  return {
    RESET_USER_PASSWORD,
    RECOVERY_USER_PASSWORD,
    CHANGE_USER_PASSWORD,
    GET_USER_PROFILE,
    UPDATE_USER_PROFILE,
    LOG_OUT_USER,
    GET_USER_ROLES,
    GET_CONTACT_METHODS,
  };
};
export default userUserTypes;
