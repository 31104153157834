import React from 'react';

const iconClosedEye = ({ color = '#5E5C5C', size = 1 }) => {
  return (
    <svg
      width={(size * 16).toString()}
      height={(size * 16).toString()}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7704 5.73772C14.5245 5.59018 14.213 5.68854 14.0819 5.91805C13.7868 6.42624 13.4261 6.90165 13.0163 7.32788C12.9343 7.40985 13.0163 7.32788 12.9343 7.40985C12.3114 8.0328 11.5737 8.5246 10.7704 8.86886C10.672 8.91804 10.7704 8.86886 10.672 8.91804C9.85234 9.26231 8.9671 9.42624 8.06546 9.44263C7.9671 9.44263 8.06546 9.44263 7.9671 9.44263C7.04906 9.44263 6.16382 9.26231 5.32775 8.91804C5.22939 8.86886 5.32775 8.91804 5.22939 8.86886C4.42611 8.5246 3.68841 8.0328 3.06546 7.40985C2.98349 7.32788 3.06546 7.40985 2.98349 7.32788C2.57366 6.90165 2.213 6.44264 1.91792 5.93444C1.77038 5.68854 1.47529 5.60657 1.22939 5.75411C0.983491 5.90165 0.901524 6.19673 1.04906 6.44264C1.32775 6.91805 1.65562 7.37706 2.03267 7.7869L1.34415 8.98362C1.19661 9.22952 1.27857 9.54099 1.52447 9.68854C1.60644 9.73772 1.68841 9.75411 1.78677 9.75411C1.9671 9.75411 2.13103 9.65575 2.22939 9.49182L2.78677 8.5246C3.29497 8.96722 3.86874 9.34427 4.47529 9.63936L4.14743 10.8197C4.08185 11.0984 4.22939 11.3771 4.50808 11.4426C4.55726 11.459 4.59005 11.459 4.63923 11.459C4.86874 11.459 5.06546 11.3115 5.13103 11.082L5.40972 10.0164C6.08185 10.2459 6.77038 10.3771 7.49169 10.4262V11.5246C7.49169 11.8033 7.7212 12.0328 7.99988 12.0328C8.27857 12.0328 8.50808 11.8033 8.50808 11.5246V10.4262C9.19661 10.3771 9.88513 10.2459 10.5409 10.0328L10.8196 11.082C10.8851 11.3115 11.0819 11.459 11.3114 11.459C11.3605 11.459 11.3933 11.459 11.4425 11.4426C11.7212 11.3771 11.8851 11.082 11.8032 10.8197L11.4917 9.63936C12.0982 9.34427 12.672 8.96722 13.1802 8.5246L13.7376 9.47542C13.8359 9.63936 13.9999 9.73772 14.1802 9.73772C14.2622 9.73772 14.3605 9.72132 14.4425 9.67214C14.6884 9.5246 14.7704 9.21313 14.6228 8.96723L13.9507 7.7869C14.3278 7.37706 14.6556 6.91805 14.9343 6.42624C15.0982 6.18034 15.0163 5.86887 14.7704 5.73772Z"
        fill={color}
      />
    </svg>
  );
};

export default iconClosedEye;
