import { useState } from 'react';

const useModal = () => {
  const [modalState, setModalState] = useState({
    show: false,
    title: '',
    description: '',
  });

  const handleShowModal = (title = '', description = '') =>
    setModalState({
      show: !modalState.show,
      title: title,
      description: description,
    });

  return {
    modalState,
    handleShowModal,
  };
};

export default useModal;
