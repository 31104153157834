import { trackPromise } from 'react-promise-tracker';
import useProviders from '../../providers';

const useVotingServices = () => {
  const { useVotingProviders } = useProviders();
  const {
    saveVoteTime,
    getVote,
    voteApplication,
    revertApplication,
    getCurrentsVotes,
  } = useVotingProviders();

  const saveVoteTimeService = (applicationId, time) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(saveVoteTime({ applicationId, time })));
      } catch (e) {
        reject(e);
      }
    });
  };
  const getVoteService = (applicationId) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getVote(applicationId)));
      } catch (e) {
        reject(e);
      }
    });
  };

  const voteApplicationService = (
    approved,
    votingTime,
    applicationId,
    hasVestedInterest,
    hasVestedType,
    hasVestedTypeOther
  ) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(
            voteApplication({
              approved,
              votingTime,
              applicationId,
              hasVestedInterest,
              hasVestedType,
              hasVestedTypeOther,
            })
          )
        );
      } catch (e) {
        reject(e);
      }
    });
  };

  const revertApplicationService = ({
    applicationId,
    comment,
    document,
    isObjection,
  }) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(
            revertApplication({
              applicationId,
              comment,
              document,
              isObjection,
            })
          )
        );
      } catch (e) {
        reject(e);
      }
    });
  };

  const getCurrentsVotesService = (applicationId) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getCurrentsVotes(applicationId)));
      } catch (e) {
        reject(e);
      }
    });
  };
  return {
    saveVoteTimeService,
    getVoteService,
    voteApplicationService,
    revertApplicationService,
    getCurrentsVotesService,
  };
};

export default useVotingServices;
