import axios from 'axios';

const useApplicationsTableProvider = () => {
  const getApplicationsTablePage = (
    page,
    search,
    licenseType,
    status,
    start,
    end
  ) => {
    return axios({
      method: 'get',
      url: `api/applications?${page && '&page=' + page}${
        search && `&search=${search}`
      }${licenseType && `&licence_type=${licenseType}`}${
        status && `&status=[${status}]`
      }${start && `&start=${start}`}${end && `&end=${end}`}`,
    });
  };

  const getApplicationById = (id) => {
    return axios({
      method: 'get',
      url: `api/application/${id}`,
    });
  };
  const getApplicationsStatusHelpList = () => {
    return axios({
      method: 'get',
      url: `api/status-list`,
    });
  };
  return {
    getApplicationsTablePage,
    getApplicationById,
    getApplicationsStatusHelpList,
  };
};

export default useApplicationsTableProvider;
