import React from 'react';

const iconTelephone = ({ color = '#FFFFFF', size = 1 }) => {
  return (
    <svg
      width={(size * 18).toString()}
      height={(size * 18).toString()}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2762 17.2498C8.16267 17.2498 0.750366 9.91121 0.750366 4.72118C0.750366 2.67181 2.03158 0.75 4.21984 0.75C4.67903 0.75 6.75107 4.2875 6.75107 4.72402C6.75107 5.43832 4.62801 6.48143 4.62801 7.11353C4.62801 8.64985 9.35034 13.3722 10.8867 13.3722C11.5188 13.3722 12.5619 11.2491 13.2762 11.2491C13.4094 11.2491 17.1028 13.2021 17.2162 13.5366C17.9985 15.3564 15.0789 17.2498 13.2762 17.2498Z"
        fill={color}
      />
    </svg>
  );
};

export default iconTelephone;
