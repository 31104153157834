import React from 'react';
import PropTypes from 'prop-types';
// Styles
import {
  StyledWizard,
  StyledStatusBar,
  StyledStatusBarRight,
  StyledFormControl,
  StyledRadioGroup,
  StyledStepContainer,
} from './WizardDynamic.styles';
import {
  FormControlLabel,
  // IconButton,
  // InputAdornment,
  Radio,
} from '@mui/material';
// import { Error } from '@mui/icons-material';
import useAssets from '../../../assets';
import _ from 'lodash';
const WizardDynamic = (props) => {
  const {
    // handleSubmitStep1,
    // // onSubmitStep1,
    // handleSubmitStep2,
    // onSubmitStep2,
    className,
    currentStep,
    wizardStatus,
    stepsEnabled,
    handleonChangeStep,
    fullWidth,
    error,
    sx,
    style,
    ...rest
  } = props;
  const { useIcons } = useAssets();
  const { iconWizardStep: IconWizardStep } = useIcons();
  return (
    <StyledWizard
      className={className}
      steps={wizardStatus.length}
      style={style}
    >
      <StyledFormControl variant="outlined" fullWidth={fullWidth}>
        <StyledRadioGroup
          row
          defaultValue={currentStep}
          value={currentStep}
          onChange={handleonChangeStep}
          sx={sx}
          type={'text'}
          error={error}
          {...rest}
        >
          <StyledStatusBar steps={wizardStatus.length}>
            {wizardStatus.length &&
              _.map(wizardStatus, (step) => {
                return (
                  <StyledStepContainer>
                    {wizardStatus.length !== step.value && (
                      <StyledStatusBarRight
                        style={{
                          borderColor: `${
                            currentStep > step.value ? '#D66127' : '#DAE0E8'
                          }`,
                        }}
                      />
                    )}
                    <FormControlLabel
                      value={step.value}
                      control={
                        <Radio
                          disabled={
                            !(currentStep === step.value
                              ? true
                              : stepsEnabled >= step.value)
                          }
                          // onClick={currentStep > 1 ? handleSubmitStep2 : () => {}}
                          icon={
                            <IconWizardStep
                              color={`${
                                currentStep > step.value ? '#D66127' : '#DAE0E8'
                              }`}
                            />
                          }
                          checkedIcon={<IconWizardStep color={'#5E5C5C'} />}
                        />
                      }
                      labelPlacement={'bottom'}
                      label={`${currentStep === step.value ? step.label : ''}`}
                    />
                  </StyledStepContainer>
                );
              })}
          </StyledStatusBar>
        </StyledRadioGroup>
      </StyledFormControl>
    </StyledWizard>
  );
};

WizardDynamic.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  error: PropTypes.any,
  helperText: PropTypes.any,
  sx: PropTypes.any,
  style: PropTypes.any,
  stepsEnabled: PropTypes.number,
  currentStep: PropTypes.number,
  wizardStatus: PropTypes.array.isRequired,
  handleonChangeStep: PropTypes.func,
};

WizardDynamic.defaultProps = {
  classNeme: '',
  fullWidth: true,
  handleonChangeStep: () => {},
};

export default WizardDynamic;
