const commentsTypes = () => {
  const GET_APPLICATION_COMMENTS = 'GET_APPLICATION_COMMENTS';
  const SEND_INTERNAL_COMMENT = 'SEND_INTERNAL_COMMENT';
  const SEND_INTERNAL_COMMENT_REPLY = 'SEND_INTERNAL_COMMENT_REPLY';

  return {
    GET_APPLICATION_COMMENTS,
    SEND_INTERNAL_COMMENT,
    SEND_INTERNAL_COMMENT_REPLY,
  };
};
export default commentsTypes;
