import React from 'react';
import useApi from '../../../api';
import useHelpers from '../../../helpers';
import useModels from '../../../models';
import useComponentHooks from '../../componentHooks';
import { StyledDescriptionModalInner } from '../../../views/components/ApplicationConfirmation/ApplicationConfirmation.styles.js';
const useApplicationConfirmation = () => {
  // Api
  const { useActions } = useApi();
  const { dispatch, useApplicationFormActions, useApplicationsTableActions } =
    useActions();
  const {
    // actSetCurrentStep,
    actSaveUserApplication,
    actSubmitApplicationFormFinal,
    actCancelApplication,
    actUpdateApplication,
    actUpdateFinalSubmitApplication,
    actSaveFinalSubmitApplication,
    actSetCurrentStep,
  } = useApplicationFormActions();
  const { actGetApplicationsTable } = useApplicationsTableActions();

  const { useModal } = useComponentHooks();
  const { modalState, handleShowModal } = useModal();
  const {
    modalState: modalStateCancel,
    handleShowModal: handleShowModalCancel,
  } = useModal();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation, usePromises } = useQuickFunctions();
  const { navigateTo, goBack } = useNavigation();
  const { promiseInProgressArea: promiseSaveUserApplication } = usePromises(
    'saveUserApplication'
  );
  const { promiseInProgressArea: promiseSaveFinalSubmitApplication } =
    usePromises('saveFinalSubmitApplication');
  const { promiseInProgressArea: promiseUpdateUserApplication } = usePromises(
    'updateUserApplication'
  );
  const { promiseInProgressArea: promiseUpdateFinalSubmitApplication } =
    usePromises('updateFinalSubmitApplication');
  const { promiseInProgressArea: promiseCancelApplication } =
    usePromises('cancelApplication');

  const { useSelectors } = useModels();
  const { useSelector, useApplicationFormSelectors } = useSelectors();
  const { applicationFormSelector } = useApplicationFormSelectors();
  const { currentStep, applicationForm } = useSelector(applicationFormSelector);

  const handleCancelApplication = () => {
    handleShowModalCancel();
  };

  const handleRequestApplication = () => {
    dispatch(actGetApplicationsTable({ page: 1 }, navigateTo(`/applications`)));
    handleShowModalCancel();
  };
  const handleCloseModalCancel = () => {
    applicationForm.status?.id !== 7 &&
      dispatch(
        actCancelApplication(
          {
            id: applicationForm.id ? applicationForm.id : '',
          },
          handleRequestApplication
        )
      );
  };

  const handleCloseModalSuccesHome = () => {
    handleShowModal();
    navigateTo(`/home`);
  };

  const handleCloseModalSucces = () => {
    handleShowModal();
    navigateTo(`/applications`);
  };

  const handleSuccessUpdate = () => {
    handleShowModal(
      'Your application was saved!',
      'To view your saved application, click on the  "my applications"  at the top of the Homepage.'
    );
  };

  const handleSuccessSave = (data) => {
    handleShowModal(
      'Your Application has been successfully sent!',
      <>
        <StyledDescriptionModalInner>
          Thank you for your submission.{' '}
        </StyledDescriptionModalInner>
        <StyledDescriptionModalInner>
          Application Reference Number is {data.applicationReference}.
        </StyledDescriptionModalInner>
      </>
    );
  };

  const handleSuccessSaveDraft = () => {
    handleShowModal(
      'Your application was saved!',
      'To view your saved application, click on the  "my applications"  at the top of the Homepage.'
    );
  };
  const handleApplicationFormStep = (nextStep) => {
    dispatch(actSetCurrentStep({ step: nextStep }));
  };

  const handleSendFinalUpdateUserApplication = (data) => {
    dispatch(actUpdateFinalSubmitApplication(data, handleSuccessSave));
  };
  const handleUpdateUserApplication = (data) => {
    dispatch(actUpdateApplication(data, handleSuccessUpdate));
  };
  const handleSaveUserApplication = (data) => {
    dispatch(actSaveFinalSubmitApplication(data, handleSuccessSave));
  };
  const handleSaveUserApplicationDraft = (data) => {
    dispatch(actSaveUserApplication(data, handleSuccessSaveDraft));
  };

  const onSubmit = () => {
    const applicationForFinalSubmit = { ...applicationForm };
    applicationForFinalSubmit.status = 2;
    if (applicationForm.id) {
      dispatch(
        actSubmitApplicationFormFinal(
          applicationForFinalSubmit,
          handleSendFinalUpdateUserApplication
        )
      );
    } else {
      dispatch(
        actSubmitApplicationFormFinal(
          applicationForFinalSubmit,
          handleSaveUserApplication
        )
      );
    }
  };

  const onSubmitDraft = () => {
    const applicationForFinalSubmit = { ...applicationForm };

    applicationForFinalSubmit.status = 1;

    if (applicationForm.id) {
      dispatch(
        actSubmitApplicationFormFinal(
          applicationForFinalSubmit,
          handleUpdateUserApplication
        )
      );
    } else {
      dispatch(
        actSubmitApplicationFormFinal(
          applicationForFinalSubmit,
          handleSaveUserApplicationDraft
        )
      );
    }
  };

  return {
    promiseSaveUserApplication,
    promiseSaveFinalSubmitApplication,
    promiseUpdateUserApplication,
    promiseUpdateFinalSubmitApplication,
    promiseCancelApplication,

    goBack,

    onSubmit,
    onSubmitDraft,
    handleCancelApplication,
    applicationForm,
    currentStep,
    modalState,
    modalStateCancel,
    handleCloseModalSucces,
    handleCloseModalSuccesHome,
    handleCloseModalCancel,
    handleShowModalCancel,
    handleApplicationFormStep,
  };
};

export default useApplicationConfirmation;
