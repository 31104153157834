import React from 'react';

const iconInstagram = ({ color = '#FFFFFF', size = 1 }) => {
  return (
    <svg
      width={(size * 24).toString()}
      height={(size * 24).toString()}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9412 0H6.58824C4.84153 0.00201777 3.16694 0.710664 1.93183 1.97047C0.696729 3.23028 0.00197821 4.93836 0 6.72V17.28C0.00197821 19.0616 0.696729 20.7697 1.93183 22.0295C3.16694 23.2893 4.84153 23.998 6.58824 24H16.9412C18.6879 23.998 20.3625 23.2893 21.5976 22.0295C22.8327 20.7697 23.5274 19.0616 23.5294 17.28V6.72C23.5274 4.93836 22.8327 3.23028 21.5976 1.97047C20.3625 0.710664 18.6879 0.00201777 16.9412 0V0ZM11.7647 17.76C10.6478 17.76 9.55602 17.4222 8.62737 16.7893C7.69871 16.1563 6.97492 15.2568 6.5475 14.2043C6.12009 13.1518 6.00826 11.9936 6.22615 10.8763C6.44405 9.75895 6.98188 8.73262 7.77163 7.92706C8.56139 7.12151 9.5676 6.57293 10.663 6.35068C11.7584 6.12843 12.8939 6.24249 13.9257 6.67845C14.9576 7.11441 15.8396 7.85269 16.4601 8.79992C17.0806 9.74714 17.4118 10.8608 17.4118 12C17.4101 13.5271 16.8146 14.9912 15.7559 16.071C14.6972 17.1509 13.2619 17.7583 11.7647 17.76V17.76ZM17.8824 7.2C17.6031 7.2 17.3302 7.11555 17.098 6.95732C16.8659 6.79909 16.6849 6.57419 16.5781 6.31106C16.4712 6.04794 16.4432 5.7584 16.4977 5.47907C16.5522 5.19974 16.6866 4.94315 16.8841 4.74177C17.0815 4.54038 17.3331 4.40323 17.6069 4.34767C17.8808 4.29211 18.1646 4.32062 18.4226 4.42961C18.6806 4.5386 18.9011 4.72317 19.0562 4.95998C19.2113 5.19679 19.2941 5.47519 19.2941 5.76C19.2941 6.14191 19.1454 6.50818 18.8806 6.77823C18.6159 7.04829 18.2568 7.2 17.8824 7.2Z"
        fill={color}
      />
    </svg>
  );
};

export default iconInstagram;
