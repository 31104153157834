import useHelpers from '../../../helpers';
import useStrings from '../../../strings';
import useInitialStates from '../../initialStates';

const useApplicationFormReducers = () => {
  // Helpers
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  //strings
  const { useTypes } = useStrings();
  const { applicationFormTypes, userUserTypes } = useTypes();
  const {
    GET_APPLICATION_HELP_DATA,
    USER_APPLICATION_SAVED,
    SUBMIT_APPLICATION_FORM_FINAL,
    SUBMIT_APPLICATION_FORM_STEP_1,
    SUBMIT_APPLICATION_FORM_STEP_2,
    SET_CURRENT_STEP,
    ENABLE_SECOND_STEP,
    ENABLE_FINAL_STEP,
    CANCEL_APPLICATION,
    RESET_FIELD_APPLICATION_FORM,
    SET_APPLICATION_FORM,
    APPLICATION_READY,
    SET_APPLICATION_DATE,
    SET_DOCUMENTS_DRAFT,
    GET_APPLICATION_FILES,
    GET_APPLICATION_IMAGES,
  } = applicationFormTypes();
  const { LOG_OUT_USER } = userUserTypes();

  //initial states
  const { useApplicationFormInitialStates } = useInitialStates();
  const { applicationFormInitialState } = useApplicationFormInitialStates();

  //handlers
  const userHandler = {
    [SUBMIT_APPLICATION_FORM_STEP_1](state, action) {
      const { payload } = action;
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          ...payload,
        },
        applicationFormDraft: {
          ...state.applicationFormDraft,
          ...payload,
        },
      };
    },
    [SUBMIT_APPLICATION_FORM_STEP_2](state, action) {
      const { payload } = action;
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          ...payload,
        },
        applicationFormDraft: {
          ...state.applicationFormDraft,
          ...payload,
          photo1: payload.photograpsExternalInternalFeatures[0]
            ? payload.photograpsExternalInternalFeatures[0]
            : { name: '', data: '' },
          photo2: payload.photograpsExternalInternalFeatures[1]
            ? payload.photograpsExternalInternalFeatures[1]
            : { name: '', data: '' },
          photo3: payload.photograpsExternalInternalFeatures[2]
            ? payload.photograpsExternalInternalFeatures[2]
            : { name: '', data: '' },
          photo4: payload.photograpsExternalInternalFeatures[3]
            ? payload.photograpsExternalInternalFeatures[3]
            : { name: '', data: '' },
          photo5: payload.photograpsExternalInternalFeatures[4]
            ? payload.photograpsExternalInternalFeatures[4]
            : { name: '', data: '' },
        },
      };
    },
    [SUBMIT_APPLICATION_FORM_FINAL](state, action) {
      const { payload } = action;
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          ...payload,
        },
        applicationFormDraft: {
          ...state.applicationFormDraft,
          ...payload,
        },
      };
    },

    // help to control  the status of the application form and the wizard
    [SET_CURRENT_STEP](state, action) {
      const { payload } = action;
      return {
        ...state,
        currentStep: payload,
      };
    },

    [ENABLE_SECOND_STEP](state, action) {
      const { payload } = action;
      return {
        ...state,
        statusSecondStep: payload,
      };
    },
    [ENABLE_FINAL_STEP](state, action) {
      const { payload } = action;
      return {
        ...state,
        statusFinalStep: payload,
      };
    },
    [RESET_FIELD_APPLICATION_FORM](state, action) {
      const { payload } = action;
      state.applicationFormDraft[payload] = { name: '', data: '' };
      state.applicationForm[payload] = { name: '', data: '' };

      return {
        ...state,
      };
    },
    [GET_APPLICATION_HELP_DATA](state, action) {
      const { payload } = action;
      const {
        aditionsCompletedBy,
        phoneIndicators,
        licenceTypes,
        contactMethods,
        applicationTypes,
        landUseRightsDocumentType,
        typeOfRegistration,
        municipalities,
      } = payload;
      return {
        ...state,
        aditionsCompletedBy: aditionsCompletedBy,
        phoneIndicators: phoneIndicators,
        licenceTypes: licenceTypes,
        contactMethods: contactMethods,
        applicationTypes: applicationTypes,
        landUseRightsDocumentType: landUseRightsDocumentType,
        typeOfRegistration: typeOfRegistration,
        municipalities: municipalities,
      };
    },
    [USER_APPLICATION_SAVED](state) {
      return {
        ...state,
        applicationSaved: true,
      };
    },
    [SET_APPLICATION_FORM](state, action) {
      const { payload } = action;
      return {
        ...state,
        applicationForm: payload,
        applicationFormDraft: {
          ...state.applicationFormDraft,
          ...payload,
        },
      };
    },

    [GET_APPLICATION_FILES](state, action) {
      const { payload } = action;
      const { files } = payload;
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          ...files,
        },
        applicationFormDraft: {
          ...state.applicationFormDraft,
          ...files,
        },
      };
    },

    [GET_APPLICATION_IMAGES](state, action) {
      const { payload } = action;
      const { photograpsExternalInternalFeatures } = payload;
      return {
        ...state,
        applicationForm: {
          ...state.applicationForm,
          photograpsExternalInternalFeatures:
            photograpsExternalInternalFeatures,
          photo1: photograpsExternalInternalFeatures[0]
            ? photograpsExternalInternalFeatures[0]
            : { name: '', data: '' },
          photo2: photograpsExternalInternalFeatures[1]
            ? photograpsExternalInternalFeatures[1]
            : { name: '', data: '' },
          photo3: photograpsExternalInternalFeatures[2]
            ? photograpsExternalInternalFeatures[2]
            : { name: '', data: '' },
          photo4: photograpsExternalInternalFeatures[3]
            ? photograpsExternalInternalFeatures[3]
            : { name: '', data: '' },
          photo5: photograpsExternalInternalFeatures[4]
            ? photograpsExternalInternalFeatures[4]
            : { name: '', data: '' },
        },
        applicationFormDraft: {
          ...state.applicationFormDraft,
          photograpsExternalInternalFeatures:
            photograpsExternalInternalFeatures,
          photo1: photograpsExternalInternalFeatures[0]
            ? photograpsExternalInternalFeatures[0]
            : { name: '', data: '' },
          photo2: photograpsExternalInternalFeatures[1]
            ? photograpsExternalInternalFeatures[1]
            : { name: '', data: '' },
          photo3: photograpsExternalInternalFeatures[2]
            ? photograpsExternalInternalFeatures[2]
            : { name: '', data: '' },
          photo4: photograpsExternalInternalFeatures[3]
            ? photograpsExternalInternalFeatures[3]
            : { name: '', data: '' },
          photo5: photograpsExternalInternalFeatures[4]
            ? photograpsExternalInternalFeatures[4]
            : { name: '', data: '' },
        },
      };
    },
    [APPLICATION_READY](state) {
      return {
        ...state,
        applicationReady: true,
      };
    },
    [CANCEL_APPLICATION]() {
      return applicationFormInitialState;
    },
    [SET_APPLICATION_DATE](state, action) {
      const { payload } = action;
      return {
        ...state,
        date: payload,
      };
    },
    [SET_DOCUMENTS_DRAFT](state, action) {
      const { payload } = action;
      const { field, data } = payload;
      state.applicationFormDraft[field] = data;
      state.applicationForm[field] = data;
      return {
        ...state,
      };
    },
    [LOG_OUT_USER]() {
      return applicationFormInitialState;
    },
  };
  //reducers
  const applicationForm = createReducer(
    applicationFormInitialState,
    userHandler
  );

  return {
    applicationForm,
  };
};

export default useApplicationFormReducers;
