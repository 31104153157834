import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledContactInfo,
  StyledRow,
  StyledRowDetails,
} from './ContactInfo.styles';
import Typography from '../Typography';
import useAssets from '../../../assets';
import useControllers from '../../../controllers';

const ContactInfo = (props) => {
  const { className } = props;
  const { useIcons } = useAssets();
  const {
    iconMarker: IconMarker,
    iconTelephone: IconTelephone,
    iconAt: IconAt,
  } = useIcons();

  const { useComponentHooks } = useControllers();
  const { useContactInfo } = useComponentHooks();
  const { goToEmail, handleRedirectNewPage } = useContactInfo();
  return (
    <StyledContactInfo className={className}>
      <StyledRow>
        <IconMarker />
        <StyledRowDetails>
          <Typography
            className="StyledTypographyBook"
            color="white"
            variant="caption"
            sx={{
              fontSize: '12px',
              lineHeight: '17px',
            }}
          >
            Physical Address: 31 McDougal Street, Kimberly, 8301
          </Typography>
          <Typography
            className="StyledTypographyBook"
            color="white"
            variant="caption"
            sx={{
              fontSize: '12px',
              lineHeight: '17px',
            }}
          >
            Postal Address: Private Bag X 5027, Kimberly, 8300
          </Typography>
        </StyledRowDetails>
      </StyledRow>
      <StyledRow>
        <IconTelephone />
        <StyledRowDetails>
          <Typography
            className="StyledTypographyBook"
            color="white"
            variant="caption"
            sx={{
              fontSize: '12px',
              lineHeight: '17px',
            }}
          >
            Tel: 087 310 5318
          </Typography>
          <Typography
            className="StyledTypographyBook"
            color="white"
            variant="caption"
            sx={{
              fontSize: '12px',
              lineHeight: '17px',
            }}
          >
            Fax: 053 831 2646/2597
          </Typography>
        </StyledRowDetails>
      </StyledRow>
      {goToEmail && (
        <StyledRow>
          <IconAt />
          <Typography
            color="white"
            variant="caption"
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
              fontSize: '12px',
              lineHeight: '17px',
            }}
            onClick={() => handleRedirectNewPage(`mailto:${goToEmail}`)}
          >
            northerncape@liquorlicense.com
          </Typography>
        </StyledRow>
      )}
    </StyledContactInfo>
  );
};

ContactInfo.propTypes = {
  className: PropTypes.string,
};

ContactInfo.defaultProps = {
  className: '',
};

export default ContactInfo;
