import React from 'react';

import PropTypes from 'prop-types';

// Styles
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionDetails,
} from './Accordion.styles';
import useAssets from '../../../assets';
// import useControllers from '../../../controllers';
// import useComponents from '..';

const Accordion = (props) => {
  const { children, title, className, disabled, disabledIcon, colorArrow } =
    props;
  const { useIcons } = useAssets();
  const { iconArrowSelect: IconArrowSelect } = useIcons();
  // const { Typography } = useComponents();
  // const { useComponentHooks } = useControllers();
  // const { useInputFile } = useComponentHooks();
  // const { filesUploaded, removeFile, handleOnChange, imageToShow } =
  //   useInputFile();
  return (
    <StyledAccordion
      disableGutters
      className={className}
      disabled={disabled}
      sx={{
        boxShadow: 0,
        '&:before': {
          height: '0px',
        },
      }}
    >
      <StyledAccordionSummary
        expandIcon={
          !disabledIcon && (
            <IconArrowSelect color={colorArrow ? colorArrow : '#5E5C5C'} />
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          boxShadow: 0,
        }}
      >
        {title}
      </StyledAccordionSummary>
      {!disabled && (
        <StyledAccordionDetails sx={{ boxShadow: 0 }}>
          {children}
        </StyledAccordionDetails>
      )}
    </StyledAccordion>
  );
};

Accordion.propTypes = {
  title: PropTypes.any.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  colorArrow: PropTypes.string,
  disabled: PropTypes.bool,
  disabledIcon: PropTypes.bool,
};

Accordion.defaultProps = {
  title: '',
  colorArrow: '',
};

export default Accordion;
