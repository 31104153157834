import React, { Suspense } from 'react';
// import useControllers from '../../../controllers';
// import Button from '../Buttons';
import useComponents from '..';

import {
  StyledSideMenu,
  StyledSideMenuOptions,
  StyledSideMenuItem,
  StyledSideBottomOptions,
  StyledTypographyItem,
  StyledUserSideMenu,
  StyledTypographyUser,
  StyledIconUsers,
  StyledIconMyAplication,
  StyledIconMyProfile,
  StyledIconNotification,
  StyledIconLogs,
  StyledIconBlogs,
} from './SideMenuBackuser.styles';
import useControllers from '../../../controllers';

const SideMenuBackuser = () => {
  const { ActivityIndicator, TycInfo, Copyright } = useComponents();

  const { useComponentHooks } = useControllers();
  const { useSideMenuBackuser, useNotifications } = useComponentHooks();
  const {
    handleUsersSelected,
    handleApplicationsSelected,
    handleMyProfileSelected,
    handleComunicationHubSelected,
    handleLogsSelected,
    handleBlogsSelected,
    handleRedirectUsers,
    handleRedirectApplications,
    handleRedirectProfile,
    handleRedirectLogs,
    handleRedirectBlogs,
    profile,
  } = useSideMenuBackuser();
  const { openSideNotifications } = useNotifications();
  return (
    <Suspense fallback={<ActivityIndicator />}>
      <StyledSideMenu>
        <StyledSideMenuOptions>
          <StyledUserSideMenu>
            <StyledTypographyUser>
              {profile?.roleType?.name?.toUpperCase()}
            </StyledTypographyUser>
          </StyledUserSideMenu>
          {profile?.roleType?.roleType !== 'frontUser' &&
            profile?.roleType?.alias !== 'administrator' &&
            profile?.roleType?.alias !== 'boardMember' && (
              <StyledSideMenuItem
                onClick={handleRedirectUsers}
                isSelected={handleUsersSelected()}
              >
                <StyledIconUsers />
                <StyledTypographyItem>Users</StyledTypographyItem>
              </StyledSideMenuItem>
            )}
          <StyledSideMenuItem
            onClick={handleRedirectApplications}
            isSelected={handleApplicationsSelected()}
          >
            <StyledIconMyAplication />
            <StyledTypographyItem>Applications</StyledTypographyItem>
          </StyledSideMenuItem>
          <StyledSideMenuItem
            onClick={handleRedirectProfile}
            isSelected={handleMyProfileSelected()}
          >
            <StyledIconMyProfile />
            <StyledTypographyItem>My profile</StyledTypographyItem>
          </StyledSideMenuItem>
          <StyledSideMenuItem
            onClick={openSideNotifications}
            isSelected={handleComunicationHubSelected()}
          >
            <StyledIconNotification />
            <StyledTypographyItem>Notifications</StyledTypographyItem>
          </StyledSideMenuItem>
          {profile?.roleType?.roleType === 'administrator' ||
            (profile?.roleType?.roleType === 'ceo' && (
              <StyledSideMenuItem
                onClick={handleRedirectLogs}
                isSelected={handleLogsSelected()}
              >
                <StyledIconLogs />
                <StyledTypographyItem>Logs</StyledTypographyItem>
              </StyledSideMenuItem>
            ))}
          {(profile?.roleType?.id === 2 ||
            profile?.roleType?.id === 6 ||
            profile?.roleType?.id === 5) && (
            <StyledSideMenuItem
              onClick={handleRedirectBlogs}
              isSelected={handleBlogsSelected()}
            >
              <StyledIconBlogs />
              <StyledTypographyItem>Blogs</StyledTypographyItem>
            </StyledSideMenuItem>
          )}
        </StyledSideMenuOptions>
        <StyledSideBottomOptions>
          <TycInfo
            style={{
              margin: '0px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              rowGap: '24px',
            }}
            color={'#FFFFFF'}
          />
          <Copyright
            style={{ placeContent: 'start', height: '48px' }}
            color={'#FFFFFF'}
          />
        </StyledSideBottomOptions>
      </StyledSideMenu>
    </Suspense>
  );
};

export default SideMenuBackuser;
