import useStrings from '../../../strings';
import useServices from '../../services';

const useApplicationReviewActions = () => {
  // Strings
  const { useTypes } = useStrings();
  const { applicationReviewTypes } = useTypes();
  const {
    SEND_COMMENT,
    SET_STATUS_APPLICATION_REVIEW,
    SET_CURRENT_STEP_APPLICATION_REVIEW,
    APPROVE_APPLICATION,
    GET_SAPS_AND_MUNICIPALITY_REPORTS,
    SET_SAPS_AND_MUNICIPALITY_REPORTS_DRAFT,
    UPLOAD_REPORTS,
    SEND_INSPECTION_REPORT,
    RESET_APPLICATION_REVIEW,
    SUBMIT_FOR_VOTE,
    EDIT_INSPECTION_FORM,
    GRANT_APPLICATION,
    REJECT_APPLICATION,
  } = applicationReviewTypes();

  // Services
  const { useApplicationReviewService } = useServices();
  const {
    sendCommentService,
    approveApplicationService,
    getSapsAndMunicipalityReportsService,
    uploadApplicationReviewReportsService,
    sendInspectionReportService,
    submitForVoteService,
    grantApplicationService,
    rejectApplicationService,
  } = useApplicationReviewService();
  const actResetApplicationReview =
    (onSuccess, onError) => async (dispatch) => {
      try {
        dispatch({
          type: RESET_APPLICATION_REVIEW,
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };
  const actSendComment =
    ({ data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await sendCommentService(data);
        dispatch({
          type: SEND_COMMENT,
          payload: response.data.user,
        });
        onSuccess && onSuccess(response.data.user);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actApproveApplication =
    (data, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await approveApplicationService(data);
        dispatch({
          type: APPROVE_APPLICATION,
          payload: response.data,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actSetStatusApplicationReview =
    ({ steps }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        dispatch({
          type: SET_STATUS_APPLICATION_REVIEW,
          payload: steps,
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };

  const actSetCurrentStepApplicationReview =
    ({ step }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        dispatch({
          type: SET_CURRENT_STEP_APPLICATION_REVIEW,
          payload: step,
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };

  const actGetSapsAndMunicipalityReports =
    ({ applicationId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await getSapsAndMunicipalityReportsService(
          applicationId
        );
        dispatch({
          type: GET_SAPS_AND_MUNICIPALITY_REPORTS,
          payload: response.data,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actUploadApplicationReviewReports =
    (data, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await uploadApplicationReviewReportsService(data);
        dispatch({
          type: UPLOAD_REPORTS,
          payload: response.data,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actSetSapsAndMunicipalityReportsDraft =
    (data, onSuccess) => (dispatch) => {
      dispatch({
        type: SET_SAPS_AND_MUNICIPALITY_REPORTS_DRAFT,
        payload: data,
      });
      onSuccess && onSuccess();
    };

  const actSendInspectionReport =
    (data, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await sendInspectionReportService(data);
        dispatch({
          type: SEND_INSPECTION_REPORT,
          payload: response.data.user,
        });
        onSuccess && onSuccess(response.data.user);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actSubmitForVote = (data, onSuccess, onError) => async (dispatch) => {
    try {
      const response = await submitForVoteService(data);
      dispatch({
        type: SUBMIT_FOR_VOTE,
        payload: response.data,
      });
      onSuccess && onSuccess(response.data);
    } catch (e) {
      onError && onError(e);
    }
  };

  const actEditInspectionForm =
    (status, onSuccess, onError) => async (dispatch) => {
      try {
        dispatch({
          type: EDIT_INSPECTION_FORM,
          payload: status,
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };

  const actGrantApplication =
    (applicationId, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await grantApplicationService(applicationId);
        dispatch({
          type: GRANT_APPLICATION,
          payload: response.data,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };
  const actRejectApplication =
    ({ applicationId, data }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await rejectApplicationService(applicationId, data);
        dispatch({
          type: REJECT_APPLICATION,
          payload: response.data,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };
  return {
    actResetApplicationReview,
    actSendComment,
    actSetStatusApplicationReview,
    actSetCurrentStepApplicationReview,
    actApproveApplication,
    actGetSapsAndMunicipalityReports,
    actSetSapsAndMunicipalityReportsDraft,
    actUploadApplicationReviewReports,
    actSendInspectionReport,
    actSubmitForVote,
    actEditInspectionForm,
    actGrantApplication,
    actRejectApplication,
  };
};
export default useApplicationReviewActions;
