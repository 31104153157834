const applicationFormTypes = () => {
  const SUBMIT_APPLICATION_FORM_STEP_1 = 'SUBMIT_APPLICATION_FORM_STEP_1';
  const SUBMIT_APPLICATION_FORM_STEP_2 = 'SUBMIT_APPLICATION_FORM_STEP_2';
  const SUBMIT_APPLICATION_FORM_FINAL = 'SUBMIT_APPLICATION_FORM_FINAL';
  const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
  const GET_APPLICATION_HELP_DATA = 'GET_APPLICATION_HELP_DATA';
  const ENABLE_SECOND_STEP = 'ENABLE_SECOND_STEP';
  const ENABLE_FINAL_STEP = 'ENABLE_FINAL_STEP';
  const CANCEL_APPLICATION = 'CANCEL_APPLICATION';
  const USER_APPLICATION_SAVED = 'USER_APPLICATION_SAVED';
  const RESET_FIELD_APPLICATION_FORM = 'RESET_FIELD_APPLICATION_FORM';
  const SET_APPLICATION_FORM = 'SET_APPLICATION_FORM';
  const APPLICATION_READY = 'APPLICATION_READY';
  const SET_APPLICATION_DATE = 'SET_APPLICATION_DATE';
  const SET_DOCUMENTS_DRAFT = 'SET_DOCUMENTS_DRAFT';
  const UPLOAD_APPLICATION_FEE = 'UPLOAD_APPLICATION_FEE';
  const GET_LICENSE_FEE_PDF = 'GET_LICENSE_FEE_PDF';
  const APPROVE_APPLICATION_FEE = 'APPROVE_APPLICATION_FEE';
  const GET_APPLICATION_FILES = 'GET_APPLICATION_FILES';
  const GET_APPLICATION_IMAGES = 'GET_APPLICATION_IMAGES';
  return {
    GET_APPLICATION_IMAGES,
    GET_APPLICATION_HELP_DATA,
    USER_APPLICATION_SAVED,
    SUBMIT_APPLICATION_FORM_STEP_1,
    SUBMIT_APPLICATION_FORM_STEP_2,
    SUBMIT_APPLICATION_FORM_FINAL,
    SET_CURRENT_STEP,
    ENABLE_SECOND_STEP,
    ENABLE_FINAL_STEP,
    CANCEL_APPLICATION,
    RESET_FIELD_APPLICATION_FORM,
    SET_APPLICATION_FORM,
    APPLICATION_READY,
    SET_APPLICATION_DATE,
    SET_DOCUMENTS_DRAFT,
    UPLOAD_APPLICATION_FEE,
    GET_LICENSE_FEE_PDF,
    APPROVE_APPLICATION_FEE,
    GET_APPLICATION_FILES,
  };
};
export default applicationFormTypes;
