// Packages
import styled from 'styled-components';
import tw from 'twin.macro';

// Components
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
export const StyledAccordion = styled(Accordion).attrs({
  className: 'StyledAccordion',
})`
  && {
    ${tw` relative`}

    .MuiPaper-root-MuiAccordion-root .Mui-expanded {
      box-shadow: none;
    }
    .MuiPaper-root {
      box-shadow: none;
    }
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary).attrs({
  className: 'StyledAccordionSummary',
})`
  && {
    ${tw` relative`}
    min-height: 40px;
    box-shadow: none;
    width: 100%;
    padding: 0 16px;
    border: 0 !important;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails).attrs({
  className: 'StyledAccordionDetails',
})`
  && {
    ${tw` relative`}
    border: 0 !important;
    padding: 16px;
    padding-left: 32px;
  }
`;
