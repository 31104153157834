import useHelpers from '../../../helpers';
import useStrings from '../../../strings';
import useInitialStates from '../../initialStates';

const useRenewalsNoticesReducers = () => {
  // Helpers
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  //strings
  const { useTypes } = useStrings();
  const { applicationRenewalsTypes } = useTypes();
  const {
    GET_APPLICATIONS_RENEWALS_TABLE,
    GET_APPLICATION_RENEWAL,
    APPLICATION_RENEWAL_READY,
    DELETE_APPLICATION_RENEWAL,
    REMOVE_PROOF_OF_PAYMENT,
  } = applicationRenewalsTypes();

  //initial states
  const { useRenewalNoticesInitialStates } = useInitialStates();

  const { renewalNoticesTable, applicationsRenewalsInitialState } =
    useRenewalNoticesInitialStates();

  //handlers
  const renewalNoticesHandler = {
    [GET_APPLICATIONS_RENEWALS_TABLE](state, action) {
      const { payload } = action;

      const { current_page, data, last_page, per_page, total } = payload;

      return {
        ...state,
        data: data,
        currentPage: current_page,
        lastPage: last_page,
        pageSize: per_page,
        totalRows: total,
      };
    },
  };
  const renewalsHandler = {
    [GET_APPLICATION_RENEWAL](state, action) {
      const { payload } = action;
      const { renewal } = payload;
      return {
        ...state,
        applicationRenewal: renewal,
      };
    },
    [APPLICATION_RENEWAL_READY](state) {
      return {
        ...state,
        applicationReady: true,
      };
    },
    [REMOVE_PROOF_OF_PAYMENT](state) {
      return {
        ...state,
        applicationRenewal: {
          ...state.applicationRenewal,
          proofOfPayment: { name: '', data: '' },
        },
      };
    },

    [DELETE_APPLICATION_RENEWAL]() {
      return applicationsRenewalsInitialState;
    },
  };
  //reducers
  const renewalsNotices = createReducer(
    renewalNoticesTable,
    renewalNoticesHandler
  );
  const applicationsRenewals = createReducer(
    applicationsRenewalsInitialState,
    renewalsHandler
  );
  return {
    renewalsNotices,
    applicationsRenewals,
  };
};

export default useRenewalsNoticesReducers;
