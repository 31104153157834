import React from 'react';

const iconOpenedEye = ({ color = '#5E5C5C', size = 1 }) => {
  return (
    <svg
      width={(size * 16).toString()}
      height={(size * 16).toString()}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5184 7.7485C15.3821 7.53794 12.1421 2.59714 8.00031 2.59714C3.85855 2.59714 0.618553 7.53794 0.482233 7.7485L0.320312 7.9997L0.482233 8.2509C0.618553 8.4621 3.85855 13.4029 8.00031 13.4029C12.1421 13.4029 15.3821 8.46242 15.5184 8.2509L15.6803 7.9997L15.5184 7.7485ZM8.00031 12.4765C4.85727 12.4765 2.13343 8.97762 1.43391 7.99906C1.85023 7.41602 2.98591 5.94402 4.49951 4.84546C4.23711 5.20354 4.02815 5.6029 3.88447 6.03106C4.18271 5.79426 4.55711 5.6461 4.96831 5.6461C5.93567 5.6461 6.72159 6.4317 6.72159 7.3997C6.72159 8.36834 5.93599 9.15394 4.96831 9.15394C4.55775 9.15394 4.18463 9.00578 3.88639 8.76962C4.46015 10.4947 6.08383 11.7383 7.99999 11.7383C10.3962 11.7383 12.3389 9.79586 12.3389 7.39938C12.3389 6.44578 12.0275 5.56674 11.5062 4.85058C13.0157 5.94818 14.1501 7.4173 14.5664 8.00066C13.8685 8.97986 11.1498 12.4765 8.00031 12.4765Z"
        fill={color}
      />
    </svg>
  );
};

export default iconOpenedEye;
