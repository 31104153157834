const useApplicationReviewInitialStates = () => {
  const applicationReviewInitialState = {
    editInspectionForm: false,
    applicationReviewWizard: [
      {
        value: 1,
        label: 'APPLICATION APPROVAL',
      },
      {
        value: 2,
        label: 'REPORTS',
      },
      {
        value: 3,
        label: 'REPORTS APPROVAL',
      },
      {
        value: 4,
        label: 'VOTING',
      },
      {
        value: 5,
        label: 'PAYMENT REVIEW',
      },
      {
        value: 6,
        label: 'FINAL APPROVAL',
      },
    ],
    currentStep: 1,
    stepsEnabled: 1,
    sapsAndMunicipalityReports: {
      applicationId: '',
      sapsReport: { name: '', data: '' },
      municipalityReport: { name: '', data: '' },
    },
    sapsAndMunicipalityReportsDraft: {
      sapsReport: { name: '', data: '' },
      municipalityReport: { name: '', data: '' },
      applicationId: '',
    },
    inspectionForm: {
      patronsNotAllowedToDrink: '',
      offenceNotKeepLicenceIssued: '',
      conductingBusinessWithoutDividingWall: '',
      structuralAlterationsWithoutConsent: '',
      failingLiquorConsumeOffThePremise: '',
      hasLicenceAppointedManager: '',
      keepLiquorSaleWhenNoPermited: '',
      sellingLiquorToIntoxicatedPerson: '',
      sellingOrEmployingMinors: '',
      controllingLeasingLiquorLicence: '',
      premiseSuitableForTheLiquorLicense: '',
      allowingPersonsUnderAge: '',
      deliveringLiquorWithoutDeliveryNotice: '',
      deliveringLiquorNonReflectedAddress: '',
      deliveringLiquorBeyondHours: '',
      sellingAlcoholOtherThanTableWine: '',
      unreasonableRefuseToCooperate: '',
      latitude: '',
      longitude: '',
      applicationId: '',
    },
  };
  return {
    applicationReviewInitialState,
  };
};

export default useApplicationReviewInitialStates;
