import { useState, useEffect } from 'react';
import useActions from '../../../api/actions';
import useHelpers from '../../../helpers';
import useModels from '../../../models';

const useRenewalTable = () => {
  // Actions
  const { dispatch, useApplicationRenewalsActions } = useActions();
  const {
    actGetApplicationsRenewalsTable,
    actGetApplicationRenewal,
    actDeleteApplicationRenewal,
  } = useApplicationRenewalsActions();
  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation } = useQuickFunctions();
  const { navigateTo } = useNavigation();

  // Models
  const { useSelectors } = useModels();
  const { useSelector, useRenewalNoticesSelectors } = useSelectors();

  const { renewalsNoticesSelector, applicationReadySelector } =
    useRenewalNoticesSelectors();
  const { data, currentPage, lastPage, pageSize, totalRows } = useSelector(
    renewalsNoticesSelector
  );
  const { applicationReady } = useSelector(applicationReadySelector);
  // React
  const [rows, setRows] = useState([]);
  const [currentPageTable, setCurrentPageTable] = useState(1);
  const [searchFilter, setSearchFilter] = useState('');
  const [lastPageTable, setLastPageTable] = useState(1);
  const [customFilter, setCustomFilter] = useState({
    licenseType: '',
    status: '',
    start: '',
    end: '',
  });
  const [redirectApplicationRenewal, setRedirectApplicationRenewal] =
    useState(false);

  const handlePageChange = (e, newPage) => {
    setCurrentPageTable(newPage);
  };

  const onSearch = (data) => {
    setSearchFilter(data.searchField);
    dispatch(
      actGetApplicationsRenewalsTable({
        page: 1,
        search: data.searchField,
        ...customFilter,
      })
    );
    setCurrentPageTable(1);
  };

  const onFilter = (data) => {
    const finalFilter = {
      licenseType: data.applicationType !== 'none' ? data.applicationType : '',
      status: data.status !== 'none' ? data.status : '',
      start: data.from,
      end: data.to,
    };
    setCustomFilter(finalFilter);
    dispatch(
      actGetApplicationsRenewalsTable({
        page: 1,
        ...finalFilter,
        ...searchFilter,
      })
    );
    setCurrentPageTable(1);
  };

  const handleRedirectApplicationRenewal = () => {
    navigateTo(`/renewal-notice`);
  };
  const handleGetApplicationRenewalSuccess = () => {
    // dispatch(actSetApplicationForm({ application: data.data.application }));
    setRedirectApplicationRenewal(true);
  };
  const handleGetApplicationRenewal = (data) => {
    data.id &&
      dispatch(
        actGetApplicationRenewal(
          { applicationId: data.id },
          handleGetApplicationRenewalSuccess
        )
      );
  };
  const handleTableUpdate = () => {
    setRows(data);
    setLastPageTable(lastPage);
  };

  const onResetSearch = (data) => {
    setSearchFilter(data);
    dispatch(
      actGetApplicationsRenewalsTable({
        page: 1,
        search: data,
        ...customFilter,
      })
    );
    setCurrentPageTable(1);
  };

  const handleResetSearch = (value) => {
    !value && onResetSearch(value);
  };

  const columns = [
    {
      field: 'id',
      sortable: false,
      hide: true,
    },
    {
      field: 'licenseNumber',
      headerName: 'LICENSE NUMBER',
      width: 290,
      sortable: false,
      renderCell: (cellValues) => {
        return cellValues.row.licenseNumber
          ? cellValues.row.licenseNumber
          : cellValues.row.reference;
      },
    },
    {
      field: 'OutletName',
      headerName: 'OUTLET NAME',
      width: 320,
      sortable: false,
    },
    {
      field: 'payAmount',
      headerName: 'PAY AMOUNT',
      width: 220,
      sortable: false,
      renderCell: (cellValues) => {
        return `R ${cellValues.row.payAmount}`;
      },
    },
  ];

  useEffect(() => {
    dispatch(
      actGetApplicationsRenewalsTable({
        page: currentPageTable,
        search: searchFilter,
        ...customFilter,
      })
    );
    dispatch(actDeleteApplicationRenewal());
  }, []);

  useEffect(() => {
    handleTableUpdate();
  }, [currentPage, data, lastPage]);

  useEffect(() => {
    dispatch(
      actGetApplicationsRenewalsTable({
        page: currentPageTable,
        search: searchFilter,
        ...customFilter,
      })
    );
  }, [currentPageTable]);

  useEffect(() => {
    applicationReady &&
      redirectApplicationRenewal &&
      handleRedirectApplicationRenewal();
  }, [applicationReady, redirectApplicationRenewal]);
  return {
    handleTableUpdate,
    handlePageChange,
    onSearch,
    onFilter,
    handleResetSearch,
    currentPageTable,
    lastPageTable,
    rows,
    data,
    pageSize,
    totalRows,
    columns,
    handleGetApplicationRenewal,
  };
};

export default useRenewalTable;
