import { trackPromise } from 'react-promise-tracker';
import useProviders from '../../providers';

const useApplicationFormServices = () => {
  const { useApplicationFormProvider } = useProviders();
  const {
    getApplicationImages,
    applicationHelpData,
    saveUserApplication,
    saveFinalSubmitApplication,
    cancelApplication,
    updateApplication,
    updateFinalSubmitApplication,
    photosZip,
    uploadApplicationFeeProofOfPayment,
    approveApplicationFeeProofOfPayment,
    getLicenseFeePdf,
    getApplicationFiles,
  } = useApplicationFormProvider();

  const getApplicationImagesService = (applicationId) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(
            getApplicationImages(applicationId),
            'getApplicationImages'
          )
        );
      } catch (e) {
        reject(e);
      }
    });
  };
  const applicationHelpDataService = (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(applicationHelpData(data)));
      } catch (e) {
        reject(e);
      }
    });
  };

  const saveUserApplicationService = (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(saveUserApplication(data), 'saveUserApplication')
        );
      } catch (e) {
        reject(e);
      }
    });
  };

  const saveFinalSubmitApplicationService = (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(
            saveFinalSubmitApplication(data),
            'saveFinalSubmitApplication'
          )
        );
      } catch (e) {
        reject(e);
      }
    });
  };

  const cancelApplicationService = (id) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(cancelApplication(id), 'cancelApplication'));
      } catch (e) {
        reject(e);
      }
    });
  };
  const updateApplicationService = (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(updateApplication(data), 'updateUserApplication')
        );
      } catch (e) {
        reject(e);
      }
    });
  };

  const updateFinalSubmitApplicationService = (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(
            updateFinalSubmitApplication(data),
            'updateFinalSubmitApplication'
          )
        );
      } catch (e) {
        reject(e);
      }
    });
  };

  const photosZipService = (id) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(photosZip(id)));
      } catch (e) {
        reject(e);
      }
    });
  };
  const uploadApplicationFeeProofOfPaymentService = (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(uploadApplicationFeeProofOfPayment(data)));
      } catch (e) {
        reject(e);
      }
    });
  };
  const getLicenseFeePdfService = (applicationId) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getLicenseFeePdf(applicationId)));
      } catch (e) {
        reject(e);
      }
    });
  };
  const approveApplicationFeeProofOfPaymentService = (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(approveApplicationFeeProofOfPayment(data)));
      } catch (e) {
        reject(e);
      }
    });
  };
  const getApplicationFilesService = (applicationId) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(
            getApplicationFiles(applicationId),
            'getApplicationFiles'
          )
        );
      } catch (e) {
        reject(e);
      }
    });
  };
  return {
    getApplicationImagesService,
    applicationHelpDataService,
    saveUserApplicationService,
    saveFinalSubmitApplicationService,
    cancelApplicationService,
    updateApplicationService,
    updateFinalSubmitApplicationService,
    photosZipService,
    getLicenseFeePdf,
    uploadApplicationFeeProofOfPaymentService,
    getLicenseFeePdfService,
    approveApplicationFeeProofOfPaymentService,
    getApplicationFilesService,
  };
};

export default useApplicationFormServices;
