import useAlgorithms from './algorithms';
import useNavigation from './navigation';
import useRouterHelper from './routerHelper';
import usePromises from './promises';
import useValidators from './validators';
import useRefreshToken from './refreshToken';
import useFileManager from './fileManager';
const useQuickFunctions = () => {
  return {
    useAlgorithms,
    useFileManager,
    useNavigation,
    useRouterHelper,
    usePromises,
    useValidators,
    useRefreshToken,
  };
};

export default useQuickFunctions;
