import React from 'react';
import PropTypes from 'prop-types';
import { StyledTycInfo } from './TycInfo.styles';
import Typography from '../Typography';
import useControllers from '../../../controllers';

const TycInfo = (props) => {
  const { className, color, style } = props;
  const { useComponentHooks } = useControllers();
  const { useTycInfo } = useComponentHooks();
  const { handleRedirectToTC, handleRedirectToPP } = useTycInfo();

  return (
    <StyledTycInfo className={className} style={style}>
      <Typography
        color={color}
        variant="caption"
        sx={{
          fontSize: '12px',
          lineHeight: '17px',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => handleRedirectToPP()}
      >
        Privacy Policy
      </Typography>

      <Typography
        color={color}
        variant="caption"
        sx={{
          fontSize: '12px',
          lineHeight: '17px',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => handleRedirectToTC()}
      >
        Terms and conditions
      </Typography>
    </StyledTycInfo>
  );
};

TycInfo.propTypes = {
  className: PropTypes.string,
  style: PropTypes.any,
  color: PropTypes.string,
};

TycInfo.defaultProps = {
  className: '',
  color: 'secondary',
  sideMenu: false,
};

export default TycInfo;
