import React from 'react';

const iconEdit = ({ color = '#5E5C5C', size = 1 }) => {
  return (
    <svg
      width={(size * 18).toString()}
      height={(size * 16).toString()}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5289 0.492951L16.5072 1.47124C16.6687 1.63236 16.7969 1.82377 16.8843 2.03451C16.9717 2.24524 17.0168 2.47115 17.0168 2.6993C17.0168 2.92746 16.9717 3.15337 16.8843 3.3641C16.7969 3.57483 16.6687 3.76624 16.5072 3.92737L15.3069 5.12768L11.8724 1.69326L13.0728 0.492951C13.4033 0.176585 13.8432 0 14.3008 0C14.7584 0 15.1983 0.176585 15.5289 0.492951ZM2.07583 11.49L10.8943 2.67155L14.3218 6.11984L5.51025 14.9314C5.46611 14.9752 5.41099 15.0063 5.35067 15.0216L1.43058 15.9999H1.34732C1.29383 16.0014 1.2407 15.9906 1.19209 15.9682C1.14348 15.9458 1.1007 15.9125 1.06711 15.8709C1.03351 15.8292 1.01001 15.7803 0.99844 15.7281C0.986869 15.6758 0.987544 15.6216 1.00041 15.5697L1.98564 11.6496C2.00085 11.5893 2.032 11.5342 2.07583 11.49Z"
        fill={color}
      />
    </svg>
  );
};

export default iconEdit;
