import React, { useEffect } from 'react';
import axios from 'axios';
import useGeneralHooks from '../../controllers/generalHooks';
import useComponents from '../../views/components';
import useTypes from '../../strings/types';

const useInterceptor = (store) => {
  const { useToast } = useGeneralHooks();
  const { error } = useToast();
  const { Toast } = useComponents();
  const { useAuthTypes } = useTypes();
  const { LOG_OUT } = useAuthTypes();
  const handleRequestSuccess = (request) => {
    const { getState } = store;
    const { session } = getState();

    if (session.accessToken && session.tokenType) {
      request.headers.authorization = `${session.tokenType} ${session.accessToken}`;
    }
    request.headers['Content-Type'] = 'application/json';
    request.headers['accept'] = 'application/json';
    return request;
  };

  const handleRequestError = (error) => {
    console.error(`REQUEST ERROR! => ${error}`);
    return error;
  };

  const handleResponseSuccess = (response) => {
    return response;
  };

  const handleResponseError = (errorRequest) => {
    switch (errorRequest.response.status) {
      case 400:
        error(
          <Toast
            text={errorRequest?.response?.data?.message}
            listOfErrors={errorRequest?.response?.data?.errors}
            state={'error'}
          />,
          {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            toastId: Math.random(),
          }
        );
        break;
      case 401:
        store.dispatch({ type: LOG_OUT });
        break;
      case 403:
        error(
          <Toast
            text={errorRequest?.response?.data?.message}
            listOfErrors={errorRequest?.response?.data?.errors}
            state={'error'}
          />,
          {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            toastId: Math.random(),
          }
        );
        break;
      case 422:
        error(
          <Toast
            text={errorRequest?.response?.data?.message}
            listOfErrors={errorRequest?.response?.data?.errors}
            state={'error'}
          />,
          {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            toastId: Math.random(),
          }
        );
        break;
      case 500:
        error(
          <Toast
            text={errorRequest?.response?.data?.message}
            listOfErrors={[]}
            state={'error'}
          />,
          {
            position: 'top-right',
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            toastId: Math.random(),
          }
        );
        break;
      default:
        console.error(error);
    }
    throw error;
  };

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.params = {};
    axios.interceptors.request.use(handleRequestSuccess, handleRequestError);
    axios.interceptors.response.use(handleResponseSuccess, handleResponseError);
  }, []);
};

export default useInterceptor;
