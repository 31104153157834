import React, { Suspense } from 'react';
import useComponents from '../../components';
import useLayouts from '../../layouts';

const BackUser = () => {
  // Components
  const { ActivityIndicator } = useComponents();

  // Layouts
  const { PrivateContentLayout } = useLayouts();

  return (
    <PrivateContentLayout background="secondary">
      <Suspense fallback={<ActivityIndicator />}>
        <h4>Back User Home Page</h4>
      </Suspense>
    </PrivateContentLayout>
  );
};

export default BackUser;
