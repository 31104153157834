import useHome from './home';
import useLogin from './login';
import useSignUp from './signUp';
import useSplash from './useSplash';
import useRecoveryPassword from './useRecoveryPassword';
import useResetPassword from './useResetPassword';
import useEmailVerification from './useEmailVerification';
import useChangePassword from './useChangePassword';
import useProfile from './profile';
import useApplicationForm from './applicationForm';
import useApplicationsTable from './applicationsTable';
import useApplication from './application';
import useUsers from './users';
import useApplicationReview from './applicationReview';
import useLogs from './logs';
import useRouting from './useRouting';
import useRenewalNotice from './renewalNotice';
import useResendEmail from './useResendEmail';
import useRenewalTable from './renewalsTable';
import useBlogsEditor from './blogsEditor';
import useBlogsTable from './blogsTable';
import useBlogVisualization from './blogVisualization';
import useBlogsLastNews from './blogsLastNews';
const useScreenHooks = () => {
  return {
    useBlogsLastNews,
    useBlogsTable,
    useBlogsEditor,
    useBlogVisualization,
    useResendEmail,
    useRouting,
    useHome,
    useSignUp,
    useSplash,
    useLogin,
    useRecoveryPassword,
    useResetPassword,
    useEmailVerification,
    useProfile,
    useChangePassword,
    useApplicationForm,
    useApplicationsTable,
    useApplication,
    useUsers,
    useApplicationReview,
    useLogs,
    useRenewalNotice,
    useRenewalTable,
  };
};

export default useScreenHooks;
