import React, { Suspense } from 'react';
import useAssets from '../../../assets';
import useComponents from '../../components';
import useControllers from '../../../controllers';
import useLayouts from '../../layouts';
import { useForm } from 'react-hook-form';

import {
  StyledSectionRenewals,
  StyledTitleRenewals,
  StyledTitleRenewalsTypography,
  StyledTotalRenewals,
  StyledContainerSearch,
  StyledTextFieldControlledSearch,
  StyledButtonSearch,
  StyledButtonSearchDisable,
  StyledCotnainerTable,
} from './renewals.styles';
import useHelpers from '../../../helpers';
const Renewals = () => {
  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { usePromises } = useQuickFunctions();
  const { promiseInProgress } = usePromises();
  // Layout
  const { PrivateContentLayout } = useLayouts();

  // Components
  const { ActivityIndicator, Wrapper, Table } = useComponents();
  // Assests
  const { useIcons } = useAssets();
  const { iconSearch: IconSearch } = useIcons();

  // Hooks
  const { useScreenHooks } = useControllers();
  const { useRenewalTable } = useScreenHooks();
  const {
    rows,
    totalRows,
    columns,
    pageSize,
    lastPageTable,
    handlePageChange,
    currentPageTable,
    handleGetApplicationRenewal,
    onSearch,
    handleResetSearch,
  } = useRenewalTable();

  // Form
  const {
    control,
    handleSubmit,
    getValues: getValueSearch,
    formState: { isDirty },
  } = useForm({
    mode: 'onChange',
  });

  return (
    <PrivateContentLayout background="tertiary">
      {promiseInProgress && <ActivityIndicator />}
      <Suspense fallback={<ActivityIndicator />}>
        <StyledSectionRenewals>
          <Wrapper
            title={
              <StyledTitleRenewals>
                <StyledTitleRenewalsTypography variant="h2">
                  Renewals notices
                </StyledTitleRenewalsTypography>
                <StyledTotalRenewals>{totalRows}</StyledTotalRenewals>
              </StyledTitleRenewals>
            }
            maxWidth={'960px'}
          >
            <StyledContainerSearch>
              <StyledTextFieldControlledSearch
                id={'searchField'}
                name={'searchField'}
                placeholder={'Search'}
                onKeyUp={(eve) => {
                  eve.key === 'Enter' && onSearch(getValueSearch());
                }}
                fullWidth={true}
                control={control}
                onInputChange={handleResetSearch}
                sx={{ background: '#F3F5F7', paddingRight: '24px' }}
              />
              {isDirty ? (
                <StyledButtonSearch onClick={handleSubmit(onSearch)}>
                  <IconSearch color={'#D66127'} />
                </StyledButtonSearch>
              ) : (
                <StyledButtonSearchDisable onClick={handleSubmit(onSearch)}>
                  <IconSearch color={'#D66127'} />
                </StyledButtonSearchDisable>
              )}
            </StyledContainerSearch>
            <StyledCotnainerTable>
              {rows && (
                <Table
                  pagination={true}
                  columns={columns}
                  rows={rows}
                  pageSize={pageSize}
                  lastPage={lastPageTable}
                  rowsPerPageOptions={[pageSize]}
                  onPageChange={handlePageChange}
                  currentPage={currentPageTable}
                  onRowClick={handleGetApplicationRenewal}
                />
              )}
            </StyledCotnainerTable>
          </Wrapper>
        </StyledSectionRenewals>
      </Suspense>
    </PrivateContentLayout>
  );
};

export default Renewals;
