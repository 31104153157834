const useCommentsInitialStates = () => {
  const commentsInitialState = {
    listOfComments: [],
    frontComments: [],
  };
  return {
    commentsInitialState,
  };
};

export default useCommentsInitialStates;
