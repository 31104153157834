const useUserInitialStates = () => {
  const userInitialState = {
    profile: {
      name: '',
      surname: '',
      email: '',
      phone: '',
      registrationNumber: '',
      image: null,
      phoneIndicatorId: '',
      applicationsCount: 0,
      roleId: '',
      roleType: {
        alias: '',
        name: '',
        roleType: '',
      },
      lastLogout: '',
    },
    userRolesList: [
      {
        id: 1,
        name: 'Board Member',
        alias: 'boardMember',
        roleType: 'backuser',
      },
      {
        id: 2,
        name: 'Licensing Manager',
        alias: 'licensingManagerAdministrator',
        roleType: 'backuser',
      },
      {
        id: 3,
        name: 'Licensing Inspector & Compliance Manager',
        alias: 'licensingInspectorComplianceManager',
        roleType: 'backuser',
      },
      {
        id: 4,
        name: 'Front user',
        alias: 'frontUser',
        roleType: 'frontUser',
      },
      {
        id: 5,
        name: 'CEO',
        alias: 'ceo',
        roleType: 'ceo',
      },
      {
        id: 6,
        name: 'Licensing Administrator',
        alias: 'administrator',
        roleType: 'backuser',
      },
    ],
    userContactMethodsList: [
      {
        id: 1,
        name: 'SMS',
      },
      {
        id: 2,
        name: 'Email',
      },
      {
        id: 3,
        name: 'Both',
      },
    ],
  };
  return {
    userInitialState,
  };
};

export default useUserInitialStates;
