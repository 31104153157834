import styled from 'styled-components';
import tw from 'twin.macro';
import { Typography } from '@mui/material';
export const StyledTypography = styled(Typography).attrs({
  className: 'StyledTypography',
})`
  && {
    ${tw`relative`}

    ${(props) => {
      return `
          ${props.color === 'success' && `color: #D66127;`};
          ${props.color === 'error' && `color: #AE1313;`};
          ${props.color === 'primary' && `color: #5E5C5C;`};
          ${props.color === 'secondary' && `color: #D66127;`};
          ${props.color === 'neutral' && `color: #4C5232;`};
          ${props.color === 'neutral-80' && `color: #6D7074;`};
          ${props.color === 'neutral-90' && `color: #494B4D;`};
          ${props.color === 'white' && `color: #ffffff;`};
        `;
    }}
  }
`;
