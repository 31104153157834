import React from 'react';

const iconFacebook = ({ color = '#FFFFFF', size = 1 }) => {
  return (
    <svg
      width={(size * 24).toString()}
      height={(size * 24).toString()}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.782 0C8.73571 5.67276e-05 5.80785 1.20357 3.61276 3.35802C1.41768 5.51246 0.125988 8.45036 0.00873895 11.5553C-0.108511 14.6601 0.957791 17.6907 2.9839 20.011C5.01001 22.3313 7.83844 23.7609 10.8757 24V14.791H8.15676C7.9164 14.791 7.68587 14.6936 7.51591 14.5202C7.34594 14.3469 7.25046 14.1117 7.25046 13.8666C7.25046 13.6214 7.34594 13.3863 7.51591 13.2129C7.68587 13.0395 7.9164 12.9421 8.15676 12.9421H10.8757V10.1688C10.8768 9.1885 11.2591 8.24862 11.9387 7.5554C12.6183 6.86219 13.5398 6.47225 14.5009 6.47113H16.3135C16.5539 6.47113 16.7844 6.56852 16.9544 6.74189C17.1244 6.91525 17.2198 7.15038 17.2198 7.39556C17.2198 7.64073 17.1244 7.87586 16.9544 8.04923C16.7844 8.22259 16.5539 8.31999 16.3135 8.31999H14.5009C14.0204 8.32055 13.5596 8.51552 13.2198 8.86213C12.88 9.20873 12.6889 9.67867 12.6883 10.1688V12.9421H15.4072C15.6476 12.9421 15.8781 13.0395 16.0481 13.2129C16.218 13.3863 16.3135 13.6214 16.3135 13.8666C16.3135 14.1117 16.218 14.3469 16.0481 14.5202C15.8781 14.6936 15.6476 14.791 15.4072 14.791H12.6883V24C15.7255 23.7609 18.554 22.3312 20.5801 20.011C22.6062 17.6907 23.6725 14.6601 23.5552 11.5553C23.438 8.45037 22.1463 5.51247 19.9512 3.35803C17.7561 1.20359 14.8283 6.83388e-05 11.782 0Z"
        fill={color}
      />
    </svg>
  );
};

export default iconFacebook;
