import React, { Suspense } from 'react';
import useComponents from '../../components';
import useLayouts from '../../layouts';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import stamp from '../../../assets/images/stamp.png';

import {
  StyledSectionApplication,
  StyledFieldsContainer,
  StyledFieldsContainerLastApprove,
  StyledFieldsContainerFeeInnerContainer,
  StyledFieldsContainerMultiple,
  StyledButtonDownloadLicense,
  StyledButtonDownloadLicenseMobile,
  StyledContainerIconDownloadTop,
  StyledContainerDownload,
  StyledFieldRow,
  StyledFieldRowDateContainer,
  StyledFieldRowTitle,
  StyledFieldRowDate,
  StyledFieldRowReference,
  StyledFieldColumn,
  StyledFieldColumnProofOfPayment,
  StyledInputFieldProofOfPayment,
  StyledFieldColumnTitle,
  StyledFieldColumnValue,
  StyledConteinerCircularProgress,
  StyledCircularProgress,
  StyledButtonContinue,
  StyledDocumentsReportAccordion,
  StyledTitleDocumentReportContainerAccordion,
  StyledTitleReportAccordion,
  StyledContainerIconView,
  StyledContainerDownloadAccordion,
  StyledContainerStamp,
  StyledContainerStampMobile,
  StyledStamp,
  StyledTitleModal,
  StyledSubtitleModalCancel,
  StyledChildContainerModal,
  StyledButtonModal,
  StyledContainerAdviseIconModal,
  StyledTooltipField,
  StyledTypographyTooltip,
  StyledTypographyTooltipBold,
  StyledTooltipBorder,
} from './Application.styles';
import useControllers from '../../../controllers';
import useAssets from '../../../assets';
import useHelpers from '../../../helpers';
import { Box } from '@mui/system';

const Application = () => {
  const {
    ActivityIndicator,
    Wrapper,
    StatusVisualization,
    Modal,
    ContainerImageShape,
    ApplicationTrackingInformation,
  } = useComponents();
  const { useIcons } = useAssets();
  const { iconDownload: IconDownload, iconOpenedEye: IconOpenedEye } =
    useIcons();
  const { PrivateContentLayout } = useLayouts();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators } = useQuickFunctions();
  const { applicationFeeValidator } = useValidators();
  // Hooks
  const { useScreenHooks } = useControllers();
  const { useApplication } = useScreenHooks();

  // Yup validator application fee
  const {
    control,
    resetField,
    getValues,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(applicationFeeValidator),
  });

  const {
    promiseInProgress,
    promiseGetApplicationFiles,
    promiseGetApplicationImages,
    applicationForm,
    profile,
    licenceTypes,
    goBack,
    date,
    handleStatusApplicationPercent,
    handleFileNameExtension,
    onChangeFilePdf,
    fileTypesPdf,
    onSubmitProofOfPayment,
    // onSubmitSaveProofOfPayment,
    base64ApplicationFeetState,
    helperModalStateSuccess,
    helperModalSuccessState,
    handleCloseHelperModalSuccess,
    handleGetPrivateDocumentView,
    handleDate,
    modalAdvisePaymentState,
    handleCloseModalAdvise,
  } = useApplication();

  return (
    <PrivateContentLayout background="tertiary">
      {promiseInProgress &&
        !promiseGetApplicationFiles &&
        !promiseGetApplicationImages && <ActivityIndicator />}
      <Suspense fallback={<ActivityIndicator />}>
        <StyledSectionApplication>
          <Wrapper
            title={'Application'}
            maxWidth={'1266px'}
            withBackButton={true}
            onClickBackButton={goBack}
          >
            {applicationForm.status.id === 8 &&
              applicationForm.licenseDocument && (
                <StyledButtonDownloadLicense
                  fullWidth={false}
                  color="secondary"
                >
                  <StyledContainerIconDownloadTop>
                    <IconDownload color={'#FFFFFF'} />
                  </StyledContainerIconDownloadTop>
                  <StyledContainerDownload
                    download={'License Document'}
                    href={applicationForm.licenseDocument.data}
                    title="Download License"
                  >
                    Download PDF
                  </StyledContainerDownload>
                </StyledButtonDownloadLicense>
              )}
            {((applicationForm.status.id !== 7 &&
              applicationForm.status.id < 8) ||
              applicationForm.status.id === 9 ||
              applicationForm.status.id === 11 ||
              applicationForm.status.id === 12) && (
              <StyledConteinerCircularProgress>
                <StyledCircularProgress
                  // value={100}
                  value={handleStatusApplicationPercent()}
                />
              </StyledConteinerCircularProgress>
            )}

            {applicationForm.status.id === 10 && (
              <StyledButtonDownloadLicense fullWidth={false} color="secondary">
                <StyledContainerIconDownloadTop>
                  <IconDownload color={'#FFFFFF'} />
                </StyledContainerIconDownloadTop>
                <StyledContainerDownload
                  download={'License Document'}
                  href={base64ApplicationFeetState}
                  title="Download License"
                >
                  Download PDF
                </StyledContainerDownload>
              </StyledButtonDownloadLicense>
            )}

            <StyledFieldRow>
              <StyledFieldRowTitle>REF.</StyledFieldRowTitle>
              <StyledFieldRowReference variant="h3">
                {applicationForm.licenseNumber
                  ? applicationForm.licenseNumber
                  : applicationForm.reference}
              </StyledFieldRowReference>
              <StatusVisualization
                idStatus={
                  applicationForm.status.id && applicationForm.status.id
                }
                userId={profile.roleType.id && profile.roleType.id}
              />
            </StyledFieldRow>
            <StyledFieldRowDateContainer>
              <StyledFieldRowTitle>DATE:</StyledFieldRowTitle>
              <StyledFieldRowDate>{date}</StyledFieldRowDate>
            </StyledFieldRowDateContainer>

            {applicationForm.status.id === 8 && (
              <StyledContainerStampMobile>
                <StyledStamp src={stamp} />
              </StyledContainerStampMobile>
            )}
            {applicationForm.status.id === 8 &&
              applicationForm.licenseDocument && (
                <StyledButtonDownloadLicenseMobile
                  fullWidth={false}
                  color="secondary"
                >
                  <StyledContainerIconDownloadTop>
                    <IconDownload color={'#FFFFFF'} />
                  </StyledContainerIconDownloadTop>
                  <StyledContainerDownload
                    download={'License Document'}
                    href={applicationForm.licenseDocument.data}
                    title="Download License"
                  >
                    Download PDF
                  </StyledContainerDownload>
                </StyledButtonDownloadLicenseMobile>
              )}
            {/* Only before payment */}
            {applicationForm.status.id === 10 && (
              <StyledButtonDownloadLicenseMobile
                fullWidth={false}
                color="secondary"
              >
                <StyledContainerIconDownloadTop>
                  <IconDownload color={'#FFFFFF'} />
                </StyledContainerIconDownloadTop>
                <StyledContainerDownload
                  download={'License Document'}
                  href={base64ApplicationFeetState}
                  title="Download License"
                >
                  Download PDF
                </StyledContainerDownload>
              </StyledButtonDownloadLicenseMobile>
            )}

            {/* License payment */}
            {(applicationForm.status.id > 9 ||
              applicationForm.status.id === 8) &&
              profile.roleType.id === 4 && (
                <StyledFieldsContainerLastApprove>
                  <StyledFieldsContainerFeeInnerContainer>
                    <StyledFieldsContainerMultiple>
                      <StyledFieldColumn>
                        <StyledFieldColumnTitle>
                          Type of license
                        </StyledFieldColumnTitle>
                        <StyledFieldColumnValue>
                          {applicationForm.licenceType &&
                            licenceTypes[applicationForm.licenceType - 1].name}
                        </StyledFieldColumnValue>
                      </StyledFieldColumn>

                      <StyledFieldColumn>
                        <StyledFieldColumnTitle>
                          Licensed to sell
                        </StyledFieldColumnTitle>
                        <StyledFieldColumnValue>
                          {applicationForm.licenseToSell
                            ? applicationForm.licenseToSell
                            : 'None'}
                        </StyledFieldColumnValue>
                      </StyledFieldColumn>

                      <StyledFieldColumn>
                        <StyledFieldColumnTitle>
                          Date of issue
                        </StyledFieldColumnTitle>
                        <StyledFieldColumnValue>
                          {applicationForm.issueDate
                            ? handleDate(applicationForm.issueDate)
                            : 'None'}
                        </StyledFieldColumnValue>
                      </StyledFieldColumn>
                    </StyledFieldsContainerMultiple>

                    <StyledFieldsContainerMultiple>
                      <StyledFieldColumn>
                        <StyledFieldColumnTitle>
                          Prescribed fees
                        </StyledFieldColumnTitle>
                        <StyledFieldColumnValue>
                          {`R ${
                            applicationForm.prescribedFees ||
                            applicationForm.prescribedFees === 0
                              ? applicationForm.prescribedFees
                              : 'None'
                          }`}
                        </StyledFieldColumnValue>
                      </StyledFieldColumn>
                      <StyledFieldColumn>
                        <StyledFieldColumnTitle>
                          Payable on or before
                        </StyledFieldColumnTitle>
                        <StyledFieldColumnValue>
                          {applicationForm.payableBefore
                            ? handleDate(applicationForm.payableBefore)
                            : 'None'}
                        </StyledFieldColumnValue>
                      </StyledFieldColumn>
                      <StyledFieldColumn>
                        <StyledFieldColumnTitle>
                          Place of issue
                        </StyledFieldColumnTitle>
                        <StyledFieldColumnValue>
                          Kimberley
                        </StyledFieldColumnValue>
                      </StyledFieldColumn>
                    </StyledFieldsContainerMultiple>
                    {(applicationForm.status.id > 10 ||
                      applicationForm.status.id === 8) &&
                      applicationForm.applicationFeeProofOfPayment.data && (
                        <StyledDocumentsReportAccordion
                          disabled={true}
                          disabledIcon
                          title={
                            <StyledTitleDocumentReportContainerAccordion>
                              <StyledTitleReportAccordion variant="body1">
                                {applicationForm.status.id === 13 ||
                                applicationForm.status.id === 12
                                  ? 'Proof of Payment Submitted'
                                  : 'Proof of Payment'}
                              </StyledTitleReportAccordion>
                              <StyledContainerIconView
                                onClick={() =>
                                  handleGetPrivateDocumentView(
                                    applicationForm.applicationFeeProofOfPayment
                                      .name
                                  )
                                }
                              >
                                <IconOpenedEye size={1.5} />
                              </StyledContainerIconView>
                              <StyledContainerDownloadAccordion
                                download={'Proof of Payment'}
                                href={
                                  applicationForm.applicationFeeProofOfPayment
                                    .data
                                }
                                title="Download pdf document"
                              >
                                <IconDownload size={1} />
                              </StyledContainerDownloadAccordion>
                            </StyledTitleDocumentReportContainerAccordion>
                          }
                        />
                      )}

                    {applicationForm.status.id === 8 && (
                      <StyledFieldsContainerMultiple>
                        <StyledFieldColumn>
                          <StyledFieldColumnTitle>
                            Amount received
                          </StyledFieldColumnTitle>
                          <StyledFieldColumnValue>
                            {`R ${
                              applicationForm.amountPaid
                                ? applicationForm.amountPaid
                                : 'None'
                            }`}
                          </StyledFieldColumnValue>
                        </StyledFieldColumn>
                        <StyledFieldColumn>
                          <StyledFieldColumnTitle>
                            Receipt No.
                          </StyledFieldColumnTitle>
                          <StyledFieldColumnValue>
                            {applicationForm.receiptNumber
                              ? applicationForm.receiptNumber
                              : 'None'}
                          </StyledFieldColumnValue>
                        </StyledFieldColumn>
                        <StyledFieldColumn>
                          <StyledFieldColumnTitle>
                            Office Date Stamp
                          </StyledFieldColumnTitle>
                          <StyledFieldColumnValue>
                            {applicationForm.officeDateStamp
                              ? handleDate(applicationForm.officeDateStamp)
                              : 'None'}
                          </StyledFieldColumnValue>
                        </StyledFieldColumn>
                      </StyledFieldsContainerMultiple>
                    )}
                  </StyledFieldsContainerFeeInnerContainer>
                  {applicationForm.status.id === 8 && (
                    <StyledContainerStamp>
                      <StyledStamp src={stamp} />
                    </StyledContainerStamp>
                  )}
                </StyledFieldsContainerLastApprove>
              )}

            {/* Proof of Payment */}
            {(applicationForm.status.id === 10 ||
              applicationForm.status.id === 13) &&
              profile.roleType.id === 4 && (
                <StyledFieldsContainer>
                  <StyledFieldColumnProofOfPayment>
                    <StyledInputFieldProofOfPayment
                      id={'applicationFeeProofOfPayment'}
                      inputValue={handleFileNameExtension(
                        applicationForm.applicationFeeProofOfPayment
                      )}
                      label={
                        <>
                          Proof of Payment
                          <StyledTooltipField
                            title={
                              <>
                                <StyledTypographyTooltip>
                                  {' '}
                                  <StyledTypographyTooltipBold>
                                    Account name:
                                  </StyledTypographyTooltipBold>
                                  NCPG PMG. ECONOMIC DEVELOPMENT
                                </StyledTypographyTooltip>{' '}
                                <StyledTypographyTooltip>
                                  {' '}
                                  <StyledTypographyTooltipBold>
                                    Account no:
                                  </StyledTypographyTooltipBold>
                                  03052695
                                </StyledTypographyTooltip>
                                <StyledTypographyTooltip>
                                  {' '}
                                  <StyledTypographyTooltipBold>
                                    Branch name:
                                  </StyledTypographyTooltipBold>
                                  Kimberley
                                </StyledTypographyTooltip>
                                <StyledTypographyTooltip>
                                  {' '}
                                  <StyledTypographyTooltipBold>
                                    Branch code:
                                  </StyledTypographyTooltipBold>
                                  050002
                                </StyledTypographyTooltip>
                                <StyledTypographyTooltip>
                                  {' '}
                                  <StyledTypographyTooltipBold>
                                    Type of account:
                                  </StyledTypographyTooltipBold>
                                  BUSSINESS CURRENT Account
                                </StyledTypographyTooltip>
                                <StyledTypographyTooltip>
                                  <StyledTooltipBorder />
                                </StyledTypographyTooltip>
                                <StyledTypographyTooltip>
                                  {' '}
                                  <StyledTypographyTooltipBold>
                                    Upload receipt of payment made
                                  </StyledTypographyTooltipBold>
                                </StyledTypographyTooltip>
                              </>
                            }
                            size={1.3333}
                          />
                        </>
                      }
                      name={'applicationFeeProofOfPayment'}
                      variant={'outlined'}
                      fullWidth={true}
                      control={control}
                      onInputChange={onChangeFilePdf}
                      // onSaveDraftById={onSaveDraftDocumentById}
                      fileTypes={fileTypesPdf}
                      resetField={resetField}
                      error={!!errors.applicationFeeProofOfPayment}
                      helperText={errors?.applicationFeeProofOfPayment?.message}
                    />
                    <StyledButtonContinue
                      disabled={
                        !(applicationForm.status.id !== 7
                          ? isValid && isDirty
                          : isValid)
                      }
                      fullWidth={false}
                      color="primary"
                      variant="outlined"
                      onClick={() => onSubmitProofOfPayment(getValues())}
                    >
                      Submit{' '}
                    </StyledButtonContinue>
                  </StyledFieldColumnProofOfPayment>
                </StyledFieldsContainer>
              )}

            <ApplicationTrackingInformation />
          </Wrapper>
        </StyledSectionApplication>
        <Modal
          color={'#FF2D55'}
          type={'child'}
          showModal={modalAdvisePaymentState.show}
          onClose={handleCloseModalAdvise}
        >
          <StyledTitleModal variant={'h2'} color={'error'}>
            Have you made your payment?
          </StyledTitleModal>

          <StyledChildContainerModal>
            <StyledContainerAdviseIconModal>
              <ContainerImageShape scale={1.4} type={'error'} />
            </StyledContainerAdviseIconModal>
            <StyledSubtitleModalCancel variant={'body1'}>
              This license shall be of no force and effect unless the prescribed
              fees have been paid to the Department of Economic Development and
              Tourism. Payment of the prescribed fees is to be made within sixty
              days after the under-mentioned date of issue.
              <Box sx={{ marginTop: '24px', textAlign: 'center' }}>
                PLEASE USE YOUR APPLICATION REFERENCE NUMBER AS A REFERENCE WHEN
                MAKING A PAYMENT.
              </Box>
            </StyledSubtitleModalCancel>
            <StyledButtonModal
              onClick={handleCloseModalAdvise}
              fullWidth={false}
              color={'error'}
            >
              ACCEPT
            </StyledButtonModal>
          </StyledChildContainerModal>
        </Modal>
        <Modal
          type={helperModalSuccessState}
          showModal={helperModalStateSuccess.show}
          onClose={handleCloseHelperModalSuccess}
          title={helperModalStateSuccess.title}
          description={helperModalStateSuccess.description}
        />
      </Suspense>
    </PrivateContentLayout>
  );
};

export default Application;
