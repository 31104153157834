import React from 'react';
import PropTypes from 'prop-types';
// Styles
import {
  StyledWizard,
  StyledWizardPromiseInProgressContainer,
  StyledWizardPromiseInProgressInnerContainer,
  StyledCircularProgress,
  StyledCircularProgressLabel,
  StyledStatusBar,
  StyledStatusBarLeft,
  StyledStatusBarRight,
  StyledFormControl,
  StyledRadioGroup,
} from './Wizard.styles';
import {
  FormControlLabel,
  // IconButton,
  // InputAdornment,
  Radio,
} from '@mui/material';
// import { Error } from '@mui/icons-material';
import useAssets from '../../../assets';
import useControllers from '../../../controllers';

const Wizard = (props) => {
  const {
    handleSubmitStep1,
    // onSubmitStep1
    fetchingData,
    fetchingLabel,
    handleSubmitStep2,
    onSubmitStep2,
    fullWidth,
    error,
    sx,
    style,
    ...rest
  } = props;
  const { useIcons } = useAssets();
  const { iconWizardStep: IconWizardStep } = useIcons();

  const { useComponentHooks } = useControllers();
  const { useWizard } = useComponentHooks();
  const {
    currentStep,
    statusSecondStep,
    statusFinalStep,
    handleApplicationFormStep,
  } = useWizard();

  return (
    <StyledWizard style={style}>
      {fetchingData && (
        <StyledWizardPromiseInProgressContainer>
          <StyledWizardPromiseInProgressInnerContainer>
            <StyledCircularProgress />
            <StyledCircularProgressLabel>
              {fetchingLabel}
            </StyledCircularProgressLabel>
          </StyledWizardPromiseInProgressInnerContainer>
        </StyledWizardPromiseInProgressContainer>
      )}
      <StyledFormControl variant="outlined" fullWidth={fullWidth}>
        <StyledRadioGroup
          row
          defaultValue={currentStep}
          value={currentStep}
          onChange={handleApplicationFormStep}
          sx={sx}
          type={'text'}
          error={error}
          {...rest}
        >
          <StyledStatusBar>
            <StyledStatusBarLeft
              style={{
                borderColor: `${currentStep > 1 ? '#D66127' : '#DAE0E8'}`,
              }}
            />
            <StyledStatusBarRight
              style={{
                borderColor: `${currentStep > 2 ? '#D66127' : '#DAE0E8'}`,
              }}
            />
            <FormControlLabel
              value={1}
              control={
                <Radio
                  onClick={currentStep > 1 ? handleSubmitStep2 : () => {}}
                  icon={
                    <IconWizardStep
                      color={`${currentStep > 1 ? '#D66127' : '#DAE0E8'}`}
                    />
                  }
                  checkedIcon={<IconWizardStep color={'#5E5C5C'} />}
                />
              }
              labelPlacement={'bottom'}
              label={`${currentStep == 1 ? 'PERSONAL INFORMATION' : ''}`}
            />
            <FormControlLabel
              value={2}
              control={
                <Radio
                  disabled={!statusSecondStep}
                  onClick={handleSubmitStep1}
                  icon={
                    <IconWizardStep
                      color={`${currentStep > 1 ? '#D66127' : '#DAE0E8'}`}
                    />
                  }
                  checkedIcon={<IconWizardStep color={'#5E5C5C'} />}
                />
              }
              labelPlacement={'bottom'}
              label={`${currentStep == 2 ? 'UPLOAD DOCUMENTS' : ''}`}
            />
            <FormControlLabel
              value={3}
              onClick={
                currentStep > 1
                  ? () => {
                      handleSubmitStep2(onSubmitStep2);
                    }
                  : handleSubmitStep2
              }
              control={
                <Radio
                  disabled={!statusFinalStep}
                  icon={<IconWizardStep />}
                  checkedIcon={<IconWizardStep color={'#5E5C5C'} />}
                />
              }
              labelPlacement={'bottom'}
              label={`${currentStep == 3 ? 'CONFIRM APPLICATION' : ''}`}
            />
          </StyledStatusBar>
        </StyledRadioGroup>
      </StyledFormControl>
    </StyledWizard>
  );
};

Wizard.propTypes = {
  fullWidth: PropTypes.bool,
  fetchingData: PropTypes.bool,
  fetchingLabel: PropTypes.string,
  error: PropTypes.any,
  helperText: PropTypes.any,
  sx: PropTypes.any,
  style: PropTypes.any,
  /**
   * Array of objects {value,text}
   */
  options: PropTypes.array,
  handleSubmitStep1: PropTypes.func,
  handleSubmitStep2: PropTypes.func,
  // onSubmitStep1: PropTypes.func,
  onSubmitStep2: PropTypes.func,
};

Wizard.defaultProps = {
  fullWidth: true,
  fetchingData: false,
  handleSubmitStep1: () => {},
  handleSubmitStep2: () => {},
  onSubmitStep2: () => {},
};

export default Wizard;
