import { useEffect, useRef, useState } from 'react';
import useActions from '../../../api/actions';
import useHelpers from '../../../helpers';
import useModels from '../../../models';

const useNotifications = () => {
  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation, usePromises } = useQuickFunctions();
  const { navigateTo } = useNavigation();
  const { promiseInProgressArea: promiseGetLatestNotifications } = usePromises(
    'getLatestNotifications'
  );
  const { promiseInProgressArea: promiseGetAllNotifications } = usePromises(
    'getAllNotifications'
  );

  // Actions
  const {
    dispatch,
    useNotificationsActions,
    useApplicationFormActions,
    useApplicationReviewActions,
    useApplicationsTableActions,
    useApplicationRenewalsActions,
  } = useActions();
  const {
    actGetLatestNotifications,
    actGetAlltNotifications,
    actSetSideNotifications,
    actSetNewNotification,
  } = useNotificationsActions();
  const { actSetApplicationForm, actSetApplicationDate, actCancelApplication } =
    useApplicationFormActions();
  const {
    actGetSapsAndMunicipalityReports,
    actSetCurrentStepApplicationReview,
    actSetStatusApplicationReview,
  } = useApplicationReviewActions();
  const { actGetApplicationById } = useApplicationsTableActions();
  const { actGetApplicationRenewal } = useApplicationRenewalsActions();

  const { useSelectors } = useModels();
  const {
    useSelector,
    useUserSelectors,
    useNotificationsSelectors,
    useApplicationFormSelectors,
    useRenewalNoticesSelectors,
  } = useSelectors();
  const { userSelector } = useUserSelectors();
  const { applicationFormSelector, applicationReadySelector } =
    useApplicationFormSelectors();
  const { applicationReadySelector: applicationReadyRenewalSelector } =
    useRenewalNoticesSelectors();
  const { applicationForm } = useSelector(applicationFormSelector);
  const { applicationReady } = useSelector(applicationReadySelector);
  const { applicationReady: applicationReadyRenewal } = useSelector(
    applicationReadyRenewalSelector
  );
  const { notificationsSelector, newNotificationSelector } =
    useNotificationsSelectors();
  const { profile } = useSelector(userSelector);
  const {
    latestNotifications,
    allNotifications,
    currentPage,
    sideNotificationStatus,
  } = useSelector(notificationsSelector);
  const { newNotificationStatus } = useSelector(newNotificationSelector);
  // react
  const [redirectApplicationForm, setRedirectApplicationForm] = useState(false);
  const [redirectApplication, setRedirectApplication] = useState(false);
  const [redirectApplicationRenewal, setRedirectApplicationRenewal] =
    useState(false);

  const [popUpStatus, setPopUpStatus] = useState(false);
  // const [sideNotificationStatus, setSideNotification] = useState(false);
  const [sideNotificationFetchingStatus, setSideNotificationFetchingStatus] =
    useState(false);

  const innerNotificationRef = useRef();
  const popUpNotificationRef = useRef();

  useEffect(() => {
    popUpStatus && dispatch(actGetLatestNotifications());
    popUpStatus && dispatch(actSetNewNotification(false));
  }, [popUpStatus]);

  useEffect(() => {
    sideNotificationStatus &&
      window.innerHeight > 720 &&
      dispatch(actGetAlltNotifications(1));
  }, [sideNotificationStatus]);

  useEffect(() => {
    innerNotificationRef.current &&
      innerNotificationRef.current.addEventListener('scroll', (event) => {
        event.target.offsetHeight ===
          event.target.scrollHeight - event.target.scrollTop &&
          handleClickTest();
      });
  }, [sideNotificationStatus]);

  useEffect(() => {
    const handleclickOutsidePopup = (event) => {
      if (
        popUpNotificationRef.current &&
        !popUpNotificationRef.current.contains(event.target)
      ) {
        setPopUpStatus(false);
      }
    };

    // Add event listener 'mousedown' when the popup is open
    popUpStatus &&
      document.addEventListener('mousedown', handleclickOutsidePopup);

    // remove the event listener 'mousedown' when the popup is open
    !popUpStatus &&
      document.removeEventListener('mousedown', handleclickOutsidePopup);
  }, [popUpNotificationRef, popUpStatus]);

  // fetch data of all notifications
  useEffect(() => {
    sideNotificationFetchingStatus &&
      dispatch(
        actGetAlltNotifications(
          currentPage + 1,
          setSideNotificationFetchingStatus(false)
        )
      );
  }, [sideNotificationFetchingStatus]);

  const handleSetCurrentStepApplicationReview = () => {
    switch (applicationForm.status.id) {
      // Pending Appoval (Submitted)
      case 2:
        dispatch(actSetCurrentStepApplicationReview({ step: 1 }));
        break;

      // Pending Appoval
      case 3:
        dispatch(actSetCurrentStepApplicationReview({ step: 1 }));
        break;

      // Reports pending
      case 4:
        dispatch(actSetCurrentStepApplicationReview({ step: 2 }));
        dispatch(actSetStatusApplicationReview({ steps: 2 }));
        break;

      // Reports approval
      case 5:
        dispatch(actSetCurrentStepApplicationReview({ step: 3 }));
        dispatch(actSetStatusApplicationReview({ steps: 3 }));
        break;

      // Voting pending
      case 6:
        dispatch(
          actGetSapsAndMunicipalityReports({
            applicationId: applicationForm.id,
          })
        );
        dispatch(actSetCurrentStepApplicationReview({ step: 4 }));
        dispatch(
          actSetStatusApplicationReview({
            steps: profile.roleType.id !== 1 ? 4 : 0,
          })
        );
        break;

      //Approved
      case 8:
        dispatch(
          actGetSapsAndMunicipalityReports({
            applicationId: applicationForm.id,
          })
        );
        dispatch(actSetCurrentStepApplicationReview({ step: 5 }));
        dispatch(actSetStatusApplicationReview({ steps: 0 }));
        break;

      // Rejected
      case 9:
        dispatch(
          actGetSapsAndMunicipalityReports({
            applicationId: applicationForm.id,
          })
        );
        dispatch(
          actSetCurrentStepApplicationReview({
            step: profile.roleType.id !== 1 ? 5 : 4,
          })
        );
        dispatch(actSetStatusApplicationReview({ steps: 0 }));
        break;
      // Payment review
      case 10:
        dispatch(
          actGetSapsAndMunicipalityReports({
            applicationId: applicationForm.id,
          })
        );
        dispatch(actSetCurrentStepApplicationReview({ step: 5 }));
        dispatch(actSetStatusApplicationReview({ steps: 0 }));
        break;

      // Final approval
      case 11:
        dispatch(
          actGetSapsAndMunicipalityReports({
            applicationId: applicationForm.id,
          })
        );
        dispatch(actSetCurrentStepApplicationReview({ step: 6 }));
        dispatch(actSetStatusApplicationReview({ steps: 0 }));
        break;

      // Payment review
      case 12:
        dispatch(
          actGetSapsAndMunicipalityReports({
            applicationId: applicationForm.id,
          })
        );
        dispatch(actSetCurrentStepApplicationReview({ step: 5 }));
        dispatch(actSetStatusApplicationReview({ steps: 0 }));
        break;

      // Payment failed
      case 13:
        dispatch(
          actGetSapsAndMunicipalityReports({
            applicationId: applicationForm.id,
          })
        );
        dispatch(actSetCurrentStepApplicationReview({ step: 5 }));
        dispatch(actSetStatusApplicationReview({ steps: 0 }));
        break;
      default:
        dispatch(actSetCurrentStepApplicationReview({ step: 1 }));
        break;
    }
    handleRedirectApplication();
  };
  //Redirect at application selected
  useEffect(() => {
    if (applicationReady) {
      redirectApplicationForm && handleRedirectApplicationForm();
      redirectApplication && handleSetCurrentStepApplicationReview();
      dispatch(actSetSideNotifications(false));
      setPopUpStatus(false);
    }
    if (applicationReadyRenewal) {
      redirectApplicationRenewal && handleRedirectApplicationRenewal();
      dispatch(actSetSideNotifications(false));
      setPopUpStatus(false);
    }
  }, [
    applicationReady,
    applicationReadyRenewal,
    redirectApplication,
    redirectApplicationForm,
    redirectApplicationRenewal,
  ]);

  const handleClickTest = () => {
    setSideNotificationFetchingStatus(true);
  };

  // Handlres: application selected
  const handleDateFormat = (dateOld) => {
    const date = new Date(dateOld);
    return `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}/${
      date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }/${date.getFullYear()}`;
  };

  const handleRedirectApplication = () => {
    profile.roleType.id === 4 && navigateTo(`/application`);
    profile.roleType.id !== 4 && navigateTo(`/application-review`);
  };
  const handleRedirectApplicationRenewal = () => {
    navigateTo(`/renewal-notice`);
  };
  const handleRedirectApplicationForm = () => {
    navigateTo(`/application-form`);
  };

  const handleGetApplicationSuccess = (data) => {
    // dispatch(actSetApplicationForm({ application: data.data.application }));
    dispatch(actSetSideNotifications(false));
    setPopUpStatus(false);
    data.application.status.id === 1
      ? dispatch(
          actSetApplicationForm(
            { application: data.application },
            setRedirectApplicationForm(true)
          )
        )
      : dispatch(
          actSetApplicationForm(
            { application: data.application },
            setRedirectApplication(true)
          )
        );
  };
  const handleGetApplicationAfterDeleteOtherApplication = ({
    id,
    type,
    applicationDate,
  }) => {
    if (type === 1) {
      dispatch(
        actSetApplicationDate({
          date: handleDateFormat(applicationDate),
        })
      );
      dispatch(actGetSapsAndMunicipalityReports({ applicationId: id }));
      dispatch(actGetApplicationById({ id: id }, handleGetApplicationSuccess));
    } else {
      dispatch(
        actGetApplicationRenewal(
          { applicationId: id },
          handleGetApplicationRenewalSuccess
        )
      );
    }
  };
  const handleGetApplicationById = ({ id, type, applicationDate }) => {
    dispatch(
      actCancelApplication(
        { id: '' },
        handleGetApplicationAfterDeleteOtherApplication({
          id,
          type,
          applicationDate,
        })
      )
    );
  };

  const handleGetApplicationRenewalSuccess = () => {
    setRedirectApplicationRenewal(true);
  };

  const handleNotificationTime = (notificationDate) => {
    const currentNotificationDate = new Date(notificationDate);
    const currentTime = new Date();
    const notificationDateDifference = new Date(
      currentTime - currentNotificationDate
    );

    const notificationMinutes = Math.floor(
      notificationDateDifference.getTime() / 1000 / 60
    );
    const notificationHours = Math.floor(
      notificationDateDifference.getTime() / 1000 / 3600
    );
    const notificationDays = Math.floor(
      notificationDateDifference.getTime() / 1000 / 86400
    );
    if (notificationDate) {
      return `${
        notificationDays
          ? notificationDays + ' days'
          : notificationHours
          ? notificationHours + ' hours'
          : notificationMinutes + ' minutes'
      } `;
    }
  };

  const openPopUp = () => {
    setPopUpStatus(!popUpStatus);
  };

  const openSideNotifications = () => {
    popUpStatus && setPopUpStatus(!popUpStatus);

    !sideNotificationStatus && currentPage === 1
      ? dispatch(actGetAlltNotifications(currentPage + 1))
      : dispatch(actGetAlltNotifications(1));

    dispatch(actSetSideNotifications(!sideNotificationStatus));
  };

  return {
    promiseGetLatestNotifications,
    promiseGetAllNotifications,

    popUpStatus,
    openPopUp,
    sideNotificationStatus,
    openSideNotifications,
    profile,
    latestNotifications,
    allNotifications,
    handleNotificationTime,
    innerNotificationRef,
    popUpNotificationRef,
    handleClickTest,
    newNotificationStatus,
    handleGetApplicationById,
  };
};

export default useNotifications;
