// import { useEffect } from 'react';
// import useHelpers from '../../../helpers';
import useHelpers from '../../../helpers';
import useModels from '../../../models';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import useApi from '../../../api';
import useComponentHooks from '../../componentHooks';

const useApplication = () => {
  // Api
  const { useActions } = useApi();
  const { dispatch, useApplicationFormActions, usePrivateDocumentsActions } =
    useActions();
  const {
    actGetZipDownloadPhotos,
    actGetLicenseFeePdf,
    actUploadApplicationFeeProofOfPayment,
    actSetCurrentStep,
  } = useApplicationFormActions();
  const { actGetPrivateDocument } = usePrivateDocumentsActions();

  //Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation, usePromises } = useQuickFunctions();
  const { goBack, navigateTo } = useNavigation();
  const { promiseInProgressArea: promiseGetApplicationFiles } = usePromises(
    'getApplicationFiles'
  );
  const { promiseInProgressArea: promiseGetApplicationImages } = usePromises(
    'getApplicationImages'
  );
  const { promiseInProgress } = usePromises();
  //selectors
  const { useSelectors } = useModels();
  const {
    useSelector,
    useUserSelectors,
    useApplicationFormSelectors,
    useCommentsSelectors,
  } = useSelectors();
  const { userSelector } = useUserSelectors();
  const { profile, userRolesList } = useSelector(userSelector);
  const {
    applicationFormSelector,
    applicationFormHelpDataSelector,
    applicationDateSelector,
  } = useApplicationFormSelectors();
  const { commentsSelector } = useCommentsSelectors();
  const { applicationForm } = useSelector(applicationFormSelector);
  const {
    phoneIndicators,
    licenceTypes,
    contactMethods,
    typeOfRegistration,
    landUseRightsDocumentType,
    municipalities,
    aditionsCompletedBy,
  } = useSelector(applicationFormHelpDataSelector);
  const { date } = useSelector(applicationDateSelector);
  const { frontComments } = useSelector(commentsSelector);

  //hooks
  const { useModal } = useComponentHooks();
  const {
    modalState: helperModalStateSuccess,
    handleShowModal: handleHelperModalStateSuccess,
  } = useModal();
  const {
    modalState: modalAdvisePaymentState,
    handleShowModal: handleModalAdvisePaymentState,
  } = useModal();

  const [base64ApplicationFeetState, setBase64ApplicationFeeState] =
    useState('');
  const [helperModalSuccessState, setHelperModalSuccessState] =
    useState('error');

  useEffect(() => {
    applicationForm.status.id === 10 &&
      dispatch(actGetLicenseFeePdf(applicationForm.id, getPdfBase64));
    applicationForm.status.id === 7 && dispatch(actSetCurrentStep({ step: 1 }));
  }, []);

  useEffect(() => {
    !applicationForm.id && handleRedirectApplications();
  }, [applicationForm.id]);

  useEffect(() => {
    applicationForm.status.id > 9 &&
      applicationForm.status.id !== 11 &&
      !modalAdvisePaymentState.show &&
      handleModalAdvisePaymentState();
  }, [applicationForm.status.id]);

  const handleIndicative = (phoneIndicatorId) => {
    return _.find(
      phoneIndicators,
      (currentIndicative) => currentIndicative.id === phoneIndicatorId
    ).phoneIndicator;
  };

  const getPdfBase64 = (data) => {
    setBase64ApplicationFeeState(data);
  };

  const handleRedirectApplications = () => {
    navigateTo(`/applications`);
  };

  const handleRedirectApplicationForm = () => {
    navigateTo(`/application-form`);
  };

  const handleUserRoleType = (roleIdRow) => {
    return _.find(userRolesList, (userRole) => userRole?.id === roleIdRow)
      ?.name;
  };

  const onSuccessDownload = (response) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'file.zip'); //any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  const handleDownloadPhotos = (id) => {
    dispatch(actGetZipDownloadPhotos(id, onSuccessDownload));
  };

  // handle fields
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const handleDate = (currentDate) => {
    const currentFullDate = new Date(currentDate);
    const currentYear = currentFullDate.getFullYear();
    const currentMonth = monthNames[currentFullDate.getMonth()];
    const currentDay = currentFullDate.getDate();

    return ` ${currentDay} ${currentMonth} ${currentYear}`;
  };
  const handleStatusApplicationPercent = () => {
    /**
     * The application percent is given by the status id
     */
    switch (applicationForm.status.id) {
      case 3:
        // Submitted
        return 0;
      case 4:
        // Reports pending
        return 20;
      case 5:
        // Reports approval
        return 40;
      case 6:
        // Voting pending
        return 60;
      case 11:
        // Last Approve
        return 80;
      case 12:
        // Review Payment
        return 80;
      case 8:
        // Approved
        return 100;
      case 9:
        // Rejected
        return 100;
      default:
        return 0;
    }
  };

  const handleStatusApplicationCopysMobile = () => {
    /**
     * The application percent is given by the status id
     */
    switch (applicationForm.status.id) {
      case 2:
        // Submitted
        return 'Your application has been submitted and is awaiting review.';
      case 3:
        // Submitted
        return 'Your application has been submitted and is awaiting review.';
      case 4:
        // Reports pending
        return 'Your application is being reviewed, the progress bar will show you how advanced it is.';
      case 5:
        // Reports approval
        return 'Your application is being reviewed, the progress bar will show you how advanced it is.';
      case 6:
        // Voting pending
        return 'Your application is being reviewed, the progress bar will show you how advanced it is.';
      case 7:
        // Request for changes
        return 'A supervisor has found a problem with your application, to correct the problem visit this application on a desktop machine and edit the application.';
      case 11:
        // Review Payment
        return 'Your application is being reviewed, the progress bar will show you how advanced it is.';
      case 8:
        // Approved
        return 'Your Application was approved, click the download button to get your license.';
      case 9:
        // Rejected
        return 'Your Application was rejected, to know more visit this application on a desktop machine.';
      case 10:
        // Payment pending
        return 'You can download the pending fee with the download button, to upload your proof of payment and continue the process visit this application on a desktop machine.';
      case 13:
        // Payment failed
        return 'The last supervisor has found a problem with your application, to correct the problem visit this application on a desktop machine.';
      default:
        return 'Your application is being reviewed, the progress bar will show you how advanced it is.';
    }
  };
  //Files handler
  const fileTypesPdf = ['application/pdf'];

  const handleFileNameExtension = (fileObject) => {
    const fileObjectWithoutExtension = {
      name: fileObject?.name ? fileObject.name?.replace(/\.[^/.]+$/, '') : '',
      data: fileObject?.data ? fileObject.data : '',
    };
    return fileObjectWithoutExtension;
  };
  const onChangeFilePdf = ({ event, remove, reset, id }) => {
    event.preventDefault();
    if (event.target.files.length > 0) {
      const { type } = event.target.files[0];
      if (!fileTypesPdf.some((s) => type.includes(s))) {
        alert('invalid document type');
        remove(reset, id);
      } else {
        if (event.target.files[0].size > 2000000) {
          alert('Document too large, please try again with 2MB or less');
          remove(reset, id);
        }
      }
    }
  };
  const handleGetPrivateLink = (url) => {
    window.open(url);
  };
  const handleGetPrivateDocumentView = (documentName) => {
    dispatch(
      actGetPrivateDocument(
        {
          documentName: documentName,
          applicationId: applicationForm.id,
        },
        handleGetPrivateLink
      )
    );
  };
  //Modal Advise payment
  const handleCloseModalAdvise = () => {
    handleModalAdvisePaymentState();
  };

  //Modal success handlers
  const handleCloseHelperModalSuccess = () => {
    handleHelperModalStateSuccess();
    goBack();
  };

  const handleSubmitRenewalSuccess = () => {
    setHelperModalSuccessState('success');
    handleHelperModalStateSuccess(
      'Success!',
      'Application Fee Proof of Payment was submitted  successfully!'
    );
  };
  const onSubmitProofOfPayment = (data) => {
    const dataToSubmit = {
      ...data,
      applicationId: applicationForm.id,
    };
    dispatch(
      actUploadApplicationFeeProofOfPayment(
        dataToSubmit,
        handleSubmitRenewalSuccess
      )
    );
  };
  return {
    promiseInProgress,
    promiseGetApplicationFiles,
    promiseGetApplicationImages,
    applicationForm,
    profile,
    licenceTypes,
    contactMethods,
    goBack,
    date,
    handleUserRoleType,
    handleRedirectApplicationForm,
    typeOfRegistration,
    landUseRightsDocumentType,
    handleStatusApplicationPercent,
    handleDownloadPhotos,
    handleIndicative,
    frontComments,
    municipalities,
    handleFileNameExtension,
    onChangeFilePdf,
    fileTypesPdf,
    onSubmitProofOfPayment,
    base64ApplicationFeetState,
    helperModalStateSuccess,
    helperModalSuccessState,
    handleCloseHelperModalSuccess,
    handleGetPrivateDocumentView,
    handleDate,
    modalAdvisePaymentState,
    handleCloseModalAdvise,
    handleStatusApplicationCopysMobile,
    aditionsCompletedBy,
  };
};

export default useApplication;
