import React from 'react';

const iconWizardStep = ({ color = '#ECEFF3', size = 1 }) => {
  return (
    <svg
      width={(size * 25).toString()}
      height={(size * 24).toString()}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.97522 0C9.39157 0.116764 11.3137 0.467052 13.1992 1.12871C16.1098 2.16012 19.0387 3.19154 21.9493 4.22295C22.8097 4.51486 23.6517 4.82623 24.5121 5.11814C25.0064 5.29328 25.043 5.35167 24.805 5.85764C24.0362 7.58964 23.2856 9.32163 22.5168 11.0536C21.5466 13.2721 20.613 15.5101 19.5513 17.6702C18.5627 19.6941 17.263 21.504 15.4325 22.7689C13.1992 24.3258 10.8926 24.3647 8.53121 23.1192C6.29791 21.9516 4.65039 20.0639 3.27746 17.9038C1.95945 15.8409 0.989249 13.5835 0.440077 11.1509C-0.109094 8.69889 -0.273846 6.24685 0.714663 3.8532C1.4652 2.06282 2.7649 0.914651 4.52225 0.428136C5.47415 0.194608 6.44435 0.097303 6.97522 0Z"
        fill={color}
      />
    </svg>
  );
};

export default iconWizardStep;
