import axios from 'axios';
const useApplicationReviewProviders = () => {
  const sendComment = (data) => {
    return axios({
      method: 'post',
      url: `api/store-coment`,
      data,
    });
  };

  const approveApplication = (data) => {
    return axios({
      method: 'patch',
      url: `api/approve-application`,
      data,
    });
  };
  const getSapsAndMunicipalityReports = (applicationId) => {
    return axios({
      method: 'get',
      url: `api/get-reports?applicationId=${applicationId}`,
    });
  };

  const uploadApplicationReviewReports = (data) => {
    return axios({
      method: 'post',
      url: `api/upload-reports`,
      data,
    });
  };

  const sendInspectionReport = (data) => {
    return axios({
      method: 'post',
      url: `api/store-inspection`,
      data,
    });
  };

  const submitForVote = (data) => {
    return axios({
      method: 'patch',
      url: `api/approve-application`,
      data,
    });
  };

  const grantApplication = (applicationId) => {
    return axios({
      method: 'post',
      url: `api/grant-application/${applicationId}`,
    });
  };

  const rejectApplication = (applicationId, data) => {
    return axios({
      method: 'post',
      url: `api/reject-application/${applicationId}`,
      data,
    });
  };
  return {
    sendComment,
    approveApplication,
    getSapsAndMunicipalityReports,
    uploadApplicationReviewReports,
    sendInspectionReport,
    submitForVote,
    grantApplication,
    rejectApplication,
  };
};
export default useApplicationReviewProviders;
