import React, { Suspense } from 'react';
import useComponents from '../../components';
import useLayouts from '../../layouts';
import {
  StyledSectionLastNews,
  StyledTitleSection,
  StyledFieldsLastNewsContainer,
} from './BlogsLastNews.styles';
import useControllers from '../../../controllers';
import _ from 'lodash';
import temporalImage from '../../../assets/images/temporalImage.jpeg';
import { Box } from '@mui/system';
import useHelpers from '../../../helpers';

const BlogsLastNews = () => {
  const { ActivityIndicator, CardBlogPreview, Pagination } = useComponents();

  const { PrivateContentLayout, PublicContentLayout } = useLayouts();
  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { usePromises } = useQuickFunctions();
  const { promiseInProgress } = usePromises();

  // Hooks
  const { useScreenHooks } = useControllers();
  const { useBlogsLastNews } = useScreenHooks();

  const {
    accessToken,
    rows,
    // pageSize,
    // totalRows,

    currentPageTable,
    lastPageTable,
    handlePageChange,
    // onSearch,
    // onFilter,
    // handleResetSearch,

    handleViewBlog,
  } = useBlogsLastNews();
  return (
    <Suspense fallback={<ActivityIndicator />}>
      {promiseInProgress && <ActivityIndicator />}
      {accessToken ? (
        <PrivateContentLayout background="primary">
          <StyledSectionLastNews>
            <StyledTitleSection>
              OUR
              <Box component="b" sx={{ padding: '0 16px', color: '#5E5C5C' }}>
                LATEST
              </Box>{' '}
              NEWS
            </StyledTitleSection>
            <StyledFieldsLastNewsContainer>
              {rows?.length !== 0 &&
                _.map(rows, (currentBlog, key) => {
                  return (
                    <CardBlogPreview
                      image={
                        currentBlog.image ? currentBlog.image : temporalImage
                      }
                      key={key}
                      title={currentBlog.title}
                      description={currentBlog.body}
                      onClick={() => handleViewBlog(currentBlog.id)}
                    />
                  );
                })}
            </StyledFieldsLastNewsContainer>

            <Pagination
              currentPage={currentPageTable}
              lastPage={lastPageTable}
              onPageChange={handlePageChange}
              // onRowClick={handleGetApplicationById}
            />
          </StyledSectionLastNews>
        </PrivateContentLayout>
      ) : (
        <PublicContentLayout background="primary">
          <StyledSectionLastNews>
            <StyledTitleSection>
              OUR
              <Box component="b" sx={{ padding: '0 16px', color: '#5E5C5C' }}>
                LATEST
              </Box>{' '}
              NEWS
            </StyledTitleSection>
            <StyledFieldsLastNewsContainer>
              {rows?.length !== 0 &&
                _.map(rows, (currentBlog, key) => {
                  return (
                    <CardBlogPreview
                      image={
                        currentBlog.image ? currentBlog.image : temporalImage
                      }
                      key={key}
                      title={currentBlog.title}
                      description={currentBlog.body}
                      onClick={() => handleViewBlog(currentBlog.id)}
                    />
                  );
                })}
            </StyledFieldsLastNewsContainer>

            {/* <Table
            pagination={true}
            columns={columns}
            rows={rows}
            pageSize={pageSize}
            lastPage={lastPageTable}
            rowsPerPageOptions={[pageSize]}
            onPageChange={handlePageChange}
            currentPage={currentPageTable}
            // onRowClick={handleGetApplicationById}
          /> */}
            <Pagination
              currentPage={currentPageTable}
              lastPage={lastPageTable}
              onPageChange={handlePageChange}
              // onRowClick={handleGetApplicationById}
            />
          </StyledSectionLastNews>
        </PublicContentLayout>
      )}
    </Suspense>
  );
};

export default BlogsLastNews;
