import useStrings from '../../../strings';
import useServices from '../../services';

const useApplicationsTableActions = () => {
  // Strings
  const { useTypes } = useStrings();
  const { applicationsTableTypes } = useTypes();
  const { GET_APPLICATIONS, GET_APPLICATION_BY_ID, GET_STATUS_HELP_LIST } =
    applicationsTableTypes();

  // Services
  const { useApplicationsTableServices } = useServices();
  const {
    getApplicationsTablePageService,
    getApplicationByIdService,
    getApplicationsStatusHelpListService,
  } = useApplicationsTableServices();

  const actGetApplicationsTable =
    (
      {
        page,
        search = '',
        licenseType = '',
        status = '',
        start = '',
        end = '',
      },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await getApplicationsTablePageService(
          page,
          search,
          licenseType,
          status,
          start,
          end
        );
        dispatch({
          type: GET_APPLICATIONS,
          payload: response.data.applications,
        });
        onSuccess && onSuccess(response.data.applications);
      } catch (e) {
        onError && onError(e);
      }
    };
  const actGetApplicationsStatusHelpList =
    (onSuccess, onError) => async (dispatch) => {
      try {
        const response = await getApplicationsStatusHelpListService();
        dispatch({
          type: GET_STATUS_HELP_LIST,
          payload: response.data.list,
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        onError && onError(e);
      }
    };
  const actGetApplicationById =
    ({ id }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await getApplicationByIdService(id);
        dispatch({
          type: GET_APPLICATION_BY_ID,
          payload: response.data,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };
  return {
    actGetApplicationsTable,
    actGetApplicationById,
    actGetApplicationsStatusHelpList,
  };
};
export default useApplicationsTableActions;
