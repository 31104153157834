import { Route, Switch } from 'react-router';
import useViews from '../../views';
import React from 'react';
import useHelpers from '../../helpers';

const PrivateRouting = () => {
  const { useScreens } = useViews();
  const {
    Profile,
    BackUser,
    ChangePassword,
    ApplicationForm,
    ApplicationsTable,
    Application,
    UsersTable,
    ApplicationReview,
    Logs,
    Renewals,
    RenewalNotice,
    BlogsTable,
    BlogsEditor,
  } = useScreens();
  const { useQuickFunctions } = useHelpers();
  const { useRouterHelper } = useQuickFunctions();
  const { requireAuth } = useRouterHelper();

  return (
    <Switch>
      <Route
        exact
        path="/profile"
        component={requireAuth({ Component: Profile })}
      />
      <Route
        exact
        path="/blogs-table"
        component={requireAuth({ Component: BlogsTable })}
      />
      <Route
        exact
        path="/new-content"
        component={requireAuth({ Component: BlogsEditor })}
      />
      <Route
        exact
        path="/backuser"
        component={requireAuth({ Component: BackUser })}
      />

      <Route
        exact
        path="/change-password"
        component={requireAuth({ Component: ChangePassword })}
      />
      <Route
        exact
        path="/application-form"
        component={requireAuth({ Component: ApplicationForm })}
      />
      <Route
        exact
        path="/applications"
        component={requireAuth({ Component: ApplicationsTable })}
      />
      <Route
        exact
        path="/application"
        component={requireAuth({ Component: Application })}
      />
      <Route
        exact
        path="/users"
        component={requireAuth({ Component: UsersTable })}
      />
      <Route
        exact
        path="/application-review"
        component={requireAuth({ Component: ApplicationReview })}
      />
      <Route exact path="/logs" component={requireAuth({ Component: Logs })} />
      <Route
        exact
        path="/renewals"
        component={requireAuth({ Component: Renewals })}
      />
      <Route
        exact
        path="/renewal-notice"
        component={requireAuth({ Component: RenewalNotice })}
      />
    </Switch>
  );
};

export default PrivateRouting;
