import React, { Suspense } from 'react';
import useComponents from '../../components';
import useLayouts from '../../layouts';
// import useHelpers from '../../../helpers';
// import { yupResolver } from '@hookform/resolvers/yup';
// import { useForm } from 'react-hook-form';
import { StyledSectionNewApplication } from './ApplicationForm.styles';
import useControllers from '../../../controllers';

const ApplicationForm = () => {
  const {
    ActivityIndicator,
    ApplicationInformation,
    ApplicationDocuments,
    ApplicationConfirmation,
  } = useComponents();

  const { PrivateContentLayout } = useLayouts();

  // Hooks
  const { useScreenHooks } = useControllers();
  const { useApplicationForm } = useScreenHooks();
  const { currentStep } = useApplicationForm();

  return (
    <PrivateContentLayout background="tertiary">
      <Suspense fallback={<ActivityIndicator />}>
        <StyledSectionNewApplication>
          {currentStep === 1 && <ApplicationInformation />}
          {currentStep === 2 && <ApplicationDocuments />}
          {currentStep === 3 && <ApplicationConfirmation />}
        </StyledSectionNewApplication>
      </Suspense>
    </PrivateContentLayout>
  );
};

export default ApplicationForm;
