const useRenewalsNoticesInitialStates = () => {
  const renewalNoticesTable = {
    data: [],
    currentPage: 0,
    lastPage: 0,
    pageSize: 0,
    totalRows: 0,
  };
  const applicationsRenewalsInitialState = {
    applicationRenewal: {},
    applicationReady: false,
  };
  return {
    renewalNoticesTable,
    applicationsRenewalsInitialState,
  };
};

export default useRenewalsNoticesInitialStates;
