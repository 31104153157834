const useApplicationsRenewalsInitialStates = () => {
  const applicationsRenewalsInitialState = {
    data: [],
    currentPage: 0,
    lastPage: 0,
    pageSize: 0,
    totalRows: 0,
    applicationRenewal: {},
    applicationReady: false,
  };
  return {
    applicationsRenewalsInitialState,
  };
};

export default useApplicationsRenewalsInitialStates;
