import React from 'react';

const iconSearch = ({ color = '#DAE0E8', size = 1 }) => {
  return (
    <svg
      width={(size * 24).toString()}
      height={(size * 24).toString()}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1632 20.3291C12.5297 20.3291 14.8219 19.4984 16.6455 17.9852L22.6074 23.7688C22.7607 23.9183 22.9643 24 23.1792 24C23.4029 24 23.6128 23.912 23.7686 23.7512C23.9207 23.5941 24.0036 23.3867 23.9999 23.1668C23.9961 22.9468 23.9081 22.742 23.751 22.5899L17.823 16.8391C19.4417 14.9854 20.329 12.6265 20.329 10.1645C20.329 4.55946 15.7695 0 10.1645 0C4.55944 0 0 4.55946 0 10.1645C0 15.7696 4.55818 20.3291 10.1632 20.3291ZM10.1632 1.6413C14.8634 1.6413 18.6864 5.46431 18.6864 10.1645C18.6864 14.8647 14.8634 18.6878 10.1632 18.6878C5.46429 18.6878 1.64004 14.8647 1.64004 10.1645C1.64004 5.46431 5.46429 1.6413 10.1632 1.6413Z"
        fill={color}
      />
    </svg>
  );
};

export default iconSearch;
