import useStrings from '../../../strings';
import useServices from '../../services';

const useVotingActions = () => {
  // Strings
  const { useTypes } = useStrings();
  const { votingTypes } = useTypes();
  const {
    SAVE_VOTE_TIME,
    GET_VOTE_TIME,
    VOTE_APPLICATION,
    REVERT_APPLICATION,
    SET_STOPWATCH_STATE,
    SET_STOPWATCH_CURRENT_TIME,
    GET_CURRENTS_VOTES,
  } = votingTypes();

  // Services
  const { useVotingServices } = useServices();
  const {
    saveVoteTimeService,
    getVoteService,
    voteApplicationService,
    revertApplicationService,
    getCurrentsVotesService,
  } = useVotingServices();

  const actSaveVoteTime =
    ({ applicationId, time }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await saveVoteTimeService(applicationId, time);
        dispatch({
          type: SAVE_VOTE_TIME,
          payload: response.data,
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };

  const actGetVote =
    ({ applicationId }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await getVoteService(applicationId);

        dispatch({
          type: GET_VOTE_TIME,
          payload: response.data,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actVoteApplication =
    (
      {
        approved,
        votingTime,
        applicationId,
        hasVestedInterest,
        hasVestedType,
        hasVestedTypeOther,
      },
      onSuccess,
      onError
    ) =>
    async (dispatch) => {
      try {
        const response = await voteApplicationService(
          approved,
          votingTime,
          applicationId,
          hasVestedInterest,
          hasVestedType,
          hasVestedTypeOther
        );

        dispatch({
          type: VOTE_APPLICATION,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actRevertApplication =
    ({ applicationId, comment, document, isObjection }, onSuccess, onError) =>
    async (dispatch) => {
      try {
        const response = await revertApplicationService({
          applicationId,
          comment,
          document,
          isObjection,
        });

        dispatch({
          type: REVERT_APPLICATION,
          payload: response.data,
        });
        onSuccess && onSuccess(response);
      } catch (e) {
        onError && onError(e);
      }
    };
  const actSetStopwatchState =
    (state, onSuccess, onError) => async (dispatch) => {
      try {
        dispatch({
          type: SET_STOPWATCH_STATE,
          payload: state,
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };

  const actSetStopwatchCurrentTime =
    (currentTime, onSuccess, onError) => async (dispatch) => {
      try {
        dispatch({
          type: SET_STOPWATCH_CURRENT_TIME,
          payload: currentTime,
        });
        onSuccess && onSuccess();
      } catch (e) {
        onError && onError(e);
      }
    };

  const actGetCurrentsVotes =
    (applicationId, onSuccess, onError) => async (dispatch) => {
      try {
        const response = await getCurrentsVotesService(applicationId);

        dispatch({
          type: GET_CURRENTS_VOTES,
          payload: response.data,
        });
        onSuccess && onSuccess(response.data);
      } catch (e) {
        onError && onError(e);
      }
    };
  return {
    actSaveVoteTime,
    actGetVote,
    actVoteApplication,
    actRevertApplication,
    actSetStopwatchState,
    actSetStopwatchCurrentTime,
    actGetCurrentsVotes,
  };
};
export default useVotingActions;
