import React from 'react';

const iconTooltipWarning = ({ color = '#FF2D55', size = 1 }) => {
  return (
    <svg
      width={(size * 12).toString()}
      height={(size * 12).toString()}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 1.125C4.698 1.125 3.47362 1.632 2.553 2.553C1.63238 3.474 1.125 4.698 1.125 6C1.125 7.302 1.632 8.52637 2.553 9.447C3.474 10.3676 4.698 10.875 6 10.875C7.302 10.875 8.52637 10.368 9.447 9.447C10.3676 8.526 10.875 7.302 10.875 6C10.875 4.698 10.368 3.47362 9.447 2.553C8.526 1.63238 7.302 1.125 6 1.125ZM6 0C9.31388 0 12 2.68613 12 6C12 9.31388 9.31388 12 6 12C2.68613 12 0 9.31388 0 6C0 2.68613 2.68613 0 6 0ZM5.25 8.25H6.75V9.75H5.25V8.25ZM5.25 2.25H6.75V6.75H5.25V2.25Z"
        fill={color}
      />
    </svg>
  );
};

export default iconTooltipWarning;
