import React from 'react';

const iconPhoto = ({ color = '#FFFFFF', size = 1 }) => {
  return (
    <svg
      width={(size * 14).toString()}
      height={(size * 16).toString()}
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.77778 0.333328L4.66667 2.55555H1.55556C0.693778 2.55555 0 3.24933 0 4.11111V12.1111C0 12.9729 0.693778 13.6667 1.55556 13.6667H14.4444C15.3062 13.6667 16 12.9729 16 12.1111V4.11111C16 3.24933 15.3062 2.55555 14.4444 2.55555H11.3333L10.2222 0.333328H5.77778V0.333328ZM8 4.33333C10.0785 4.33333 11.7778 6.03259 11.7778 8.11111C11.7778 10.1896 10.0785 11.8889 8 11.8889C5.92151 11.8889 4.22222 10.1896 4.22222 8.11111C4.22222 6.03259 5.92151 4.33333 8 4.33333V4.33333ZM8 5.66666C6.64213 5.66666 5.55556 6.75319 5.55556 8.11111C5.55556 9.46902 6.64213 10.5555 8 10.5555C9.35793 10.5555 10.4444 9.46902 10.4444 8.11111C10.4444 6.75319 9.35793 5.66666 8 5.66666Z"
        fill={color}
      />
    </svg>
  );
};

export default iconPhoto;
