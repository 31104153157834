import { useEffect, useState } from 'react';
// import useApi from '../../../api';
import useModels from '../../../models';
import _ from 'lodash';

const useStatusVisualization = (id, applicationTypeId = 1) => {
  // const { useActions } = useApi();
  // const { dispatch, useApplicationsTableActions } =
  //   useActions();
  // const {
  //   actGetApplicationsStatusHelpList,
  // } = useApplicationsTableActions();

  const { useSelectors } = useModels();
  const { useSelector, useApplicationsTableSelectors, useUserSelectors } =
    useSelectors();
  const { applicationStatusHelpListSelector } = useApplicationsTableSelectors();
  const { userSelector } = useUserSelectors();
  const { statusHelpList } = useSelector(applicationStatusHelpListSelector);
  const { profile } = useSelector(userSelector);
  const [statusApplication, setStatusApplication] = useState(' ');

  useEffect(() => {
    handleFilterStatusApplication(id);
  }, [id, statusHelpList]);
  const handleFilterStatusApplication = (id) => {
    const status = _.find(statusHelpList, (option) => option.id === id);
    if (status.id === 8) {
      applicationTypeId === 1
        ? setStatusApplication(status?.status)
        : setStatusApplication(status?.subStatus);
    } else {
      switch (profile.roleType.id) {
        case 1:
          // Board
          id === 6 || id === 14
            ? setStatusApplication(status?.subStatus)
            : setStatusApplication(status?.status);
          break;
        case 2:
          // manager
          id === 2
            ? setStatusApplication('Pending approval')
            : status?.subStatus
            ? setStatusApplication(status?.subStatus)
            : setStatusApplication(status?.status);
          break;
        case 3:
          // Inspector
          id === 4
            ? setStatusApplication(status?.subStatus)
            : setStatusApplication(status?.status);
          break;
        case 4:
          // frontuser
          setStatusApplication(status?.status);
          id === 12 && setStatusApplication('Payment in review');
          break;
        case 5:
          // ceo
          status?.status && setStatusApplication(status?.status);
          status?.subStatus && setStatusApplication(status?.subStatus);
          break;
        case 6:
          // administrator
          id === 2
            ? setStatusApplication('Pending approval')
            : status?.subStatus
            ? setStatusApplication(status?.subStatus)
            : setStatusApplication(status?.status);

          id === 11 && setStatusApplication('Sent to CEO');
          break;
        default:
          status?.status && setStatusApplication(status?.status);
          break;
      }
    }
  };
  return {
    statusApplication,
  };
};

export default useStatusVisualization;
