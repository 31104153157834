import { useSelector } from 'react-redux';
import useApplicationFormSelectors from './applicationForm';
import useApplicationReviewSelectors from './applicationReview';
import useRenewalNoticesSelectors from './renewalNotices';
import useApplicationsTableSelectors from './applicationsTable';
import useAuthSelectors from './auth';
import useCommentsSelectors from './comments';
import useNotificationsSelectors from './notifications';
import usePrivateDocumentsSelectors from './privateDocuments';
import useUserSelectors from './user';
import useUsersTableSelectors from './usersTable';
import useVotingSelectors from './voting';
import useBlogsSelectors from './blogs';
const useSelectors = () => {
  return {
    useSelector,
    useApplicationFormSelectors,
    useApplicationsTableSelectors,
    useAuthSelectors,
    useUserSelectors,
    useUsersTableSelectors,
    useApplicationReviewSelectors,
    useRenewalNoticesSelectors,
    usePrivateDocumentsSelectors,
    useNotificationsSelectors,
    useVotingSelectors,
    useCommentsSelectors,
    useBlogsSelectors,
  };
};

export default useSelectors;
