import styled from 'styled-components';
import tw from 'twin.macro';
import Button from '../../components/Buttons';
import Accordion from '../../components/Accordion';
import Typography from '../../components/Typography';
import TextAreaControlled from '../../components/TextAreaControlled';
import TextFieldControlled from '../../components/TextFieldControlled';
import SwitchControlled from '../../components/SwitchControlled';
import TooltipField from '../../components/TooltipField';
export const StyledSectionApplication = styled.div.attrs({
  className: 'StyledSectionMyApplications',
})`
&& {
    ${tw`relative  mt-[48px] pb-[48px] mx-[10px]`}
    width: calc(100% - 20px);
    box-sizing:content-box;

    @media (max-width: 768px){
      width: calc(100% - 32px);
      margin: 0 16px;
      padding-top: 16px;
      padding-bottom: 24px;
    }
    }}
    
`;

export const StyledGoBack = styled.div.attrs({
  className: 'StyledGoBack',
})`
  && {
    ${tw` absolute`}
    top: -48px;
    left: -10px;
    display: flex;
    align-items: center;
    height: 56px;
    padding-left: 16px;
    cursor: pointer;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
export const StyledGoBackTypography = styled(Typography).attrs({
  className: 'StyledGoBackTypography',
})`
  && {
    ${tw` absolute`}
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    margin-left: 12px;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
export const StyledButtonDownloadRenewal = styled(Button).attrs({
  className: 'StyledButtonDownloadRenewal',
})`
  && {
    ${tw`absolute flex`}
    column-gap: 16px;
    align-items: center;
    width: 195px;
    height: 44px;
    right: -16px;
    top: -120px;

    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
export const StyledButtonDownloadRenewalMobile = styled(Button).attrs({
  className: 'StyledButtonDownloadRenewalMobile',
})`
  && {
    ${tw`relative `}
    display: none;
    margin: 16px auto;
    column-gap: 16px;
    align-items: center;
    width: 195px;
    height: 44px;

    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      display: flex;
    }
  }
`;
export const StyledTitleSection = styled(Typography).attrs({
  className: 'StyledTitleSection StyledTypographyBold',
})`
  && {
    ${tw`relative`}
    padding: 24px 0;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.017em;

    color: #d66127;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
export const StyledContainerStamp = styled.div.attrs({
  className: 'StyledContainerStamp',
})`
  && {
    ${tw`absolute grid`}
    width: 120px;
    heigt: 120px;
    right: 0;
    place-content: center;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledContainerStampMobile = styled.div.attrs({
  className: 'StyledContainerStampMobile',
})`
  && {
    ${tw`relative `}
    display:none;
    margin: 32px auto;
    width: 120px;
    heigt: 120px;
    right: 0;
    place-content: center;
    @media (max-width: 768px) {
      display: grid;
    }
  }
`;
export const StyledStamp = styled.img.attrs({
  className: 'StyledStamp',
})`
  && {
    ${tw`relative`}
    width: 120px;
    heigt: 120px;
  }
`;
export const StyledFieldsContainer = styled.div.attrs({
  className: 'StyledFieldsContainer',
})`
  && {
    ${tw`relative flex flex-wrap pb-[32px]`}
    // max-width: 860px;
    gap: 20px 40px;
    justify-content: space-between;
    // & > * {
    //   flex: 0 1 calc(30% - 20px);
    // }
    @media (max-width: 767.98px) {
      padding-top: 20px;
      padding-bottom: 0;
      flex-direction: column;
    }
  }
`;

export const StyledFieldColumn = styled.div.attrs({
  className: 'StyledFieldColumn',
})`
  && {
    ${tw`relative flex flex-col`}
  }
`;
export const StyledContainerAdressPremises = styled.div.attrs({
  className: 'StyledContainerAdressPremises',
})`
  && {
    ${tw`relative flex flex-col`}
    @media (max-width: 767.98px) {
      padding-top: 24px;
    }
  }
`;
export const StyledFieldColumnInputField = styled.div.attrs({
  className: 'StyledFieldColumnInputField',
})`
  && {
    ${tw`relative flex flex-col`}
    margin-bottom: 32px;
    @media (max-width: 767.98px) {
      display: none;
    }
  }
`;
export const StyledContainerInputFile = styled.div.attrs({
  className: 'StyledContainerInputFile',
})`
  && {
    ${tw`relative`}
    @media (max-width: 767.98px) {
      display: none;
    }
  }
`;

export const StyledFieldColumnTitle = styled(Typography).attrs({
  className: 'StyledFieldColumnTitle',
})`
  && {
    ${tw`relative`}
    font-size: 12px;
    line-height: 17px;

    color: #6d7074;
  }
`;

export const StyledFieldColumnValue = styled(Typography).attrs({
  className: 'StyledFieldColumnValue StyledTypographyBook',
})`
  && {
    ${tw`relative`}
    font-size: 14px;
    line-height: 20px;flex;
    align-items: center;
    letter-spacing: -0.006em;

    color: #2C2D2E;

  }
`;

export const StyledFieldsContainerMiddle = styled.div.attrs({
  className: 'StyledFieldsContainerMiddle',
})`
  && {
    ${tw`relative flex flex-wrap pt-[20px]`}
    gap: 20px 40px;
    & > * {
      flex: 0 1 calc(50% - 20px);
    }
    @media (max-width: 767.98px) {
      padding-top: 20px;
      flex-direction: column;
    }
  }
`;

export const StyledTextAreaControlled = styled(TextAreaControlled).attrs({
  className: 'StyledTextAreaControlled',
})`
  && {
    ${tw`relative`}
    width:100%;
    &:focus {
      border: 1px solid red !important;
    }
    .StyledTextareaAutosize {
      min-width: 100%;
      max-width: 100%;
      min-height: 80px;
      max-height: 420px;
      resize: vertical;
      border: 1px solid #dae0e8 !important;
    }
    @media (max-width: 767.98px) {
      display: none;
    }
  }
`;
export const StyledTextFieldControlled = styled(TextFieldControlled).attrs({
  className: 'StyledTextFieldControlled',
})`
  && {
    ${tw`relative`}
    .MuiOutlinedInput-root {
      background: #f3f5f7;
    }
  }
`;

export const StyledDocumentsReportAccordion = styled(Accordion).attrs({
  className: 'StyledDocumentsReportAccordion',
})`
  && {
    ${tw`relative`}
    margin: 24px 0 !important;
    background-color: #ffffff !important;
    opacity: 1 !important;
    color: #242731;
    border: 1px solid #eceff3;
    border-radius: 4px;
    .Mui-disabled {
      pointer-events: auto;
      opacity: 1;
    }
  }
`;
export const StyledTitleDocumentReportContainerAccordion = styled.div.attrs({
  className: 'StyledTitleDocumentReportContainerAccordion ',
})`
  && {
    ${tw`relative`}
    width: 100%;
    display: flex;
    align-items: center;
  }
`;
export const StyledTitleReportAccordion = styled(Typography).attrs({
  className: 'StyledTitleReportAccordion StyledTypographyBook',
})`
  && {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #242731;
  }
`;
export const StyledContainerIconView = styled.a.attrs({
  className: 'StyledContainerIconView ',
})`
  && {
    ${tw`absolute`}
    right:0;
    transform: translate(-48px, 0);
    display: grid;
    place-content: center;
    cursor: pointer;
  }
`;

export const StyledContainerDownload = styled.a.attrs({
  className: 'StyledContainerDownload ',
})`
  && {
    ${tw`absolute flex`}
    padding-left: 72px;
    width: 100%;
    height: 44px;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
  }
`;

export const StyledForDownloadDisabled = styled.a.attrs({
  className: 'StyledForDownloadDisabled',
})`
  && {
    ${tw`absolute flex`}
    width: 0;
    height: 0px;
    text-decoration: none;
  }
`;
export const StyledContainerIconDownloadTop = styled.a.attrs({
  className: 'StyledContainerIconDownloadTop ',
})`
  && {
    ${tw`absolute`}
    left: 24px;
    display: grid;
    place-content: center;
    cursor: pointer;
  }
`;

export const StyledContainerIconDownload = styled.a.attrs({
  className: 'StyledContainerIconDownload ',
})`
  && {
    ${tw`absolute`}
    right:0;
    display: grid;
    place-content: center;
    cursor: pointer;
  }
`;

export const StyledFieldRowBottomContainer = styled.div.attrs({
  className: 'StyledFieldRowBottomContainer',
})`
  && {
    ${tw`relative flex`}
    margin-top: 60px;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767.98px) {
      display: none;
    }
  }
`;
export const StyledRowButtons = styled.div.attrs({
  className: 'StyledRowButtons',
})`
  && {
    ${tw`relative flex`}
    column-gap: 16px;
  }
`;

export const StyledButtonSaveAndContinue = styled(Button).attrs({
  className: 'StyledButtonSaveAndContinue',
})`
  && {
    ${tw`relative`}

    width: 250px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 130px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;

export const StyledButtonContinue = styled(Button).attrs({
  className: 'StyledButtonContinue',
})`
  && {
    ${tw`relative`}

    width: 250px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 130px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;

export const StyledButtonComment = styled(Button).attrs({
  className: 'StyledButtonComment',
})`
  && {
    ${tw`relative`}
    width: 142px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 130px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;
export const StyledButtonApprove = styled(Button).attrs({
  className: 'StyledButtonApprove',
})`
  && {
    ${tw`relative`}

    width: 132px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 130px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;

//Comments
export const StyledTitleModal = styled(Typography).attrs({
  className: 'StyledTitleModal StyledTypographyBold',
})`
  && {
    max-width: 460px;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.021em;
  }
`;

export const StyledSubtitleModal = styled(Typography).attrs({
  className: 'StyledSubtitleModal',
})`
  && {
    max-width: 460px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #6d7074;
  }
`;
export const StyledChildContainerModal = styled.div.attrs({
  className: 'StyledChildContainerModal',
})`
  && {
    ${tw`relative flex flex-col`}
    row-gap: 16px;
    margin-top: 36px;
    min-width: 750px;
    align-items: center;
  }
`;

export const StyledDescriptionModal = styled(Typography).attrs({
  className: 'StyledDescriptionModal',
})`
  && {
    align-self: flex-start;
    color: #494b4d;
    max-width: 750px;
    font-size: 18px;
    line-height: 20px;
    margin-top: 16px;
    margin-bottom: 50px;
  }
`;

export const StyledTextAreaField = styled(TextAreaControlled).attrs({
  className: 'StyledTextAreaField',
})`
  && {
    ${tw`relative`}
    width: 750px;
    .StyledTextareaAutosize {
      min-height: 160px;
      max-height: 320px;
    }
  }
`;

export const StyledSwitchField = styled(SwitchControlled).attrs({
  className: 'StyledSwitchField',
})`
  && {
    ${tw`relative`}
    align-self: flex-start;
  }
`;
export const StyledButtonModal = styled(Button).attrs({
  className: 'StyledButtonModal',
})`
  && {
    ${tw`relative`}

    width: 250px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 160px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;

// Comments at reques for changes
export const StyledCommentsContainer = styled.div.attrs({
  className: 'StyledCommentsContainer',
})`
  && {
    ${tw`relative mt-[36px]`}
  }
`;
export const StyledCommentsInnerContainer = styled.div.attrs({
  className: 'StyledCommentsInnerContainer',
})`
  && {
    ${tw`relative flex flex-col`}
    row-gap: 8px;
  }
`;
export const StyledCommentTitle = styled(Typography).attrs({
  className: 'StyledCommentTitle StyledTypographyBold',
})`
  && {
    ${tw`relative flex`}
    flex-wrap: no-wrap;
    column-gap: 8px;
    align-items: center;

    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.011em;
    color: #ff2d55;
  }
`;

export const StyledCommentDescription = styled(Typography).attrs({
  className: 'StyledCommentDescription StyledTypographyBook',
})`
  && {
    ${tw`relative`}
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #000000;
    @media (max-width: 768px) {
      margin-left: 24px;
      margin-bottom: 16px;
    }
  }
`;

// Tooltip
export const StyledTooltipField = styled(TooltipField).attrs({
  className: 'StyledTooltipField',
})`
  && {
    .MuiTooltip-popper .MuiTooltip-tooltip {
      max-width: 400px !important;
    }
  }
`;

export const StyledTypographyTooltipContainer = styled.div.attrs({
  className: 'StyledTypographyTooltipContainer',
})`
  && {
    ${tw`relative grid`}
    grid-template-columns: 24px auto;
    // grid-templeate-rows:
  }
`;

export const StyledTypographyTooltip = styled(Typography).attrs({
  className: 'StyledTypographyTooltip StyledTypographyLight',
})`
  && {
    font-size: 12px;
    line-height: 17px;
    color: #ffffff;
    margin-bottom: 4px;
  }
`;

export const StyledTypographyTooltipBold = styled(Typography).attrs({
  className: 'StyledTypographyTooltipBold',
})`
  && {
    font-size: 12px;
    line-height: 17px;
    color: #ffffff;
    margin-bottom: 4px;
  }
`;

// comment mobile

export const StyledCommentsMobileContainer = styled.div.attrs({
  className: 'StyledCommentsMobileContainer',
})`
  && {
    ${tw`relative`}
    display: none;

    @media (max-width: 767.98px) {
      display: flex;
      flex-wrap: no-wrap;
      column-gap: 8px;
    }
  }
`;
export const StyledCommentsRejectedContainer = styled.div.attrs({
  className: 'StyledCommentsRejectedContainer',
})`
  && {
    ${tw`relative`}
    display: flex;
    flex-wrap: no-wrap;
    column-gap: 8px;
  }
`;
export const StyledCommentsMobileIconContainer = styled.div.attrs({
  className: 'StyledCommentsMobileIconContainer',
})`
&& {
    ${tw`relative  `}
    display: grid;
    place-content: center;
    margin: 0;
    width: 16px;
    height: 16px;

    }}
`;
export const StyledCommentsMobile = styled(Typography).attrs({
  className: 'StyledCommentsMobile',
})`
  && {
    ${tw`relative`}
    font-size: 12px;
    line-height: 17px;
    color: #0b1e2e;
  }
`;
