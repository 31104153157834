import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import useApi from '../../../api';
import useHelpers from '../../../helpers';
import useModels from '../../../models';
import useComponentHooks from '../../componentHooks';

const useRenewalNotice = (resetField) => {
  // Api
  const { useActions } = useApi();
  const {
    dispatch,
    useApplicationRenewalsActions,
    useApplicationFormActions,
    usePrivateDocumentsActions,
    useCommentsActions,
  } = useActions();
  const {
    actSaveApplicationRenewal,
    actSubmitApplicationRenewal,
    actApproveApplicationRenewal,
    actRemoveProofOfPayment,
    actGetRenewalPaymentPdf,
  } = useApplicationRenewalsActions();
  const { actGetApplicationHelpData } = useApplicationFormActions();
  const { actGetPrivateDocument } = usePrivateDocumentsActions();
  const { actSendComment } = useCommentsActions();

  //hooks
  const { useModal } = useComponentHooks();
  const {
    modalState: helperModalStateSuccess,
    handleShowModal: handleHelperModalStateSuccess,
  } = useModal();
  const {
    modalState: modalStateComments,
    handleShowModal: handleShowModalComments,
  } = useModal();

  //Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation } = useQuickFunctions();
  const { goBack, navigateTo } = useNavigation();

  // Selectors
  const { useSelectors } = useModels();
  const {
    useSelector,
    useRenewalNoticesSelectors,
    useApplicationFormSelectors,
    useUserSelectors,
  } = useSelectors();
  const { applicationRenewalSelector } = useRenewalNoticesSelectors();
  const { applicationFormHelpDataSelector } = useApplicationFormSelectors();
  const { userSelector } = useUserSelectors();
  const { applicationRenewal } = useSelector(applicationRenewalSelector);
  const { licenceTypes } = useSelector(applicationFormHelpDataSelector);
  const { profile, userRolesList } = useSelector(userSelector);

  const [base64RenewalPaymentState, setBase64RenewalPaymentState] =
    useState('');
  const [helperModalSuccessState, setHelperModalSuccessState] =
    useState('error');
  const [downloadPdfState, setDownloadPdfState] = useState(false);

  const downloadRenewalNoticePaymentRef = useRef();

  useEffect(() => {
    dispatch(actGetApplicationHelpData());
    !applicationRenewal.id && handleRedirectApplications();
    applicationRenewal.id &&
      dispatch(actGetRenewalPaymentPdf(applicationRenewal.id, getPdfBase64));
  }, []);

  useEffect(() => {
    downloadPdfState &&
      base64RenewalPaymentState &&
      handleDownloadRenewalPayment();
  }, [base64RenewalPaymentState]);

  const handleGetRenewalPaymentAndDownload = (data) => {
    setDownloadPdfState(true);
    dispatch(
      actGetRenewalPaymentPdf(data.renewalId, setBase64RenewalPaymentState)
    );
  };

  const handleRedirectRenewals = () => {
    navigateTo(`/renewals`);
  };
  const handleRedirectApplications = () => {
    navigateTo(`/applications`);
  };

  const getPdfBase64 = (data) => {
    setBase64RenewalPaymentState(data);
  };

  const handleUserRoleType = (roleIdRow) => {
    return _.find(userRolesList, (userRole) => userRole?.id === roleIdRow)
      ?.name;
  };
  //Files handler
  const fileTypesPdf = ['application/pdf'];

  const handleFileNameExtension = (fileObject) => {
    const fileObjectWithoutExtension = {
      name: fileObject?.name ? fileObject.name?.replace(/\.[^/.]+$/, '') : '',
      data: fileObject?.data ? fileObject.data : '',
    };
    return fileObjectWithoutExtension;
  };
  const handleSaveRenewalBeforeDownloadOfPayment = (data) => {
    const dataToSave = {
      ...data,
      statusId: applicationRenewal.statusId,
      applicationId: applicationRenewal.applicationId,
      proofOfPayment: data.proofOfPayment
        ? data.proofOfPayment
        : applicationRenewal.proofOfPayment,
    };

    !downloadPdfState &&
      dispatch(
        actSaveApplicationRenewal(
          dataToSave,
          handleGetRenewalPaymentAndDownload
        )
      );
  };

  const handleDownloadRenewalPayment = () => {
    setDownloadPdfState(false);
    downloadRenewalNoticePaymentRef.current &&
      downloadRenewalNoticePaymentRef.current.click();
  };

  const onChangeFilePdf = ({ event, remove, reset, id }) => {
    event.preventDefault();
    if (event.target.files.length > 0) {
      const { type } = event.target.files[0];
      if (!fileTypesPdf.some((s) => type.includes(s))) {
        alert('invalid document type');
        remove(reset, id);
      } else {
        if (event.target.files[0].size > 2000000) {
          alert('Document too large, please try again with 2MB or less');
          remove(reset, id);
        }
      }
    }
  };

  // handle fields
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const handleDate = (currentDate) => {
    const currentFullDate = new Date(currentDate);
    const currentYear = currentFullDate.getFullYear();
    const currentMonth = monthNames[currentFullDate.getMonth()];
    const currentDay = currentFullDate.getDate();

    return ` ${currentDay} ${currentMonth} ${currentYear}`;
  };

  const handleYear = (currentDate) => {
    const currentFullDate = new Date(currentDate);
    const currentYear = currentFullDate.getFullYear();
    return currentYear;
  };
  const handleLicenseType = (idLicenseType) => {
    return _.find(
      licenceTypes,
      (currentLicenseType) => currentLicenseType.id == idLicenseType
    ).name;
  };
  const handleGetPrivateLink = (url) => {
    window.open(url);
  };

  const handleGetPrivateDocumentView = (documentName) => {
    dispatch(
      actGetPrivateDocument(
        {
          documentName: documentName,
          applicationId: applicationRenewal.id,
        },
        handleGetPrivateLink
      )
    );
  };

  //Modal success handlers
  const handleCloseHelperModalSuccess = () => {
    handleHelperModalStateSuccess();
    goBack();
  };

  const handleSubmitApproveSuccess = () => {
    setHelperModalSuccessState('success');
    handleHelperModalStateSuccess(
      'Success!',
      'Application Renewal was approved  successfully!'
    );
  };

  const handleSubmitRenewalSuccess = () => {
    setHelperModalSuccessState('success');
    handleHelperModalStateSuccess(
      'Success!',
      'Application Renewal was submitted  successfully!'
    );
  };

  const handleSubmitSaveRenewalSuccess = () => {
    setHelperModalSuccessState('success');
    handleHelperModalStateSuccess(
      'Success!',
      'Application Renewal was saved successfully!'
    );
  };

  //Modal Comments
  const handleSendCommentsSuccess = () => {
    setHelperModalSuccessState('success');
    handleShowModalComments();
    handleHelperModalStateSuccess('Success!', 'Comments sent successfully!');
  };

  const handleCloseModalComments = () => {
    handleShowModalComments();
  };

  // submit handlers
  const onSubmitComments = (data) => {
    const dataComments = {
      comment: data.textAreaComments,
      applicationId: applicationRenewal.id,
      sendFrontUserNotification: 1,
      applicationStatusId: 7,
    };
    dispatch(actSendComment({ data: dataComments }, handleSendCommentsSuccess));
  };

  const onSubmitApprove = (data) => {
    const dataApprove = {
      ...data,
      applicationId: applicationRenewal.id,
    };
    dispatch(
      actApproveApplicationRenewal(dataApprove, handleSubmitApproveSuccess)
    );
  };
  const handleRemoveProofOfPayment = (id, data) => {
    dispatch(actRemoveProofOfPayment(resetField(id, data)));
  };

  const handleStatusApplicationCopysMobile = () => {
    /**
     * The application percent is given by the status id
     */
    switch (applicationRenewal.statusId) {
      case 2:
        // Submitted
        return 'Your proof of payment for license renewal has been submitted and is pending review.';
      case 3:
        // Submitted
        return 'Your application has been submitted and is awaiting review.';
      case 4:
        // Reports pending
        return 20;
      case 5:
        // Reports approval
        return 40;
      case 6:
        // Voting pending
        return 60;
      case 7:
        // Request for changes
        return 'To upload a new proof of payment with the requested changes visit this renewal again from a desktop machine.';
      case 11:
        // Review Payment
        return 80;
      case 8:
        // Approved
        return 'Your renewal was approved, click the download button to get your renewal document.';
      case 9:
        // Rejected
        return 'Your Renewal was rejected, for more information on what to do communicate with the Northern Cape Liquor Board.';
      case 10:
        // Payment pending
        return 'You can download the pending fee with the download button, to upload your proof of payment and continue the process visit this application on a desktop machine.';
      case 13:
        // Payment failed
        return 'The last supervisor has found a problem with your application, to correct the problem visit this application on a desktop machine.';
      default:
        return 0;
    }
  };
  const onSubmitRenewal = (data) => {
    const dataToSubmit = {
      ...data,
      statusId: 2,
      applicationId: applicationRenewal.applicationId,
    };
    dispatch(
      actSubmitApplicationRenewal(dataToSubmit, handleSubmitRenewalSuccess)
    );
  };

  const onSubmitSaveRenewal = (data) => {
    const dataToSave = {
      ...data,
      statusId: 1,
      applicationId: applicationRenewal.applicationId,
      proofOfPayment: data.proofOfPayment
        ? data.proofOfPayment
        : applicationRenewal.proofOfPayment,
    };
    dispatch(
      actSaveApplicationRenewal(dataToSave, handleSubmitSaveRenewalSuccess)
    );
  };
  return {
    applicationRenewal,
    goBack,
    handleRedirectRenewals,
    handleRedirectApplications,
    fileTypesPdf,
    onChangeFilePdf,
    onSubmitRenewal,
    onSubmitSaveRenewal,
    handleFileNameExtension,
    handleYear,
    handleDate,
    handleLicenseType,
    helperModalStateSuccess,
    helperModalSuccessState,
    handleCloseHelperModalSuccess,
    handleGetPrivateDocumentView,
    profile,
    onSubmitApprove,
    onSubmitComments,
    modalStateComments,
    handleShowModalComments,
    handleCloseModalComments,
    handleRemoveProofOfPayment,
    getPdfBase64,
    base64RenewalPaymentState,
    handleUserRoleType,
    downloadRenewalNoticePaymentRef,
    handleDownloadRenewalPayment,
    handleSaveRenewalBeforeDownloadOfPayment,
    handleStatusApplicationCopysMobile,
  };
};

export default useRenewalNotice;
