import useHelpers from '../../../helpers';

const useApplicationFormSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const applicationFormHelpDataSelector = createSelector(
    (state) => state.applicationForm,
    (applicationForm) => {
      return {
        aditionsCompletedBy: applicationForm.aditionsCompletedBy,
        phoneIndicators: applicationForm.phoneIndicators,
        licenceTypes: applicationForm.licenceTypes,
        contactMethods: applicationForm.contactMethods,
        applicationTypes: applicationForm.applicationTypes,
        landUseRightsDocumentType: applicationForm.landUseRightsDocumentType,
        typeOfRegistration: applicationForm.typeOfRegistration,
        municipalities: applicationForm.municipalities,
      };
    }
  );

  const applicationFormSelector = createSelector(
    (state) => state.applicationForm,
    (applicationForm) => applicationForm
  );

  const applicationReadySelector = createSelector(
    (state) => state.applicationForm,
    (applicationForm) => {
      return {
        applicationReady: applicationForm.applicationReady,
      };
    }
  );

  const applicationDateSelector = createSelector(
    (state) => state.applicationForm,
    (applicationForm) => {
      return {
        date: applicationForm.date,
      };
    }
  );

  return {
    applicationFormHelpDataSelector,
    applicationFormSelector,
    applicationReadySelector,
    applicationDateSelector,
  };
};

export default useApplicationFormSelectors;
