import React from 'react';
import useAssets from '../../../assets';
import useControllers from '../../../controllers';
import Button from '../Buttons';
import {
  StyledHeader,
  StyledNavBar,
  StyledLogoContainer,
  StyledLogoMobileContainer,
  // StyledHamMenuContainer,
} from './Header.styles';

const Header = () => {
  const { useIcons } = useAssets();
  const {
    iconLogo: IconLogo,
    iconLogoMobile: IconLogoMobile,
    // iconHamMenu: IconHamMenu,
  } = useIcons();

  //Hooks
  const { useComponentHooks } = useControllers();
  const { useHeader } = useComponentHooks();
  const { gotToSignUp, gotToLogin, gotToHome } = useHeader();

  return (
    <StyledHeader>
      <StyledLogoContainer onClick={gotToHome}>
        <IconLogo />
      </StyledLogoContainer>

      {/* <StyledHamMenuContainer>
        <IconHamMenu />
      </StyledHamMenuContainer> */}

      <StyledLogoMobileContainer onClick={gotToHome}>
        <IconLogoMobile />
      </StyledLogoMobileContainer>
      <StyledNavBar>
        <Button
          variant={'outlined'}
          color={'secondary'}
          fullWidth={false}
          sx={{ minWidth: '115px' }}
          onClick={gotToLogin}
        >
          Login
        </Button>
        <Button
          color={'secondary'}
          fullWidth={false}
          sx={{ minWidth: '115px' }}
          onClick={gotToSignUp}
        >
          Register
        </Button>
      </StyledNavBar>
    </StyledHeader>
  );
};

export default Header;
