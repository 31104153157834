import useAuth from '../auth';
import useScreens from '../../../views/screens';

const useRouterHelper = () => {
  const { getSession } = useAuth();
  const { accessToken } = getSession;
  const { Splash } = useScreens();
  const requireAuth = ({ Component, redirect = Splash }) => {
    return !accessToken ? redirect : Component;
  };
  return {
    requireAuth,
  };
};
export default useRouterHelper;
