const usePrivateDocumentsInitialStates = () => {
  const privateDocumentsInitialState = {
    reportDeleted: false,
  };
  return {
    privateDocumentsInitialState,
  };
};

export default usePrivateDocumentsInitialStates;
