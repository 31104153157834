import { useEffect } from 'react';
import useApi from '../../../api';
import useModels from '../../../models';
import { useTimer } from 'react-timer-hook';

const useRefreshToken = () => {
  const { isRunning, pause, restart } = useTimer({
    autoStart: false,
    onExpire: () => handleRefreshTokenConfirmation(),
  });

  //Api
  const { useActions } = useApi();
  const { dispatch, useAuthActions } = useActions();
  const { actRefreshToken, actRefreshTokenConfirmation } = useAuthActions();

  //Models
  const { useSelectors } = useModels();
  const { useSelector, useAuthSelectors } = useSelectors();
  const { session } = useAuthSelectors();
  const {
    accessToken,
    expiresAt,
    refreshToken,
    toggleRefreshToken,
    refreshTokenConfirmation,
  } = useSelector(session);

  // Handlers
  const handleRefreshToken = () => {
    dispatch(actRefreshToken(refreshToken, pause));
  };

  const handleRefreshTokenConfirmation = () => {
    dispatch(actRefreshTokenConfirmation(handleRefreshToken));
  };

  useEffect(() => {
    // Set new Time
    const lastOnActionDate = new Date();
    const tokenExpirationDate = new Date(expiresAt);
    const datesDifference = new Date(tokenExpirationDate - lastOnActionDate);
    const minutesDifference = datesDifference.getTime() / 1000 / 60;
    const millisecondsLeft = datesDifference.getTime();
    const millisecondsLeftForRefresh =
      millisecondsLeft <= 300000 ? 0 : millisecondsLeft - 300000;

    // Execution of the refersh token when time to expire is 20 min
    if (
      !refreshTokenConfirmation &&
      minutesDifference < 20 &&
      accessToken &&
      !isRunning
    ) {
      lastOnActionDate.setSeconds(
        lastOnActionDate.getSeconds() + millisecondsLeftForRefresh / 1000 + 10
      );
      restart(lastOnActionDate, true);
    }
  }, [toggleRefreshToken]);
};
export default useRefreshToken;
