import styled from 'styled-components';
import tw from 'twin.macro';
import Typography from '../../components/Typography';

export const StyledLoggedHeader = styled.header.attrs({
  className: 'StyledLoggedHeader',
})`
  && {
    ${tw` flex flex-row py-[16px] px-[40px] justify-between`}
    position:relative;
    z-index: 10;
    height: 80px;
    align-items: center;
    background-color: rgb(248 249 250 / 80%);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    @media (max-width: 768px) {
      align-items: center;
      background: #5e5c5c;
      height: 44px;
      padding: 6px 16px;
    }
  }
`;

export const StyledContainerLinks = styled.div.attrs({
  className: 'StyledContainerLinks',
})`
  && {
    ${tw` relative flex`}
    align-items: center;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledContainerSection = styled.div.attrs({
  className: 'StyledContainerSection',
})`
  && {
    ${tw` relative flex`}
    padding: 0 4px;
    justify-content: center;
    align-items: center;
    margin: 10px;
    height: 80px;
    cursor: pointer;
    text-align: center;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledActiveLink = styled.div.attrs({
  className: 'StyledActiveLink',
})`
  && {
    ${tw` absolute flex`}
    width: 100%;
    top: 0;
    justify-content: center;
    align-items: center;
    height: 4px;
    background: #d66127;
    opacity: 0.64;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledSectionHeader = styled(Typography).attrs({
  className: 'StyledSectionHeader',
})`
  && {
    ${tw` relative`}

    background: transparent;
    font-size: 12px;
    line-height: 17px;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
export const StyledNotificationContainerBackuser = styled.div.attrs({
  className: 'StyledNotificationContainerBackuser',
})`
  && {
    // position: absolute;
    // top: 0;
    // left: 100%;
    min-height: 48px;
    cursor: pointer;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledLogoContainer = styled.div.attrs({
  className: 'StyledLogoContainer',
})`
  && {
    ${tw` relative cursor-pointer`}
    margin-right: 48px;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledLogoMobileContainer = styled.div.attrs({
  className: 'StyledLogoMobileContainer',
})`
  && {
    ${tw` relative cursor-pointer`}
    display: none;
    @media (max-width: 768px) {
      display: block;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
`;

export const StyledHamMenuContainer = styled.div.attrs({
  className: 'StyledHamMenuContainer',
})`
  && {
    ${tw` absolute cursor-pointer`}
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
`;

export const StyledLoggedNavBar = styled.div.attrs({
  className: 'StyledLoggedNavBar',
})`
  && {
    ${tw` relative flex flex-col  `}
    justify-content: center;
    cursor: pointer;
  }
`;
