// Packages
import styled from 'styled-components';
import tw from 'twin.macro';
import { CircularProgress } from '@mui/material';
import Typography from '../Typography';
// Components
import {
  FormControl,
  FormHelperText,
  InputLabel,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';

export const StyledWizard = styled.div.attrs({
  className: 'StyledWizard',
})`
  && {
    position: absolute;
    width: 320px;
    height: 104px;
    background: #f8f9fa;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    transform: translate(-232px, -32px);
  }
`;

export const StyledWizardPromiseInProgressContainer = styled.div.attrs({
  className: 'StyledWizardPromiseInProgressContainer',
})`
  && {
    ${tw`absolute grid`}
    width: 320px;
    place-content: center;
    border-radius: 16px;
    height: 104px;
    backdrop-filter: blur(3px);
    z-index: 2;
  }
`;

export const StyledWizardPromiseInProgressInnerContainer = styled.div.attrs({
  className: 'StyledWizardPromiseInProgressInnerContainer',
})`
  && {
    ${tw`relative flex flex-col`}
    align-items: center;
    justy-content: center;
    row-gap: 4px;
  }
`;

export const StyledCircularProgress = styled(CircularProgress).attrs({
  className: 'StyledCircularProgress',
})`
  && {
    ${tw`relative`}
  }
`;

export const StyledCircularProgressLabel = styled(Typography).attrs({
  className: 'StyledCircularProgressLabel',
})`
  && {
    ${tw`relative`}
    font-size: 8px;
    line-height: 12px;
    color: #494b4d;
  }
`;

export const StyledStatusBar = styled.div.attrs({
  className: 'StyledStatusBar',
})`
  && {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 31px;
    left: 47px;
    width: 226px;
    height: 0px;
  }
`;
export const StyledStatusBarLeft = styled.div.attrs({
  className: 'StyledStatusBarLeft',
})`
  && {
    position: absolute;
    top: 0;
    left: 3px;
    width: 110px;
    height: 0px;
    border: 1px solid;
    border-color: #dae0e8;
  }
`;
export const StyledStatusBarRight = styled.div.attrs({
  className: 'StyledStatusBarRight',
})`
  && {
    position: absolute;
    top: 0;
    left: 50%;
    width: 110px;
    height: 0px;
    border: 1px solid;
    border-color: red;
  }
`;
export const StyledFormControl = styled(FormControl).attrs({
  className: 'StyledFormControl',
})`
  & {
    .MuiFormControl-root {
      ${tw`mt-[0px] mb-[4px]`}
    }

    .MuiInputBase-input {
      ${tw`box-border pt-[10px] pb-[14px] px-[16px] h-[44px] border-black`}
      font-family: 'GothamRnd-Book' !important;
      font-size: 14px;
      line-height: 20px;
    }
    .MuiOutlinedInput-root {
      @media (max-width: 768px) {
        min-width: 230px;
      }
    }
    .MuiInputLabel-outlined {
      ${tw`top-[-4px]`}
    }
    .MuiOutlinedInput-notchedOutline {
      ${tw` rounded`}
      border: 1px solid #DAE0E8;
    }
    .MuiFormControlLabel-root {
      display: flex;
      flex-wrap: nowrap;
      column-gap: 60px;
      margin: 0;
      top: 0;
    }
    // .MuiFormGroup-root {
    //   display: flex;
    //   flex-wrap: nowrap;
    //   justify-content: space-between;

    // }
    .MuiFormControlLabel-root .MuiFormControlLabel-label {
      position: absolute;
      font-family: 'GothamRnd-Medium' !important;
      top: 25px;
      max-width: 85px;
      font-size: 12px;
      line-height: 17px;
      text-align: center;
      color: #494b4d;
    }

    .MuiRadio-root {
      top: -6px;
      padding: 0;
    }
  }
`;

export const StyledInputLabel = styled(InputLabel).attrs({
  className: 'formInputLabel',
})`
  left: -14px !important;
  font-size: 20px !important;
  top: 12px !important;
  color: #494b4d !important;
  @media (max-width: 768px) {
    font-size: 18px !important;
    line-height: '20px';
  }
`;

export const StyledRadioGroup = styled(RadioGroup).attrs({
  className: 'StyledRadioGroup',
})`
  margin-bottom: 40px;
  margin-top: 8px;
  top: 12px !important;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  color: 'red !important';
`;

export const StyledControlled = styled(FormControlLabel).attrs({
  className: 'StyledControlled',
})`
  margin-bottom: 40px;
  margin-top: 8px;
  top: 12px !important;
  margin-bottom: 20px !important;
  margin-top: 20px !important;
  color: 'red !important';
`;
export const StyledFormHelperText = styled(FormHelperText).attrs({
  className: 'errorInputLabel',
})`
  color: #ff2d55 !important;
`;
