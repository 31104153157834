import React from 'react';
import PropTypes from 'prop-types';
// Styles
import { StyledDatePicker } from './DateField.styles';

const DateField = (props) => {
  const { id, label, sx, className, ...rest } = props;

  return (
    <StyledDatePicker
      className={className}
      fullWidth={true}
      id={id}
      label={label}
      type="date"
      sx={sx}
      {...rest}
    />
  );
};

DateField.propTypes = {
  id: PropTypes.string.isRequired,
  sx: PropTypes.any,
  label: PropTypes.any,
  inputFormat: PropTypes.string,
  className: PropTypes.string,
  // error: PropTypes.any,
  // helperText: PropTypes.any,
};

// DateField.defaultProps = {
// };

export default DateField;
