import styled from 'styled-components';
import tw from 'twin.macro';
import Button from '../../components/Buttons';
import Typography from '../../components/Typography';

export const StyledSectionNewApplication = styled.div.attrs({
  className: 'StyledSectionNewApplication',
})`
  && {
    ${tw`relative  mt-[56px] pb-[56px] mx-[10px]`}
    width: calc(100% - 20px);
    box-sizing: content-box;

    @media (max-width: 767.98px) {
      width: calc(100% - 32px);
      margin: 0 16px;
      padding-top: 16px;
      padding-bottom: 24px;
    }
  }
`;
export const StyledContainerConfirmation = styled.div.attrs({
  className: 'StyledContainerConfirmation',
})`
  && {
    ${tw`relative `}
    margin-top: 40px;
  }
`;

export const StyledConfirmationText = styled(Typography).attrs({
  className: 'StyledConfirmationText',
})`
  && {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;

    color: #494b4d;
  }
`;
export const StyledFieldsContainer = styled.div.attrs({
  className: 'StyledFieldsContainer',
})`
  && {
    ${tw`relative flex flex-wrap pt-[32px]`}
    // max-width: 860px;
    gap: 20px 40px;
    & > * {
      flex: 0 1 calc(50% - 20px);
    }
    @media (max-width: 767.98px) {
      padding-top: 20px;
      flex-direction: column;
    }
  }
`;

export const StyledFieldsColumnContainer = styled.div.attrs({
  className: 'StyledFieldsColumnContainer',
})`
  && {
    ${tw`relative flex flex-col pt-[32px]`}
    max-width: 100%;

    @media (max-width: 768px) {
      padding-top: 20px;
    }
  }
`;

export const StyledField = styled.div.attrs({
  className: 'StyledField',
})`
  && {
    ${tw`relative flex flex-col`}
  }
`;

export const StyledFieldRow = styled.div.attrs({
  className: 'StyledFieldRow',
})`
  && {
    ${tw`relative flex`}
    align-items: center;
  }
`;

export const StyledFieldRowBottom = styled.div.attrs({
  className: 'StyledFieldRowBottom',
})`
  && {
    ${tw`relative flex`}
    margin-top: 22px;
    align-items: center;
    justify-content: space-between;
  }
`;

export const StyledDocumentsFieldRowBottom = styled.div.attrs({
  className: 'StyledDocumentsFieldRowBottom',
})`
  && {
    ${tw`relative flex`}
    margin-top: 60px;
    align-items: center;
    justify-content: space-between;
  }
`;
export const StyledRowButtons = styled.div.attrs({
  className: 'StyledRowButtons',
})`
  && {
    ${tw`relative flex`}
    column-gap: 16px;
  }
`;

export const StyledRowPhoneIndicative = styled.div.attrs({
  className: 'StyledRowPhoneIndicative',
})`
  && {
    ${tw`relative`}
    width: 78px;
    max-width: 78px;
    margin-right: 16px;
    @media (max-width: 768px) {
      width: 78px;
      max-width: 78px;
    }
  }
`;

export const StyledRowPhoneNumber = styled.div.attrs({
  className: 'StyledRowPhoneNumber',
})`
  && {
    ${tw`relative`}
    height: 100%;
    width: 100%;
    max-width: 268px;
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
`;
export const StyledButtonSaveAndContinue = styled(Button).attrs({
  className: 'StyledButtonSaveAndContinue',
})`
  && {
    ${tw`relative`}

    width: 250px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 130px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;

export const StyledButtonContinue = styled(Button).attrs({
  className: 'StyledButtonContinue',
})`
  && {
    ${tw`relative`}

    width: 250px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 130px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;
export const StyledTermContainer = styled.div.attrs({
  className: 'StyledTermContainer',
})`
  && {
    ${tw`relative my-4 `}
    @media (max-width: 768px) {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
`;

export const StyledLabel = styled.label.attrs({
  className: 'StyledLabel',
})`
  && {
    ${tw`relative my-4 grid place-content-center`}
  }
`;

export const StyledSignInRow = styled.div.attrs({
  className: 'StyledSignInRow',
})`
  && {
    ${tw`relative flex justify-center mt-[36px]`}

`;
export const StyledSubTitle = styled(Typography).attrs({
  className: 'StyledSubTitle',
})`
  && {
    margin: 6px 0;
    margin-top: 70px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.017em;
    color: #494b4d;
    @media (max-width: 768px) {
      margin-top: 0px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;

export const StyledDescription = styled(Typography).attrs({
  className: 'StyledDescription',
})`
  && {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #6d7074;
    @media (max-width: 768px) {
      color: #494b4d;
    }
  }
`;
