import useModels from '../../../models';

const useApplicationForm = () => {
  const { useSelectors } = useModels();
  const { useSelector, useApplicationFormSelectors } = useSelectors();
  const { applicationFormSelector } = useApplicationFormSelectors();
  const { currentStep } = useSelector(applicationFormSelector);

  return {
    currentStep,
  };
};

export default useApplicationForm;
