import useApi from '../../../api';
import useModels from '../../../models';

const useWizard = () => {
  // Api
  const { useActions } = useApi();
  const { dispatch, useApplicationFormActions } = useActions();
  const { actSetCurrentStep } = useApplicationFormActions();

  const { useSelectors } = useModels();
  const { useSelector, useApplicationFormSelectors } = useSelectors();
  const { applicationFormSelector } = useApplicationFormSelectors();
  const { currentStep, statusSecondStep, statusFinalStep } = useSelector(
    applicationFormSelector
  );

  const handleApplicationFormStep = (event) => {
    const nextStep = parseInt(event.target.value);
    dispatch(actSetCurrentStep({ step: nextStep }));
  };

  return {
    currentStep,
    statusSecondStep,
    statusFinalStep,
    handleApplicationFormStep,
  };
};

export default useWizard;
