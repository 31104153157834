import React from 'react';

const iconSuccess = ({ color = '#FFFFFF', size = 1 }) => {
  return (
    <svg
      width={(size * 64).toString()}
      height={(size * 53).toString()}
      viewBox="0 0 64 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.8996 1.16317C61.527 -0.293044 59.1334 -0.397942 57.6488 0.938867L21.4907 33.4765C21.0649 33.8581 20.4262 33.8827 19.9803 33.5267L10.7313 26.1696C9.69126 25.3422 8.45272 24.9053 7.14871 24.9053C5.40008 24.9053 3.75029 25.7066 2.62099 27.1036L1.41868 28.5916C-0.67081 31.1771 -0.425365 34.9773 1.97778 37.2431L16.5011 50.9339C17.5918 51.9623 18.9994 52.5286 20.4652 52.5286C20.4657 52.5286 20.4657 52.5286 20.4657 52.5286C22.1296 52.5286 23.7269 51.7898 24.8479 50.5022L63.0046 6.66227C64.3714 5.0911 64.3251 2.67573 62.8996 1.16317Z"
        fill={color}
      />
    </svg>
  );
};

export default iconSuccess;
