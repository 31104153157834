import { trackPromise } from 'react-promise-tracker';
import useProviders from '../../providers';

const useCommentsServices = () => {
  const { useCommentsProviders } = useProviders();
  const { getApplicationComments, sendComment, sendCommentReply } =
    useCommentsProviders();

  const getApplicationCommentsService = (
    applicationId,
    withFrontComments,
    withReplies
  ) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(
            getApplicationComments(
              applicationId,
              withFrontComments,
              withReplies
            ),
            'getApplicationComments'
          )
        );
      } catch (e) {
        reject(e);
      }
    });
  };
  const sendCommentService = (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(sendComment(data)));
      } catch (e) {
        reject(e);
      }
    });
  };
  const sendCommentReplyService = (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(sendCommentReply(data)));
      } catch (e) {
        reject(e);
      }
    });
  };

  return {
    getApplicationCommentsService,
    sendCommentService,
    sendCommentReplyService,
  };
};

export default useCommentsServices;
