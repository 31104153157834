import { lazy } from 'react';
import ActivityIndicator from './ActivityIndicator';
import Header from './Header';
import Footer from './Footer';
import Button from './Buttons';
import LoggedHeader from './LoggedHeader';
import Toast from './Toast';
import SideMenuMobile from './SideMenuMobile';
import SideMenuBackuser from './SideMenuBackuser';
import TycInfo from './TycInfo';
import Copyright from './Copyright';
import AvatarField from './AvatarField';
import AvatarControlled from './AvatarControlled';
import TooltipField from './TooltipField';
import Wizard from './Wizard';
import WizardDynamic from './WizardDynamic';

const TextField = lazy(() => import('./TextField'));
const SelectField = lazy(() => import('./SelectField'));
const SelectFieldControlled = lazy(() => import('./SelectFieldControlled'));
const RadioGroupField = lazy(() => import('./RadioGroupField'));
const RadioGroupFieldControlled = lazy(() =>
  import('./RadioGroupFieldControlled')
);
const TextFieldControlled = lazy(() => import('./TextFieldControlled'));
const PasswordField = lazy(() => import('./PasswordField'));
const PassWordFieldControlled = lazy(() => import('./PasswordFieldControlled'));
const CheckboxControlled = lazy(() => import('./CheckboxControlled'));
const Checkbox = lazy(() => import('./Checkbox'));
const DocumentFieldControlled = lazy(() => import('./DocumentFieldControlled'));
const InputFileFieldControlled = lazy(() =>
  import('./InputFileFieldControlled')
);
const InputFileField = lazy(() => import('./InputFileField'));

const Typography = lazy(() => import('./Typography'));
const Card = lazy(() => import('./Card'));
const ContainerImageShape = lazy(() => import('./ContainerImageShape'));
const SocialMedia = lazy(() => import('./SocialMedia'));
const ContactInfo = lazy(() => import('./ContactInfo'));
const Table = lazy(() => import('./Table'));
const Modal = lazy(() => import('./Modal'));
const Wrapper = lazy(() => import('./Wrapper'));
const ApplicationConfirmation = lazy(() => import('./ApplicationConfirmation'));
const ApplicationDocuments = lazy(() => import('./ApplicationDocuments'));
const ApplicationInformation = lazy(() => import('./ApplicationInformation'));
const Accordion = lazy(() => import('./Accordion'));
const StatusVisualization = lazy(() => import('./StatusVisualization'));
const Filter = lazy(() => import('./Filter'));
const DateFieldControlled = lazy(() => import('./DateFieldControlled'));
const DateField = lazy(() => import('./DateField'));
const TextArea = lazy(() => import('./TextArea'));
const TextAreaControlled = lazy(() => import('./TextAreaControlled'));
const Switch = lazy(() => import('./Switch'));
const SwitchControlled = lazy(() => import('./SwitchControlled'));
const ApplicationReports = lazy(() => import('./ApplicationReports'));
const ReportsSubmitted = lazy(() => import('./ReportsSubmitted'));
const ReportsApproval = lazy(() => import('./ReportsApproval'));
const CircularProgress = lazy(() => import('./CircularProgress'));
const PhoneInputField = lazy(() => import('./PhoneInputField'));
const PhoneInputFieldControlled = lazy(() =>
  import('./PhoneInputFieldControlled')
);
const PopUpNotifications = lazy(() => import('./PopUpNotifications'));
const FilterUser = lazy(() => import('./FilterUser'));
const ApplicationVoting = lazy(() => import('./ApplicationVoting'));
const Timer = lazy(() => import('./Timer'));
const InternalComments = lazy(() => import('./InternalComments'));

const RichTextEditor = lazy(() => import('./RichTextEditor'));
const RichTextEditorControlled = lazy(() =>
  import('./RichTextEditorControlled')
);
const FilterBlogs = lazy(() => import('./FilterBlogs'));
const CardBlogPreview = lazy(() => import('./CardBlogPreview'));
const Pagination = lazy(() => import('./Pagination'));
const ApplicationTrackingInformation = lazy(() =>
  import('./ApplicationTrackingInformation')
);

const useComponents = () => {
  return {
    ApplicationTrackingInformation,
    AvatarField,
    FilterBlogs,
    ApplicationVoting,
    AvatarControlled,
    ActivityIndicator,
    Button,
    Typography,
    Header,
    LoggedHeader,
    Footer,
    Card,
    ContainerImageShape,
    SocialMedia,
    TycInfo,
    ContactInfo,
    Copyright,
    TextField,
    TextFieldControlled,
    PasswordField,
    PassWordFieldControlled,
    Checkbox,
    CheckboxControlled,
    Modal,
    Toast,
    SelectField,
    SelectFieldControlled,
    DocumentFieldControlled,
    Wrapper,
    SideMenuMobile,
    SideMenuBackuser,
    RadioGroupField,
    RadioGroupFieldControlled,
    TooltipField,
    InputFileField,
    InputFileFieldControlled,
    Wizard,
    ApplicationConfirmation,
    ApplicationDocuments,
    ApplicationInformation,
    Table,
    Accordion,
    StatusVisualization,
    Filter,
    DateFieldControlled,
    DateField,
    TextArea,
    TextAreaControlled,
    Switch,
    SwitchControlled,
    WizardDynamic,
    ApplicationReports,
    ReportsSubmitted,
    ReportsApproval,
    CircularProgress,
    PhoneInputFieldControlled,
    PhoneInputField,
    PopUpNotifications,
    FilterUser,
    Timer,
    InternalComments,
    RichTextEditor,
    RichTextEditorControlled,
    CardBlogPreview,
    Pagination,
  };
};

export default useComponents;
