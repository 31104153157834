import { useEffect, useState } from 'react';
import useApi from '../../../api';
import useHelpers from '../../../helpers';
import useModels from '../../../models';
import useComponentHooks from '../../componentHooks';
import _ from 'lodash';

const useLogin = () => {
  // Api
  const { useActions } = useApi();
  const { dispatch, useAuthActions, useUserActions, useNotificationsActions } =
    useActions();
  const { actLogin } = useAuthActions();
  const { actGetUserProfile, actGetUserRoles } = useUserActions();
  const { actGetLatestNotifications, actSetNewNotification } =
    useNotificationsActions();
  const { useQuickFunctions } = useHelpers();
  const { useNavigation } = useQuickFunctions();
  const { replaceAndNavigateTo, navigateTo } = useNavigation();

  const { useSelectors } = useModels();
  const { useSelector, useAuthSelectors, useUserSelectors } = useSelectors();
  const { userSelector } = useUserSelectors();
  const { session } = useAuthSelectors();
  const { profile } = useSelector(userSelector);
  const { accessToken } = useSelector(session);

  const { useModal } = useComponentHooks();
  const { modalState, handleShowModal } = useModal();
  const {
    modalState: modalStateAdvise,
    handleShowModal: handleShowModalAdvise,
  } = useModal();
  const [loginState, setLoginState] = useState('');
  const [isMobileState, setIsMobileState] = useState(false);

  useEffect(() => {
    handleResolutionDevice();
  }, []);

  useEffect(() => {
    if (profile.roleType?.roleType && accessToken) {
      profile.lastLogout && handleGetNotifications(profile.lastLogout);
      if (
        profile.roleType.roleType === 'frontUser' &&
        profile.applicationsCount > 0
      ) {
        // front user with applications
        handleRedirectApplications();
      } else if (profile.roleType?.roleType !== 'frontUser') {
        // back user
        isMobileState && !modalStateAdvise.show
          ? handleShowModalAdvise()
          : !isMobileState && handleRedirectApplications();
      } else {
        // front user without applciations
        isMobileState
          ? handleRedirectProfile()
          : handleRedirectNewApplication();
      }
    }
  }, [accessToken, loginState, profile.email]);

  const handleResolutionDevice = () => {
    const widthDevice = window.innerWidth;
    widthDevice <= 768 && setIsMobileState(true);
  };
  const handleRedirectNewApplication = () => {
    replaceAndNavigateTo('/application-form');
  };

  const handleRedirectApplications = () => {
    replaceAndNavigateTo('/applications');
  };
  const handleCloseModalSucces = () => {
    handleShowModalAdvise();
    handleRedirectApplications();
  };
  const handleRedirectProfile = () => {
    replaceAndNavigateTo('/profile');
  };

  const handleRedirectRegister = () => {
    navigateTo('/signup');
  };

  const handleRedirectRecoveryPassword = () => {
    navigateTo('/recovery-password');
  };

  const handleCloseModal = () => {
    handleShowModal();
  };

  const handleNotificationTime = (notificationDate, lastLogout) => {
    const currentNotificationDate = new Date(notificationDate);
    const lastLogoutDate = new Date(lastLogout);
    const currentDate = new Date();
    const notificationDifference = new Date(
      currentDate - currentNotificationDate
    );

    const lastLogoutDifference = new Date(currentDate - lastLogoutDate);
    const notificationMinutes = notificationDifference.getTime() / 1000 / 60;

    const lastLogoutMinutes = lastLogoutDifference.getTime() / 1000 / 60;

    if (lastLogoutMinutes > notificationMinutes) {
      dispatch(actSetNewNotification(true));
    }
  };

  const handleNewNotificationOffline = (data) => {
    const lastLogout = profile.lastLogout;
    const notificationsArray = data.notifications;
    _.map(notificationsArray, (currentNotification) => {
      handleNotificationTime(currentNotification.notificatedAt, lastLogout);
    });
  };

  const handleGetNotifications = () => {
    dispatch(actGetLatestNotifications(handleNewNotificationOffline));
  };

  const handleSuccessLogin = (data) => {
    dispatch(actGetUserRoles());
    dispatch(actGetUserProfile(data));
    setLoginState('success');
  };

  const onSubmit = (data) => {
    dispatch(actLogin(data, handleSuccessLogin));
  };

  return {
    modalState,
    loginState,
    handleRedirectNewApplication,
    handleRedirectApplications,
    handleRedirectRegister,
    handleRedirectRecoveryPassword,
    onSubmit,
    handleCloseModal,
    modalStateAdvise,
    handleCloseModalSucces,
  };
};

export default useLogin;
