const votingTypes = () => {
  const SAVE_VOTE_TIME = 'SAVE_VOTE_TIME';
  const GET_VOTE_TIME = 'GET_VOTE_TIME';
  const VOTE_APPLICATION = 'VOTE_APPLICATION';
  const REVERT_APPLICATION = 'REVERT_APPLICATION';
  const SET_STOPWATCH_STATE = 'SET_STOPWATCH_STATE';
  const SET_STOPWATCH_CURRENT_TIME = 'SET_STOPWATCH_CURRENT_TIME';
  const GET_CURRENTS_VOTES = 'GET_CURRENTS_VOTES';

  return {
    SAVE_VOTE_TIME,
    GET_VOTE_TIME,
    VOTE_APPLICATION,
    REVERT_APPLICATION,
    SET_STOPWATCH_STATE,
    SET_STOPWATCH_CURRENT_TIME,
    GET_CURRENTS_VOTES,
  };
};
export default votingTypes;
