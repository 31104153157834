import React, { Suspense } from 'react';
import useAssets from '../../../assets';
import useComponents from '../../components';
import {
  StyledSectionHome,
  StyledContainerArticle,
  StyledArticle,
  StyledCarousel,
  StyledContainerImage,
  StyledInfo,
  StyledArticleTitle,
  StyledArticleTitleMobile,
  StyledArticleParagraph,
  StyledArticleCallToAction,
  StyledArticleButton,
  StyledButtonsContainer,
  StyledImageShape,
  StyledButtonBlogContainer,
  StyledViewMoreButton,
} from './Home.styles';
import useLayouts from '../../layouts';
import { Box } from '@mui/system';
import useControllers from '../../../controllers';
import temporalImage from '../../../assets/images/temporalImage.jpeg';
import useHelpers from '../../../helpers';

const Home = () => {
  // Layout
  const { PublicContentLayout } = useLayouts();
  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { usePromises } = useQuickFunctions();
  const { promiseInProgress } = usePromises();

  // Components & Skeleton
  const { Button, Card, ActivityIndicator, Typography } = useComponents();
  const { useIcons } = useAssets();
  const { iconBlogs } = useIcons();

  //Hooks
  const { useScreenHooks } = useControllers();
  const { useHome } = useScreenHooks();
  const {
    dataBlogsHomepage,
    handleGoToBlogApplyNow,
    handleGetBlog,
    gotToSignUp,
    gotToLogin,
    handleGoToBlogs,
  } = useHome();

  return (
    <PublicContentLayout background="primary">
      {promiseInProgress && <ActivityIndicator />}
      <Suspense fallback={<ActivityIndicator />}>
        <StyledSectionHome>
          <StyledContainerArticle>
            <StyledArticle>
              <StyledArticleTitleMobile color="secondary" variant="h2">
                APPLY FOR A{' '}
                <Box component="b" sx={{ color: '#5E5C5C' }}>
                  LICENSE
                </Box>{' '}
                ONLINE
              </StyledArticleTitleMobile>
              <StyledArticleTitle color="secondary" variant="h1">
                APPLY FOR A{' '}
                <Box component="b" sx={{ color: '#5E5C5C' }}>
                  LICENSE
                </Box>{' '}
                ONLINE
              </StyledArticleTitle>
              <StyledArticleParagraph
                className="StyledTypographyBook"
                color="neutral"
                variant="body2"
              >
                Create and verify your account to apply for a license online.
              </StyledArticleParagraph>
              <StyledArticleCallToAction color="primary" onClick={gotToSignUp}>
                Learn more
              </StyledArticleCallToAction>
              <StyledArticleButton
                onClick={handleGoToBlogApplyNow}
                color={'primary'}
                fullWidth={false}
              >
                Apply Now
              </StyledArticleButton>
            </StyledArticle>
            <StyledContainerImage>
              <StyledImageShape
                scale={4.88}
                imageUrl={temporalImage}
                xOffset="-30px"
                isHome={true}
              />
            </StyledContainerImage>
          </StyledContainerArticle>
          <StyledCarousel>
            {dataBlogsHomepage[0]?.title && (
              <Card
                icon={iconBlogs}
                title={`${dataBlogsHomepage[0].title}`}
                scale={1}
                callToAction="Learn more"
                onClick={() => {
                  handleGetBlog(dataBlogsHomepage[0].id);
                }}
              />
            )}
            {dataBlogsHomepage[1]?.title && (
              <Card
                icon={iconBlogs}
                title={`${dataBlogsHomepage[1].title}`}
                scale={1}
                callToAction="Learn more"
                onClick={() => {
                  handleGetBlog(dataBlogsHomepage[1].id);
                }}
              />
            )}
          </StyledCarousel>
          <StyledButtonBlogContainer>
            <StyledViewMoreButton
              onClick={handleGoToBlogs}
              color={'secondary'}
              fullWidth={false}
            >
              View more
            </StyledViewMoreButton>
          </StyledButtonBlogContainer>
          <StyledButtonsContainer>
            <Button
              variant={'outlined'}
              color={'primary'}
              fullWidth={false}
              sx={{ minWidth: '114px', maxHeight: '44px' }}
              onClick={gotToLogin}
            >
              Login
            </Button>
            <Button
              color={'primary'}
              fullWidth={false}
              sx={{ minWidth: '114px', maxHeight: '44px' }}
              onClick={gotToSignUp}
            >
              Register
            </Button>
          </StyledButtonsContainer>
          <StyledInfo>
            <Typography
              className="StyledTypographyBook"
              color={'neutral-90'}
              variant="body2"
              sx={{
                padding: '30px 0',
                fontSize: '14px',
                lineHeight: '20px',
                letterSpacing: '-0.006em',
              }}
            >
              The board is committed to promoting{' '}
              <Box component="b">responsible consumption of liquor</Box> and
              reducing the socio-economic problem emanating from alcohol abuse
              in province.
            </Typography>
          </StyledInfo>
        </StyledSectionHome>
      </Suspense>
    </PublicContentLayout>
  );
};

export default Home;
