const useVotingInitialStates = () => {
  const votingInitialState = {
    votingTime: 0,
    time: 0,
    state: false,
    currentTime: 0,
    alreadyVoted: 0,
    hasVestedInterest: '',
    hasVestedType: '',
    hasVestedTypeOther: '',
  };

  const votesInitialState = {
    votes: [],
  };
  return {
    votingInitialState,
    votesInitialState,
  };
};

export default useVotingInitialStates;
