import styled from 'styled-components';
import tw from 'twin.macro';
import TextFieldControlled from '../../components/TextFieldControlled';
import Typography from '../../components/Typography';

export const StyledSectionRenewals = styled.div.attrs({
  className: 'StyledSectionRenewals',
})`
&& {
    ${tw`relative  mt-[32px] pb-[24px] mx-[10px]`}
    width: calc(100% - 20px);
    box-sizing:content-box;
    @media (max-width: 768px){
      width: calc(100% - 32px);
      margin: 0 16px;
      padding-top: 16px;
      padding-bottom: 24px;
    }
    }}
    
`;

export const StyledTitleRenewals = styled.div.attrs({
  className: 'StyledTitleRenewals',
})`
&& {
    ${tw`relative flex`}
    column-gap: 8px;
    align-items: center;
    }}
    
`;

export const StyledTitleRenewalsTypography = styled(Typography).attrs({
  className: 'StyledTitleRenewalsTypography',
})`
  && {
    ${tw`relative`}
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.021em;
    color: #d66127;
  }
`;
export const StyledTotalRenewals = styled.div.attrs({
  className: 'StyledTotalRenewals StyledTypographyBook',
})`
  && {
    ${tw`relative`}

    height: 21px;
    padding: 2px 8px;
    background: #5e5c5c;
    border-radius: 32px;

    font-size: 12px;
    line-height: 17px;
    color: #ffffff;
  }
`;

export const StyledContainerSearch = styled.div.attrs({
  className: 'StyledContainerSearch',
})`
  && {
    ${tw`absolute`}
    top: -78px;
    right: 0;
    width: 288px;
    max-width: 288px;
    z-index: 6;
    @media (max-width: 860px) and (min-width: 768px) {
      width: 220px;
      max-width: 220px;
    }
    @media (max-width: 767.9px) {
      ${tw`relative  `}
      margin: 0;
      top: 0px;
      left: 100%;
      transform: translate(calc(-100% - 75px), 0);
      width: 192px;
      max-width: 192px;
    }
  }
`;

export const StyledTextFieldControlledSearch = styled(
  TextFieldControlled
).attrs({
  className: 'StyledTextFieldControlledSearch',
})`
  && {
    @media (max-width: 768px) {
      ${tw`relative  `}
      .MuiOutlinedInput-root {
        min-width: 192px;
      }
    }
  }
`;

export const StyledButtonSearch = styled.div.attrs({
  className: 'StyledButtonSearch',
})`
  && {
    ${tw`absolute  `}
    top: 0;
    left: 100%;
    transform: translate(-100%, 32px);
    display: grid;
    place-content: center;
    width: 44px;
    height: 44px;
    padding: 0;
    cursor: pointer;
  }
`;

export const StyledButtonSearchDisable = styled.div.attrs({
  className: 'StyledButtonSearchDisable',
})`
  && {
    ${tw`absolute  `}
    top: 0;
    left: 100%;
    transform: translate(-100%, 32px);
    display: grid;
    place-content: center;
    width: 44px;
    height: 44px;
    padding: 0;
  }
`;

export const StyledCotnainerTable = styled.div.attrs({
  className: 'StyledCotnainerTable',
})`
  && {
    ${tw`relative  `}
    display: block;
    width: 100%;
    height: 590px;
    margin-top: 36px;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
