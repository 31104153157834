import { useEffect, useState } from 'react';
import useComponentHooks from '..';
import useApi from '../../../api';
import useHelpers from '../../../helpers';
import useModels from '../../../models';
import _ from 'lodash';
const useApplicationInformation = (isValid, setValue, watch) => {
  // Api
  const { useActions } = useApi();
  const { dispatch, useApplicationFormActions, useApplicationsTableActions } =
    useActions();
  const {
    actSetCurrentStep,
    actSaveUserApplication,
    actSubmitApplicationFormStep1,
    actEnableSecondStep,
    actCancelApplication,
    actUpdateApplication,
  } = useApplicationFormActions();
  const { actGetApplicationsTable } = useApplicationsTableActions();
  const { useModal } = useComponentHooks();
  const { modalState, handleShowModal } = useModal();
  const {
    modalState: modalStateCancel,
    handleShowModal: handleShowModalCancel,
  } = useModal();
  const {
    modalState: modalStateWarningRightOfOcupation,
    handleShowModal: handleShowModalWarningRightOfOcupation,
  } = useModal();
  const {
    modalState: modalStateWarningBuildingErected,
    handleShowModal: handleShowModalWarningBuildingErected,
  } = useModal();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation, usePromises } = useQuickFunctions();
  const { navigateTo, goBack } = useNavigation();
  const { promiseInProgressArea: promiseSaveUserApplication } = usePromises(
    'saveUserApplication'
  );
  const { promiseInProgressArea: promiseUpdateUserApplication } = usePromises(
    'updateUserApplication'
  );
  const { promiseInProgressArea: promiseCancelApplication } =
    usePromises('cancelApplication');
  const { promiseInProgressArea: promiseGetApplicationImages } = usePromises(
    'getApplicationFiles'
  );

  const { useSelectors } = useModels();
  const { useSelector, useUserSelectors, useApplicationFormSelectors } =
    useSelectors();
  const { userSelector } = useUserSelectors();
  const { profile } = useSelector(userSelector);
  const { applicationFormSelector, applicationFormHelpDataSelector } =
    useApplicationFormSelectors();

  const { currentStep, applicationForm } = useSelector(applicationFormSelector);
  const {
    phoneIndicators: phoneIndicatorChoices,
    licenceTypes: licenceTypeChoices,
    contactMethods: contactMethodChoices,
    municipalities: municipalitiesChoices,
    aditionsCompletedBy: aditionsCompletedByChoices,
  } = useSelector(applicationFormHelpDataSelector);

  const [watchValuesState, setwatchValuesState] = useState({
    oultetAddressSameAsResidence: false,
    rightOfOcuppation: '0',
    buildingNotErectedYet: '0',
    erectedRequireAdditions: '0',
  });

  // console.warn({ watchValuesState });
  const onChangeInputFieldPhone = (
    inputPhone,
    country,
    id,
    fieldIndicativeId
  ) => {
    const fieldId = id.slice(0, id.length - 'Visual'.length);
    const countryCode = country.countryCode.toUpperCase();
    const indicativeId = _.find(
      phoneIndicatorChoices,
      (currentIndicative) => currentIndicative.countryCode === countryCode
    ).id;
    setValue(fieldId, inputPhone, { shouldValidate: true });
    setValue(fieldIndicativeId, indicativeId);
  };
  const handleIndicative = (choice, phone) => {
    const indicativeCode = choice.phoneIndicator
      ? choice.phoneIndicator.replace(/\D/g, '')
      : '27';
    const finalPhone = indicativeCode + phone;
    return finalPhone;
  };
  const defaultIndicative = (choices) => {
    return _.find(
      choices,
      (currentIndicative) => currentIndicative.phoneIndicator === '+27'
    );
  };
  const yesNoType = [
    { value: '1', text: 'Yes' },
    { value: '0', text: 'No' },
  ];

  const watchOultetAddressSameAsResidence = watch(
    'oultetAddressSameAsResidence'
  );
  const watchRightOfOcuppation = watch('rightOfOcuppation');
  const watchBuildingNotErectedYet = watch('buildingNotErectedYet');
  const watchErectedRequireAdditions = watch('erectedRequireAdditions');

  useEffect(() => {
    setwatchValuesState({
      oultetAddressSameAsResidence: watchOultetAddressSameAsResidence,
      rightOfOcuppation: watchRightOfOcuppation,
      buildingNotErectedYet: watchBuildingNotErectedYet,
      erectedRequireAdditions: watchErectedRequireAdditions,
    });
  }, [
    watchOultetAddressSameAsResidence,
    watchRightOfOcuppation,
    watchBuildingNotErectedYet,
    watchErectedRequireAdditions,
  ]);

  useEffect(() => {
    watchRightOfOcuppation === '0' && handleMessageRightOfOcupation();
  }, [watchRightOfOcuppation]);
  useEffect(() => {
    watchBuildingNotErectedYet === '1' && handleMessageBuildingErected();
  }, [watchBuildingNotErectedYet]);

  useEffect(() => {
    dispatch(actEnableSecondStep({ status: isValid }));
  }, [isValid]);

  const handleApplicationFormStep = (nextStep) => {
    dispatch(actSetCurrentStep({ step: nextStep }));
  };

  const handleCancelApplication = () => {
    handleShowModalCancel();
  };
  const handleRequestApplication = () => {
    dispatch(actGetApplicationsTable({ page: 1 }, navigateTo(`/applications`)));
    handleShowModalCancel();
  };
  const handleCloseModalCancel = () => {
    applicationForm.status?.id !== 7 &&
      dispatch(
        actCancelApplication(
          {
            id: applicationForm.id ? applicationForm.id : '',
          },
          handleRequestApplication
        )
      );
  };

  const handleCloseModalWarningRightOfOcupation = () => {
    handleShowModalWarningRightOfOcupation();
  };

  const handleCloseModalWarningBuildingErected = () => {
    handleShowModalWarningBuildingErected();
  };

  const handleMessageRightOfOcupation = () => {
    handleShowModalWarningRightOfOcupation(
      'The Applicant cannot proceed with this Application',
      'Right of occupation is mandatory.'
    );
  };

  const handleMessageBuildingErected = () => {
    handleShowModalWarningBuildingErected(
      'The Applicant cannot proceed with this Application',
      'The Building must have been erected.'
    );
  };

  const handleCloseModalSuccesHome = () => {
    handleShowModal();
    navigateTo(`/home`);
  };

  const handleCloseModalSucces = () => {
    handleShowModal();
    navigateTo(`/applications`);
  };

  const handleSuccessSave = () => {
    handleShowModal(
      'Your application was saved!',
      'To view your saved application, click on the  "my applications"  at the top of the Homepage.'
    );
  };

  const handleSaveUserApplication = (data) => {
    const draftApplicationData = { ...applicationForm, ...data };
    dispatch(actSaveUserApplication(draftApplicationData, handleSuccessSave));
  };

  const handleUpdateUserApplication = (data) => {
    if (data.oultetAddressSameAsResidence) {
      data.oultetAddressSameAsResidence = '1';
      data.outletAddress = '';
    } else {
      data.oultetAddressSameAsResidence = '0';
    }
    const draftApplicationData = { ...applicationForm, ...data };
    dispatch(actUpdateApplication(draftApplicationData, handleSuccessSave));
  };

  const onSubmit = (data) => {
    delete data.phoneVisual;
    delete data.phoneAlternativeVisual;
    dispatch(actSubmitApplicationFormStep1(data, handleApplicationFormStep(2)));
  };

  const onSubmitDraft = (data) => {
    delete data.phoneVisual;
    delete data.phoneAlternativeVisual;

    applicationForm.status = 1;

    if (data.oultetAddressSameAsResidence) {
      data.oultetAddressSameAsResidence = '1';
      data.outletAddress = '';
    } else {
      data.oultetAddressSameAsResidence = '0';
    }
    if (applicationForm.id) {
      dispatch(
        actSubmitApplicationFormStep1(data, handleUpdateUserApplication)
      );
    } else {
      dispatch(actSubmitApplicationFormStep1(data, handleSaveUserApplication));
    }
  };

  return {
    promiseSaveUserApplication,
    promiseUpdateUserApplication,
    promiseCancelApplication,
    promiseGetApplicationImages,

    goBack,
    onSubmit,
    onSubmitDraft,
    handleCancelApplication,
    applicationForm,
    // applicationInfo,
    profile,
    phoneIndicatorChoices,
    licenceTypeChoices,
    yesNoType,
    contactMethodChoices,
    handleApplicationFormStep,
    currentStep,
    modalState,
    modalStateCancel,
    handleCloseModalSucces,
    handleCloseModalSuccesHome,
    handleCloseModalCancel,
    handleShowModalCancel,
    defaultIndicative,
    onChangeInputFieldPhone,
    handleIndicative,
    municipalitiesChoices,
    aditionsCompletedByChoices,
    watchValuesState,
    modalStateWarningRightOfOcupation,
    modalStateWarningBuildingErected,
    handleCloseModalWarningRightOfOcupation,
    handleCloseModalWarningBuildingErected,
  };
};

export default useApplicationInformation;
