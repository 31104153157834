import React, { Suspense } from 'react';
import useAssets from '../../../assets';
import useControllers from '../../../controllers';
import ProfileNavBar from '../ProfileNavBar';
import PropTypes from 'prop-types';

import {
  StyledLoggedHeader,
  StyledLoggedNavBar,
  StyledLogoContainer,
  StyledLogoMobileContainer,
  StyledHamMenuContainer,
  StyledContainerLinks,
  StyledContainerSection,
  StyledSectionHeader,
  StyledActiveLink,
  StyledNotificationContainerBackuser,
} from './LoggedHeader.styles';
import useComponents from '..';

const LoggedHeader = (props) => {
  const { openSideMenu } = props;
  const { PopUpNotifications, ActivityIndicator } = useComponents();

  const { useIcons } = useAssets();
  const {
    iconLogo: IconLogo,
    iconLogoMobile: IconLogoMobile,
    iconHamMenu: IconHamMenu,
  } = useIcons();

  //Hooks
  const { useComponentHooks } = useControllers();
  const { useLoggedHeader } = useComponentHooks();
  const {
    gotToHome,
    gotToMyApplications,
    goToRenewals,
    accessToken,
    handleShowNewApplication,
    handleShowMyApplications,
    handleRedirectNewApplication,
    profile,
    handleShowRenewals,
  } = useLoggedHeader();
  return (
    <Suspense fallback={<ActivityIndicator />}>
      <StyledLoggedHeader>
        <StyledContainerLinks>
          <StyledLogoContainer
            onClick={() => {
              profile.roleType.roleType !== 'frontUser'
                ? gotToMyApplications()
                : gotToHome();
            }}
          >
            <IconLogo />
          </StyledLogoContainer>

          {profile.roleType?.roleType === 'frontUser' && (
            <>
              <StyledContainerSection onClick={handleRedirectNewApplication}>
                {handleShowNewApplication() && <StyledActiveLink />}
                <StyledSectionHeader
                  color={handleShowNewApplication() ? 'secondary' : 'primary'}
                >
                  NEW APPLICATION
                </StyledSectionHeader>
              </StyledContainerSection>
              <StyledContainerSection onClick={gotToMyApplications}>
                {handleShowMyApplications() && <StyledActiveLink />}
                <StyledSectionHeader
                  color={handleShowMyApplications() ? 'secondary' : 'primary'}
                >
                  MY APPLICATIONS
                </StyledSectionHeader>
              </StyledContainerSection>
              <StyledContainerSection onClick={goToRenewals}>
                {handleShowRenewals() && <StyledActiveLink />}
                <StyledSectionHeader
                  color={handleShowRenewals() ? 'secondary' : 'primary'}
                >
                  RENEWALS
                </StyledSectionHeader>
              </StyledContainerSection>
            </>
          )}
        </StyledContainerLinks>
        {profile.roleType?.roleType !== 'frontUser' && (
          <StyledNotificationContainerBackuser>
            <PopUpNotifications />
          </StyledNotificationContainerBackuser>
        )}
        {accessToken && (
          <StyledHamMenuContainer onClick={openSideMenu}>
            <IconHamMenu />
          </StyledHamMenuContainer>
        )}
        <StyledLogoMobileContainer onClick={gotToHome}>
          <IconLogoMobile />
        </StyledLogoMobileContainer>
        <StyledLoggedNavBar>
          <ProfileNavBar />
        </StyledLoggedNavBar>
      </StyledLoggedHeader>
    </Suspense>
  );
};

LoggedHeader.propTypes = {
  openSideMenu: PropTypes.func,
};

LoggedHeader.defaultProps = {
  className: '',
  color: 'secondary',
  openSideMenu: () => {},
};
export default LoggedHeader;
