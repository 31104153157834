import React from 'react';
import PropTypes from 'prop-types';
import { StyledItem, StyledSocialMedia } from './SocialMedia.styles';
import useAssets from '../../../assets';
import useControllers from '../../../controllers';

const SocialMedia = (props) => {
  const { className, color } = props;
  const { useIcons } = useAssets();
  const {
    iconFacebook: IconFacebook,
    // iconInstagram: IconInstagram,
    // iconTwitter: IconTwitter,
    // iconWhatsApp: IconWhatsApp,
  } = useIcons();

  const { useComponentHooks } = useControllers();
  const { useSocialMedia } = useComponentHooks();
  const {
    goToFacebook,
    // goToInstagram,
    // goToTwitter,
    // goToWhatsapp,
    handleRedirectNewPage,
  } = useSocialMedia();

  return (
    <StyledSocialMedia className={className}>
      {goToFacebook && (
        <StyledItem onClick={() => handleRedirectNewPage(goToFacebook)}>
          <IconFacebook color={color} />
        </StyledItem>
      )}
      {/* {goToInstagram && (
        <StyledItem onClick={() => handleRedirectNewPage(goToInstagram)}>
          <IconInstagram />
        </StyledItem>
      )}
      {goToTwitter && (
        <StyledItem onClick={() => handleRedirectNewPage(goToTwitter)}>
          <IconTwitter />
        </StyledItem>
      )}
      {goToWhatsapp && (
        <StyledItem onClick={() => handleRedirectNewPage(goToWhatsapp)}>
          <IconWhatsApp />
        </StyledItem>
      )} */}
    </StyledSocialMedia>
  );
};

SocialMedia.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

SocialMedia.defaultProps = {
  className: '',
  color: '#D66127',
};

export default SocialMedia;
