import { trackPromise } from 'react-promise-tracker';
import useProviders from '../../providers';

const usePrivateDocumentsServices = () => {
  const { usePrivateDocumentsProviders } = useProviders();
  const { getPrivateDocument, deleteReport, getPrivateCommentDocument } =
    usePrivateDocumentsProviders();

  const getPrivateDocumentService = (documentName, applicationId) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(getPrivateDocument(documentName, applicationId))
        );
      } catch (e) {
        reject(e);
      }
    });
  };
  const getPrivateCommentDocumentService = (documentName, commentId) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(
          await trackPromise(
            getPrivateCommentDocument(documentName, commentId),
            'getPrivateCommentDocument'
          )
        );
      } catch (e) {
        reject(e);
      }
    });
  };
  const deleteReportService = (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(deleteReport(data)));
      } catch (e) {
        reject(e);
      }
    });
  };
  return {
    getPrivateDocumentService,
    getPrivateCommentDocumentService,
    deleteReportService,
  };
};

export default usePrivateDocumentsServices;
