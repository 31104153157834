import React from 'react';

const iconAt = ({ color = '#FFFFFF', size = 1 }) => {
  return (
    <svg
      width={(size * 20).toString()}
      height={(size * 20).toString()}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99992 0.249146C8.25665 0.248985 6.54522 0.716219 5.04387 1.60219C3.54252 2.48815 2.30616 3.76045 1.46356 5.28656C0.620968 6.81268 0.202952 8.53679 0.253051 10.2793C0.30315 12.0219 0.819532 13.7191 1.74842 15.1943C2.6773 16.6695 3.98471 17.8687 5.53448 18.6669C7.08426 19.4652 8.81971 19.8333 10.5601 19.733C12.3005 19.6327 13.9821 19.0676 15.4299 18.0965C16.8777 17.1255 18.0387 15.7841 18.7919 14.2119C18.2587 14.4095 17.6935 14.5069 17.1249 14.4991C15.4105 14.4991 14.4508 13.7487 13.9476 13.1191C13.8304 12.972 13.7245 12.8163 13.6309 12.6532C13.0948 13.3877 12.3487 13.9426 11.491 14.2447C10.6333 14.5468 9.70413 14.582 8.826 14.3457C7.94788 14.1094 7.16195 13.6125 6.57175 12.9207C5.98155 12.2289 5.61478 11.3745 5.51974 10.4701C5.4247 9.56571 5.60587 8.65374 6.03936 7.85433C6.47285 7.05493 7.13834 6.4056 7.94816 5.99188C8.75797 5.57816 9.67412 5.41947 10.5759 5.5367C11.4777 5.65393 12.3228 6.04159 12.9999 6.64861V6.24915C12.9999 6.05023 13.0789 5.85947 13.2196 5.71881C13.3602 5.57816 13.551 5.49915 13.7499 5.49915C13.9488 5.49915 14.1396 5.57816 14.2803 5.71881C14.4209 5.85947 14.4999 6.05023 14.4999 6.24915V9.99915C14.4999 11.3678 14.9553 12.9991 17.1249 12.9991C19.2198 12.9991 19.7164 11.4782 19.7481 10.1414C19.7488 10.094 19.7499 10.0467 19.7499 9.99915C19.747 7.41418 18.7188 4.93593 16.891 3.10809C15.0631 1.28024 12.5849 0.252071 9.99992 0.249146Z"
        fill={color}
      />
    </svg>
  );
};

export default iconAt;
