import styled from 'styled-components';
import tw from 'twin.macro';
export const StyledTycInfo = styled.div.attrs({
  className: 'StyledTycInfo',
})`
  && {
    ${tw`flex flex-row`}
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    column-gap: 16px !important;
    @media (max-width: 768px) {
      align-items: flex-start;
      width: 100%;
    }
  }
`;
