import useHelpers from '../../../helpers';

const useTycInfo = () => {
  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation } = useQuickFunctions();
  const { navigateTo } = useNavigation();

  // Blogid fixed from backebd
  const handleRedirectToTC = () => {
    navigateTo(`/blog-view?id=${25}`);
  };

  const handleRedirectToPP = () => {
    navigateTo(`/blog-view?id=${26}`);
  };
  return {
    handleRedirectToTC,
    handleRedirectToPP,
  };
};

export default useTycInfo;
