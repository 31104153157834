import React, { Suspense } from 'react';
import useComponents from '../../components';
import useLayouts from '../../layouts';
import stamp from '../../../assets/images/stamp.png';

import {
  StyledSectionApplication,
  StyledWizardDynamic,
  StyledTitleAccordion,
  StyledAccordionCurrentVotesContainer,
  StyledVotesContainer,
  StyledCurrentVotesContainer,
  StyledVoteContainer,
  StyledVoteIconContainer,
  StyledRowVoteLabel,
  StyledCurrentVotesInnerContainer,
  StyledRowVoteInfoContainer,
  StyledRowVoteInfo,
  StyledRowVoteInfoDescription,
  StyledFieldDate,
  StyledFieldRowWithComments,
  StyledFieldRowTitle,
  // StyledTitleSectionDeny,
  StyledFieldRowDate,
  StyledFieldRowReference,
  StyledContainerComments,
  StyledFieldColumn,
  StyledFieldColumnTitle,
  StyledFieldColumnValue,
  StyledFieldRowBottom,
  StyledRowButtons,
  StyledRowButtonsVoting,
  StyledButtonRevertApplication,
  StyledButtonComment,
  StyledButtonDeny,
  StyledButtonDenyLastApprove,
  StyledButtonApprovePayment,
  StyledButtonLastApprove,
  StyledButtonApprove,
  StyledTitleModal,
  StyledSubtitleModal,
  StyledChildContainerModal,
  StyledTitleModalLastApprove,
  StyledChildContainerModalLastApprove,
  StyledContainerAdviseIconModalLastApprove,
  StyledSubtitleModalLastApprove,
  StyledButtonModal,
  StyledTextAreaField,
  StyledSwitchField,
  StyledButtonCommentVoting,
  StyledButtonApproveVoting,
  StyledFieldRowBottomContainer,
  StyledFieldsContainerLastApprove,
  StyledFieldsContainerFeeInnerContainer,
  StyledFieldsBorder,
  StyledFieldsContainerMultiple,
  StyledDocumentsReportAccordion,
  StyledTitleDocumentReportContainerAccordion,
  StyledTitleReportAccordion,
  StyledContainerIconViewAccordion,
  StyledContainerDownloadAccordion,
  StyledTextFieldControlledAmount,
  StyledContainerStamp,
  StyledStamp,
  StyledTextFieldControlledDisabled,
  StyledButtonDownloadLicense,
  StyledContainerIconDownloadTop,
  StyledContainerDownload,
  StyledApplicationRevertedContainer,
  StyledAdviceIconContainer,
  StyledApplicationRevertedLabel,
  StyledInputFileFieldControlled,
  // StyledTitleInspectorReport,
  StyledRowVoteTypeOfInterest,
} from './ApplicationReview.styles';
import useControllers from '../../../controllers';
import useAssets from '../../../assets';
import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import useHelpers from '../../../helpers';

const ApplicationReview = () => {
  const {
    ActivityIndicator,
    Wrapper,
    Accordion,
    Modal,
    ApplicationReports,
    ReportsApproval,
    ApplicationVoting,
    InternalComments,
    ContainerImageShape,
    ApplicationTrackingInformation,
  } = useComponents();
  const { useIcons } = useAssets();
  const {
    iconDownload: IconDownload,
    iconOpenedEye: IconOpenedEye,
    iconSuccess: IconSuccess,
    iconClose: IconClose,
    iconWarning: IconWarning,
  } = useIcons();
  const { PrivateContentLayout } = useLayouts();
  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators } = useQuickFunctions();
  const {
    commentsValidator,
    applicationFeeApproveValidator,
    boardmemberVotingSchemaValidator,
  } = useValidators();

  // Hooks
  const { useScreenHooks } = useControllers();
  const { useApplicationReview } = useScreenHooks();

  // Yup validator RenewalForm
  const {
    control: controlPaymentFee,
    getValues: getValuesPaymentFee,
    setValue: setValuePaymentFee,
    formState: {
      errors: errorsPaymentFee,
      isValid: isValidPaymentFee,
      // isDirty: isDirtyPaymentFee,
    },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(applicationFeeApproveValidator),
  });
  // Yup validator
  const {
    control: controlComments,
    getValues: getValuesComment,
    handleSubmit: handleSubmitComment,
    setValue: setValueComments,
    resetField: resetFiedComments,
    // watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(commentsValidator),
  });
  const {
    control: controlBoardMemberVoting,
    watch: watchBoardMemberVoting,
    setValue: setValueMemberVoting,
    resetField: resetFiedMemberVoting,
    formState: {
      errors: errorsBoardMemberVoting,
      isValid: isValidBoardMemberVoting,
      // isDirty: isDirtyPaymentFee,
    },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(boardmemberVotingSchemaValidator),
  });
  const {
    promiseInProgress,
    promiseGetApplicationFiles,
    promiseGetApplicationImages,
    stateStopWatch,
    applicationForm,
    profile,
    licenceTypes,
    handleRedirectBack,
    date,
    onSubmitComments,
    modalStateComments,
    handleShowModalComments,
    sendCommentsSuccessState,
    helperModalStateSuccess,
    handleCloseHelperModalSuccess,
    currentStepWizard,
    applicationReviewWizard,
    stepsEnabledWizzard,
    handleApplicationReviewStep,
    onSubmitApprove,
    handleStepTitle,
    handleGetPrivateDocumentView,
    onSubmitVoteApplicationApprove,
    onSubmitVoteApplicationDeny,
    handleCloseModalComments,
    onSubmitApplicationFeeApprove,
    handleDate,
    onRejectApplication,
    onApplicationLastApprove,
    lastApproveState,
    lastApproveModalState,
    handleShowModalApproveApplication,
    handleShowModalRejectApplication,
    handleCloseModalApproveApplication,
    votes,
    handleTimeExpend,
    onSubmitVoteRevertApplication,
    alreadyVoted,
    canRepeatVote,
    onChangeFilePdf,
    fileTypesPdf,
    typeOfInterest,
  } = useApplicationReview(
    setValueComments,
    setValuePaymentFee,
    watchBoardMemberVoting,
    setValueMemberVoting,
    resetFiedMemberVoting
  );

  return (
    <PrivateContentLayout background="tertiary">
      {promiseInProgress &&
        !promiseGetApplicationFiles &&
        !promiseGetApplicationImages && <ActivityIndicator />}
      <Suspense fallback={<ActivityIndicator />}>
        <StyledSectionApplication>
          <Wrapper
            title={handleStepTitle()}
            maxWidth={'1200px'}
            isReview={
              applicationForm.status.id !== 9 && applicationForm.status.id !== 8
            }
            withBackButton={true}
            onClickBackButton={handleRedirectBack}
          >
            {/* For download license document when licnese issued*/}
            {applicationForm.status.id === 8 &&
              applicationForm.licenseDocument && (
                <StyledButtonDownloadLicense
                  fullWidth={false}
                  color="secondary"
                >
                  <StyledContainerIconDownloadTop>
                    <IconDownload color={'#FFFFFF'} />
                  </StyledContainerIconDownloadTop>
                  <StyledContainerDownload
                    download={'License Document'}
                    href={applicationForm.licenseDocument.data}
                    title="Download License"
                  >
                    Download PDF
                  </StyledContainerDownload>
                </StyledButtonDownloadLicense>
              )}
            {/* principal application info */}
            <StyledFieldRowWithComments>
              <StyledFieldRowTitle>REF.</StyledFieldRowTitle>
              <StyledFieldRowReference variant="h3">
                {applicationForm.licenseNumber
                  ? applicationForm.licenseNumber
                  : applicationForm.reference}
              </StyledFieldRowReference>
              <StyledContainerComments>
                <InternalComments />
              </StyledContainerComments>
            </StyledFieldRowWithComments>
            <StyledFieldDate>
              <StyledFieldRowTitle>DATE.</StyledFieldRowTitle>
              <StyledFieldRowDate>{date}</StyledFieldRowDate>
            </StyledFieldDate>
            {/* Upload of application reports */}
            {currentStepWizard === 2 && (
              <ApplicationReports
                handleShowModalComments={handleShowModalComments}
              />
            )}
            {/* Approval of application reports by secretary */}
            {currentStepWizard === 3 && <ReportsApproval />}
            {/* Voting of application by Boardmembers */}
            {currentStepWizard === 4 && profile.roleType.id === 1 && (
              <ApplicationVoting
                control={controlBoardMemberVoting}
                errors={errorsBoardMemberVoting}
                watch={watchBoardMemberVoting}
              />
            )}
            {/* Final application payment info for back and fron users */}
            {currentStepWizard > 4 && applicationForm.status.id !== 9 && (
              <StyledFieldsContainerLastApprove>
                <StyledFieldsContainerFeeInnerContainer>
                  <StyledFieldsContainerMultiple>
                    <StyledFieldColumn>
                      <StyledFieldColumnTitle>
                        Type of license
                      </StyledFieldColumnTitle>
                      <StyledFieldColumnValue>
                        {applicationForm.licenceType &&
                          licenceTypes[applicationForm.licenceType - 1].name}
                      </StyledFieldColumnValue>
                    </StyledFieldColumn>

                    <StyledFieldColumn>
                      <StyledFieldColumnTitle>
                        Licensed to sell
                      </StyledFieldColumnTitle>
                      <StyledFieldColumnValue>
                        {applicationForm.licenseToSell
                          ? applicationForm.licenseToSell
                          : 'None'}
                      </StyledFieldColumnValue>
                    </StyledFieldColumn>

                    <StyledFieldColumn>
                      <StyledFieldColumnTitle>
                        Date of issue
                      </StyledFieldColumnTitle>
                      <StyledFieldColumnValue>
                        {applicationForm.issueDate
                          ? handleDate(applicationForm.issueDate)
                          : 'None'}
                      </StyledFieldColumnValue>
                    </StyledFieldColumn>
                  </StyledFieldsContainerMultiple>

                  <StyledFieldsContainerMultiple>
                    <StyledFieldColumn>
                      <StyledFieldColumnTitle>
                        Prescribed fees
                      </StyledFieldColumnTitle>
                      <StyledFieldColumnValue>
                        {`R ${
                          applicationForm.prescribedFees ||
                          applicationForm.prescribedFees === 0
                            ? applicationForm.prescribedFees
                            : 'None'
                        }`}
                      </StyledFieldColumnValue>
                    </StyledFieldColumn>
                    <StyledFieldColumn>
                      <StyledFieldColumnTitle>
                        Payable on or before
                      </StyledFieldColumnTitle>
                      <StyledFieldColumnValue>
                        {applicationForm.payableBefore
                          ? handleDate(applicationForm.payableBefore)
                          : 'None'}
                      </StyledFieldColumnValue>
                    </StyledFieldColumn>
                    <StyledFieldColumn>
                      <StyledFieldColumnTitle>
                        Place of issue
                      </StyledFieldColumnTitle>
                      <StyledFieldColumnValue>Kimberley</StyledFieldColumnValue>
                    </StyledFieldColumn>
                  </StyledFieldsContainerMultiple>
                  <StyledFieldsBorder />
                  {(applicationForm.status.id > 10 ||
                    applicationForm.status.id === 8) &&
                    applicationForm.applicationFeeProofOfPayment.data && (
                      <StyledDocumentsReportAccordion
                        disabled={true}
                        disabledIcon
                        title={
                          <StyledTitleDocumentReportContainerAccordion>
                            <StyledTitleReportAccordion variant="body1">
                              Proof of Payment
                            </StyledTitleReportAccordion>
                            <StyledContainerIconViewAccordion
                              onClick={() =>
                                handleGetPrivateDocumentView(
                                  applicationForm.applicationFeeProofOfPayment
                                    .name
                                )
                              }
                            >
                              <IconOpenedEye size={1.5} />
                            </StyledContainerIconViewAccordion>
                            <StyledContainerDownloadAccordion
                              download={'Proof of Payment'}
                              href={
                                applicationForm.applicationFeeProofOfPayment
                                  .data
                              }
                              title="Download pdf document"
                            >
                              <IconDownload size={1} />
                            </StyledContainerDownloadAccordion>
                          </StyledTitleDocumentReportContainerAccordion>
                        }
                      />
                    )}
                  {(applicationForm.status.id === 8 ||
                    applicationForm.status.id === 11) && (
                    <StyledFieldsContainerMultiple>
                      <StyledFieldColumn>
                        <StyledFieldColumnTitle>
                          Amount received
                        </StyledFieldColumnTitle>
                        <StyledFieldColumnValue>
                          {`R ${
                            applicationForm.amountPaid ||
                            applicationForm.amountPaid === 0
                              ? applicationForm.amountPaid
                              : 'None'
                          }`}
                        </StyledFieldColumnValue>
                      </StyledFieldColumn>
                      <StyledFieldColumn>
                        <StyledFieldColumnTitle>
                          Receipt No.
                        </StyledFieldColumnTitle>
                        <StyledFieldColumnValue>
                          {applicationForm.receiptNumber
                            ? applicationForm.receiptNumber
                            : 'None'}
                        </StyledFieldColumnValue>
                      </StyledFieldColumn>
                      {applicationForm.status.id === 8 && (
                        <StyledFieldColumn>
                          <StyledFieldColumnTitle>
                            Office Date Stamp
                          </StyledFieldColumnTitle>
                          <StyledFieldColumnValue>
                            {applicationForm.officeDateStamp
                              ? handleDate(applicationForm.officeDateStamp)
                              : 'None'}
                          </StyledFieldColumnValue>
                        </StyledFieldColumn>
                      )}

                      {/* last approve */}
                      {applicationForm.status.id === 11 &&
                        profile.roleType.id === 5 && (
                          <StyledRowButtons>
                            <StyledButtonDenyLastApprove
                              fullWidth={false}
                              color="secondary"
                              onClick={handleShowModalComments}
                            >
                              Deny
                            </StyledButtonDenyLastApprove>

                            <StyledButtonLastApprove
                              fullWidth={false}
                              color="primary"
                              onClick={handleShowModalApproveApplication}
                            >
                              Approve
                            </StyledButtonLastApprove>
                          </StyledRowButtons>
                        )}
                    </StyledFieldsContainerMultiple>
                  )}
                </StyledFieldsContainerFeeInnerContainer>
                {applicationForm.status.id === 8 && (
                  <StyledContainerStamp>
                    <StyledStamp src={stamp} />
                  </StyledContainerStamp>
                )}
              </StyledFieldsContainerLastApprove>
            )}
            {/* Approve of final application payment by manager */}
            {currentStepWizard === 5 &&
              applicationForm.status.id === 12 &&
              (profile.roleType.id === 2 || profile.roleType.id === 6) && (
                <StyledFieldRowBottomContainer>
                  <StyledTextFieldControlledDisabled
                    id={'prescribedFees'}
                    placeholder={'R'}
                    label={'Prescribed Fees'}
                    name={'prescribedFees'}
                    variant={'outlined'}
                    fullWidth={true}
                    control={controlPaymentFee}
                    error={!!errorsPaymentFee.prescribedFees}
                    helperText={errorsPaymentFee?.prescribedFees?.message}
                  />
                  <StyledTextFieldControlledAmount
                    id={'amountReceived'}
                    placeholder={'R'}
                    label={'Amount received'}
                    name={'amountReceived'}
                    variant={'outlined'}
                    fullWidth={true}
                    control={controlPaymentFee}
                    error={!!errorsPaymentFee.amountReceived}
                    helperText={errorsPaymentFee?.amountReceived?.message}
                  />
                  <StyledTextFieldControlledAmount
                    id={'receiptNumber'}
                    placeholder={'Receipt No.'}
                    label={'Receipt No.'}
                    name={'receiptNumber'}
                    variant={'outlined'}
                    fullWidth={true}
                    control={controlPaymentFee}
                    error={!!errorsPaymentFee.receiptNumber}
                    helperText={errorsPaymentFee?.receiptNumber?.message}
                  />
                  <StyledRowButtons>
                    <StyledButtonDeny
                      fullWidth={false}
                      color="secondary"
                      onClick={handleShowModalComments}
                    >
                      Return Back to Applicant
                    </StyledButtonDeny>

                    <StyledButtonApprovePayment
                      disabled={!isValidPaymentFee}
                      fullWidth={false}
                      color="primary"
                      onClick={() =>
                        onSubmitApplicationFeeApprove(getValuesPaymentFee())
                      }
                    >
                      Send for CEO Signature
                    </StyledButtonApprovePayment>
                  </StyledRowButtons>
                </StyledFieldRowBottomContainer>
              )}
            {/* Current votes, only show to CEO */}
            {profile.roleType.id === 5 && votes?.length !== 0 && (
              <StyledAccordionCurrentVotesContainer>
                <Accordion
                  title={
                    <StyledTitleAccordion variant="h4">
                      Application votes
                    </StyledTitleAccordion>
                  }
                >
                  <StyledVotesContainer>
                    {_.map(votes, (currentVote) => {
                      return (
                        <StyledCurrentVotesContainer>
                          <StyledVoteContainer>
                            <StyledVoteIconContainer>
                              {currentVote.approved ? (
                                <IconSuccess color={'#5E5C5C'} size={0.4} />
                              ) : (
                                <IconClose color={'#FF2D55'} size={0.7} />
                              )}
                            </StyledVoteIconContainer>
                            <StyledRowVoteLabel>
                              {currentVote.approved ? 'Approve' : 'Deny'}
                            </StyledRowVoteLabel>
                          </StyledVoteContainer>
                          <StyledCurrentVotesInnerContainer>
                            <StyledRowVoteInfoContainer>
                              <StyledRowVoteInfo>
                                Vote made by:
                              </StyledRowVoteInfo>
                              <StyledRowVoteInfoDescription>
                                {`${currentVote.user?.name} ${currentVote.user?.surname}`}
                              </StyledRowVoteInfoDescription>
                            </StyledRowVoteInfoContainer>
                            <StyledRowVoteInfoContainer>
                              <StyledRowVoteInfo>Vote date:</StyledRowVoteInfo>
                              <StyledRowVoteInfoDescription>
                                {`${handleDate(currentVote.voteDate)} `}
                              </StyledRowVoteInfoDescription>
                            </StyledRowVoteInfoContainer>
                            <StyledRowVoteInfoContainer>
                              <StyledRowVoteInfo>Vote time:</StyledRowVoteInfo>
                              <StyledRowVoteInfoDescription>
                                {`${handleTimeExpend(currentVote.votingTime)}`}
                              </StyledRowVoteInfoDescription>
                            </StyledRowVoteInfoContainer>
                            <StyledRowVoteInfoContainer>
                              <StyledRowVoteInfo>
                                Had a vested interest:
                              </StyledRowVoteInfo>
                              <StyledRowVoteInfoDescription>
                                {`${
                                  currentVote.hasVestedInterest ? 'Yes' : 'No'
                                }`}
                              </StyledRowVoteInfoDescription>
                            </StyledRowVoteInfoContainer>
                            {currentVote.hasVestedInterest === 1 && (
                              <>
                                {currentVote.hasVestedType?.id !== 4 ? (
                                  <StyledRowVoteInfoContainer>
                                    <StyledRowVoteInfo>
                                      Type of interest:
                                    </StyledRowVoteInfo>
                                    <StyledRowVoteInfoDescription>
                                      {
                                        typeOfInterest[
                                          currentVote.hasVestedType?.id - 1
                                        ]?.text
                                      }
                                    </StyledRowVoteInfoDescription>
                                  </StyledRowVoteInfoContainer>
                                ) : (
                                  <StyledRowVoteInfoContainer>
                                    <StyledRowVoteInfoDescription>
                                      <StyledRowVoteTypeOfInterest>
                                        Type of interest:
                                      </StyledRowVoteTypeOfInterest>{' '}
                                      {currentVote.hasVestedTypeOther}
                                    </StyledRowVoteInfoDescription>
                                  </StyledRowVoteInfoContainer>
                                )}
                              </>
                            )}
                          </StyledCurrentVotesInnerContainer>
                        </StyledCurrentVotesContainer>
                      );
                    })}
                  </StyledVotesContainer>
                </Accordion>
              </StyledAccordionCurrentVotesContainer>
            )}
            {applicationForm.status.id === 14 && (
              <StyledApplicationRevertedContainer>
                <StyledAdviceIconContainer>
                  <IconWarning size={0.8} />
                </StyledAdviceIconContainer>
                <StyledApplicationRevertedLabel>
                  {profile.roleType.id === 2 ||
                  profile.roleType.id === 5 ||
                  profile.roleType.id === 6
                    ? 'Application reverted by Board member. Please reply to the last objection comment'
                    : 'Application reverted by Board member.'}
                </StyledApplicationRevertedLabel>
              </StyledApplicationRevertedContainer>
            )}

            {/* Tracking information */}
            <ApplicationTrackingInformation />

            {/* First approval by manager */}
            {currentStepWizard === 1 &&
              applicationForm.status.id !== 14 &&
              (profile.roleType.id === 2 || profile.roleType.id === 6) && (
                <StyledFieldRowBottom>
                  <StyledRowButtons>
                    <StyledButtonComment
                      fullWidth={false}
                      color="secondary"
                      onClick={handleShowModalComments}
                      // onClick={() => {
                      //   onSubmitDraft(getValues());
                      // }}
                    >
                      Return Back to Applicant
                    </StyledButtonComment>
                    {(applicationForm.status.id === 2 ||
                      applicationForm.status.id === 3) && (
                      <StyledButtonApprove
                        disabled={
                          !(
                            profile.roleType.id === 2 ||
                            profile.roleType.id === 6
                          )
                        }
                        fullWidth={false}
                        color="primary"
                        onClick={onSubmitApprove}
                      >
                        Move to the next Phase
                      </StyledButtonApprove>
                    )}
                  </StyledRowButtons>
                </StyledFieldRowBottom>
              )}
            {/* Buttons for vote application by Boradmember */}
            {currentStepWizard === 4 &&
              profile.roleType.id === 1 &&
              applicationForm.status.id < 8 &&
              (!(alreadyVoted === 1) || canRepeatVote === 1) && (
                <StyledFieldRowBottom>
                  <StyledRowButtonsVoting>
                    <StyledButtonRevertApplication
                      disabled={
                        !(
                          profile.roleType.id === 1 &&
                          stateStopWatch &&
                          isValidBoardMemberVoting
                        )
                      }
                      fullWidth={false}
                      color="error"
                      onClick={handleCloseModalComments}
                    >
                      Revert Application
                    </StyledButtonRevertApplication>
                    <StyledButtonCommentVoting
                      disabled={
                        !(
                          profile.roleType.id === 1 &&
                          stateStopWatch &&
                          isValidBoardMemberVoting
                        )
                      }
                      fullWidth={false}
                      color="secondary"
                      onClick={onSubmitVoteApplicationDeny}
                      // onClick={() => {
                      //   onSubmitDraft(getValues());
                      // }}
                    >
                      Decline Application
                    </StyledButtonCommentVoting>

                    <StyledButtonApproveVoting
                      disabled={
                        !(
                          profile.roleType.id === 1 &&
                          stateStopWatch &&
                          isValidBoardMemberVoting
                        )
                      }
                      fullWidth={false}
                      color="primary"
                      onClick={onSubmitVoteApplicationApprove}
                    >
                      Approve Application
                    </StyledButtonApproveVoting>
                  </StyledRowButtonsVoting>
                </StyledFieldRowBottom>
              )}
            {/* Wizzard for navigate between steps for the backusers */}
            {applicationForm.status.id !== 9 &&
              applicationForm.status.id !== 8 &&
              applicationForm.status.id !== 14 && (
                <StyledWizardDynamic
                  currentStep={currentStepWizard}
                  wizardStatus={applicationReviewWizard}
                  handleonChangeStep={handleApplicationReviewStep}
                  stepsEnabled={stepsEnabledWizzard}
                />
              )}
          </Wrapper>
        </StyledSectionApplication>
        {/* Modal of comments */}
        <Modal
          color={'#5E5C5C'}
          type={'child'}
          showModal={modalStateComments.show}
          onClose={handleCloseModalComments}
        >
          <StyledTitleModal variant={'h2'} color={'success'}>
            Comments
          </StyledTitleModal>

          <StyledSubtitleModal variant={'body1'}>
            About this application
          </StyledSubtitleModal>

          <StyledChildContainerModal>
            <StyledTextAreaField
              id={'textAreaComments'}
              label={
                currentStepWizard !== 4
                  ? 'The application was rejected because:'
                  : 'The application was reverted because:'
              }
              name={'textAreaComments'}
              placeholder={'Comments'}
              control={controlComments}
              error={currentStepWizard !== 6 && !!errors.textAreaComments}
              helperText={
                currentStepWizard !== 6 && errors?.textAreaComments?.message
              }
              multiline
            />
            {/* At last approve and revert application from voting */}
            {currentStepWizard !== 6 && currentStepWizard !== 4 && (
              <StyledSwitchField
                id={'sendToApplicant'}
                label={'Send to applicant'}
                name={'sendToApplicant'}
                inputValue={false}
                control={controlComments}
              />
            )}
            {currentStepWizard === 4 && (
              <StyledInputFileFieldControlled
                id={`document`}
                inputValue={{ name: '', data: '' }}
                label={'Document'}
                name={`document`}
                variant={'outlined'}
                control={controlComments}
                onInputChange={onChangeFilePdf}
                fileTypes={fileTypesPdf}
                resetField={resetFiedComments}
                error={!!errors.document}
                helperText={errors?.document?.message}
              />
            )}
            {currentStepWizard === 6 ? (
              <StyledButtonModal
                onClick={handleShowModalRejectApplication}
                fullWidth={false}
              >
                Reject Application
              </StyledButtonModal>
            ) : currentStepWizard === 4 ? (
              <StyledButtonModal
                disabled={!isValid}
                onClick={handleSubmitComment(onSubmitVoteRevertApplication)}
                fullWidth={false}
              >
                Revert Application
              </StyledButtonModal>
            ) : (
              <StyledButtonModal
                disabled={!isValid}
                onClick={handleSubmitComment(onSubmitComments)}
                fullWidth={false}
              >
                Send Comment
              </StyledButtonModal>
            )}
          </StyledChildContainerModal>
        </Modal>

        {/* Modal when comments are sent */}
        <Modal
          type={sendCommentsSuccessState}
          showModal={helperModalStateSuccess.show}
          onClose={handleCloseHelperModalSuccess}
          title={helperModalStateSuccess.title}
          description={helperModalStateSuccess.description}
        />

        {/* Modal for the final approve or reject application */}
        <Modal
          color={'#FF2D55'}
          type={'child'}
          showModal={lastApproveModalState.show}
          onClose={handleCloseModalApproveApplication}
        >
          <StyledTitleModalLastApprove variant={'h2'} color={'error'}>
            {lastApproveModalState.title}
          </StyledTitleModalLastApprove>

          <StyledChildContainerModalLastApprove>
            <StyledContainerAdviseIconModalLastApprove>
              <ContainerImageShape scale={1.4} type={'error'} />
            </StyledContainerAdviseIconModalLastApprove>
            <StyledSubtitleModalLastApprove variant={'body1'}>
              {lastApproveModalState.description}
            </StyledSubtitleModalLastApprove>
            {lastApproveState === 'reject' && (
              <StyledButtonModal
                onClick={() => onRejectApplication(getValuesComment())}
                fullWidth={false}
                color={'error'}
              >
                YES, REJECT APPLICATION
              </StyledButtonModal>
            )}
            {lastApproveState === 'approve' && (
              <StyledButtonModal
                onClick={onApplicationLastApprove}
                fullWidth={false}
                color={'primary'}
              >
                YES, APPROVE APPLICATION
              </StyledButtonModal>
            )}
          </StyledChildContainerModalLastApprove>
        </Modal>
      </Suspense>
    </PrivateContentLayout>
  );
};

export default ApplicationReview;
