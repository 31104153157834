import React from 'react';

const iconCalendar = ({ color = '#D66127', size = 1 }) => {
  return (
    <svg
      width={(size * 24).toString()}
      height={(size * 26).toString()}
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 1.5H18V0.75C18 0.551088 17.921 0.360322 17.7803 0.21967C17.6397 0.0790178 17.4489 0 17.25 0C17.0511 0 16.8603 0.0790178 16.7197 0.21967C16.579 0.360322 16.5 0.551088 16.5 0.75V1.5H7.5V0.75C7.5 0.551088 7.42098 0.360322 7.28033 0.21967C7.13968 0.0790178 6.94891 0 6.75 0C6.55109 0 6.36032 0.0790178 6.21967 0.21967C6.07902 0.360322 6 0.551088 6 0.75V1.5H4.5C3.30653 1.5 2.16193 1.97411 1.31802 2.81802C0.474106 3.66193 0 4.80653 0 6V21C0 22.1935 0.474106 23.3381 1.31802 24.182C2.16193 25.0259 3.30653 25.5 4.5 25.5H19.5C20.6935 25.5 21.8381 25.0259 22.682 24.182C23.5259 23.3381 24 22.1935 24 21V6C24 4.80653 23.5259 3.66193 22.682 2.81802C21.8381 1.97411 20.6935 1.5 19.5 1.5ZM22.5 21C22.4991 21.7954 22.1827 22.5579 21.6203 23.1203C21.0579 23.6827 20.2954 23.9991 19.5 24H4.5C3.70463 23.9991 2.9421 23.6827 2.37969 23.1203C1.81728 22.5579 1.50091 21.7954 1.5 21V9H22.5V21ZM22.5 7.5H1.5V6C1.50091 5.20463 1.81728 4.4421 2.37969 3.87969C2.9421 3.31728 3.70463 3.00091 4.5 3H6V3.75C6 3.94891 6.07902 4.13968 6.21967 4.28033C6.36032 4.42098 6.55109 4.5 6.75 4.5C6.94891 4.5 7.13968 4.42098 7.28033 4.28033C7.42098 4.13968 7.5 3.94891 7.5 3.75V3H16.5V3.75C16.5 3.94891 16.579 4.13968 16.7197 4.28033C16.8603 4.42098 17.0511 4.5 17.25 4.5C17.4489 4.5 17.6397 4.42098 17.7803 4.28033C17.921 4.13968 18 3.94891 18 3.75V3H19.5C20.2954 3.00091 21.0579 3.31728 21.6203 3.87969C22.1827 4.4421 22.4991 5.20463 22.5 6V7.5Z"
        fill={color}
      />
    </svg>
  );
};
export default iconCalendar;
