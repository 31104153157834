import _ from 'lodash';
import { useEffect } from 'react';
// import useComponentHooks from '..';
import useApi from '../../../api';
import useModels from '../../../models';
import useHelpers from '../../../helpers';

const useTrackingInformation = (setValueInspectionReport) => {
  // // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation, usePromises } = useQuickFunctions();
  const { navigateTo } = useNavigation();
  const { promiseInProgressArea: promiseGetSapsAndMunicipalityReports } =
    usePromises('getSapsAndMunicipalityReports');
  //Api
  const { useActions } = useApi();
  const {
    dispatch,
    usePrivateDocumentsActions,
    useApplicationFormActions,
    useCommentsActions,
  } = useActions();
  const { actGetPrivateDocument } = usePrivateDocumentsActions();
  const { actGetZipDownloadPhotos } = useApplicationFormActions();
  const { actGetApplicationComments } = useCommentsActions();

  //hooks

  //Selectors
  const { useSelectors } = useModels();
  const {
    useSelector,
    useUserSelectors,
    useApplicationFormSelectors,
    useApplicationReviewSelectors,
    useCommentsSelectors,
  } = useSelectors();

  const { userSelector } = useUserSelectors();
  const { commentsSelector } = useCommentsSelectors();
  const { profile, userRolesList } = useSelector(userSelector);
  const { applicationFormSelector, applicationFormHelpDataSelector } =
    useApplicationFormSelectors();
  const { frontComments } = useSelector(commentsSelector);
  const { applicationReviewStateSelector } = useApplicationReviewSelectors();
  const { applicationForm } = useSelector(applicationFormSelector);
  const {
    phoneIndicators,
    municipalities,
    licenceTypes,
    typeOfRegistration,
    landUseRightsDocumentType,
    aditionsCompletedBy,
  } = useSelector(applicationFormHelpDataSelector);

  const {
    currentStep: currentStepWizard,
    sapsAndMunicipalityReports,
    inspectionForm,
  } = useSelector(applicationReviewStateSelector);
  const optionsInspectorForm = [
    { value: '1', text: ' ' },
    { value: '0', text: ' ' },
  ];

  useEffect(() => {
    applicationForm.id &&
      (applicationForm.status.id === 7 ||
        applicationForm.status.id === 9 ||
        applicationForm.status.id === 13) &&
      dispatch(
        actGetApplicationComments({
          applicationId: applicationForm.id,
          withFrontComments: 1,
        })
      );
  }, []);
  // inspection report
  useEffect(() => {
    _.forEach(inspectionForm, (value, key) => {
      setValueInspectionReport && setValueInspectionReport(key, `${value}`);
    });
  }, [inspectionForm]);

  const handleGetPrivateLink = (url) => {
    window.open(url);
  };

  const handleRedirectApplicationForm = () => {
    navigateTo(`/application-form`);
  };

  // Handlers Documents
  const handleGetPrivateDocumentView = (documentName) => {
    dispatch(
      actGetPrivateDocument(
        {
          documentName: documentName,
          applicationId: applicationForm.id,
        },
        handleGetPrivateLink
      )
    );
  };
  // handlers phone
  const handleMunicipality = (municipalityId) => {
    const municipailityFound = _.find(
      municipalities,
      (currentMunicipality) => currentMunicipality.id === municipalityId
    );
    return municipailityFound?.name ? municipailityFound.name : 'None';
  };

  // handlers phone
  const handleIndicative = (phoneIndicatorId) => {
    return _.find(
      phoneIndicators,
      (currentIndicative) => currentIndicative.id === phoneIndicatorId
    ).phoneIndicator;
  };

  // Images
  const handleDownloadPhotos = (id) => {
    dispatch(actGetZipDownloadPhotos(id, onSuccessDownload));
  };

  const onSuccessDownload = (response) => {
    const downloadUrl = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', 'file.zip'); //any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  // role type
  const handleUserRoleType = () => {
    const findRole = _.find(
      userRolesList,
      (userRole) =>
        userRole.id ===
        applicationForm.licensingMemberComment?.commentMadeBy?.roleId
    );
    return findRole?.name ? findRole.name : '';
  };
  return {
    promiseGetSapsAndMunicipalityReports,
    profile,
    applicationForm,
    licenceTypes,
    landUseRightsDocumentType,
    typeOfRegistration,
    aditionsCompletedBy,
    sapsAndMunicipalityReports,
    frontComments,

    currentStepWizard,
    inspectionForm,
    optionsInspectorForm,

    handleRedirectApplicationForm,
    handleGetPrivateDocumentView,
    handleMunicipality,
    handleIndicative,
    handleDownloadPhotos,
    handleUserRoleType,
  };
};

export default useTrackingInformation;
