import React, { useEffect, useState } from 'react';
import useApi from '../../../api';
import useHelpers from '../../../helpers';
import useModels from '../../../models';
import StatusVisualization from '../../../views/components/StatusVisualization';

const useApplicationsTable = () => {
  const { useActions } = useApi();
  const {
    dispatch,
    useApplicationsTableActions,
    useApplicationFormActions,
    useApplicationReviewActions,
    useApplicationRenewalsActions,
  } = useActions();
  const {
    actGetApplicationsTable,
    actGetApplicationById,
    actGetApplicationsStatusHelpList,
  } = useApplicationsTableActions();
  const { actDeleteApplicationRenewal, actGetApplicationRenewal } =
    useApplicationRenewalsActions();
  const {
    actSetApplicationForm,
    actCancelApplication,
    actSetApplicationDate,
    actGetApplicationHelpData,
    actGetApplicationImages,
  } = useApplicationFormActions();
  const { actResetApplicationReview } = useApplicationReviewActions();

  const { useSelectors } = useModels();
  const {
    useSelector,
    useApplicationsTableSelectors,
    useApplicationFormSelectors,
    useUserSelectors,
    useRenewalNoticesSelectors,
  } = useSelectors();
  const { applicationsTableDataSelector } = useApplicationsTableSelectors();
  const { applicationReadySelector } = useApplicationFormSelectors();
  const { applicationReadySelector: applicationReadyRenewalSelector } =
    useRenewalNoticesSelectors();
  const { userSelector } = useUserSelectors();
  const { data, currentPage, lastPage, pageSize, totalRows } = useSelector(
    applicationsTableDataSelector
  );
  const { profile } = useSelector(userSelector);
  const { applicationReady } = useSelector(applicationReadySelector);
  const { applicationReady: applicationReadyRenewal } = useSelector(
    applicationReadyRenewalSelector
  );

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useNavigation, usePromises } = useQuickFunctions();
  const { navigateTo } = useNavigation();
  const { promiseInProgress } = usePromises();
  const { promiseInProgressArea: promiseGetLatestNotifications } = usePromises(
    'getLatestNotifications'
  );
  const { promiseInProgressArea: promiseGetAllNotifications } = usePromises(
    'getAllNotifications'
  );

  const [currentPageTable, setCurrentPageTable] = useState(1);
  const [lastPageTable, setLastPageTable] = useState(1);
  const [rows, setRows] = useState([]);

  const [redirectApplicationForm, setRedirectApplicationForm] = useState(false);
  const [redirectApplication, setRedirectApplication] = useState(false);
  const [redirectApplicationRenewal, setRedirectApplicationRenewal] =
    useState(false);

  const [searchFilter, setSearchFilter] = useState('');
  const [customFilter, setCustomFilter] = useState({
    licenseType: '',
    status: '',
    start: '',
    end: '',
  });

  useEffect(() => {
    dispatch(actGetApplicationHelpData());
    dispatch(actGetApplicationsStatusHelpList());
    dispatch(
      actGetApplicationsTable({
        page: currentPageTable,
        search: searchFilter,
        ...customFilter,
      })
    );
    dispatch(actResetApplicationReview());
    dispatch(actCancelApplication({ id: '' }));
    dispatch(actDeleteApplicationRenewal());
  }, []);
  useEffect(() => {
    dispatch(
      actGetApplicationsTable({
        page: currentPageTable,
        search: searchFilter,
        ...customFilter,
      })
    );
  }, [currentPageTable, profile.roleType.id]);

  useEffect(() => {
    handleTableUpdate();
  }, [currentPage, data, lastPage]);

  //Redirect at application selected
  useEffect(() => {
    if (applicationReady) {
      redirectApplicationForm && handleRedirectApplicationForm();
      redirectApplication && handleRedirectApplication();
    }
    if (applicationReadyRenewal) {
      redirectApplicationRenewal && handleRedirectApplicationRenewal();
    }
  }, [
    applicationReady,
    applicationReadyRenewal,
    redirectApplication,
    redirectApplicationForm,
    redirectApplicationRenewal,
  ]);

  const handleTableUpdate = () => {
    setRows(data);
    setLastPageTable(lastPage);
  };

  const handlePageChange = (e, newPage) => {
    setCurrentPageTable(newPage);
  };

  const handleRedirectApplication = () => {
    profile.roleType.id === 4 && navigateTo(`/application`);
    profile.roleType.id !== 4 && navigateTo(`/application-review`);
  };
  const handleRedirectApplicationRenewal = () => {
    navigateTo(`/renewal-notice`);
  };
  const handleRedirectApplicationForm = () => {
    navigateTo(`/application-form`);
  };

  const handleDateFormat = (dateOld) => {
    const date = new Date(dateOld);
    return `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}/${
      date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }/${date.getFullYear()}`;
  };

  const handleReriderctToForm = (id) => {
    dispatch(actGetApplicationImages(id));
    setRedirectApplicationForm(true);
  };

  const handleReriderctToApplicationReview = (id) => {
    dispatch(actGetApplicationImages(id));
    setRedirectApplication(true);
  };

  const handleGetApplicationSuccess = (data) => {
    // dispatch(actSetApplicationForm({ application: data.data.application }));

    data.application.status.id === 1
      ? dispatch(
          actSetApplicationForm(
            { application: data.application },
            handleReriderctToForm(data.application.id)
          )
        )
      : dispatch(
          actSetApplicationForm(
            { application: data.application },
            handleReriderctToApplicationReview(data.application.id)
          )
        );
  };

  const handleGetApplicationRenewalSuccess = () => {
    setRedirectApplicationRenewal(true);
  };

  const handleGetApplicationById = (data) => {
    if (data.row.applicationTypeId === 1) {
      dispatch(
        actGetApplicationById({ id: data.id }, handleGetApplicationSuccess)
      );
      dispatch(
        actSetApplicationDate({
          date: handleDateFormat(data.row.applicationDate),
        })
      );
    } else {
      dispatch(
        actGetApplicationRenewal(
          { applicationId: data.id },
          handleGetApplicationRenewalSuccess
        )
      );
    }
  };

  const handleGetApplicationByIdMobile = (data) => {
    if (data.row.applicationTypeId === 1) {
      if (data.row.applicationStatusId > 1 && profile.roleType.id === 4) {
        dispatch(
          actGetApplicationById({ id: data.id }, handleGetApplicationSuccess)
        );
        dispatch(
          actSetApplicationDate({
            date: handleDateFormat(data.row.applicationDate),
          })
        );
      }
    } else {
      if (profile.roleType.id === 4) {
        dispatch(
          actGetApplicationRenewal(
            { applicationId: data.id },
            handleGetApplicationRenewalSuccess
          )
        );
      }
    }
  };

  const onSearch = (data) => {
    setSearchFilter(data.searchField);
    dispatch(
      actGetApplicationsTable({
        page: 1,
        search: data.searchField,
        ...customFilter,
      })
    );
    setCurrentPageTable(1);
  };

  const onResetSearch = (data) => {
    setSearchFilter(data);
    dispatch(
      actGetApplicationsTable({
        page: 1,
        search: data,
        ...customFilter,
      })
    );
    setCurrentPageTable(1);
  };
  const onFilter = (data) => {
    const finalFilter = {
      licenseType: data.applicationType !== 'none' ? data.applicationType : '',
      status: data.status !== 'none' ? data.status : '',
      start: data.from,
      end: data.to,
    };
    dispatch(
      actGetApplicationsTable({
        page: 1,
        ...finalFilter,
        ...searchFilter,
      })
    );
    setCustomFilter(finalFilter);
    setCurrentPageTable(1);
  };

  const handleResetSearch = (value) => {
    !value && onResetSearch(value);
  };

  const columns = [
    {
      field: 'id',
      sortable: false,
      hide: true,
    },
    {
      field: 'reference',
      headerName: 'REFERENCE',
      width: 220,
      sortable: false,
      renderCell: (cellValues) => {
        return cellValues.row.licenseNumber
          ? cellValues.row.licenseNumber
          : cellValues.row.reference;
      },
    },
    {
      field: 'propocedOutletName',
      headerName: 'OUTLET NAME',
      width: 250,
      sortable: false,
    },
    {
      field: 'applicationDate',
      headerName: 'APPLICATION DATE',
      width: 220,
      sortable: false,
      renderCell: (cellValues) =>
        handleDateFormat(cellValues.row.applicationDate),
    },
    {
      field: 'applicationType',
      headerName: 'APPLICATION TYPE',
      width: 220,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          cellValues.row.applicationType.name &&
          cellValues.row.applicationType.name
        );
      },
    },
    {
      field: 'applicationStatusId',
      headerName: 'STATUS',
      width: 200,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <StatusVisualization
            applicationTypeId={
              cellValues.row.applicationType.id &&
              cellValues.row.applicationType.id
            }
            idStatus={
              cellValues.row.applicationStatusId &&
              cellValues.row.applicationStatusId
            }
            userId={profile.roleType.id && profile.roleType.id}
          />
        );
      },
    },
  ];

  const columnsMobile = [
    {
      field: 'id',
      sortable: false,
      hide: true,
    },
    {
      field: 'reference',
      headerName: 'REF.',
      width: 145,
      sortable: false,
      renderCell: (cellValues) => {
        return cellValues.row.licenseNumber
          ? cellValues.row.licenseNumber
          : cellValues.row.reference;
      },
    },
    {
      field: 'applicationStatusId',
      headerName: 'STATUS',
      width: 180,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <StatusVisualization
            applicationTypeId={
              cellValues.row.applicationType.id &&
              cellValues.row.applicationType.id
            }
            idStatus={
              cellValues.row.applicationStatusId &&
              cellValues.row.applicationStatusId
            }
            userId={profile.roleType.id && profile.roleType.id}
          />
        );
      },
    },
  ];

  return {
    promiseInProgress,
    promiseGetLatestNotifications,
    promiseGetAllNotifications,

    redirectApplicationForm,
    redirectApplication,
    columns,
    columnsMobile,
    rows,
    pageSize,
    totalRows,
    currentPageTable,
    lastPageTable,
    handlePageChange,
    handleGetApplicationById,
    profile,
    onSearch,
    onFilter,
    handleResetSearch,
    handleGetApplicationByIdMobile,
    setRedirectApplicationRenewal,
  };
};

export default useApplicationsTable;
