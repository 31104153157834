import React from 'react';

const iconWhatsApp = ({ color = '#FFFFFF', size = 1 }) => {
  return (
    <svg
      width={(size * 24).toString()}
      height={(size * 24).toString()}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7639 0C9.72062 0.000562493 7.71271 0.543806 5.93779 1.57626C4.16286 2.60872 2.6821 4.0948 1.64123 5.88824C0.600371 7.68168 0.0352871 9.72067 0.00159975 11.8045C-0.0320876 13.8884 0.466783 15.9452 1.44911 17.7727L0.482337 21.2242C0.393642 21.5407 0.389555 21.8757 0.4705 22.1944C0.551446 22.5131 0.714478 22.8039 0.9427 23.0367C1.17092 23.2695 1.45603 23.4358 1.76847 23.5184C2.08092 23.601 2.40933 23.5968 2.71967 23.5064L6.10359 22.5203C7.67614 23.4004 9.42517 23.9021 11.217 23.9871C13.0088 24.072 14.7959 23.7379 16.4417 23.0103C18.0874 22.2827 19.5483 21.1809 20.7126 19.7891C21.8769 18.3973 22.7138 16.7523 23.1592 14.9801C23.6047 13.2078 23.6469 11.355 23.2827 9.56355C22.9185 7.77206 22.1575 6.08923 21.0579 4.64372C19.9584 3.19822 18.5493 2.02832 16.9384 1.22347C15.3276 0.418615 13.5576 0.000127998 11.7639 0ZM14.4927 19.3833C14.4894 19.386 14.4832 19.3833 14.4785 19.3833C13.1701 19.3833 11.8746 19.1202 10.666 18.609C9.4574 18.0979 8.35947 17.3487 7.43497 16.4044C6.51048 15.4601 5.77757 14.3391 5.27817 13.1056C4.77877 11.8721 4.52267 10.5503 4.52452 9.21582C4.52864 8.11684 4.95944 7.06428 5.72262 6.28852C6.48579 5.51276 7.51919 5.07698 8.59663 5.07657C8.89352 5.07695 9.18507 5.15718 9.44186 5.30916C9.69866 5.46115 9.91162 5.67951 10.0593 5.94224L11.3817 8.30295C11.541 8.58772 11.6232 8.9106 11.6197 9.23845C11.6163 9.5663 11.5274 9.88733 11.3622 10.1686L10.5579 11.536C10.9557 12.2395 11.5283 12.8235 12.218 13.2293L13.5585 12.4088C13.8343 12.2403 14.149 12.1497 14.4704 12.1462C14.7919 12.1427 15.1084 12.2264 15.3876 12.3889L17.7019 13.7378C17.9595 13.8884 18.1736 14.1056 18.3226 14.3675C18.4717 14.6295 18.5503 14.9269 18.5507 15.2297C18.5503 16.3287 18.123 17.3827 17.3625 18.1611C16.602 18.9396 15.5701 19.379 14.4927 19.3833Z"
        fill={color}
      />
    </svg>
  );
};

export default iconWhatsApp;
