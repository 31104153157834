import styled from 'styled-components';
import tw from 'twin.macro';
import Button from '../../components/Buttons';
import Typography from '../../components/Typography';
import TextAreaControlled from '../../components/TextAreaControlled';
import SwitchControlled from '../../components/SwitchControlled';
import RadioGroupFieldControlled from '../../components/RadioGroupFieldControlled';
import WizardDynamic from '../../components/WizardDynamic';
import Accordion from '../../components/Accordion';
import TextFieldControlled from '../../components/TextFieldControlled';
import InputFileFieldControlled from '../../components/InputFileFieldControlled';

import { Avatar } from '@mui/material';

export const StyledSectionApplication = styled.div.attrs({
  className: 'StyledSectionMyApplications',
})`
&& {
    ${tw`relative  mt-[88px] px-[50px] mx-[10px] pb-[48px]`}
    min-width: calc(100% - 120px);
    box-sizing:content-box;

    @media (max-width: 768px){
      width: calc(100% - 32px);
      margin: 0 16px;
      padding-top: 16px;
      padding-bottom: 24px;
    }
    }}
    
`;
export const StyledButtonDownloadLicense = styled(Button).attrs({
  className: 'StyledButtonDownloadLicense',
})`
  && {
    ${tw`absolute flex`}
    column-gap: 16px;
    align-items: center;
    width: 195px;
    height: 44px;
    right: 0px;
    top: -32px;

    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    z-index: 7;
    @media (max-width: 767.98px) {
      display: none;
    }
  }
`;
export const StyledContainerIconDownloadTop = styled.a.attrs({
  className: 'StyledContainerIconDownloadTop ',
})`
  && {
    ${tw`absolute`}
    left: 24px;
    display: grid;
    place-content: center;
    cursor: pointer;
  }
`;
export const StyledContainerDownload = styled.a.attrs({
  className: 'StyledContainerDownload ',
})`
  && {
    ${tw`absolute flex`}
    padding-left: 72px;
    width: 100%;
    height: 44px;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
  }
`;
export const StyledWizardDynamic = styled(WizardDynamic).attrs({
  className: 'StyledWizardDynamic',
})`
  && {
    ${tw`absolute`}

    // margin-32px;
    margin-left: 100%;
    transform: translate(calc(-100% + 80px), 0);
    top: -148px;
  }
`;

export const StyledAccordionContainer = styled.div.attrs({
  className: 'StyledAccordionContainer',
})`
  && {
    ${tw`relative`}
    border: 1px solid #ECEFF3;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;
export const StyledAccordionContainerComments = styled.div.attrs({
  className: 'StyledAccordionContainerComments',
})`
  && {
    ${tw`relative`}
    margin-top: 32px;
    border: 1px solid #eceff3;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;

export const StyledAccordionCurrentVotesContainer = styled.div.attrs({
  className: 'StyledAccordionCurrentVotesContainer',
})`
  && {
    ${tw`relative`}
    margin-top: 32px;
    border: 1px solid #eceff3;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;
export const StyledTitleAccordion = styled(Typography).attrs({
  className: 'StyledTitleAccordion StyledTypographyBold',
})`
  && {
    ${tw`relative`}
    font-size: 14px;
    line-height: 20px;
    letter-spacing: +0.021em;

    color: #d66127;
  }
`;
export const StyledButtonComments = styled(Button).attrs({
  className: 'StyledButtonComments',
})`
  && {
    ${tw`relative`}

    width: 160px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #d66127;
    align-self: center !important;
  }
`;

export const StyledFieldsContainer = styled.div.attrs({
  className: 'StyledFieldsContainer',
})`
  && {
    ${tw`relative flex flex-wrap pb-[32px]`}
    // max-width: 860px;
    gap: 20px 40px;
    & > * {
      flex: 0 1 calc(50% - 20px);
    }
    @media (max-width: 767.98px) {
      padding-top: 20px;
      flex-direction: column;
    }
  }
`;

export const StyledFieldsColumnContainer = styled.div.attrs({
  className: 'StyledFieldsColumnContainer',
})`
  && {
    ${tw`relative flex flex-col pb-[32px]`}
    max-width: 100%;
    row-gap: 28px;

    @media (max-width: 768px) {
      padding-top: 20px;
    }
  }
`;
export const StyledFieldDate = styled.div.attrs({
  className: 'StyledFieldDate',
})`
  && {
    ${tw`relative flex `}
    margin-bottom: 6px;
    align-items: center;
    flex-wrap: no-wrap;
    column-gap: 24px;
  }
`;

export const StyledCommentsContainer = styled.div.attrs({
  className: 'StyledCommentsContainer',
})`
  && {
    ${tw`relative mt-[36px]`}
  }
`;
export const StyledCommentsInnerContainer = styled.div.attrs({
  className: 'StyledCommentsInnerContainer',
})`
  && {
    ${tw`relative flex flex-col`}
    row-gap: 8px;
  }
`;
export const StyledCommentTitle = styled(Typography).attrs({
  className: 'StyledCommentTitle StyledTypographyBold',
})`
  && {
    ${tw`relative flex`}
    flex-wrap: no-wrap;
    column-gap: 8px;
    align-items: center;

    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.011em;
    color: #ff2d55;
  }
`;

export const StyledCommentDescription = styled(Typography).attrs({
  className: 'StyledCommentDescription StyledTypographyBook',
})`
  && {
    ${tw`relative`}
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #000000;
  }
`;

//Votes
export const StyledVotesContainer = styled.div.attrs({
  className: 'StyledVotesContainer',
})`
  && {
    ${tw`relative flex flex-col`}
    row-gap: 24px;
  }
`;

export const StyledCurrentVotesContainer = styled.div.attrs({
  className: 'StyledCurrentVotesContainer',
})`
  && {
    ${tw`relative flex`}
    column-gap: 16px;
    aling-items: center;
  }
`;

export const StyledVoteContainer = styled.div.attrs({
  className: 'StyledVoteContainer',
})`
  && {
    ${tw`relative flex flex-col`}
    min-width: 60px;
    justify-content: center;
    align-items: center;
    row-gap: 4px;
  }
`;
export const StyledVoteIconContainer = styled.div.attrs({
  className: 'StyledVoteIconContainer',
})`
  && {
    ${tw`relative grid`}
    place-content: center;
    width: 26px;
    height: 26px;
  }
`;

export const StyledRowVoteLabel = styled(Typography).attrs({
  className: 'StyledRowVoteLabel StyledTypographyBook',
})`
  && {
    ${tw`relative`}
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #494b4d;
  }
`;
export const StyledCurrentVotesInnerContainer = styled.div.attrs({
  className: 'StyledCurrentVotesInnerContainer',
})`
  && {
    ${tw`relative flex flex-col`}
    row-gap: 4px;
  }
`;

export const StyledRowVoteInfoContainer = styled.div.attrs({
  className: 'StyledRowVoteInfoContainer',
})`
  && {
    ${tw`relative flex`}
    column-gap: 8px;
    overflow: hidden;
  }
`;
export const StyledRowVoteInfo = styled(Typography).attrs({
  className: 'StyledRowVoteInfo ',
})`
  && {
    ${tw`relative`}
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #2c2d2e;
  }
`;

export const StyledRowVoteVestedInfo = styled(Typography).attrs({
  className: 'StyledRowVoteVestedInfo ',
})`
  && {
    ${tw`relative`}
    min-width: 120px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #2c2d2e;
  }
`;

export const StyledRowVoteTypeOfInterest = styled.span.attrs({
  className: 'StyledRowVoteTypeOfInterest StyledTypography',
})`
  && {
    ${tw`relative`}
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #2c2d2e;
  }
`;

export const StyledRowVoteInfoDescription = styled(Typography).attrs({
  className: 'StyledRowVoteInfoDescription StyledTypographyBook',
})`
  && {
    ${tw`relative`}
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #494b4d;
    overflow: hidden;
    white-space: break-spaces;
    word-break: break-word;
  }
`;
export const StyledFieldRow = styled.div.attrs({
  className: 'StyledFieldRow',
})`
  && {
    ${tw`relative flex `}
    margin-bottom: 6px;
    justify-content: space-between;

    align-items: center;
    flex-wrap: no-wrap;
    column-gap: 24px;
  }
`;
export const StyledFieldRowWithComments = styled.div.attrs({
  className: 'StyledFieldRowWithComments',
})`
  && {
    ${tw`relative flex `}
    margin-bottom: 6px;
    align-items: center;
    flex-wrap: no-wrap;
    column-gap: 24px;
  }
`;

export const StyledFieldRowTitle = styled(Typography).attrs({
  className: 'StyledFieldRowTitle ',
})`
  && {
    ${tw`relative`}
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #b6bbc1;
    max-height: 20px;
  }
`;

export const StyledItemStatusInProgress = styled.div.attrs({
  className: 'StyledItemStatusInProgress',
})`
  && {
    ${tw`relative  `}
    padding: 2px 8px;
    height: 21px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 32px;
    font-size: 12px;
    line-height: 17px;

    background: #dce3bf;
    border: 1px solid #b9c780;
    color: #64722b;
  }
  .StyledItemStatusInProgress::first-letter {
    text-transform: uppercase;
  }
`;

export const StyledFieldRowDate = styled(Typography).attrs({
  className: 'StyledFieldRowDate',
})`
  && {
    ${tw`relative`}
    left: -10px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;

    color: #2c2d2e;
  }
`;

export const StyledTitleSection = styled(Typography).attrs({
  className: 'StyledTitleSection StyledTypographyBold',
})`
  && {
    ${tw`relative`}
    padding-top: 32px;
    padding-bottom: 24px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.017em;

    color: #d66127;
  }
`;

export const StyledTitleSectionDeny = styled(Typography).attrs({
  className: 'StyledTitleSectionDeny StyledTypographyBold',
})`
  && {
    ${tw`relative flex`}
    column-gap: 8px;
    padding-top: 32px;
    padding-bottom: 24px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.017em;

    color: #ff2d55;
  }
`;

export const StyledFieldRowReference = styled(Typography).attrs({
  className: 'StyledFieldRowReference StyledTypographyBold',
})`
  && {
    ${tw`relative`}
    font-size: 20px;
    // line-height: 28px;
    letter-spacing: -0.017em;
    color: #2c2d2e;
  }
`;

export const StyledContainerComments = styled.div.attrs({
  className: 'StyledContainerComments',
})`
  && {
    ${tw`relative flex flex-col`}
    align-items: center;
  }
`;
export const StyledFieldColumn = styled.div.attrs({
  className: 'StyledFieldColumn',
})`
  && {
    ${tw`relative flex flex-col`}
    min-width: 25%;
  }
`;

export const StyledFieldColumnAmountPay = styled.div.attrs({
  className: 'StyledFieldColumnAmountPay',
})`
  && {
    ${tw`relative flex flex-col`}
    width: 15%;
  }
`;

export const StyledFieldsRowContainer = styled.div.attrs({
  className: 'StyledFieldsRowContainer',
})`
  justify-content: space-between;
  && {
    ${tw`relative flex flex-wrap`}
    column-gap: 40px;
  }
`;

export const StyledContainerImage = styled.div.attrs({
  className: 'StyledContainerImage',
})`
  flex-direction: row;
  flex: 1;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledDownloadExternalContainer = styled.a.attrs({
  className: 'StyledDownloadExternalContainer',
})`
  && {
    ${tw`relative flex`}
    align-items: center;
    column-gap: 16px;
  }
`;
export const StyledDownloadContainer = styled.a.attrs({
  className: 'StyledDownloadContainer',
})`
  && {
    ${tw`relative`}
    display: grid;
    place-content: center;
    width: 21px;
    height: 21px;
    cursor: pointer;
  }
`;
export const StyledDownloadImageContainer = styled.a.attrs({
  className: 'StyledDownloadImageContainer',
})`
  margin-left: 10px;
`;

export const StyledFieldColumnTitle = styled(Typography).attrs({
  className: 'StyledFieldColumnTitle',
})`
  && {
    ${tw`relative`}
    font-size: 12px;
    line-height: 17px;

    color: #6d7074;
  }
`;

export const StyledFieldColumnValue = styled(Typography).attrs({
  className: 'StyledFieldColumnValue StyledTypographyBook',
})`
  && {
    ${tw`relative`}
    font-size: 14px;
    line-height: 20px;flex;
    align-items: center;
    letter-spacing: -0.006em;

    color: #2C2D2E;

  }
`;

export const StyledRowPhoneIndicative = styled.div.attrs({
  className: 'StyledRowPhoneIndicative',
})`
  && {
    ${tw`relative`}
    width: 78px;
    max-width: 78px;
    margin-right: 16px;
    @media (max-width: 768px) {
      width: 78px;
      max-width: 78px;
    }
  }
`;

export const StyledRowPhoneNumber = styled.div.attrs({
  className: 'StyledRowPhoneNumber',
})`
  && {
    ${tw`relative`}
    height: 100%;
    width: 100%;
    max-width: 268px;
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
`;

export const StyledImagesContainer = styled.div.attrs({
  className: 'StyledImagesContainer',
})`
  display: flex;
`;

export const StyledSubTitle = styled(Typography).attrs({
  className: 'StyledSubTitle',
})`
  && {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 6px 0;
    margin-top: 70px;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.017em;
    color: #494b4d;
    @media (max-width: 768px) {
      margin-top: 0px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;
export const StyledAvatarField = styled(Avatar).attrs({
  className: 'StyledAvatarField',
})`
  && {
    margin-left: 5px;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    border: 1px solid #dae0e8;
  }
`;
export const StyledFileImageLabel = styled.label.attrs({
  className: 'StyledFileImageLabel',
})`
  && {
    position: relative;
    cursor: pointer;
    z-index: 10;
  }
`;

export const StyledFieldRowBottom = styled.div.attrs({
  className: 'StyledFieldRowBottom',
})`
  && {
    ${tw`relative flex`}
    width: 100%;
    margin-top: 22px;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const StyledRowButtons = styled.div.attrs({
  className: 'StyledRowButtons',
})`
  && {
    ${tw`relative flex flex-wrap`}
    column-gap: 16px;
    row-gap: 16px;
  }
`;

export const StyledRowButtonsVoting = styled.div.attrs({
  className: 'StyledRowButtonsVoting',
})`
  && {
    ${tw`relative flex flex-wrap`}
    column-gap: 16px;
    row-gap: 16px;
    @media (max-width: 1030px) {
      justify-content: center;
    }
  }
`;

export const StyledButtonComment = styled(Button).attrs({
  className: 'StyledButtonComment',
})`
  && {
    ${tw`relative`}
    width: 210px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 130px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;
export const StyledButtonDeny = styled(Button).attrs({
  className: 'StyledButtonDeny',
})`
  && {
    ${tw`relative`}
    top: 11px;
    width: 185px;
    padding: 12px 8px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 130px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;
export const StyledButtonDenyLastApprove = styled(Button).attrs({
  className: 'StyledButtonDenyLastApprove',
})`
  && {
    ${tw`relative`}
    width: 105px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 130px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;

export const StyledButtonCommentInspector = styled(Button).attrs({
  className: 'StyledButtonCommentInspector',
})`
  && {
    ${tw`absolute`}
    top: 1919px;
    left: 100%;
    transform: translate(calc(-100% - 220px), 0);
    width: 142px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 130px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;

export const StyledButtonApprove = styled(Button).attrs({
  className: 'StyledButtonApprove',
})`
  && {
    ${tw`relative`}

    width: 190px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 130px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;

export const StyledButtonApprovePayment = styled(Button).attrs({
  className: 'StyledButtonApprovePayment',
})`
  && {
    ${tw`relative`}
    top: 11px;
    width: 180px;
    padding: 12px 8px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 130px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;
export const StyledButtonLastApprove = styled(Button).attrs({
  className: 'StyledButtonLastApprove',
})`
  && {
    ${tw`relative`}
    width: 105px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 130px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;

export const StyledButtonCommentVoting = styled(Button).attrs({
  className: 'StyledButtonCommentVoting',
})`
  && {
    ${tw`relative`}
    width: 180px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 130px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;

export const StyledButtonApproveVoting = styled(Button).attrs({
  className: 'StyledButtonApproveVoting',
})`
  && {
    ${tw`relative`}

    width: 180px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 130px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;

export const StyledButtonRevertApplication = styled(Button).attrs({
  className: 'StyledButtonRevertApplication',
})`
  && {
    ${tw`relative`}

    width: 180px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 130px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;

//Comments
export const StyledTitleModal = styled(Typography).attrs({
  className: 'StyledTitleModal StyledTypographyBold',
})`
  && {
    max-width: 460px;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.021em;
  }
`;

export const StyledSubtitleModal = styled(Typography).attrs({
  className: 'StyledSubtitleModal',
})`
  && {
    max-width: 460px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #6d7074;
  }
`;
export const StyledChildContainerModal = styled.div.attrs({
  className: 'StyledChildContainerModal',
})`
  && {
    ${tw`relative flex flex-col`}
    row-gap: 16px;
    margin-top: 36px;
    min-width: 750px;
    align-items: center;
  }
`;
// Modal Last approve
export const StyledTitleModalLastApprove = styled(Typography).attrs({
  className: 'StyledTitleModalLastApprove StyledTypographyBold',
})`
  && {
    margin-top: 24px;
    font-size: 20px;
    line-height: 28px;
    /* identical to box height, or 140% */

    text-align: center;
    letter-spacing: -0.017em;
  }
`;
export const StyledChildContainerModalLastApprove = styled.div.attrs({
  className: 'StyledChildContainerModalLastApprove',
})`
  && {
    ${tw`relative flex flex-col`}
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;
export const StyledContainerAdviseIconModalLastApprove = styled.div.attrs({
  className: 'StyledContainerAdviseIconModalLastApprove',
})`
  && {
    ${tw`relative grid`}

    margin-top: 24px;
    margin-bottom: 8px;
    width: 160px;
    height: 160px;
    place-content: center;
    @media (max-width: 768px) {
      margin: 0;
      width: 80px;
      height: 80px;
    }
  }
`;
export const StyledSubtitleModalLastApprove = styled(Typography).attrs({
  className: 'StyledSubtitleModalLastApprove',
})`
  && {
    margin-top: 16px;
    margin-bottom: 24px;
    max-width: 460px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */

    letter-spacing: -0.006em;

    color: #6d7074;
  }
`;
export const StyledDescriptionModal = styled(Typography).attrs({
  className: 'StyledDescriptionModal',
})`
  && {
    align-self: flex-start;
    color: #494b4d;
    max-width: 750px;
    font-size: 18px;
    line-height: 20px;
    margin-top: 16px;
    margin-bottom: 50px;
  }
`;

export const StyledTextAreaField = styled(TextAreaControlled).attrs({
  className: 'StyledTextAreaField',
})`
  && {
    ${tw`relative`}
    width: 750px;
    .StyledTextareaAutosize {
      min-height: 160px;
      max-height: 320px;
    }
  }
`;

export const StyledSwitchField = styled(SwitchControlled).attrs({
  className: 'StyledSwitchField',
})`
  && {
    ${tw`relative`}
    align-self: flex-start;
  }
`;
export const StyledButtonModal = styled(Button).attrs({
  className: 'StyledButtonModal',
})`
  && {
    ${tw`relative`}

    width: 250px;
    fontsize: 16px;
    line-height: 22px;
    letter-spacing: -0.011em;
    align-self: center !important;
    @media (max-width: 768px) {
      margin-top: 36px;
      width: 160px;
      height: 44px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.006em;
    }
  }
`;

//Inspector
export const StyledInspectorContainer = styled.div.attrs({
  className: 'StyledInspectorContainer',
})`
  && {
    ${tw`relative`}
  }
`;

export const StyledInspectionSheetContainer = styled.div.attrs({
  className: 'StyledInspectionSheetContainer',
})`
  && {
    ${tw`relative flex flex-col mt-[76px]`}
  }
`;
export const StyledInspectionSheetHeader = styled.div.attrs({
  className: 'StyledInspectionSheetHeader',
})`
  && {
    ${tw`relative`}
    display: grid;
    grid-template-columns: auto 130px 130px;
    padding: 12px;
    padding-left: 8px;
    min-height: 48px;
    border-bottom: 1px dashed #dae0e8;
  }
`;
export const StyledTitleInspectorSheetSection = styled(Typography).attrs({
  className: 'StyledTitleInspectorSheetSection StyledTypographyBold',
})`
  && {
    display: flex;
    align-items: center;

    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.017em;
    color: #494b4d;
  }
`;

export const StyledTitleInspectorSheetOption = styled(Typography).attrs({
  className: 'StyledTitleInspectorSheetOption',
})`
  && {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
    line-height: 17px;
    color: #b6bbc1;
  }
`;

export const StyledInspectionSheetRowOdd = styled.div.attrs({
  className: 'StyledInspectionSheetRowOdd',
})`
  && {
    ${tw`relative`}
    display: grid;
    grid-template-columns: auto 260px;
    align-items: center;
    padding: 12px;
    padding-left: 32px;
    min-height: 48px;
    background: #ffffff;
    border-bottom: 1px dashed #dae0e8;
  }
`;
export const StyledInspectionSheetRowEven = styled.div.attrs({
  className: 'StyledInspectionSheetRowEven',
})`
  && {
    ${tw`relative`}
    display: grid;
    grid-template-columns: auto 260px;
    align-items: center;
    padding: 12px;
    padding-left: 32px;
    min-height: 48px;
    background: #f8f9fa;
    border-bottom: 1px dashed #dae0e8;
  }
`;

export const StyledTitleInspectorSheetRowRadioGroup = styled(
  RadioGroupFieldControlled
).attrs({
  className: 'StyledTitleInspectorSheetRowRadioGroup',
})`
  && {
    ${tw`relative`}
    display: grid;
    align-self: center;
    max-height: 42px;
  }
  .MuiFormGroup-root {
    flex-wrap: no-wrap !important;
    justify-content: center !important;
    column-gap: 96px !important;
  }
`;

export const StyledInspectorContainerComments = styled.div.attrs({
  className: 'StyledInspectorContainerComments',
})`
  && {
    ${tw`relative flex flex-col`}
    row-gap: 8px;
    margin-top: 16px;
    width: 100%;
    padding: 16px;
  }
`;

export const StyledTitleInspectorSheetRowDescription = styled(Typography).attrs(
  {
    className: 'StyledTitleInspectorSheetRowDescription StyledTypographyBook',
  }
)`
  && {
    display: flex;
    align-items: center;
    width: 100%;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #2c2d2e;
  }
`;
export const StyledTitleInspectorComments = styled(Typography).attrs({
  className: 'StyledTitleInspectorComments',
})`
  && {
    font-weight: 350;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #494b4d;
  }
`;

export const StyledInnerContainerComments = styled.div.attrs({
  className: 'StyledInnerContainerComments',
})`
  && {
    width: 100%;
    padding: 8px;
    border: 2px solid #dae0e8;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;
export const StyledTitleInspectorReport = styled(Typography).attrs({
  className: 'StyledTitleInspectorReport StyledTypographyBold',
})`
  && {
    display: flex;
    align-items: center;
    margin-bottom: 18px;

    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.021em;
    color: #494b4d;
  }
`;

export const StyledContainerIconView = styled.a.attrs({
  className: 'StyledContainerIconView ',
})`
  && {
    ${tw`absolute`}
    right:0;
    transform: translate(-48px, 0);
    display: grid;
    place-content: center;
    cursor: pointer;
  }
`;
export const StyledFieldRowBottomContainer = styled.div.attrs({
  className: 'StyledFieldRowBottomContainer',
})`
  && {
    ${tw`relative flex`}
    gap: 20px 20px;
    & > * {
      flex: 1 1 calc(30% - 10px);
    }
    flex-wrap: wrap;
  }
`;

// Application Fee approve

export const StyledFieldsContainerLastApprove = styled.div.attrs({
  className: 'StyledFieldsContainerLastApprove',
})`
  && {
    ${tw`relative grid`}
    margin-top: 40px;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr;
    column-gap: 20px;
    @media (max-width: 767.98px) {
      display: none;
    }
  }
`;
export const StyledFieldsContainerFeeInnerContainer = styled.div.attrs({
  className: 'StyledFieldsContainerFeeInnerContainer',
})`
  && {
    ${tw`relative`}
    @media (max-width: 767.98px) {
      display: none;
    }
  }
`;
export const StyledFieldsBorder = styled.div.attrs({
  className: 'StyledFieldsBorder',
})`
  && {
    ${tw`relative`}
    width: 100%
    height: 0;
    border-top: 1px solid #dae0e8;
    @media (max-width: 767.98px) {
      display: none;
    }
  }
`;
export const StyledFieldsContainerMultiple = styled.div.attrs({
  className: 'StyledFieldsContainerMultiple',
})`
  && {
    ${tw`relative flex flex-wrap pb-[32px]`}
    gap: 20px 40px;
    & > * {
      flex: 1 1 calc(33% - 80px);
    }
    @media (max-width: 767.98px) {
      display: none;
    }
  }
`;

export const StyledFieldsContainerMultipleAmountPay = styled.div.attrs({
  className: 'StyledFieldsContainerMultipleAmountPay',
})`
  && {
    ${tw`relative flex flex-wrap pb-[32px]`}
    gap: 20px 20px;
    & > * {
      flex: 1 1 calc(33% - 40px);
    }
    @media (max-width: 767.98px) {
      display: none;
    }
  }
`;
export const StyledTextFieldControlledDisabled = styled(
  TextFieldControlled
).attrs({
  className: 'StyledTextFieldControlledDisabled',
})`
  && {
    ${tw`relative`}
    display:none;
    .MuiOutlinedInput-root {
      background: #f3f5f7;
    }
  }
`;

export const StyledTextFieldControlled = styled(TextFieldControlled).attrs({
  className: 'StyledTextFieldControlled',
})`
  && {
    ${tw`relative`}
    .MuiOutlinedInput-root {
      background: #f3f5f7;
    }
  }
`;
export const StyledTextFieldControlledAmount = styled(
  TextFieldControlled
).attrs({
  className: 'StyledTextFieldControlledAmount',
})`
  && {
    max-width: 320px;
    ${tw`relative`}
    @media (max-width: 1337.98px) {
      max-width: 100%;
    }
    .MuiOutlinedInput-root {
      background: #f3f5f7;
    }
  }
`;

export const StyledContainerStamp = styled.div.attrs({
  className: 'StyledContainerStamp',
})`
  && {
    ${tw`relative grid`}
    place-content: center;
    width: 300px;
    @media (max-width: 767.98px) {
      display: none;
    }
  }
`;
export const StyledStamp = styled.img.attrs({
  className: 'StyledStamp',
})`
  && {
    ${tw`relative`}
    width: 120px;
    heigt: 120px;
  }
`;
// Accordion Document

export const StyledDocumentsReportAccordion = styled(Accordion).attrs({
  className: 'StyledDocumentsReportAccordion',
})`
  && {
    ${tw`relative`}
    max-width: 764px;

    margin: 32px 0 !important;
    background-color: #ffffff !important;
    opacity: 1 !important;
    color: #242731 !important;
    border: 1px solid #eceff3 !important;
    border-radius: 4px !important;
    .Mui-disabled {
      pointer-events: auto;
      opacity: 1;
    }
  }
`;
export const StyledTitleDocumentReportContainerAccordion = styled.div.attrs({
  className: 'StyledTitleDocumentReportContainerAccordion ',
})`
  && {
    ${tw`relative`}
    width: 100%;
    max-width: 764px;

    display: flex;
    align-items: center;
  }
`;
export const StyledTitleReportAccordion = styled(Typography).attrs({
  className: 'StyledTitleReportAccordion StyledTypographyBook',
})`
  && {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #242731;
  }
`;
export const StyledContainerIconViewAccordion = styled.a.attrs({
  className: 'StyledContainerIconViewAccordion ',
})`
  && {
    ${tw`absolute`}
    right:0;
    transform: translate(-48px, 0);
    display: grid;
    place-content: center;
    cursor: pointer;
  }
`;

export const StyledContainerDownloadAccordion = styled.a.attrs({
  className: 'StyledContainerDownloadAccordion ',
})`
  && {
    ${tw`absolute flex`}
    left: 100%;
    transform: translate(-100%, 0);
    width: 30px;
    height: 44px;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
  }
`;

// Application reverted by boardmember

export const StyledApplicationRevertedContainer = styled.div.attrs({
  className: 'StyledApplicationRevertedContainer',
})`
  && {
    ${tw`relative flex`}
    margin-top: 24px;
    height: 48px;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    background: #f3f5f7;
    border-radius: 4px;
  }
`;

export const StyledAdviceIconContainer = styled.div.attrs({
  className: 'StyledAdviceIconContainer',
})`
  && {
    ${tw`relative grid`}
    place-content: center;
    width: 24px;
    height: 24px;
  }
`;
export const StyledApplicationRevertedLabel = styled(Typography).attrs({
  className: 'StyledApplicationRevertedLabel StyledTypography',
})`
  && {
    ${tw`relative`}
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #494b4d;
  }
`;
export const StyledInputFileFieldControlled = styled(
  InputFileFieldControlled
).attrs({
  className: 'StyledInputFileFieldControlled',
})`
  && {
    ${tw` relative`}
    width: 100%;
    margin-bottom: 16px;
    z-index: 2;
  }
`;
