import styled from 'styled-components';
import tw from 'twin.macro';
import Watermark from '../../../assets/images/Watermark.png';

export const StyledPrivateContent = styled.div`
  && {
    ${tw`relative `}
    min-height: calc(100vh);
    background-position: left top;
    ${(props) => {
      switch (props.background) {
        case 'primary':
          return `background: #FFFFFF;`;
        case 'secondary':
          return ` 
          background: linear-gradient(107.17deg, rgba(240, 243, 245, 0.64) -2.4%, rgba(211, 215, 220, 0.64) 100.98%);`;
        case 'tertiary':
          return `background: linear-gradient(107.17deg, rgba(240, 243, 245, 0.64) -2.4%, rgba(211, 215, 220, 0.64) 100.98%);`;
        default:
          return tw`bg-white`;
      }
    }}
    @media (max-width: 768px) {
      overflow-x: hidden;
      background: background: linear-gradient(107.17deg, rgba(240, 243, 245, 0.64) -2.4%, rgba(211, 215, 220, 0.64) 100.98%);

    }
  }
`;
export const StyledOuterContent = styled.div.attrs({
  className: 'StyledOuterContent',
})`
  && {
    ${tw`relative `}
    min-height: calc(100vh);
    background-image: url(${Watermark});
    background-repeat: no-repeat;
    background-position-x: 200px;
    @media (max-width: 768px) {
      background-position-x: -80px;
      background-position-y: 72px;
      background-size: 334px 400px;
    }
  }
`;
export const StyledInnerContentContainer = styled.div.attrs({
  className: 'StyledInnerContentContainer',
})`
  && {
    ${tw`relative grid`}
    min-height: calc(100vh);
    grid-template-rows: auto 240px;
    grid-template-columns: 1fr;
    @media (max-width: 768px) {
      min-height: calc(100vh);
      grid-template-rows: auto 160px;
    }
  }
`;

export const StyledInnerContentBackuser = styled.div.attrs({
  className: 'StyledInnerContentBackuser',
})`
  && {
    ${tw`relative `}
    display: grid;
    grid-template-columns: 194px auto;
    @media (max-width: 768px) {
      display: flex;
    }
  }
`;
export const StyledHeaderContent = styled.header`
  background-color: #f8f9fa;
  opacity: 0.8;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
`;

export const StyledImgWatermark = styled.img`
  && {
    ${tw`absolute`}
    top: 0;
    left: 200px;
    @media (max-width: 768px) {
      width: 334px;
      height: 400px;
      top: 72px;
      left: -80px;
    }
  }
`;
