const useAuthTypes = () => {
  const LOGIN = 'LOGIN';
  const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';
  const LOG_OUT = 'LOG_OUT';
  const REFRESH_TOKEN = 'REFRESH_TOKEN';
  const TOGGLE_REFRESH_TOKEN = 'TOGGLE_REFRESH_TOKEN';
  const REFRESH_TOKEN_CONFIRMATION = 'REFRESH_TOKEN_CONFIRMATION';
  const LEAVE_BROADCASTING = 'LEAVE_BROADCASTING';
  return {
    LOGIN,
    EMAIL_VERIFICATION,
    LOG_OUT,
    REFRESH_TOKEN,
    TOGGLE_REFRESH_TOKEN,
    REFRESH_TOKEN_CONFIRMATION,
    LEAVE_BROADCASTING,
  };
};
export default useAuthTypes;
