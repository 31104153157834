import useHelpers from '../../../helpers';

const useApplicationReviewSelectors = () => {
  const { useCreateSelector } = useHelpers();
  const { createSelector } = useCreateSelector();

  const applicationReviewStateSelector = createSelector(
    (state) => state.applicationReview,
    (applicationReview) => {
      return {
        applicationReviewWizard: applicationReview.applicationReviewWizard,
        currentStep: applicationReview.currentStep,
        stepsEnabled: applicationReview.stepsEnabled,
        sapsAndMunicipalityReports:
          applicationReview.sapsAndMunicipalityReports,
        sapsAndMunicipalityReportsDraft:
          applicationReview.sapsAndMunicipalityReportsDraft,
        inspectionForm: applicationReview.inspectionForm,
        editInspectionForm: applicationReview.editInspectionForm,
      };
    }
  );

  return {
    applicationReviewStateSelector,
  };
};

export default useApplicationReviewSelectors;
