import { useEffect } from 'react';
import useApi from '../../../api';
import { useStopwatch } from 'react-timer-hook';
import useModels from '../../../models';

const useTimer = () => {
  const { seconds, minutes, hours, isRunning, start, pause, reset } =
    useStopwatch({
      autoStart: false,
    });

  //Api
  const { useActions } = useApi();
  const { dispatch, useVotingActions } = useActions();
  const {
    actGetVote,
    actSaveVoteTime,
    actSetStopwatchState,
    actSetStopwatchCurrentTime,
  } = useVotingActions();

  const { useSelectors } = useModels();
  const { useSelector, useApplicationFormSelectors, useVotingSelectors } =
    useSelectors();
  const { votingSelector } = useVotingSelectors();
  const { applicationFormSelector } = useApplicationFormSelectors();
  const {
    state: stateStopWatch,
    alreadyVoted,
    vote,
    canRepeatVote,
    hasVestedInterest,
    hasVestedType,
    hasVestedTypeOther,
  } = useSelector(votingSelector);

  const { applicationForm } = useSelector(applicationFormSelector);
  // const [timerOffSe, setstate] = useState(initialState);
  // const [timeState, setTimeState] = useState(false);

  useEffect(() => {
    // isVoting &&
    dispatch(
      actGetVote({ applicationId: applicationForm.id }, handleSetOffset)
    );
  }, []);

  useEffect(() => {
    if (!isRunning) {
      handleSetCurrentTime();
    } else {
      dispatch(
        actSetStopwatchState(true),
        dispatch(actSetStopwatchCurrentTime(''))
      );
    }
  }, [isRunning]);

  useEffect(() => {
    !stateStopWatch && isRunning && pause();
  }, [stateStopWatch]);

  const handleSetCurrentTime = () => {
    const secondsToMili = seconds * 1000;
    const minutesToMili = minutes * 60000;
    const hoursToMili = hours * 360000;
    const pauseTimeStopWatch = hoursToMili + minutesToMili + secondsToMili;

    pauseTimeStopWatch > 0 &&
      dispatch(
        actSaveVoteTime(
          {
            applicationId: applicationForm.id,
            time: pauseTimeStopWatch,
          },
          dispatch(
            actSetStopwatchCurrentTime(
              pauseTimeStopWatch,
              dispatch(actSetStopwatchState(false))
            )
          )
        )
      );
  };
  const handleSetOffset = (data) => {
    const currentOffset = data.currentTime.time / 1000;
    const stopwatchOffset = new Date();
    stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + currentOffset);
    reset(stopwatchOffset, false);
  };
  return {
    vote,
    alreadyVoted,
    canRepeatVote,
    hasVestedInterest,
    hasVestedType,
    hasVestedTypeOther,
    seconds,
    minutes,
    hours,
    isRunning,
    start,
    pause,
    applicationForm,
  };
};

export default useTimer;
