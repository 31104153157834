import axios from 'axios';
const useVotingProviders = () => {
  const saveVoteTime = (data) => {
    return axios({
      method: 'post',
      url: `api/save-vote-time`,
      data,
    });
  };
  const getVote = (applicationId) => {
    return axios({
      method: 'get',
      url: `api/get-vote?applicationId=${applicationId}`,
    });
  };
  const voteApplication = (data) => {
    return axios({
      method: 'post',
      url: `api/vote-application`,
      data,
    });
  };
  const revertApplication = (data) => {
    return axios({
      method: 'post',
      url: `api/object-vote`,
      data,
    });
  };
  const getCurrentsVotes = (applicationId) => {
    return axios({
      method: 'get',
      url: `api/application-votes/${applicationId}`,
    });
  };
  return {
    saveVoteTime,
    getVote,
    voteApplication,
    revertApplication,
    getCurrentsVotes,
  };
};
export default useVotingProviders;
