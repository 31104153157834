import { trackPromise } from 'react-promise-tracker';
import useProviders from '../../providers';

const useUsersTableService = () => {
  const { useUsersTableProvider } = useProviders();
  const { getUsersTablePage, createUser, disableUser, updateUser } =
    useUsersTableProvider();

  const getUsersTablePageService = (page, search, role) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(getUsersTablePage(page, search, role)));
      } catch (e) {
        reject(e);
      }
    });
  };
  const createUserService = (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(createUser(data)));
      } catch (e) {
        reject(e);
      }
    });
  };
  const disableUserService = (id) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(disableUser(id)));
      } catch (e) {
        reject(e);
      }
    });
  };
  const updateUserService = (data) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        resolve(await trackPromise(updateUser(data)));
      } catch (e) {
        reject(e);
      }
    });
  };
  return {
    getUsersTablePageService,
    createUserService,
    disableUserService,
    updateUserService,
  };
};

export default useUsersTableService;
