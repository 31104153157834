import useHelpers from '../../../helpers';
import useStrings from '../../../strings';
import useInitialStates from '../../initialStates';

const useAuthReducers = () => {
  // Helpers
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  //strings
  const { useTypes } = useStrings();
  const { useAuthTypes } = useTypes();
  const {
    LOGIN,
    LOG_OUT,
    LEAVE_BROADCASTING,
    REFRESH_TOKEN,
    TOGGLE_REFRESH_TOKEN,
    REFRESH_TOKEN_CONFIRMATION,
  } = useAuthTypes();

  //initial states
  const { useAuthInitialStates } = useInitialStates();
  const { sessionInitialState, broadcastingInitialState } =
    useAuthInitialStates();

  //handlers
  const sessionHandler = {
    [LOGIN](state, action) {
      const { payload } = action;
      const { tokenType, token, expiresAt, refreshToken } = payload;

      return {
        ...state,
        tokenType: tokenType,
        accessToken: token,
        expiresAt: expiresAt,
        refreshToken: refreshToken,
      };
    },
    [REFRESH_TOKEN](state, action) {
      const { payload } = action;
      const { token, expiresAt, refreshToken } = payload;
      return {
        ...state,
        accessToken: token,
        expiresAt: expiresAt,
        refreshToken: refreshToken,
        refreshTokenConfirmation: false,
      };
    },
    [TOGGLE_REFRESH_TOKEN](state) {
      return {
        ...state,
        toggleRefreshToken: !state.toggleRefreshToken,
      };
    },

    [REFRESH_TOKEN_CONFIRMATION](state) {
      return {
        ...state,
        refreshTokenConfirmation: true,
      };
    },
    [LOG_OUT](state) {
      return {
        ...state,
        accessToken: '',
        expiresAt: '',
        refreshToken: '',
        refreshTokenConfirmation: false,
        toggleRefreshToken: false,
      };
    },
  };

  //handlers
  const broadcastingHandler = {
    [LOGIN](state, action) {
      const { payload } = action;
      const { user } = payload;
      return {
        ...state,
        idBroadcasting: user.id,
      };
    },
    [LEAVE_BROADCASTING](state) {
      return {
        ...state,
        leaveBroadcasting: true,
      };
    },
    [LOG_OUT](state) {
      return {
        ...state,
        leaveBroadcasting: false,
      };
    },
  };
  //reducers
  const session = createReducer(sessionInitialState, sessionHandler);
  const broadcasting = createReducer(
    broadcastingInitialState,
    broadcastingHandler
  );

  return {
    session,
    broadcasting,
  };
};

export default useAuthReducers;
