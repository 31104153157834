import React, { Suspense } from 'react';
import useComponents from '../../components';
import useLayouts from '../../layouts';
import useHelpers from '../../../helpers';
import useControllers from '../../../controllers';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  StyledFormContainer,
  StyledFieldsContainer,
  StyledSectionResetPassword,
} from './resetpassword.styles';

const ResetPassword = () => {
  // Layouts
  const { PublicContentLayout } = useLayouts();

  // Components
  const {
    ActivityIndicator,
    Modal,
    Button,
    Typography,
    PassWordFieldControlled,
  } = useComponents();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useResetPassword } = useScreenHooks();
  const { onSubmit, modalState, registerState, handleCloseModal } =
    useResetPassword();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, usePromises } = useQuickFunctions();
  const { resetPasswordSchema } = useValidators();
  const { promiseInProgress } = usePromises();

  // Yup validator
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(resetPasswordSchema),
  });
  const isPassword = watch('password');
  const isConfirmPassword = watch('passwordConfirmation');

  return (
    <PublicContentLayout background={'secondary'}>
      {promiseInProgress && <ActivityIndicator />}
      <Suspense fallback={<ActivityIndicator />}>
        <StyledSectionResetPassword>
          <StyledFormContainer className="container">
            <Typography
              color="primary"
              variant="h2"
              sx={{
                fontSize: '32px',
                lineHeight: '40px',
                letterSpacing: '-0.021em',
              }}
            >
              Reset Password
            </Typography>
            <StyledFieldsContainer>
              <PassWordFieldControlled
                id={'password'}
                label={'Password'}
                placeholder={'Password'}
                name={'password'}
                control={control}
                fullWidth={true}
                error={!!errors.password}
                helperText={errors?.password?.message}
              />
              <PassWordFieldControlled
                id={'passwordConfirmation'}
                label={'Confirm Password'}
                placeholder={'Password'}
                name={'passwordConfirmation'}
                control={control}
                fullWidth={true}
                error={
                  !!errors.passwordConfirmation ||
                  (isConfirmPassword !== '' &&
                    isConfirmPassword !== undefined &&
                    isPassword !== isConfirmPassword)
                }
                helperText={
                  errors?.passwordConfirmation?.message ||
                  (isConfirmPassword !== '' &&
                    isConfirmPassword !== undefined &&
                    isPassword !== isConfirmPassword &&
                    "Password doesn't match")
                }
              />
            </StyledFieldsContainer>
            <Button
              disabled={!isValid}
              fullWidth={false}
              color="primary"
              sx={{ width: '130px', marginTop: '40px', alignSelf: 'center' }}
              className="self-center"
              onClick={handleSubmit(onSubmit)}
            >
              Continue
            </Button>
          </StyledFormContainer>
          <Modal
            type={registerState}
            showModal={modalState.show}
            onClose={handleCloseModal}
            title={modalState.title}
            description={modalState.description}
          />
        </StyledSectionResetPassword>
      </Suspense>
    </PublicContentLayout>
  );
};

export default ResetPassword;
