import React, { Suspense } from 'react';
import useComponents from '../../components';
import useLayouts from '../../layouts';
import {
  StyledFieldsContainer,
  StyledSectionRecoveryPassword,
  StyledField,
} from './recoveryPassword.styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import useHelpers from '../../../helpers';
import useControllers from '../../../controllers';

const RecoveryPassword = () => {
  // Components
  const { ActivityIndicator, Button, Modal, TextFieldControlled, Wrapper } =
    useComponents();

  // Layouts
  const { PublicContentLayout } = useLayouts();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, usePromises } = useQuickFunctions();
  const { recoveryPasswordSchema } = useValidators();
  const { promiseInProgress } = usePromises();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useRecoveryPassword } = useScreenHooks();
  const { onSubmit, modalState, handleCloseModal, registerState, goBack } =
    useRecoveryPassword();

  // Yup validator
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(recoveryPasswordSchema),
  });

  return (
    <PublicContentLayout background={'secondary'}>
      {promiseInProgress && <ActivityIndicator />}
      <Suspense fallback={<ActivityIndicator />}>
        <StyledSectionRecoveryPassword>
          <Wrapper
            title={'Recover Password'}
            maxWidth={'580px'}
            className="container"
            withBackButton={true}
            onClickBackButton={goBack}
          >
            <StyledFieldsContainer>
              <TextFieldControlled
                id={'email'}
                placeholder={'Email'}
                label={'Email'}
                name={'email'}
                variant={'outlined'}
                fullWidth={true}
                control={control}
                error={!!errors.email}
                sx={{ color: '#6D7074' }}
                helperText={errors?.email?.message}
              />
            </StyledFieldsContainer>
            <StyledField>
              <Button
                disabled={!isValid}
                fullWidth={false}
                color="primary"
                sx={{ width: '130px', marginTop: '40px', alignSelf: 'center' }}
                className="self-center"
                onClick={handleSubmit(onSubmit)}
              >
                Continue
              </Button>
            </StyledField>
          </Wrapper>
          <Modal
            type={registerState}
            showModal={modalState.show}
            onClose={handleCloseModal}
            title={modalState.title}
            description={modalState.description}
          />
        </StyledSectionRecoveryPassword>
      </Suspense>
    </PublicContentLayout>
  );
};
export default RecoveryPassword;
