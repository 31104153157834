import { useRef, useState } from 'react';
import _ from 'lodash';
import useApi from '../../../api';
const useInputFile = () => {
  const { useActions } = useApi();
  const { dispatch, useApplicationFormActions, useBlogsActions } = useActions();
  const { actResetFieldApplicationForm } = useApplicationFormActions();
  const { actResetFieldImage } = useBlogsActions();

  const [fileNameSelected, setFileNameSelected] = useState(' ');
  const [imageToShow, setImageToShow] = useState('');
  const [filesUploaded, setFilesUploaded] = useState({ files: [] });
  const inputFile = useRef();

  const openFile = () => {
    inputFile.current && inputFile.current.click();
  };

  const handleImageToShow = (data) => {
    const reader = new FileReader();
    const aux = data.target.files;
    aux.length && reader.readAsDataURL(aux[0]);

    reader.onload = () => {
      setImageToShow(reader.result);
    };
  };

  const handleShowFileName = () => {
    return _.map(filesUploaded.files, (file) => `${file.name} `);
  };

  const removeFile = (reset, id) => {
    setFilesUploaded({
      files: [],
    });
    document.getElementById(`${id}`).value = '';
    setImageToShow('');
    reset && reset(`${id}`, { defaultValue: { name: '', data: '' } });
    dispatch(actResetFieldApplicationForm({ field: id }));
    dispatch(actResetFieldImage());
  };

  const handleOnChange = (event, onInputChange, reset, id, type) => {
    const files = event.target.files;
    if (files.length > 0) {
      setFilesUploaded({ files: [...files] });
      setFileNameSelected(files[0].name);
      onInputChange({ event: event, remove: removeFile, reset: reset, id: id });
      type === 'image' && handleImageToShow(event);
    }
    // else {
    //   removeFile(reset, id);
    // }
  };

  return {
    handleOnChange,
    openFile,
    inputFile,
    filesUploaded,
    handleShowFileName,
    removeFile,
    fileNameSelected,
    imageToShow,
  };
};

export default useInputFile;
