import React from 'react';

const iconHamMenu = ({ color = '#FFFFFF', size = 1 }) => {
  return (
    <svg
      width={(size * 24).toString()}
      height={(size * 18).toString()}
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.44117 2.86559H22.9483C23.5286 2.86559 24 2.2237 24 1.4328C24 0.641893 23.5293 0 22.9483 0H7.44117C6.86089 0 6.39022 0.641893 6.39022 1.4328C6.39022 2.2237 6.86017 2.86559 7.44117 2.86559ZM22.5666 7.16398H1.4328C0.641893 7.16398 0 7.80588 0 8.59678C0 9.38768 0.641893 10.0296 1.4328 10.0296H22.5666C23.3575 10.0296 23.9993 9.38768 23.9993 8.59678C23.9993 7.80588 23.3575 7.16398 22.5666 7.16398ZM7.44117 14.209H22.9476C23.5286 14.209 24 14.8509 24 15.6418C24 16.4327 23.5286 17.0746 22.9483 17.0746H7.44117C6.86089 17.0746 6.39022 16.4327 6.39022 15.6418C6.39022 14.8509 6.86089 14.209 7.44117 14.209Z"
        fill={color}
      />
    </svg>
  );
};

export default iconHamMenu;
