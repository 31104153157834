import React from 'react';
import PrivateRouting from '../PrivateRouting';
import PublicRouting from '../PublicRouting';
import useScreenHooks from '../../controllers/screenHooks';
import IdleTimer from 'react-idle-timer';
import useHelpers from '../../helpers';

const Routing = () => {
  const { useQuickFunctions } = useHelpers();
  const { useRefreshToken } = useQuickFunctions();
  const { useRouting } = useScreenHooks();
  const {
    accessToken,
    expiresAt,
    refreshToken,
    handleOnAction,
    handleOnActive,
    handleOnIdle,
  } = useRouting();

  useRefreshToken();
  // Return routing according to session state
  return (
    <>
      {/* Logout after 2h on idle (1000 * 60 * 120) */}
      {accessToken && expiresAt && refreshToken && (
        <IdleTimer
          timeout={7211111}
          onActive={handleOnActive}
          onIdle={handleOnIdle}
          onAction={handleOnAction}
          debounce={1000}
          crossTab={{
            emitOnAllTabs: true,
          }}
        />
      )}

      <PublicRouting />
      <PrivateRouting />
    </>
  );
};

export default Routing;
