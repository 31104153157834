import * as yup from 'yup';
import useQuickFunctions from '..';
import _ from 'lodash';

/**
 *
 * @author Serempre
 * @name useValidators
 * @type {Function}
 * @description Custom hook to manage the inputs validation schema
 * @param {} -
 *
 * @return {Function}
 */

const useValidators = () => {
  /**
   * Common validations functions
   */
  const { useAlgorithms } = useQuickFunctions();
  const { luhnAlgorithmValidation } = useAlgorithms();
  const email = yup.string().email('Must be a valid email');
  const currentPassword = yup
    .string()
    .required('Current password is a required field.');
  const password = yup.string().required('Password is a required field.');

  //const passwordConfirmation = yup
  //.string()
  //.required('Confirm password is a required field.')
  //.oneOf([yup.ref('password'), null]);

  const alphaLettersStringInput = yup
    .string()
    .matches(/^[A-Za-zÀ-ÖØ-öø-ÿ ,.'-]+$|^$/i, 'Must contain only letters');
  const pdfFileInput = yup
    .string()
    .matches(
      /^data:(application\/pdf)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)$/i,
      'Must be pdf'
    );
  const imageFileInput = yup
    .string()
    .matches(
      /^data:(image\/\b(jpg|jpeg|png|tiff)\b)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@/?%\s]*)$/i,
      'Must be pdf'
    );
  const isCheck = yup.boolean().oneOf([true], 'Required terms and conditions');

  // const isYes = yup.string().oneOf(['1'], 'Required field.');
  // const registrationTyo = yup.boolean().oneOf([true], 'Required terms and conditions');
  const isLocationAdded = yup
    .boolean()
    .oneOf([true], 'Add your location is required');
  const checkbox = yup.boolean();
  const number = yup
    .number('Must be a number')
    .positive('Must be greater than 0')
    .typeError('Must be a number');

  const naturalAmountNumber = yup
    .string()
    .matches(/^([0-9]+\.?[0-9]*|\.[0-9]+)$|^$/i, 'Invalid amount');
  const phoneNumber = yup
    .string()
    .matches(/^[0-9]+$|^$/i, 'Contact Number must contain only numbers');
  const documentType = yup
    .string()
    .oneOf(['PP', 'ID'], 'Invalid Document Type');
  const hasVestedInterest = yup.string().oneOf(['0', '1'], 'Required field');
  const hasVestedType = yup
    .string()
    .oneOf(['1', '2', '3', '4'], 'Required field');
  const contactMethod = yup
    .string()
    .oneOf(['1', '2', '3'], 'Invalid Method of Communication');
  const topicType = yup
    .string()
    .oneOf(['logins', 'application', 'renewal'], 'Invalid Topic Type');
  const documentIdInput = yup
    .string()
    .min(13, 'ID must be a 13 digits number')
    .max(13, 'ID must be a 13 digits number')
    .matches(
      /^[0-9][0-9](0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])([0-9]{7})+$|^$/i,
      'Invalid ID number'
    )
    .test('Luhn validation', 'Invalid ID number', (value) =>
      luhnAlgorithmValidation(value)
    );
  const documentPassportInput = yup
    .string()
    .matches(/^([a-zA-Z]{1}[0-9]{8})+$|^$/i, 'Passport number invalid.');
  const stringField = yup.string('Invalid field.');
  // const radioTrue = yup.string().matches(/^[1]+$|^$/i);
  // Schemas
  const loginValidatorSchema = yup.object().shape({
    email: email.required('Email is a required field.'),
    password: password.required('Password is a required field.'),
  });

  // const filePdfDocuments = yup.object().shape({
  //   name: stringField,
  //   data: pdfFileInput.required('Required field.'),
  // });

  const filePdf = yup.object().shape({
    name: stringField,
    data: pdfFileInput.required('Required field.'),
  });

  const filePdfDocuments = yup.object().shape({
    name: stringField.required('Required field.'),
    data: yup.string().when('name', {
      is: (name) => {
        if (name) {
          const extension = name.split('.').pop();
          return extension === 'pdf';
        } else {
          return false;
        }
      },
      then: (pdfFileInput) => pdfFileInput.required('Invalid pdf'),
      otherwise: (stringField) => stringField,
    }),
  });

  const filePdfNotRequired = yup.object().shape({
    name: stringField,
    data: stringField,
  });
  const fileImageNotRequired = yup.object().shape({
    name: stringField,
    data: stringField,
  });
  const fileImage = yup.object().shape({
    name: stringField.required('Required field.'),
    data: imageFileInput.required('Required field.'),
  });

  const signUpSchemaValidator = yup.object().shape({
    name: alphaLettersStringInput.required('First name is a required.'),
    surname: alphaLettersStringInput.required('Last name is a required.'),
    email: email.required('Email is a required field.'),
    contactMethodId: contactMethod.required(
      'Contact Method is a required field.'
    ),
    alias: documentType.required('Document type is a required field.'),
    registrationNumber: yup
      .string()
      .when('alias', {
        is: (alias) => alias === 'ID',
        then: documentIdInput.required('ID is a required field.'),
      })
      .when('alias', {
        is: (alias) => alias === 'PP',
        then: documentPassportInput.required(
          'Registration Number is a required field.'
        ),
      }),
    phone: phoneNumber.required('Phone number is a required field.'),
    password: password
      .min(8, 'Password must be greater or equal than 8 characters')
      .max(15, 'Password must be lower or equal than 15 characters'),
    passwordConfirmation: password
      .min(8, 'Password must be greater or equal than 8 characters')
      .max(15, 'Password must be lower or equal than 15 characters'),
    isCheck: isCheck.required('Required terms and conditions'),
  });

  const profileSchemaValidator = yup.object().shape({
    name: alphaLettersStringInput.required('First name is a required.'),
    surname: alphaLettersStringInput.required('Last name is a required.'),
    email: email.required('Email is a required field.'),
    contactMethodId: contactMethod,

    alias: documentType.required('Document type is a required field.'),
    registrationNumber: yup
      .string()
      .when('alias', {
        is: (alias) => alias === 'ID',
        then: documentIdInput.required('ID is a required field.'),
      })
      .when('alias', {
        is: (alias) => alias === 'PP',
        then: documentPassportInput.required(
          'Registration Number is a required field.'
        ),
      }),
    phone: phoneNumber.required('Phone number is a required field.'),
  });

  const boardmemberVotingSchemaValidator = yup.object().shape({
    hasVestedInterest: hasVestedInterest.required('Required field'),
    hasVestedType: yup.string().when('hasVestedInterest', {
      is: (hasVestedInterest) => hasVestedInterest === '1',
      then: hasVestedType.required('Required field.'),
      otherwise: yup.string().notRequired(),
    }),
    hasVestedTypeOther: yup
      .string()
      .when(['hasVestedInterest', 'hasVestedType'], {
        is: (hasVestedInterest, hasVestedType) =>
          hasVestedType === '4' && hasVestedInterest === '1',
        then: yup.string().required('Required field.'),
        otherwise: yup.string().notRequired(),
      }),
  });

  const recoveryPasswordSchema = yup.object().shape({
    email: email.required('Email is required'),
  });

  const resendEmailSchema = yup.object().shape({
    email: email.required('Email is required'),
  });

  const resetPasswordSchema = yup.object().shape({
    password: password
      .min(8, 'Password must be greater or equal than 8 characters')
      .max(15, 'Password must be lower or equal than 15 characters'),
    passwordConfirmation: password
      .min(8, 'Password must be greater or equal than 8 characters')
      .max(15, 'Password must be lower or equal than 15 characters'),
  });

  const changePasswordSchema = yup.object().shape({
    currentPassword: currentPassword,
    password: password
      .min(8, 'Password must be greater or equal than 8 characters')
      .max(15, 'Password must be lower or equal than 15 characters'),
    passwordConfirmation: password
      .min(8, 'Password must be greater or equal than 8 characters')
      .max(15, 'Password must be lower or equal than 15 characters'),
  });

  const personalInformationApplicationchemaValidator = yup.object().shape({
    name: alphaLettersStringInput.required('Applicant name is required.'),
    registrationNumber: documentIdInput.required(
      'ID / Registration Number is a required field.'
    ),
    phone: phoneNumber.required('Phone number is a required field.'),
    phoneAlternative: phoneNumber.nullable(true),
    email: email.required('Email is a required field.'),
    taxNumber: number.required('Tax Number is a required field.'),
    postalResidenceAddress: stringField.required(
      'Address is a required field.'
    ),
    oultetAddressSameAsResidence: checkbox,

    outletAddress: stringField.when('oultetAddressSameAsResidence', {
      is: (oultetAddressSameAsResidence) => !oultetAddressSameAsResidence,
      then: stringField.required('Outlet Address is a required field.'),
      otherwise: stringField.nullable(true),
    }),
    propocedOutletName: stringField.required(
      'Proposed Outlet Name is a required field.'
    ),
    rightOfOcuppation: yup
      .string()
      .oneOf(['1'], 'Right of occupation is mandatory.'),
    buildingNotErectedYet: yup
      .string()
      .oneOf(['0'], 'The Building must have been erected.'),
    erectedRequireAdditions: yup
      .string()
      .oneOf(['0', '1'], 'The Building must have been erected.'),
    erectedDoNotRequireAdditions: yup
      .string()
      .oneOf(['0', '1'], 'The Building must have been erected.'),
  });

  const documentsApplicationchemaValidator = yup.object().shape({
    typeOfRegistrationId: number.required('Required field.'),
    certifiedId: filePdfNotRequired.when('typeOfRegistrationId', {
      is: (typeOfRegistrationId) => typeOfRegistrationId == 1,
      then: filePdfDocuments.required('Required field.'),
      otherwise: filePdfNotRequired,
    }),
    businessDocument: filePdfNotRequired.when('typeOfRegistrationId', {
      is: (typeOfRegistrationId) => typeOfRegistrationId == 2,
      then: filePdfDocuments.required('Required field.'),
      otherwise: filePdfNotRequired,
    }),
    proofProvincialGovermentGazette:
      filePdfDocuments.required('Required field.'),
    affidavitVicinity: filePdfDocuments.required('Required field.'),
    validWorkPermit: filePdfNotRequired,
    detailedSketch: filePdfDocuments.required('Required field.'),
    proofOfPayment: filePdfDocuments.required('Required field.'),
    contributionCombatingAlcoholAbuse:
      filePdfDocuments.required('Required field.'),
    motivationSupport: filePdfDocuments.required('Required field.'),
    photo1: fileImageNotRequired.required('At least 3 photos are required.'),
    photo2: fileImageNotRequired.required('At least 3 photos are required.'),
    photo3: fileImageNotRequired.required('At least 3 photos are required.'),
    photo4: fileImageNotRequired.required('At least 3 photos are required.'),
    photo5: fileImageNotRequired.required('At least 3 photos are required.'),
    photos: fileImageNotRequired.when(
      ['photo1', 'photo2', 'photo3', 'photo4', 'photo5'],
      {
        is: (photo1, photo2, photo3, photo4, photo5) => {
          return (
            _.filter(
              [
                !photo1.data,
                !photo2.data,
                !photo3.data,
                !photo4.data,
                !photo5.data,
              ],
              Boolean
            ).length >= 3
          );
        },
        then: fileImage.required('At least 3 photos are required.'),
      }
    ),
    landUseRightsDocumentTypeId: number.required('Required field.'),
    townPlanningScheme: filePdfNotRequired.when('landUseRightsDocumentTypeId', {
      is: (landUseRightsDocumentTypeId) => landUseRightsDocumentTypeId === 1,
      then: filePdfDocuments.required('Required field.'),
      otherwise: filePdfNotRequired,
    }),
    foundingConditions: filePdfNotRequired.when('landUseRightsDocumentTypeId', {
      is: (landUseRightsDocumentTypeId) => landUseRightsDocumentTypeId === 2,
      then: filePdfDocuments.required('Required field.'),
      otherwise: filePdfNotRequired,
    }),
    titleDeedOfLand: filePdfNotRequired.when('landUseRightsDocumentTypeId', {
      is: (landUseRightsDocumentTypeId) => landUseRightsDocumentTypeId === 3,
      then: filePdfDocuments.required('Required field.'),
      otherwise: filePdfNotRequired,
    }),
    tribalResolution: filePdfNotRequired.when('landUseRightsDocumentTypeId', {
      is: (landUseRightsDocumentTypeId) => landUseRightsDocumentTypeId === 4,
      then: filePdfDocuments.required('Required field.'),
      otherwise: filePdfNotRequired,
    }),
    anyOtherSource: filePdfNotRequired.when('landUseRightsDocumentTypeId', {
      is: (landUseRightsDocumentTypeId) => landUseRightsDocumentTypeId === 5,
      then: filePdfDocuments.required('Required field.'),
      otherwise: filePdfNotRequired,
    }),
  });

  const createUserValidator = yup.object().shape({
    name: alphaLettersStringInput.required('First name is a required.'),
    surname: alphaLettersStringInput.required('Last name is a required.'),
    email: email.required('Email is a required field.'),

    phone: phoneNumber.required('Contact number is a required field.'),
  });

  const commentsValidator = yup.object().shape({
    textAreaComments: stringField
      .max(600, 'Comments must be lower or equal than 600 characters')
      .required('You have to send a comment.'),
  });

  const reportsValidator = yup.object().shape({
    sapsReport: filePdfNotRequired,
    municipalityReport: filePdfNotRequired,
    reports: filePdfNotRequired.when(['sapsReport', 'municipalityReport'], {
      is: (sapsReport, municipalityReport) =>
        !sapsReport.data && !municipalityReport.data,
      then: filePdf,
    }),
  });

  const inspectorReport = yup.object().shape({
    addLocation: isLocationAdded.required('Add your location is required'),
    comment: stringField.max(
      600,
      'Comments must be lower or equal than 600 characters'
    ),
    // socialDistancingOnePointFiveMeters: radioTrue.required(),
    // allowingCertainNumberOfTatrons: radioTrue.required(),
    // allPersonsInsideWearMask: radioTrue.required(),
    // sanitizingBeforeEntering: radioTrue.required(),
    // sanitizingBeforeForwardingCash: radioTrue.required(),
    // patronsNotAllowedToDrink: radioTrue.required(),
    // offenceNotKeepLicenceIssued: radioTrue.required(),
    // conductingBusinessWithoutDividingWall: radioTrue.required(),
    // structuralAlterationsWithoutConsent: radioTrue.required(),
    // failingLiquorConsumeOffThePremise: radioTrue.required(),
    // hasLicenceAppointedManager: radioTrue.required(),
    // keepLiquorSaleWhenNoPermited: radioTrue.required(),
    // sellingLiquorToIntoxicatedPerson: radioTrue.required(),
    // sellingOrEmployingMinors: radioTrue.required(),
    // controllingLeasingLiquorLicence: radioTrue.required(),
    // premiseSuitableForTheLiquorLicense: radioTrue.required(),
    // allowingPersonsUnderAge: radioTrue.required(),
    // deliveringLiquorWithoutDeliveryNotice: radioTrue.required(),
    // deliveringLiquorNonReflectedAddress: radioTrue.required(),
    // deliveringLiquorBeyondHours: radioTrue.required(),
    // sellingAlcoholOtherThanTableWine: radioTrue.required(),
    // unreasonableRefuseToCooperate: radioTrue.required(),
  });

  const logsSchemaValidator = yup.object().shape({
    topic: topicType.required('Topic is a required.'),
    referenceNumber: yup
      .string()
      .when('topic', {
        is: (topic) => topic === 'renewal',
        then: stringField.required(
          'Reference number is required for renewals.'
        ),
        otherwise: stringField.nullable(true),
      })
      .when('topic', {
        is: (topic) => topic === 'application',
        then: stringField.required(
          'Reference number is required for applications.'
        ),
        otherwise: stringField.nullable(true),
      }),
  });
  const renewalNoticeValidator = yup.object().shape({
    newAddressIfChanged: stringField.nullable(true),
    nameLicensedPremises: stringField.required('Required field.'),
    addressLicensedPremises: stringField.required('Required field.'),
    proofOfPayment: filePdf.required('Required field.'),
  });

  const renewalApproveValidator = yup.object().shape({
    amountReceived: number.required('Required field.'),
    receiptNumber: stringField.required('Required field.'),
  });

  const applicationFeeValidator = yup.object().shape({
    applicationFeeProofOfPayment: filePdf.required('Required field.'),
  });

  const applicationFeeApproveValidator = yup.object().shape({
    prescribedFees: naturalAmountNumber.nullable(true),
    amountReceived: stringField.when(
      ['prescribedFees'],
      (prescribedFees, schema, node) => {
        if (node.value === `${prescribedFees}`) {
          return naturalAmountNumber.required('Required field.');
        } else {
          return yup.lazy((value) =>
            value === ''
              ? stringField.required('Required field.')
              : naturalAmountNumber
                  .required('Required field.')
                  .max(0, 'Invalid amount')
          );
        }
      }
    ),
    receiptNumber: stringField.required('Required field.'),
  });

  const newBlogSchemaValidator = yup.object().shape({
    title: stringField.required('A Title is required.'),
    body: stringField,
  });
  return {
    loginValidatorSchema,
    signUpSchemaValidator,
    profileSchemaValidator,
    resendEmailSchema,
    recoveryPasswordSchema,
    resetPasswordSchema,
    changePasswordSchema,
    personalInformationApplicationchemaValidator,
    documentsApplicationchemaValidator,
    createUserValidator,
    commentsValidator,
    reportsValidator,
    inspectorReport,
    logsSchemaValidator,
    renewalNoticeValidator,
    renewalApproveValidator,
    applicationFeeValidator,
    applicationFeeApproveValidator,
    newBlogSchemaValidator,
    boardmemberVotingSchemaValidator,
  };
};

export default useValidators;
